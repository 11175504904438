import {
  ADD_PROFILE,
  LOADING,
  UPDATE_PROGRESS,
  ADD_EXPERIENCE,
  ADD_EDUCATION,
  LOAD_EXPERIENCE,
  LOAD_EDUCATION,
  LOAD_VERIFICATION,
  CELLPHONE_VALIDATED,
  GET_USERSPREFERENCE,
  UPDATED_PROFILE,
} from "../actions/types";

const initialState = {
  profile: {},
  isUploading: false,
  percentage: 0,
  experiences: [],
  education: [],
  verification: {},
  usersPreference: {},
  validation: false,
  updated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PROFILE:
      return {
        ...state,
        profile: action.payload,
        percentage: 100,
        isUploading: false,
      };

    case UPDATE_PROGRESS:
      return {
        ...state,
        percentage: action.payload,
      };
    case CELLPHONE_VALIDATED:
      return {
        ...state,
        validation: action.payload,
      };

    case LOAD_VERIFICATION:
      return {
        ...state,
        verification: action.payload,
      };

    case LOADING:
      return {
        ...state,
        isUploading: true,
      };

    case UPDATED_PROFILE:
      return {
        ...state,
        updated: action.payload,
      };

    case ADD_EXPERIENCE:
      return {
        ...state,
        experiences: [...state.experiences, action.payload],
      };
    case ADD_EDUCATION:
      return {
        ...state,
        education: [...state.education, action.payload],
      };
    case LOAD_EXPERIENCE:
      return {
        ...state,
        experiences: action.payload,
      };
    case LOAD_EDUCATION:
      return {
        ...state,
        education: action.payload,
      };
    case GET_USERSPREFERENCE:
      return {
        ...state,
        usersPreference: action.payload,
      };
    default:
      return state;
  }
}
