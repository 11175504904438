import React from "react";
import classnames from "classnames";
import propTypes from "prop-types";

// cambiar a objeto tipo
const SelectCiudad = ({
  paises,
  estados,
  ciudades,
  errorC,
  errorP,
  errorE,
  info,
  onChange,
  disabled,
  paisValue,
  estadoValue,
  ciudadValue
}) => {
  return (
    <div className="form-row">
      <div className="col-12">
        <div className="form-group">
          <label htmlFor="Pais">Pais</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errorP
            })}
            id="Pais"
            onChange={onChange}
            disabled={disabled}
            name="pais"
            value={paisValue}
          >
            <option value="0" key="999">
              Seleccione un pais
            </option>

            {paises.map((pais, i) => {
              return (
                <option value={pais._id} key={pais._id ? pais._id : i}>
                  {pais.nombre}
                </option>
              );
            })}
          </select>
          {errorP && <div className="invalid-feedback">{errorP}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="Estado">Estado</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errorE
            })}
            id="Estado"
            onChange={onChange}
            disabled={disabled}
            name="estado"
            value={estadoValue}
          >
            <option value="0" key="999">
              Seleccione un estado
            </option>
            {estados.map((estado, i) => {
              return (
                <option value={estado._id} key={estado._id ? estado._id : i}>
                  {estado.nombre}
                </option>
              );
            })}
          </select>
          {errorE && <div className="invalid-feedback">{errorE}</div>}
        </div>
        <div className="form-group">
          <label htmlFor="Ciuddad">Ciudad</label>
          <select
            className={classnames("form-control", {
              "is-invalid": errorC
            })}
            id="Ciuddad"
            onChange={onChange}
            disabled={disabled}
            name="ciudad"
            value={ciudadValue}
          >
            <option value="0" key="999">
              Seleccione una ciudad
            </option>
            {ciudades.map((ciudad, i) => {
              return (
                <option value={ciudad._id} key={ciudad._id ? ciudad._id : i}>
                  {ciudad.nombre}
                </option>
              );
            })}
          </select>
          {errorC && <div className="invalid-feedback">{errorC}</div>}
        </div>

        {info && <small className="fomr-text text-muted">{info}</small>}
      </div>
    </div>
  );
};

SelectCiudad.propTypes = {
  paises: propTypes.array.isRequired,
  estados: propTypes.array.isRequired,
  ciudades: propTypes.array.isRequired,
  errorC: propTypes.string,
  errorE: propTypes.string,
  errorP: propTypes.string,
  info: propTypes.string,
  paisValue: propTypes.string.isRequired,
  estadoValue: propTypes.string.isRequired,
  ciudadValue: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  disabled: propTypes.bool
};

SelectCiudad.defaultProps = {
  disabled: false
};

export default SelectCiudad;
