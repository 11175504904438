import axios from "axios";
import { GET_SAS, UPDATE_PROGRESS, GET_ERRORS, GET_DATA_FILES } from "./types";
const utils = require("../utils/utils");
const uuidv4 = require("uuid/v4");

export const uploadFiles = (fileInput, containerClient) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: 0,
    });
    const promises = [];
    for (const file of fileInput) {
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      promises.push(blockBlobClient.uploadBrowserData(file));
    }
    await Promise.all(promises);
    dispatch({
      type: UPDATE_PROGRESS,
      payload: 100,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const getSASUrl = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("api/azure/getSASUrl", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SAS,
        payload: res.data.url,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getFilesData = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: GET_DATA_FILES,
    payload: [],
  });

  axios
    .post("api/azure/listFiles", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_DATA_FILES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const deleteFile = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("api/azure/deleteFile", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_DATA_FILES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const uploadPicture = (fileInput, containerClient) => async (
  dispatch
) => {
  try {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: 0,
    });

    const blockBlobClient = containerClient.getBlockBlobClient(
      uuidv4() + ".jpeg"
    );

    const blob = await utils.b64toBlob(fileInput);

    await blockBlobClient.uploadBrowserData(blob);

    dispatch({
      type: UPDATE_PROGRESS,
      payload: 100,
    });
  } catch (error) {
    console.log(error.message);
  }
};
