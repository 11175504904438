import {
  LOAD_MEMBRESIAS,
  LOAD_ASOCIACIONES,
  EMTPTY_ASOCIACIONES,
  LOAD_TIPOASOCIACIONES,
  LOAD_SUBASOCIACIONES,
  LOAD_SUBASOCIACIONES2STAGE,
  ADD_SOLICITUD,
  CANCELAR_SOLICITUD,
  LOAD_MEMBRESIAACTIVA,
  LOAD_SOLICITUDESP,
  LOAD_SERVICIOS,
  UPDATED_INFO,
  SENT_INVITATION,
  ADD_SERVICIO,
  RESET_ADDED,
  PUBLICAR_COMUNICADO,
  GET_COMUNICADOS,
  GET_ACCESOS,
  GET_ULTIMOS_ACCESOS_DESTINO,
  GET_AREAS_COMUNES,
  GET_EVENTOS_AREA,
  LOAD_MEMBRESIAS_INACTIVAS,
  GET_ADMINISTRADORES,
  GET_TAREAS,
  GET_ESTADO_CUENTA,
} from "../actions/types";

const initialState = {
  membresias: null,
  asociacionesCiudad: [],
  tipoDeAsociaciones: null,
  subs: [],
  casas: [],
  solicitudes: [],
  solicitudesPendientes: [],
  cancelaciones: [],
  membresiaActiva: {},
  inactivas: [],
  servicios: [],
  updated: false,
  respuesta: {},
  added: false,
  comunicados: [],
  accesos: [],
  ultimosAccesosDestino: [],
  areas: [],
  eventosArea: [],
  administradores: [],
  tareas: [],
  estadoCuenta: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_MEMBRESIAS:
      return {
        ...state,
        membresias: action.payload,
      };
    case LOAD_MEMBRESIAS_INACTIVAS:
      return {
        ...state,
        inactivas: action.payload,
      };
    case LOAD_ASOCIACIONES:
      return {
        ...state,
        asociacionesCiudad: action.payload,
      };
    case GET_ESTADO_CUENTA:
      return {
        ...state,
        estadoCuenta: action.payload,
      };

    case GET_AREAS_COMUNES:
      return {
        ...state,
        areas: action.payload,
      };
    case GET_EVENTOS_AREA:
      return {
        ...state,
        eventosArea: action.payload,
      };

    case GET_ADMINISTRADORES:
      return {
        ...state,
        administradores: action.payload,
      };

    case EMTPTY_ASOCIACIONES:
      return {
        ...state,
        asociacionesCiudad: [],
      };
    case LOAD_TIPOASOCIACIONES:
      return {
        ...state,
        tipoDeAsociaciones: action.payload,
      };
    case LOAD_SUBASOCIACIONES:
      return {
        ...state,
        subs: action.payload,
      };
    case LOAD_SUBASOCIACIONES2STAGE:
      return {
        ...state,
        casas: action.payload,
      };
    case ADD_SOLICITUD:
      return {
        ...state,
        solicitudes: action.payload,
      };
    case CANCELAR_SOLICITUD:
      return {
        ...state,
        cancelaciones: [...state.cancelaciones, action.payload],
      };
    case LOAD_MEMBRESIAACTIVA:
      return {
        ...state,
        membresiaActiva: action.payload,
      };
    case LOAD_SOLICITUDESP:
      return {
        ...state,
        solicitudesPendientes: action.payload,
      };
    case LOAD_SERVICIOS:
      return {
        ...state,
        servicios: action.payload,
      };
    case PUBLICAR_COMUNICADO:
      return {
        ...state,
        comunicados: action.payload,
      };
    case GET_COMUNICADOS:
      return {
        ...state,
        comunicados: action.payload,
      };
    case GET_ACCESOS:
      return {
        ...state,
        accesos: action.payload,
      };
    case ADD_SERVICIO:
      return {
        ...state,
        servicios: action.payload,
        added: true,
      };
    case RESET_ADDED:
      return {
        ...state,
        added: false,
      };

    case GET_TAREAS:
      return {
        ...state,
        tareas: action.payload,
      };

    case UPDATED_INFO:
      return {
        ...state,
        updated: action.payload,
      };
    case SENT_INVITATION:
      return { ...state, respuesta: action.payload };
    case GET_ULTIMOS_ACCESOS_DESTINO:
      return {
        ...state,
        ultimosAccesosDestino: action.payload,
      };
    default:
      return state;
  }
}
