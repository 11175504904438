import React, { Component } from "react";

import { loadCuenta } from "../../actions/cuentaActions";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../css/tabla.css";

function getDateName(params) {
  switch (params) {
    case 1:
      return "enero";

    case 2:
      return "febrero";

    case 3:
      return "marzo";

    case 4:
      return "abril";

    case 5:
      return "mayo";

    case 6:
      return "junio";

    case 7:
      return "julio";

    case 8:
      return "agosto";

    case 9:
      return "septiembre";

    case 10:
      return "octubre";

    case 11:
      return "noviembre";
    case 12:
      return "diciembre";
    default:
      return params;
  }
}

class Cuenta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valores: {},
      busqueda: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    this.props.loadCuenta({
      asociacion: this.props.membresiaActiva.asociacion._id,
    });
  }

  render() {
    const { cuenta } = this.props.cuentas;

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Cuenta</h5>
                  <hr></hr>
                </div>
              </div>
              {this.props.cuentas && Object.keys(cuenta).length > 0 ? (
                <div className="row">
                  <div className="col-sm-6 cuenta">
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Número de cuenta
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.identificador}</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Nombre
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.nombre}</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Estatus
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.estatus.nombre}</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Saldo
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.saldo} MXN</h6>
                  </div>
                  <div className="col-sm-6 cuenta tx-right d-none d-md-block">
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Ingresos en {getDateName(new Date().getMonth() + 1)}
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.saldo} MXN</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Ingresos en {new Date().getFullYear()}
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.saldo} MXN</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Gastos en {getDateName(new Date().getMonth() + 1)}
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.saldo} MXN</h6>
                    <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                      Gastos en {new Date().getFullYear()}
                    </label>
                    <h6 className="tx-15 mg-b-10">{cuenta.saldo} MXN</h6>
                  </div>
                </div>
              ) : (
                <div className="timeline-item">
                  <div className="animated-background">
                    <div className="background-masker header-top"></div>
                    <div className="background-masker header-left"></div>
                    <div className="background-masker header-right"></div>
                    <div className="background-masker header-bottom"></div>
                    <div className="background-masker subheader-left"></div>
                    <div className="background-masker subheader-right"></div>
                    <div className="background-masker subheader-bottom"></div>
                    <div className="background-masker content-top"></div>
                    <div className="background-masker content-first-end"></div>
                    <div className="background-masker content-second-line"></div>
                    <div className="background-masker content-second-end"></div>
                    <div className="background-masker content-third-line"></div>
                    <div className="background-masker content-third-end"></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Cuenta.propTypes = {
  asociacion: PropTypes.object.isRequired,
  cuentas: PropTypes.object.isRequired,
  loadCuenta: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  cuentas: state.cuentas,
});

export default connect(mapStateToProps, { loadCuenta })(Cuenta);
