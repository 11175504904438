import axios from "axios";

import { GET_ERRORS, SEND_EMAIL, UPDATE_PROGRESS } from "./types";

// Register User
export const sendEmail = (userData) => (dispatch) => {
  dispatch({
    type: SEND_EMAIL,
    payload: false,
  });

  axios
    .post("/api/users/sendEmailInfo", userData, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: SEND_EMAIL,
        payload: res.data.enviado,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
