import axios from "axios";

import { LOAD_NOTIFICACIONES, UPDATE_PROGRESS, GET_ERRORS } from "./types";

export const loadNotificaciones = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  axios
    .get("/api/notificaciones/loadNotificaciones", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_NOTIFICACIONES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
