import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import classnames from "classnames";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import {
  loadMembresias,
  loadAccesosDestino,
} from "../../actions/asociacionActions";

import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";
import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

const startDate = new Date();
startDate.setDate(startDate.getDate() - 15);

class Ingresos extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      pagina: 1,
      paginaAccesos: 1,
      nombreFiltro: false,
      placasFiltro: false,
      identificacionFiltro: false,
      marbeteFiltro: true,
      busqueda: "",
      rangoStart: startDate,
      rangoEnd: new Date(),
    };
    this.onChange = this.onChange.bind(this);

    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
    this.toggleFiltro = this.toggleFiltro.bind(this);
    this.buscarAccesos = this.buscarAccesos.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (a, b) => {
    this.setState({
      rangoStart: b.startDate.zone("-06:00").format(),
      rangoEnd: b.endDate.zone("-06:00").format(),
    });

    if (a.type === "apply") {
      this.props.loadAccesosDestino({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: 1,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: b.startDate.zone("-06:00").format(),
        rangoEnd: b.endDate.zone("-06:00").format(),
      });

      this.setState({ paginaAccesos: 1 });
    }
  };

  adelantarPagina = (e) => {
    if (
      Math.ceil(this.props.asociacion.accesos[0]._id.total.count / 10) >
      this.state.paginaAccesos
    ) {
      this.setState(
        (prevState, e) => ({
          paginaAccesos: prevState.paginaAccesos + 1,
        }),
        () => {
          this.props.loadAccesosDestino({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            page: this.state.paginaAccesos,
            nombreFiltro: this.state.nombreFiltro,
            placasFiltro: this.state.placasFiltro,
            identificacionFiltro: this.state.identificacionFiltro,
            marbeteFiltro: this.state.marbeteFiltro,
            busqueda: this.state.busqueda,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
          });
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ paginaAccesos: e });
    this.props.loadAccesosDestino({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      page: e,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
    });
  };

  buscarAccesos = (e) => {
    this.setState({ paginaAccesos: 1 });
    this.props.loadAccesosDestino({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      page: 1,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
    });
  };

  toggleFiltro = (e, campo) => {
    this.setState({
      [campo]: e.target.checked,
    });
  };

  atrasarPagina = (e) => {
    if (this.state.paginaAccesos !== 1) {
      this.setState(
        (prevState, e) => ({
          paginaAccesos: prevState.paginaAccesos - 1,
        }),
        () => {
          this.props.loadAccesosDestino({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            page: this.state.paginaAccesos,
            nombreFiltro: this.state.nombreFiltro,
            placasFiltro: this.state.placasFiltro,
            identificacionFiltro: this.state.identificacionFiltro,
            marbeteFiltro: this.state.marbeteFiltro,
            busqueda: this.state.busqueda,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
          });
        }
      );
    }
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
    if (
      this.props.asociacion.accesos.length === 0 &&
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadAccesosDestino({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: this.state.paginaAccesos,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    /* if (
      this.props.asociacion.updated &&
      prevProps.asociacion.accesos !== this.props.asociacion.accesos
    ) {
      window.cerrrarModal("registrarAcceso");
      NotificationManager.success("", "Comunicado guardado exitosamente", 6000);
    } */

    if (
      this.props.asociacion.accesos.length === 0 &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadAccesosDestino({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: this.state.paginaAccesos,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;
    const { paginaAccesos } = this.state;
    const { accesos } = this.props.asociacion;

    let content;
    let modalContent;

    modalContent = "";

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="hint-text">
                          <h5>Ultimos accesos</h5>
                        </div>
                        <div style={{ display: "inline-flex" }}>
                          <input
                            className="form-control"
                            type="text"
                            style={{ minWidth: "100px", marginRight: "15px" }}
                            placeholder="Buscar"
                            name="busqueda"
                            onChange={this.onChange}
                            onKeyUp={() => {
                              this.buscarAccesos();
                            }}
                          />

                          <div className="btn-group">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ marginRight: "15px" }}
                            ></button>
                            <div
                              className="dropdown-menu"
                              style={{ padding: "15px" }}
                            >
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="dropdownCheck2"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "nombreFiltro")
                                  }
                                  checked={this.state.nombreFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="dropdownCheck2"
                                >
                                  Nombre
                                </label>
                              </div>
                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="placasFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "placasFiltro")
                                  }
                                  checked={this.state.placasFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="placasFiltroID"
                                >
                                  Placas
                                </label>
                              </div>
                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="identificacionFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "identificacionFiltro")
                                  }
                                  checked={this.state.identificacionFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="identificacionFiltroID"
                                >
                                  Identificación
                                </label>
                              </div>

                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="marbeteFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "marbeteFiltro")
                                  }
                                  checked={this.state.marbeteFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marbeteFiltroID"
                                >
                                  Marbete/Tarjetón
                                </label>
                              </div>
                            </div>
                          </div>

                          <DateRangePicker
                            onEvent={this.handleChange}
                            initialSettings={{
                              endDate: new Date(),
                              locale: {
                                format: "YYYY/MM/DD hh:mm A",
                                cancelLabel: "Cancelar",
                                applyLabel: "Buscar",
                                fromLabel: "de",
                                toLabel: "a",
                                daysOfWeek: [
                                  "Dom",
                                  "Lun",
                                  "Mar",
                                  "Mié",
                                  "Jue",
                                  "Vie",
                                  "Sáb",
                                ],
                                monthNames: [
                                  "Enero",
                                  "Febrero",
                                  "Marzo",
                                  "Abril",
                                  "Mayo",
                                  "Junio",
                                  "Julio",
                                  "Agosto",
                                  "Septiembre",
                                  "Octubre",
                                  "Noviembre",
                                  "Diciembre",
                                ],
                              },
                              startDate: startDate,
                              timePicker: true,
                            }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              style={{ minWidth: "380px", marginRight: "15px" }}
                              name="rangoDeFechas"
                              onChange={this.onChange}
                            />
                          </DateRangePicker>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr></hr>
                      </div>
                    </div>

                    {!accesos ||
                    accesos.length === 0 ||
                    accesos[0].registros.length === 0 ? (
                      <div className="row">
                        <div className="col-12">
                          Aún no hay ningún acceso registrado
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {accesos[0].registros.map((acceso) => {
                          return (
                            <div key={acceso._id} className="col-12 list-div">
                              <div className="titulo">
                                {acceso.visitante.nombre} &nbsp; &nbsp;
                                {acceso.visitante.placas} &nbsp; &nbsp;
                                {acceso.visitante.tipoTransporte} &nbsp; &nbsp;
                                {acceso.visitante.color} &nbsp; &nbsp;
                                {
                                  acceso.visitante.tipoDeIdentificacion.nombre
                                }{" "}
                                &nbsp; &nbsp;
                                {acceso.visitante.numeroDeIdentificacion} &nbsp;
                                &nbsp; Marbete/Tarjetón:{" "}
                                {acceso.visitante.referencia} &nbsp; &nbsp;
                              </div>
                              <div className="contenido">
                                <span
                                  className={classnames("", {
                                    "dot-warning":
                                      acceso.estatus.nombre === "Activo",
                                    "dot-ok":
                                      acceso.estatus.nombre === "Finalizado",
                                  })}
                                ></span>
                                Entró {timeAgo.format(new Date(acceso.created))}{" "}
                                &nbsp; &nbsp;
                                {acceso.tipo.nombre} &nbsp; &nbsp;
                                {acceso.destino.nombre} &nbsp; &nbsp;
                                {acceso.cluster.nombre} &nbsp; &nbsp;
                              </div>
                            </div>
                          );
                        })}
                        <div className="col-12">
                          {accesos &&
                            accesos.length > 0 &&
                            accesos[0]._id &&
                            accesos[0]._id.total.count > 0 && (
                              <ul className="pagination">
                                <li className="page-item">
                                  <button
                                    type="button"
                                    className="page-link"
                                    disabled={
                                      paginaAccesos === 1 ? true : false
                                    }
                                    onClick={() => {
                                      this.atrasarPagina();
                                    }}
                                  >
                                    Anterior
                                  </button>
                                </li>
                                {Array.from(
                                  {
                                    length: Math.min(
                                      10,
                                      Math.ceil(accesos[0]._id.total.count / 10)
                                    ),
                                  },
                                  (item, i) => {
                                    return (
                                      <li
                                        key={i}
                                        className={
                                          paginaAccesos === i + 1
                                            ? "page-item active"
                                            : "page-item"
                                        }
                                      >
                                        <button
                                          className="page-link"
                                          onClick={() =>
                                            this.actualizaPagina(i + 1)
                                          }
                                        >
                                          {i + 1}
                                        </button>
                                      </li>
                                    );
                                  }
                                )}

                                <li className="page-item">
                                  <button
                                    onClick={() => {
                                      this.adelantarPagina();
                                    }}
                                    className={
                                      Math.ceil(
                                        accesos[0]._id.total.count / 10
                                      ) <= paginaAccesos
                                        ? "page-link disabled"
                                        : "page-link"
                                    }
                                  >
                                    Siguiente
                                  </button>
                                </li>
                              </ul>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Ingresos</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Ingresos</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Ingresos.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,

  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadAccesosDestino: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  loadAccesosDestino,
})(Ingresos);
