import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

class CondominoInformacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { membresiaActiva } = this.props.asociacion;

    if (!membresiaActiva) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Información del fraccionamiento</h5>
                  <hr></hr>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4>
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .nombre
                      : membresiaActiva.asociacion.departamento[0].nombre}
                  </h4>
                  <h5>
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.calle
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .calle}{" "}
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.numero
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .numero}
                  </h5>

                  <h5>
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.colonia
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .colonia}
                  </h5>
                  <h5>
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.codigoPostal
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .codigoPostal}
                  </h5>
                  <h5>
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.ciudad.nombre
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .ciudad.nombre}
                    ,{" "}
                    {membresiaActiva.asociacion.departamento[0].asociacion
                      .length > 0
                      ? membresiaActiva.asociacion.departamento[0].asociacion[0]
                          .direccion.ciudad.estado.nombre
                      : membresiaActiva.asociacion.departamento[0].direccion
                          .ciudad.estado.nombre}
                  </h5>

                  <h5>
                    <strong>
                      {membresiaActiva.asociacion.departamento[0].nombre}
                    </strong>
                  </h5>
                  <h5>
                    {membresiaActiva.asociacion.tipo.nombre}{" "}
                    {membresiaActiva.asociacion.nombre}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CondominoInformacion.propTypes = {
  asociacion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(CondominoInformacion);
