import React from "react";
export default () => {
  return (
    <div
      className="modal-body modal-scroll"
      style={{
        marginTop: window.location.pathname === "/privacidad" ? "80px" : "0px",
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h4 className="center-align">
              <strong>AVISO DE PRIVACIDAD INTEGRAL PARA CLIENTES </strong>
            </h4>{" "}
            <p>&nbsp;</p>{" "}
            <ol id="l1">
              {" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>IDENTIDAD Y DOMICILIO DEL RESPONSABLE.</strong>
                  </u>
                  &nbsp;En cumplimiento a la Ley Federal de Protección de Datos
                  Personales en Posesión de los Particulares (LFPDP), su
                  Reglamento y los LINEAMIENTOS del Aviso de privacidad,&nbsp;
                  <strong>Wirhub, S.A. de C.V.</strong>, (en adelante “Wirhub”){" "}
                  {/* con domicilio en Avenida Juarez 2039, Colonia La Paz, Puebla, C.P. 72400, Puebla, Puebla, */}{" "}
                  en su carácter de Responsable pone a su disposición el
                  presente Aviso de Privacidad (en lo sucesivo referido como
                  “Aviso”), con el fin de informarle los términos conforme a los
                  cuales se tratarán los datos personales de usted el Titular de
                  los datos:
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>CONSENTIMIENTO DEL TITULAR.</strong>&nbsp;El Titular
                    manifiesta (i) que el presente Aviso le ha sido dado a
                    conocer por Wirhub ya sea de forma física, electrónica y/o
                    auditiva, y (ii) haber entendido y acordado los términos
                    expuestos en este Aviso, por lo que otorga su consentimiento
                    respecto del tratamiento de sus datos personales de acuerdo
                    a lo siguiente:
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <ol id="l2">
                  {" "}
                  <li>
                    {" "}
                    <p>
                      Consentimiento tácito. Wirhub se apega a la regla general
                      de “<u>Consentimiento tácito</u>”, por lo que se entenderá
                      consentido el presente Aviso, con la puesta a disposición
                      del mismo y la entrega de los datos del Titular.
                    </p>{" "}
                    <p>&nbsp;</p>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <p>
                      Consentimiento expreso. Únicamente en caso de que los
                      datos personales recopilados incluyan datos patrimoniales
                      o financieros, y/o impliquen la validación de su
                      credencial para votar ante el Instituto Nacional Electoral
                      (INE), Wirhub mediante la firma del contrato
                      correspondiente, sea en formato impreso, o utilizando
                      medios electrónicos y sus correspondientes procesos
                      obtendrá el “Consentimiento expreso” del Titular.
                    </p>{" "}
                    <p>&nbsp;</p>{" "}
                  </li>{" "}
                </ol>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>DATOS PERSONALES QUE RECABAMOS.&nbsp;</strong>Wirhub
                    puede recolectar datos personales del Titular mediante la
                    entrega directa y/o personal por cualquier medio de contacto
                    entre el Titular y el Responsable o sus Encargados.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Wirhub recaba de acuerdo al servicio solicitado datos
                  personales de identificación, datos financieros y/o
                  patrimoniales, académicos, laborales, así como datos
                  relacionados con la prestación de los servicios que brinda,
                  datos relacionados con la interacción del uso de las diversas
                  aplicaciones y sitios Web, y datos referentes al acceso y/o
                  uso de los servicios prestados.
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>FINALIDAD DE LOS DATOS PERSONALES.</strong>
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <u>
                    <strong>FINALIDADES PRIMARIAS.&nbsp;</strong>Wirhub tratará
                    los datos personales del Titular con la finalidad de llevar
                    a cabo las actividades y gestiones enfocadas al cumplimiento
                    de las obligaciones originadas y derivadas de cualquier
                    relación jurídica y/o comercial que establezca con motivo de
                    la prestación de sus servicios y/o venta de productos;
                    validar la veracidad de sus datos; corroborar su identidad a
                    través del servicio de verificación que ofrece el INE;
                    facturación; atención a clientes; servicio técnico; gestión
                    de servicios de valor agregado y contenidos; portabilidad;
                    reciclaje de terminales; administración de aplicaciones y
                    sitios Web; entrega y envío de productos o servicios;
                    contacto con el cliente, con la fuerza de ventas y
                    distribuidores; y proporcionar, renovar, fidelizar, activar,
                    cambiar, devolver o cancelar los servicios/productos que el
                    Titular solicite. Asimismo para actividades relacionadas con
                    el control de acceso y resguardo de los recursos materiales
                    y humanos dentro de las instalaciones del Responsable.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <u>
                    <strong>FINALIDADES SECUNDARIAS.</strong>
                  </u>
                  <strong>&nbsp;</strong>
                  <u>
                    Asimismo, Wirhub tratará datos personales para otras
                    finalidades: enviar notificaciones de ofertas, avisos y/o
                    mensajes promocionales; comunicaciones con fines de
                    mercadotecnia, publicidad o telemarketing sobre productos y
                    servicios nuevos o existentes ya sean propios o de socios
                    comerciales; realizar encuestas; estadísticas; estudios de
                    mercado con la finalidad de proporcionarles productos,
                    servicios, contenidos y publicidad acordes a sus
                    necesidades; registro sobre hábitos de consumo a través de
                    herramientas de captura automática de datos, intereses y
                    comportamiento; realizar programas de beneficios e
                    incentivos; participar en redes sociales, chats y/o foros de
                    discusión; participar en eventos, trivias, concursos,
                    rifas,&nbsp;juegos y sorteos; evaluar la calidad de los
                    servicios; y en general para cualquier actividad encaminada
                    a promover, mantener, mejorar y evaluar sus productos y
                    servicios.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  El Titular puede oponerse en cualquier momento al tratamiento
                  de sus datos para las finalidades secundarias a través de los
                  medios puestos a su disposición para el ejercicio de sus
                  derechos ARCO referidos en el numeral 7.
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>VIDEOVIGILANCIA Y FOTOGRAFIA.&nbsp;</strong>Toda
                    persona que ingrese a las instalaciones de Wirhub, podrá ser
                    videograbada y/o fotografiada por nuestras cámaras de
                    seguridad.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Las imágenes captadas por las cámaras del sistema de circuito
                  cerrado de televisión serán utilizadas para su seguridad y de
                  las personas que nos visitan, con el propósito de monitorear
                  vía remota los inmuebles y, confirmar en tiempo real cualquier
                  condición de riesgo para minimizarla. Asimismo, con el fin de
                  resguardar los recursos materiales y humanos dentro de
                  nuestras instalaciones. Las imágenes son destinadas a llevar a
                  cabo el control de acceso a nuestras instalaciones
                  corporativas.Las videograbaciones y/o fotografías son
                  almacenadas por un periodo de 25 días naturales.
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <strong>REMISIONES Y TRANSFERENCIAS DE DATOS.</strong>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <strong>De la REMISIÓN:</strong> Wirhub requiere remitir, y/o
                  permitir el acceso a los datos personales del Titular con el
                  objeto de dar cumplimiento a sus obligaciones jurídicas y/o
                  comerciales, para lo cual ha celebrado o celebrará diversos
                  acuerdos legales tanto en territorio nacional como en el
                  extranjero. Wirhub podrá comunicar los datos personales
                  recolectados a cualquier otra sociedad del mismo grupo
                  empresarial que opere con los mismos procesos y políticas
                  internas, sea que se encuentre en territorio nacional o en el
                  extranjero, para su tratamiento de acuerdo a las finalidades
                  descritas en este Aviso.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Su información personal puede remitirse, almacenarse y
                  procesarse en un país distinto, lo cual se llevará a cabo de
                  conformidad con las leyes de protección de datos aplicables.
                  Wirhub toma medidas para proteger la información personal sin
                  importar el país donde se almacena o a donde se remite.
                  Tenemos procedimientos y controles oportunos para procurar
                  esta protección.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Para el caso puntual de la finalidad de corroborar su
                  identidad a través del servicio de verificación que ofrece el
                  INE, los datos del Titular serán remitidos a través de la
                  figura de “Encargado” a dicho Instituto.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <strong>De la TRANSFERENCIA: </strong>Wirhub no requiere el
                  consentimiento del Titular para realizar transferencias de
                  datos personales nacionales o internacionales en los casos
                  previstos en el Artículo 37 de la LFPDP o en cualquier otro
                  caso de excepción previsto por la misma u otra legislación
                  aplicable.
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>
                      PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO Y REVOCACIÓN
                      DEL CONSENTIMIENTO.
                    </strong>{" "}
                    El Titular tiene, en todo momento, derecho de ejercer el
                    derecho de Acceso, Rectificación, Cancelación y Oposición
                    (en adelante referidos como derechos ARCO) respecto de sus
                    datos, así como a revocar el consentimiento que haya
                    proporcionado, presentando una solicitud en el formato que
                    para tal fin le entregaremos en los medios disponibles a
                    petición expresa, misma que debe contener la información y
                    documentación siguiente:
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <ol id="l3">
                  {" "}
                  <li>
                    {" "}
                    <p>
                      Nombre del Titular y domicilio o correo electrónico u otro
                      medio para comunicarle la respuesta a su solicitud;
                    </p>{" "}
                    <p>&nbsp;</p>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <p>
                      Documento vigente que acredite su identidad (copia simple
                      en formato impreso o formato digital, según el medio
                      elegido de su credencial para votar, cédula profesional,
                      pasaporte o VISA).
                    </p>{" "}
                    <p>&nbsp;</p>{" "}
                    <p>
                      En caso de que el ejercicio se realice a través de un
                      representante legal, se deberán adjuntar los documentos
                      con los que se acredita la representación legal del
                      Titular (copia simple en formato impreso o electrónico de
                      la carta poder simple con firma autógrafa del Titular, el
                      mandatario y sus correspondientes identificaciones
                      oficiales vigentes – credencial de para votar, cédula
                      profesional, pasaporte o VISA);
                    </p>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <p>
                      El derecho o derechos ARCO que desea ejercer, así como la
                      descripción clara y precisa de los datos respecto de los
                      que busca ejercer alguno de los Derechos ARCO, y;
                    </p>{" "}
                    <p>&nbsp;</p>{" "}
                  </li>{" "}
                  <li>
                    {" "}
                    <p>
                      Opcionalmente, cualquier otro elemento o documento que
                      facilite la localización de los datos personales del
                      Titular<i>.</i>
                    </p>{" "}
                  </li>{" "}
                </ol>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  En el caso de las solicitudes de rectificación de datos
                  personales, el Titular deberá también indicar las
                  modificaciones a realizarse y aportar la documentación que
                  sustente su petición<i>.</i>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Para la petición del formato, recepción y respuesta de las
                  solicitudes para ejercer sus derechos ARCO, la revocación de
                  su consentimiento y demás derechos previstos en la Ley ponemos
                  a su disposición a la Oficina de Protección de Datos
                  Personales, a través de los siguientes medios:
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <strong>Correo electrónico</strong>
                  <a
                    href="mailto:datos_personales@wirhub.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>:</strong>&nbsp;
                  </a>
                  <a
                    href="mailto:datos_personales@wirhub.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    datos_personales@wirhub.com
                  </a>
                  , con un horario de 9:00 a 16:00 horas de lunes a viernes, en
                  días hábiles.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  En caso de que la información proporcionada en su solicitud
                  sea errónea o insuficiente, o bien, no se acompañen los
                  documentos de acreditación correspondientes, podremos
                  solicitarle, dentro de los cinco días hábiles siguientes a la
                  recepción de la solicitud, que aporte los elementos o
                  documentos necesarios para dar trámite a la misma. El Titular
                  contará con diez días hábiles para atender el requerimiento,
                  contados a partir del día siguiente en que lo haya recibido.
                  De no dar respuesta en dicho plazo, se tendrá por no
                  presentada la solicitud correspondiente.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Wirhub responderá al Titular en un plazo máximo de veinte días
                  hábiles, contados desde la fecha efectiva en que se recibió la
                  solicitud a efecto de que, si resulta procedente, haga
                  efectiva la misma dentro de los quince días hábiles siguientes
                  a que se comunique la respuesta. En todos los casos, la
                  respuesta se dará por la misma vía por la que haya presentado
                  su solicitud o en su caso por cualquier otro medio acordado
                  con el Titular. Los plazos antes referidos podrán ser
                  ampliados en términos de la LFPDP<i>.</i>
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>
                      LIMITACIÓN DE USO Y DIVULGACIÓN DE LA INFORMACIÓN.&nbsp;
                    </strong>
                    Wirhub conservará los datos personales del Titular
                    únicamente durante el tiempo que sea necesario para procesar
                    sus solicitudes de información, productos y/o servicios, así
                    como para mantener los registros contables, financieros y de
                    auditoria en términos de la normativa en materia de
                    protección de datos personales, así como de la legislación
                    mercantil, fiscal y administrativa vigente.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Los datos personales recolectados se encontrarán protegidos
                  por medidas de seguridad administrativas, técnicas y físicas
                  adecuadas contra el daño, pérdida, alteración, destrucción o
                  uso, acceso o tratamiento no autorizados, de conformidad con
                  lo dispuesto en la legislación aplicable. No obstante lo
                  señalado, Wirhub no es infalible a un ataque por parte de
                  terceros no autorizados para obtener acceso a los sistemas
                  físicos o lógicos de los Titulares o del Responsable o en los
                  documentos electrónicos y archivos almacenados en sus
                  sistemas, en tal caso, Wirhub realizará las acciones oportunas
                  para controlar cualquier incidente, por lo que no será en
                  ningún caso responsable de los daños y perjuicios que pudieran
                  derivarse de dicho acceso no autorizado.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  El Titular o su representante legal debidamente acreditado
                  podrán limitar el uso o divulgación de sus datos personales, a
                  través de los mismos medios dispuestos para el ejercicio de
                  los Derechos ARCO, si su solicitud resulta procedente, será
                  registrado en el listado de exclusión dispuesto por Wirhub
                  para dejar de recibir información relativa a campañas
                  publicitarias o de mercadotecnia.
                </p>{" "}
                <p>
                  Asimismo, le asiste el derecho de inscribirse en el Registro
                  Público para Evitar Publicidad (REPEP) de la PROFECO&nbsp;
                  <u>
                    <a
                      href="http://repep.profeco.gob.mx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://repep.profeco.gob.mx
                    </a>
                  </u>
                </p>{" "}
                <p>
                  En caso de que el Titular considere que Wirhub ha vulnerado su
                  derecho a la protección de sus datos personales, puede acudir
                  al Instituto Nacional de Transparencia, Acceso a la
                  Información y Protección de Datos Personales (“INAI”).
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>
                      RECOLECCIÓN DE DATOS AL NAVEGAR EN SITIOS Y PÁGINAS WEB DE
                      Wirhub.
                    </strong>
                    &nbsp;Wirhub puede recabar datos a través de sus sitios Web,
                    o mediante el uso de herramientas de captura automática de
                    datos. Dichas herramientas le permiten recolectar la
                    información que envía su navegador a dichos sitios Web,
                    tales como el tipo de navegador que utiliza, el idioma de
                    usuario, los tiempos de acceso, y la dirección IP de sitios
                    Web que utilizó para acceder a los sitios del Responsable o
                    sus Encargados.
                  </u>
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  Dentro de las herramientas de captura automática de datos
                  utilizadas por Wirhub en sus sitios y páginas web se
                  encuentran las cookies, los Web beacons, y los enlaces en los
                  correos electrónicos.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <em>Uso de Cookies.-&nbsp;</em>El correcto funcionamiento de
                  los sitios de Wirhub requieren de la habilitación de “cookies”
                  en su navegador de Internet. Las "cookies" son pequeños
                  archivos de datos transferidos por el sitio Web al disco duro
                  de su computadora cuando navega por el sitio. En la mayoría de
                  los navegadores las cookies se aceptan automáticamente en
                  virtud de su configuración predeterminada, usted puede ajustar
                  las preferencias de su navegador para aceptar o rechazar las
                  cookies. La desactivación de las cookies puede inhabilitar
                  diversas funciones de los sitios web de Wirhub o que no se
                  muestren correctamente. En caso de que usted prefiera eliminar
                  las cookies, usted puede eliminar el archivo al final de cada
                  sesión del explorador. Si se inhabilitan las cookies del Sitio
                  web, nuestro Sitio no se cargará apropiadamente.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <em>Uso de Web beacons.-&nbsp;</em>También conocidos como
                  etiquetas de Internet, etiquetas de píxel y clear GIFs. Wirhub
                  puede utilizar en su sitios web y en sus correos electrónicos
                  con formato HTML los Web beacons, solos o en combinación con
                  las cookies, para recopilar información sobre el uso de los
                  sitios web y su interacción con el correo electrónico. El Web
                  beacon es una imagen electrónica, llamada de un solo píxel
                  (1x1) o GIF que puede reconocer información que es procesada
                  en su computadora, como el caso de las cookies, la hora y
                  fecha en que el sitio y sus secciones son visualizados.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <em>
                    Vínculos en los correos electrónicos de Wirhub.-&nbsp;
                  </em>
                  Los correos electrónicos pueden incluir vínculos que permiten
                  a Wirhub saber si usted activó dicho vínculo y visitó la
                  página web de destino, pudiendo esta información ser incluida
                  en su perfil. Asimismo, pueden incluir vínculos diseñados para
                  dirigirlo a las secciones relevantes de los sitios Web, al
                  re-direccionarlo a través de los servidores de Wirhub el
                  sistema de re-direccionamiento permite determinar la eficacia
                  de las campañas de marketing en línea.
                </p>{" "}
                <p>&nbsp;</p>{" "}
                <p>
                  <em>
                    Protección a menores, a personas en estado de interdicción o
                    incapacidad.-&nbsp;
                  </em>
                  Wirhub alienta a los padres y/o tutores a tomar un papel
                  activo en las actividades en línea de sus hijos o
                  representados. En caso de que considere que los datos
                  personales han sido proporcionados por un menor o por una
                  persona en estado de interdicción o incapacidad, en
                  contravención al presente Aviso, por favor inicie el proceso
                  para el ejercicio de su derecho de Cancelación, para efectos
                  de que Wirhub proceda a eliminar tales datos personales a la
                  brevedad.
                </p>{" "}
                <p>&nbsp;</p>{" "}
              </li>{" "}
              <li>
                {" "}
                <p>
                  <u>
                    <strong>CAMBIOS AL AVISO.&nbsp;</strong>Wirhub se reserva el
                    derecho de actualizar periódicamente el presente Aviso para
                    reflejar los cambios en sus prácticas de información. Es
                    responsabilidad del Titular revisar el contenido del Aviso
                    en el sitio
                    <a
                      href="http://www.Wirhub.com.mx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      &nbsp;
                    </a>
                    http://www.wirhub.com&nbsp;o solicitándolo al correo
                    electrónico
                    <a
                      href="mailto:datos_personales@wirhub.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      &nbsp;
                    </a>
                    <a
                      href="mailto:datos_personales@wirhub.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      datos_personales@wirhub.com
                    </a>
                    <a
                      href="mailto:datos_personales@wirhub.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      .
                    </a>
                  </u>
                </p>{" "}
              </li>{" "}
            </ol>{" "}
            <p>&nbsp;</p>{" "}
            <p className="center-align">
              <em>Última actualización: 10 de junio de 2020</em>
            </p>{" "}
            <p>&nbsp;</p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
