import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import classnames from "classnames";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import {
  loadMembresias,
  publicarComunicado,
  getComunicados,
  eliminaComunicado,
} from "../../actions/asociacionActions";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../css/profile.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { NotificationManager } from "react-notifications";
import { PermissibleRender } from "@brainhubeu/react-permissible";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class Comunicados extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
      editorState: EditorState.createEmpty(),
      tituloComunicado: "",
      edicion: false,
      comunicadoActivo: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  publicarComunicado = () => {
    this.props.publicarComunicado({
      titulo: this.state.tituloComunicado,
      contenido: convertToRaw(this.state.editorState.getCurrentContent()),
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      comunicado: this.state.comunicadoActivo,
    });
  };

  eliminarComunicado = () => {
    this.props.eliminaComunicado({
      comunicado: this.state.comunicadoActivo,
    });
  };

  editarComunicado = (titulo, content, comunicado) => {
    window.abrirModal("nuevoComunicado");
    this.setState({
      edicion: true,
      tituloComunicado: titulo,
      editorState: EditorState.createWithContent(convertFromRaw(content)),
      comunicadoActivo: comunicado,
    });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.tipo ===
          "Fraccionamiento"
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getComunicados({
        asociacion: asociacion,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.tipo ===
          "Fraccionamiento"
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getComunicados({
        asociacion: asociacion,
      });
    }

    if (
      this.state.tituloComunicado !== "" &&
      prevProps.asociacion.comunicados !== this.props.asociacion.comunicados
    ) {
      window.cerrrarModal("nuevoComunicado");
      this.setState({ tituloComunicado: "" });

      if (
        prevProps.asociacion.comunicados.length >
        this.props.asociacion.comunicados.length
      ) {
        NotificationManager.success(
          "",
          "Comunicado eliminado correctamente",
          6000
        );
      } else {
        NotificationManager.success(
          "",
          "Comunicado guardado exitosamente",
          6000
        );
      }
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;
    const { comunicados } = this.props.asociacion;
    const { editorState, edicion } = this.state;

    let content;
    let modalContent;

    modalContent = (
      <div>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="nuevoComunicado"
          id="nuevoComunicado"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {edicion ? "Editar " : "Nuevo "} comunicado
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                <div className="row">
                  <div className="col-12">
                    <div className="group inputPlaceholder form-group">
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.props.errors["tituloComunicado"],
                        })}
                        id="tituloComunicado"
                        name="tituloComunicado"
                        value={this.state.tituloComunicado}
                        onChange={this.onChange}
                        required
                      />
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label htmlFor="tituloComunicado">Título</label>
                    </div>
                  </div>
                </div>

                <Editor
                  editorState={editorState}
                  wrapperClassName="comunicado-wrapper"
                  editorClassName="comunicado-editor"
                  onEditorStateChange={this.onEditorStateChange}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                {edicion ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => this.eliminarComunicado()}
                  >
                    Eliminar
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.publicarComunicado()}
                >
                  {edicion ? "Guardar" : "Publicar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        this.props.asociacion &&
        this.props.asociacion.membresias &&
        this.props.asociacion.membresias.length > 0 &&
        this.props.asociacion.membresiaActiva &&
        this.props.asociacion.membresiaActiva.rol
      ) {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="hint-text">
                          <h5>Comunicados</h5>
                        </div>
                        <div>
                          <PermissibleRender
                            userPermissions={
                              this.props.asociacion.membresiaActiva.rol[0]
                                .permissions
                            }
                            requiredPermissions={["TODO", "COMUNICADOS_CREAR"]}
                            oneperm
                          >
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="modal"
                              data-target="#nuevoComunicado"
                              onClick={() => {
                                this.setState({
                                  edicion: false,
                                  tituloComunicado: "",
                                  editorState: EditorState.createEmpty(),
                                  comunicadoActivo: "",
                                });
                              }}
                            >
                              Nuevo comunicado
                            </button>
                          </PermissibleRender>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr></hr>
                      </div>
                    </div>

                    {!comunicados || comunicados.length === 0 ? (
                      <div className="row">
                        <div className="col-12">
                          Aún no hay ningún comunicado registrado
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <div className="list-group">
                            {comunicados.map((comunicado) => {
                              return (
                                <div
                                  key={comunicado._id}
                                  className="card card-waves mt-3"
                                >
                                  <div className="card-header">
                                    <div
                                      className="float-left"
                                      style={{
                                        display: "flex",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <strong>{comunicado.titulo}</strong>
                                    </div>

                                    <div className="float-right">
                                      {timeAgo.format(
                                        new Date(comunicado.updated)
                                      )}

                                      {this.props.asociacion.membresiaActiva
                                        .rol[0].title === "Condómino" ? (
                                        ""
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-secondary ml-3"
                                          onClick={() => {
                                            this.editarComunicado(
                                              comunicado.titulo,
                                              {
                                                entityMap: "",
                                                blocks:
                                                  comunicado.contenido.blocks,
                                              },
                                              comunicado._id
                                            );
                                          }}
                                        >
                                          Editar
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div
                                      className="py-10"
                                      dangerouslySetInnerHTML={{
                                        __html: draftToHtml(
                                          comunicado.contenido
                                        ),
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Comunicados</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Comunicados</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Comunicados.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  publicarComunicado: PropTypes.func.isRequired,
  getComunicados: PropTypes.func.isRequired,
  eliminaComunicado: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  publicarComunicado,
  getComunicados,
  eliminaComunicado,
})(Comunicados);
