import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../css/tabla.css";

class Solicitudes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.onClickAceptar = this.onClickAceptar.bind(this);
    this.onClickEliminar = this.onClickEliminar.bind(this);
  }

  onClickAceptar(e) {
    e.preventDefault();

    const data = {
      id: e.target.id,
      estatus: "5e0e68c3cd719b9cf5f0504a",
    };
    this.props.aceptarSolicitud(data);
  }

  onClickEliminar(e) {
    e.preventDefault();

    const data = {
      id: e.target.id,
      estatus: "5e01128003f8a31d5755f9c9",
    };
    this.props.cancelarSolicitud(data);
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { solicitudesPendientes } = this.props.asociacion;

    if (!solicitudesPendientes) {
      return <div>Loading...</div>;
    }

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Solicitudes pendientes</h5>
                  <hr></hr>
                </div>
                <div className="col-12">
                  {solicitudesPendientes.map((solicitud, i) => {
                    return (
                      <div
                        className="card-body d-flex align-items-center"
                        key={i}
                      >
                        <div className="d-inline-block p-2">
                          <img
                            src={`${window.location.protocol}//${
                              window.location.hostname
                            }:${window.location.port}/img/${
                              solicitud.profile.avatar
                                ? solicitud.profile.avatar
                                : "defaultUser.png"
                            }`}
                            className="rounded-circle"
                            style={{
                              width: "100px",
                              verticalAlign: "bottom",
                            }}
                          />
                        </div>
                        <div className="d-inline-block p-2">
                          <span className="d-block">
                            {solicitud.user.name} {solicitud.user.lastName}
                          </span>
                          <span className="d-block">
                            {solicitud.user.email}
                          </span>
                          <span className="d-block">
                            {solicitud.cluster && solicitud.cluster.nombre}
                          </span>
                          <span className="d-block">
                            {solicitud.solicitud.nombre}
                          </span>
                          <span className="d-block">{solicitud.rol.title}</span>
                        </div>
                        <div
                          className="d-inline-block p-2"
                          style={{ marginLeft: "auto" }}
                        >
                          <button
                            type="button"
                            className="btn btn-success"
                            id={solicitud._id}
                            onClick={this.onClickAceptar}
                          >
                            Agregar
                          </button>
                          <button
                            type="button"
                            className="btn btn-info ml-2"
                            id={solicitud._id}
                            onClick={this.onClickEliminar}
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Solicitudes.propTypes = {
  asociacion: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
});

export default connect(mapStateToProps, {})(Solicitudes);
