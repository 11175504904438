import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import draftToHtml from "draftjs-to-html";
import {
  loadMembresias,
  getTareas,
  getComunicados,
} from "../../actions/asociacionActions";
import { getCustomer } from "../../actions/stripeActions";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
/* import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"; */
TimeAgo.addLocale(es);

const timeAgo = new TimeAgo("es-MX");

let data = [
  {
    name: "Ene",
    ingresos: 0,
    egresos: 0,
    mes: 1,
  },
  {
    name: "Feb",
    ingresos: 0,
    egresos: 0,
    mes: 2,
  },
  {
    name: "Mar",
    ingresos: 0,
    egresos: 0,
    mes: 3,
  },
  {
    name: "Abr",
    ingresos: 0,
    egresos: 0,
    mes: 4,
  },
  {
    name: "May",
    ingresos: 0,
    egresos: 0,
    mes: 5,
  },
  {
    name: "Jun",
    ingresos: 0,
    egresos: 0,
    mes: 6,
  },
  {
    name: "Jul",
    ingresos: 0,
    egresos: 0,
    mes: 7,
  },
  {
    name: "Ago",
    ingresos: 0,
    egresos: 0,
    mes: 8,
  },
  {
    name: "Sept",
    ingresos: 0,
    egresos: 0,
    mes: 9,
  },
  {
    name: "Oct",
    ingresos: 0,
    egresos: 0,
    mes: 10,
  },
  {
    name: "Nov",
    ingresos: 0,
    egresos: 0,
    mes: 11,
  },
  {
    name: "Dic",
    ingresos: 0,
    egresos: 0,
    mes: 12,
  },
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      cuentaDatos: data,
    };
  }

  componentDidMount() {
    this.props.loadProfile();
    this.props.loadMembresias();
    this.props.getCustomer();
    this.props.getCurrentPage({ page: window.location.pathname });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.departamento[0]
            .asociacion.length > 0
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getTareas({
        asociacion: asociacion,
      });

      this.props.getComunicados({
        asociacion: asociacion,
      });
    }

    if (
      this.props.estadistica &&
      this.props.estadistica.cuenta !== prevProps.estadistica.cuenta
    ) {
      this.props.estadistica.cuenta.ingresos.map((a) => {
        return (data.filter((b) => b.mes === a.mes)[0].ingresos = a.suma);
      });

      this.props.estadistica.cuenta.egresos.map((a) => {
        return (data.filter((b) => b.mes === a.mes)[0].egresos = a.suma);
      });

      this.setState({ cuentaDatos: data });
    }
  }

  render() {
    const { percentage } = this.props.profile;
    const { casas, usuarios, cuotas, clusters } = this.props.estadistica;
    const { tareas, comunicados } = this.props.asociacion;

    let dashboardContent;

    if (
      !this.props.asociacion ||
      !this.props.asociacion.membresias ||
      !this.props.estadistica ||
      (this.props.asociacion.membresiaActiva &&
        this.props.asociacion.membresiaActiva.rol &&
        this.props.asociacion.membresiaActiva.rol[0]._id ===
          "5f0f3a3909ee3c02e0f38b7a")
    ) {
      dashboardContent = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        dashboardContent = (
          <div className="container">
            <div className="row">
              <div className="col-12 pt-5 pb-3">
                <h4>Avisos y comunicados</h4>
              </div>
            </div>

            {!comunicados || comunicados.length === 0 ? (
              <div className="row">
                <div className="col-12">
                  Aún no hay ningún comunicado registrado
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="list-group">
                    {comunicados.map((comunicado) => {
                      return (
                        <div
                          key={comunicado._id}
                          className="card card-waves mt-3"
                        >
                          <div className="card-header">
                            <div
                              className="float-left"
                              style={{
                                display: "flex",
                                marginTop: "10px",
                              }}
                            >
                              <strong>{comunicado.titulo}</strong>
                            </div>

                            <div className="float-right">
                              {timeAgo.format(new Date(comunicado.updated))}
                            </div>
                          </div>
                          <div className="card-body">
                            <div
                              className="py-10"
                              dangerouslySetInnerHTML={{
                                __html: draftToHtml(comunicado.contenido),
                              }}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 pt-4 pb-4">
                <h4>Actividades en progreso</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <h5 class="card-header">Pendientes</h5>
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                          <div class="list-group w-100">
                            {tareas &&
                              tareas
                                .filter((tarea) => tarea.estatus === "TODO")
                                .map((tarea, i) => (
                                  <a
                                    href="#"
                                    class="list-group-item list-group-item-action"
                                    aria-current="true"
                                    key={tarea._id}
                                  >
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">{tarea.nombre}</h5>
                                      <small>
                                        Creada{" "}
                                        {timeAgo.format(
                                          new Date(tarea.created)
                                        )}
                                      </small>
                                    </div>
                                    <p class="mb-1">{tarea.descripcion}</p>
                                  </a>
                                ))}
                            {tareas &&
                            tareas.filter((tarea) => tarea.estatus === "TODO")
                              .length === 0 ? (
                              <p class="mb-1">
                                No hay ninguna tarea registrada
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <h5 class="card-header">En proceso</h5>
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                          <div class="list-group w-100">
                            {tareas &&
                              tareas
                                .filter(
                                  (tarea) => tarea.estatus === "IN_PROGRESS"
                                )
                                .map((tarea, i) => (
                                  <a
                                    href="#"
                                    class="list-group-item list-group-item-action"
                                    aria-current="true"
                                    key={tarea._id}
                                  >
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">{tarea.nombre}</h5>
                                      <small>
                                        Creada{" "}
                                        {timeAgo.format(
                                          new Date(tarea.created)
                                        )}
                                      </small>
                                    </div>
                                    <p class="mb-1">{tarea.descripcion}</p>
                                  </a>
                                ))}
                            {tareas &&
                            tareas.filter(
                              (tarea) => tarea.estatus === "IN_PROGRESS"
                            ).length === 0 ? (
                              <p class="mb-1">
                                No hay ninguna tarea registrada
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <h5 class="card-header">Finalizadas</h5>
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between">
                          <div class="list-group w-100">
                            {tareas &&
                              tareas
                                .filter((tarea) => tarea.estatus === "DONE")
                                .map((tarea, i) => (
                                  <a
                                    href="#"
                                    class="list-group-item list-group-item-action"
                                    aria-current="true"
                                    key={tarea._id}
                                  >
                                    <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1">{tarea.nombre}</h5>
                                      <small>
                                        Creada{" "}
                                        {timeAgo.format(
                                          new Date(tarea.created)
                                        )}
                                      </small>
                                    </div>
                                    <p class="mb-1">{tarea.descripcion}</p>
                                  </a>
                                ))}

                            {tareas &&
                            tareas.filter((tarea) => tarea.estatus === "DONE")
                              .length === 0 ? (
                              <p class="mb-1">
                                No hay ninguna tarea registrada
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*             <div className="row">
              <div className="col-12 pt-5 pb-4">
                <h4>Ingresos del mes</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mb-3 col-md-3">
                <div className="widget-indicators">
                  <div className="indicator-item">
                    <div className="indicator-item-icon">
                      <div className="icon">
                        <span className="s7-graph1"></span>
                      </div>
                    </div>
                    <div className="indicator-item-value">
                      <span
                        className="indicator-value-counter"
                        data-toggle="counter"
                        data-end="36"
                      >
                        {usuarios}
                      </span>
                      <div className="indicator-value-title">Usuarios</div>
                    </div>
                  </div>
                  <div className="indicator-item">
                    <div className="indicator-item-icon">
                      <div className="icon">
                        <span className="s7-graph"></span>
                      </div>
                    </div>
                    <div className="indicator-item-value">
                      <span
                        className="indicator-value-counter"
                        data-toggle="counter"
                        data-end="157"
                      >
                        {clusters}
                      </span>
                      <div className="indicator-value-title">Casas</div>
                    </div>
                  </div>

                  <div className="indicator-item">
                    <div className="indicator-item-icon">
                      <div className="icon">
                        <span className="s7-cart"></span>
                      </div>
                    </div>
                    <div className="indicator-item-value">
                      <span
                        className="indicator-value-counter"
                        data-toggle="counter"
                        data-decimals="2"
                        data-end="78,450"
                        data-prefix="$"
                      >
                        {cuotas}
                      </span>
                      <div className="indicator-value-title">Cuotas</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-9">
                <div className="card">
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h5>Cuenta</h5>
                          <hr></hr>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart
                              data={this.state.cuentaDatos}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="ingresos"
                                stroke="#8884d8"
                                activeDot={{ r: 8 }}
                              />
                              <Line
                                type="monotone"
                                dataKey="egresos"
                                stroke="#82ca9d"
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           */}
          </div>
        );
      } else {
        dashboardContent = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Dashboard</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/profile">Perfil</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Dashboard</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {dashboardContent}
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  loadProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  estadistica: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  asociacion: PropTypes.object.isRequired,
  getTareas: PropTypes.func.isRequired,
  getComunicados: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  asociacion: state.asociacion,
  estadistica: state.estadistica,
});

export default connect(mapStateToProps, {
  loadProfile,
  getCurrentPage,
  loadMembresias,
  getCustomer,
  getTareas,
  getComunicados,
})(Dashboard);
