import React from "react";
import classnames from "classnames";
import propTypes from "prop-types";

const TextFieldGroup = ({
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  label,
  id
}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <div>
        <input
          type={type}
          className={classnames("form-control form-control-line", {
            "is-invalid": error
          })}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          id={id}
        />
        {info && <small className="fomr-text text-muted">{info}</small>}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: propTypes.string.isRequired,
  placeholder: propTypes.string,
  value: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  error: propTypes.string,
  info: propTypes.string,
  type: propTypes.string,
  onChange: propTypes.func.isRequired,
  disabled: propTypes.bool
};

TextFieldGroup.defaultProps = {
  type: "text",
  disabled: false
};

export default TextFieldGroup;
