import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { logoutUser, getProfilePicture } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";
import { loadMembresia } from "../../actions/asociacionActions";
import { loadNotificaciones } from "../../actions/notificacionActions";
import { Link } from "react-router-dom";
import img1 from "../../images/logo.png";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      membresiaActivaID: "",
    };

    this.onClickFrac = this.onClickFrac.bind(this);
  }

  onClickFrac = (e) => {
    e.preventDefault();
    this.setState({ membresiaActivaID: e.target.id });
    this.props.loadMembresia({ membresia: e.target.id });
    localStorage.setItem(this.props.auth.user.id, e.target.id);
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated && this.props.auth.user.id) {
      this.props.getProfilePicture();
      this.props.loadNotificaciones();

      if (localStorage.getItem(this.props.auth.user.id)) {
        const membresiaLocal = localStorage.getItem(this.props.auth.user.id);
        this.setState({
          membresiaActivaID: membresiaLocal,
        });
        this.props.loadMembresia({ membresia: membresiaLocal });
      } else {
        if (
          this.props.asociacion &&
          this.props.asociacion.membresias &&
          this.props.asociacion.membresias.length > 0
        ) {
          localStorage.setItem(
            this.props.auth.user.id,
            this.props.asociacion.membresias[0]._id
          );
          this.setState({
            membresiaActivaID: this.props.asociacion.membresias[0]._id,
          });
          const asociacion = {};
          asociacion.membresia = this.props.asociacion.membresias[0]._id;
          this.props.loadMembresia(asociacion);
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.asociacion.membresias !== prevProps.asociacion.membresias &&
      this.props.auth.isAuthenticated
    ) {
      if (localStorage.getItem(this.props.auth.user.id)) {
        const membresiaLocal = localStorage.getItem(this.props.auth.user.id);
        this.setState({
          membresiaActivaID: membresiaLocal,
        });
        this.props.loadMembresia({ membresia: membresiaLocal });
      } else {
        if (
          this.props.asociacion &&
          this.props.asociacion.membresias.length > 0
        ) {
          localStorage.setItem(
            this.props.auth.user.id,
            this.props.asociacion.membresias[0]._id
          );
          this.setState({
            membresiaActivaID: this.props.asociacion.membresias[0]._id,
          });
          const asociacion = {};
          asociacion.membresia = this.props.asociacion.membresias[0]._id;
          this.props.loadMembresia(asociacion);
        }
      }
    }
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { membresias } = this.props.asociacion;
    const { notificaciones } = this.props.notificaciones;
    const { membresiaActivaID } = this.state;

    const path = window.location.pathname;

    const authLinks = (
      <ul className="navbar-nav text-uppercase ml-auto">
        <li className="nav-item">
          <div className="pRelative">
            <a
              href="#"
              className="header-icon"
              data-toggle="dropdown"
              title="No tienes ninguna notificación"
              aria-expanded="false"
            >
              <i className="fas fa-bell"></i>
              {notificaciones &&
                notificaciones[0] &&
                notificaciones[0].pendientes && (
                  <span className="badge badge-icon">
                    {notificaciones[0].pendientes.numero}
                  </span>
                )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-animated dropdown-xl dropdown-menu-left"
              x-placement="top-start"
            >
              <div className="nHeader">
                <div className="rfloat vCenter">
                  <span>Marcar todas como leídas</span>
                </div>

                <div>
                  <h3 className="nTitle">Notificaciones</h3>
                </div>
              </div>
              <div className="nBody">
                {notificaciones &&
                notificaciones[0] &&
                notificaciones[0].lista &&
                notificaciones[0].lista.length > 0 ? (
                  notificaciones[0].lista.map((notificacion) => {
                    return (
                      <div
                        className="notificacionElement"
                        key={notificacion._id}
                      >
                        <div className="_4l_v">
                          <span>
                            <span className="tituloNotificacion">
                              {notificacion.titulo}
                            </span>
                            <span className="contenidoNotificacion">
                              {notificacion.texto}
                            </span>
                          </span>
                          <div className="_33f clearfix" direction="left">
                            <div className="_ohe lfloat"></div>
                            <div className="">
                              <div className="_42ef _8u">
                                <span className="_6t8b">
                                  <span className="timestampContent">
                                    {timeAgo.format(
                                      new Date(notificacion.created)
                                    )}
                                  </span>

                                  <span></span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="notificacionElement">
                    <div className="_4l_v">
                      <span>
                        <span className="tituloNotificacion">
                          No tienes ninguna notificación
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="nFooter">
                <Link to="/notificaciones">
                  <span>Ver todas</span>
                </Link>
              </div>
            </div>
          </div>
        </li>
        {membresias && membresias.length > 0 && (
          <li className="nav-item" style={{ alignSelf: "center" }}>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle btn-navbar"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {membresias.filter((el) => el._id === membresiaActivaID)
                  .length === 0
                  ? ""
                  : membresias
                      .filter((el) => el._id === membresiaActivaID)[0]
                      .asociacion.nombre.substr(0, 23)}
              </button>

              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                {membresias.map((el, i) => {
                  return (
                    <button
                      className="dropdown-item"
                      type="button"
                      key={el._id}
                      id={el._id}
                      onClick={this.onClickFrac}
                    >
                      {el.asociacion.nombre}
                    </button>
                  );
                })}
              </div>
            </div>
          </li>
        )}
        <li className="nav-item" style={{ alignSelf: "center" }}>
          <a
            href="#"
            onClick={this.onLogoutClick.bind(this)}
            className="nav-link"
            color="#00bfd8"
          >
            <img
              className="rounded-circle"
              src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/img/${this.props.auth.profilePicture}`}
              alt={user.name}
              style={{ width: "25px", marginRight: "5px" }}
              title="Foto de perfil"
            />{" "}
            Logout
          </a>
        </li>
      </ul>
    );

    const guestLinks =
      path.indexOf("/confirmation") === -1 &&
      path.indexOf("/recovery") === -1 &&
      window.location.pathname.indexOf("/privacidad") === -1 ? (
        <ul className="navbar-nav text-uppercase ml-auto">
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#header">
              Inicio <span className="sr-only">(current)</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link page-scroll" href="#services">
              Servicios
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#precios">
              Precios
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#acerca">
              Acerca
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link page-scroll" href="#contacto">
              Contacto
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link registro page-scroll" href="#register">
              Registrase
            </a>
          </li>
          <li className="nav-item">
            <span className="nav-item social-icons">
              <span className="fa-stack">
                <a
                  href="https://www.facebook.com/wirhub"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-circle fa-stack-2x facebook"></i>
                  <i className="fab fa-facebook-f fa-stack-1x"></i>
                </a>
              </span>
              <span className="fa-stack">
                <a
                  href="https://twitter.com/WirhubOficial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-circle fa-stack-2x twitter"></i>
                  <i className="fab fa-twitter fa-stack-1x"></i>
                </a>
              </span>
            </span>
          </li>
        </ul>
      ) : (
        ""
      );

    return (
      <nav
        className={classnames("navbar navbar-expand-lg fixed-top", {
          "navbar-custom": !isAuthenticated,
          "navbar-dark": isAuthenticated,
        })}
      >
        {isAuthenticated && (
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Menu"
          >
            <span className="navbar-toggler-awesome fas fa-bars"></span>
          </button>
        )}

        <Link
          className="navbar-brand logo-image"
          to="/"
          style={{ paddingTop: "0px" }}
        >
          <img src={img1} alt="Wirhub" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Opciones"
        >
          <span className="navbar-toggler-awesome fa fa-ellipsis-v"></span>
          <span className="navbar-toggler-awesome fas fa-times"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresia: PropTypes.func.isRequired,
  getProfilePicture: PropTypes.func.isRequired,
  loadNotificaciones: PropTypes.func.isRequired,
  notificaciones: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  asociacion: state.asociacion,
  notificaciones: state.notificaciones,
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile,
  getProfilePicture,
  loadMembresia,
  loadNotificaciones,
})(Navbar);
