import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../css/tabla.css";

class InformacionAsociacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
  }

  conClickAgregarCluster(e) {
    e.preventDefault();
    window.abrirModal("agregarClusterModal");
  }

  componentDidMount() {}

  adelantarPagina = (e) => {
    if (
      Math.ceil(
        this.props.asociacion.membresiaActiva.asociacion.departamentos.length /
          5
      ) > this.state.pagina
    ) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina + 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ pagina: parseInt(e.currentTarget.dataset.id, 10) });
  };

  atrasarPagina = (e) => {
    if (this.state.pagina !== 1) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina - 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { membresiaActiva } = this.props.asociacion;
    const { filasSeleccionadas, celdaActiva } = this.props;
    const { pagina, busqueda } = this.state;

    if (!membresiaActiva) {
      return <div>Loading...</div>;
    }

    return (
      <div className="col-12">
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand">Clústers</a>
          <form className="form-inline">
            <a
              href="#agregarClusterModal"
              className="btn btn-success btn-tabla"
              data-toggle="modal"
            >
              <i className="material-icons">&#xE147;</i>{" "}
              <span>Agregar {this.props.nombre}</span>
            </a>
            <a
              href="#deleteObjectModal"
              className={
                filasSeleccionadas.length === 0
                  ? "btn btn-danger btn-tabla disabledBtn"
                  : "btn btn-danger btn-tabla"
              }
              data-toggle="modal"
            >
              <i className="material-icons">&#xE15C;</i> <span>Eliminar</span>
            </a>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
              name="busqueda"
              onChange={this.onChange}
            />
          </form>
        </nav>
        <div className="card">
          <div className="card-body">
            <div className="container list-asociaciones">
              <div className="row">
                {membresiaActiva.asociacion &&
                membresiaActiva.asociacion.departamentos.length > 0 ? (
                  <div className="col-12">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>
                            <span className="custom-checkbox">
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={(e) =>
                                  this.props.handleChangeChkAll(e, this.check)
                                }
                              />
                              <label htmlFor="selectAll" />
                            </span>
                          </th>
                          <th>Nombre</th>
                          <th>Estatus</th>
                          <th>Casas</th>
                          <th>% de pago</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {membresiaActiva.asociacion.departamentos
                          .filter((depa) =>
                            busqueda
                              ? depa.nombre
                                  .toUpperCase()
                                  .includes(busqueda.toUpperCase())
                              : true
                          )
                          .map((fila, index) => {
                            if (
                              index + 1 <= pagina * 5 &&
                              index + 1 > pagina * 5 - 5
                            )
                              return (
                                <tr
                                  key={fila._id}
                                  className={
                                    fila._id === celdaActiva
                                      ? "table-active pointerFila"
                                      : "pointerFila"
                                  }
                                  onClick={() => this.props.clicTabla(fila._id)}
                                  onDoubleClick={() =>
                                    this.props.dblClicTabla(fila._id)
                                  }
                                >
                                  <td>
                                    <span className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name={fila._id}
                                        ref={(ref) => (this.check[index] = ref)}
                                        value="1"
                                        onChange={this.props.handleChangeChk}
                                      />
                                      <label htmlFor="checkbox1" />
                                    </span>
                                  </td>
                                  <td>{fila.nombre}</td>
                                  <td>{fila.estatus.nombre}</td>
                                  <td>{fila.areas.length}</td>
                                  <td>0</td>
                                  <td>
                                    <a
                                      href="#editInstanciaModelo"
                                      className="edit"
                                      data-toggle="modal"
                                      onClick={() => {
                                        this.props.onClickEditar(fila._id);
                                      }}
                                    >
                                      <i
                                        className="material-icons"
                                        data-toggle="tooltip"
                                        title="Editar"
                                      >
                                        &#xE254;
                                      </i>
                                    </a>
                                    <a
                                      href="#deleteObjectModal"
                                      className="delete"
                                      data-toggle="modal"
                                      onClick={() => {
                                        this.props.onClickEliminar(fila._id);
                                      }}
                                    >
                                      <i
                                        className="material-icons"
                                        data-toggle="tooltip"
                                        title="Eliminar"
                                      >
                                        &#xE872;
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            else return "";
                          })}
                      </tbody>
                    </table>

                    <div className="clearfix">
                      <div className="hint-text">
                        Mostrando{" "}
                        <b>
                          {Math.ceil(
                            membresiaActiva.asociacion.departamentos.filter(
                              (depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                            ).length / 5
                          ) === pagina
                            ? membresiaActiva.asociacion.departamentos.filter(
                                (depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                              ).length %
                                5 ===
                              0
                              ? 5
                              : membresiaActiva.asociacion.departamentos.filter(
                                  (depa) =>
                                    busqueda
                                      ? depa.nombre
                                          .toUpperCase()
                                          .includes(busqueda.toUpperCase())
                                      : true
                                ).length % 5
                            : membresiaActiva.asociacion.departamentos.filter(
                                (depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                              ).length === 0
                            ? "0"
                            : "5"}
                        </b>{" "}
                        de{" "}
                        <b>
                          {
                            membresiaActiva.asociacion.departamentos.filter(
                              (depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                            ).length
                          }
                        </b>{" "}
                        registros
                      </div>
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className={
                              pagina === 1 ? "page-link disabled" : "page-link"
                            }
                            onClick={this.atrasarPagina}
                          >
                            Anterior
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              membresiaActiva.asociacion.departamentos.filter(
                                (depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                              ).length / 5
                            ),
                          },
                          (item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  pagina === i + 1
                                    ? "page-item active"
                                    : "page-item"
                                }
                              >
                                <button
                                  className="page-link"
                                  onClick={this.actualizaPagina}
                                  data-id={i + 1}
                                >
                                  {i + 1}
                                </button>
                              </li>
                            );
                          }
                        )}

                        <li className="page-item">
                          <button
                            onClick={this.adelantarPagina}
                            className={
                              Math.ceil(
                                membresiaActiva.asociacion.departamentos.filter(
                                  (depa) =>
                                    busqueda
                                      ? depa.nombre
                                          .toUpperCase()
                                          .includes(busqueda.toUpperCase())
                                      : true
                                ).length / 5
                              ) <= pagina
                                ? "page-link disabled"
                                : "page-link"
                            }
                          >
                            Siguiente
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    Aún no tienes clústers registrados,{" "}
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={this.conClickAgregarCluster}
                    >
                      agregar el primero!
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InformacionAsociacion.propTypes = {
  asociacion: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
});

export default connect(mapStateToProps, {})(InformacionAsociacion);
