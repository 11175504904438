import {
  GET_ESTADISTICA_USUARIOS,
  GET_ESTADISTICA_CLUSTERS,
  GET_ESTADISTICA_CUOTAS,
  GET_ESTADISTICA_CUENTA,
  GET_ESTADISTICA_CASAS,
} from "../actions/types";

const initialState = {
  casas: 0,
  usuarios: 0,
  cuotas: 0,
  clusters: 0,
  cuenta: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ESTADISTICA_USUARIOS:
      return {
        ...state,
        usuarios: action.payload,
      };

    case GET_ESTADISTICA_CLUSTERS:
      return {
        ...state,
        clusters: action.payload,
      };

    case GET_ESTADISTICA_CUOTAS:
      return {
        ...state,
        cuotas: action.payload,
      };

    case GET_ESTADISTICA_CUENTA:
      return {
        ...state,
        cuenta: action.payload,
      };

    case GET_ESTADISTICA_CASAS:
      return {
        ...state,
        casas: action.payload,
      };

    default:
      return state;
  }
}
