import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { loadPagosRealizados } from "../../actions/cuentaActions";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

const fechaInicio = new Date();
fechaInicio.setDate(fechaInicio.getDate() - 365);

class CondominoPagosRealizados extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      rangoStart: fechaInicio,
      rangoEnd: new Date(),
      pagina: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
  }

  handleChange = (a, b) => {
    this.setState({
      rangoStart: b.startDate.zone("-06:00").format(),
      rangoEnd: b.endDate.zone("-06:00").format(),
    });

    if (a.type === "apply") {
      this.props.loadPagosRealizados({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: 1,
        rangoStart: b.startDate.zone("-06:00").format(),
        rangoEnd: b.endDate.zone("-06:00").format(),
      });

      this.setState({ pagina: 1 });
    }
  };

  adelantarPagina = (e) => {
    if (
      Math.ceil(this.props.cuentas.pagos[0]._id.total.count / 10) >
      this.state.pagina
    ) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina + 1,
        }),
        () => {
          this.props.loadPagosRealizados({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
            page: this.state.pagina,
          });
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ pagina: e });
    this.props.loadPagosRealizados({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
      page: e,
    });
  };

  atrasarPagina = (e) => {
    if (this.state.pagina !== 1) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina - 1,
        }),
        () => {
          this.props.loadPagosRealizados({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
            page: this.state.pagina,
          });
        }
      );
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.asociacion &&
      this.props.asociacion !== prevProps.asociacion &&
      this.props.asociacion.membresiaActiva
    ) {
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        this.props.loadPagosRealizados({
          asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
          page: 1,
          rangoStart: this.state.rangoStart,
          rangoEnd: this.state.rangoEnd,
        });
        this.setState({ pagina: 1 });
      }
    }
  }

  render() {
    const { membresiaActiva } = this.props.asociacion;
    const { pagos } = this.props.cuentas;
    const { rangoStart, pagina } = this.state;

    if (!membresiaActiva) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between border-bottom pb-3">
                  <div className="hint-text">
                    <h5>Últimos pagos</h5>
                  </div>
                  <div style={{ display: "inline-flex" }}>
                    <DateRangePicker
                      onEvent={this.handleChange}
                      initialSettings={{
                        endDate: new Date(),
                        startDate: rangoStart,
                        timePicker: true,
                        locale: {
                          format: "YYYY/MM/DD hh:mm A",
                          cancelLabel: "Cancelar",
                          applyLabel: "Buscar",
                          fromLabel: "de",
                          toLabel: "a",
                          daysOfWeek: [
                            "Dom",
                            "Lun",
                            "Mar",
                            "Mié",
                            "Jue",
                            "Vie",
                            "Sáb",
                          ],
                          monthNames: [
                            "Enero",
                            "Febrero",
                            "Marzo",
                            "Abril",
                            "Mayo",
                            "Junio",
                            "Julio",
                            "Agosto",
                            "Septiembre",
                            "Octubre",
                            "Noviembre",
                            "Diciembre",
                          ],
                        },
                      }}
                    >
                      <input className="form-control search-date" type="text" />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 pt-3">
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <thead className="border-bottom">
                        <tr className="small text-uppercase text-muted">
                          <th scope="col" style={{ width: "40%" }}>
                            Concepto
                          </th>
                          <th
                            className="text-right"
                            style={{ width: "20%" }}
                            scope="col"
                          >
                            Estatus
                          </th>
                          <th
                            className="text-right"
                            style={{ width: "20%" }}
                            scope="col"
                          >
                            Tipo
                          </th>
                          <th
                            className="text-right"
                            style={{ width: "20%" }}
                            scope="col"
                          >
                            Monto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pagos.length > 0 &&
                          pagos[0].registros.length > 0 &&
                          pagos[0].registros.map((pago) => {
                            return (
                              <tr className="border-bottom" key={pago._id}>
                                <td>
                                  <div className="font-weight-bold">
                                    {pago.identificador}
                                  </div>
                                  <div className="small text-muted d-none d-md-block">
                                    Pagado{" "}
                                    {timeAgo.format(new Date(pago.created))}
                                  </div>
                                  <div className="small text-muted d-none d-md-block">
                                    {pago.concepto.map((concepto) => {
                                      return (
                                        <div key={concepto._id}>
                                          {concepto.concepto}{" "}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </td>
                                <td className="text-right">
                                  {pago.estatus.nombre}
                                </td>
                                <td className="text-right">
                                  {pago.tipo.nombre}
                                </td>
                                <td className="text-right font-weight-bold">
                                  $ {pago.monto} MXN
                                </td>
                              </tr>
                            );
                          })}

                        <tr>
                          <td className="text-right pb-0" colSpan="3">
                            <div className="text-uppercase small font-weight-700 text-muted">
                              Total:
                            </div>
                          </td>
                          <td className="text-right pb-0">
                            <div className="h5 mb-0 font-weight-700 text-green">
                              $
                              {pagos.length > 0 && pagos[0].registros.length > 0
                                ? pagos[0].registros.reduce(
                                    (sum, { monto }) => sum + monto,
                                    0
                                  )
                                : "0"}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  {pagos &&
                    pagos.length > 0 &&
                    pagos[0]._id &&
                    pagos[0]._id.total.count > 0 && (
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            type="button"
                            className="page-link"
                            disabled={pagina === 1 ? true : false}
                            onClick={() => {
                              this.atrasarPagina();
                            }}
                          >
                            Anterior
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.min(
                              10,
                              Math.ceil(pagos[0]._id.total.count / 10)
                            ),
                          },
                          (item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  pagina === i + 1
                                    ? "page-item active"
                                    : "page-item"
                                }
                              >
                                <button
                                  className="page-link"
                                  onClick={() => this.actualizaPagina(i + 1)}
                                >
                                  {i + 1}
                                </button>
                              </li>
                            );
                          }
                        )}

                        <li className="page-item">
                          <button
                            onClick={() => {
                              this.adelantarPagina();
                            }}
                            className={
                              Math.ceil(pagos[0]._id.total.count / 10) <= pagina
                                ? "page-link disabled"
                                : "page-link"
                            }
                          >
                            Siguiente
                          </button>
                        </li>
                      </ul>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CondominoPagosRealizados.propTypes = {
  asociacion: PropTypes.object.isRequired,
  cuentas: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loadPagosRealizados: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  cuentas: state.cuentas,
  asociacion: state.asociacion,
  errors: state.errors,
});

export default connect(mapStateToProps, { loadPagosRealizados })(
  CondominoPagosRealizados
);
