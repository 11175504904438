import React from "react";
import img1 from "../../images/video-frame.svg";
import img2 from "../../images/testimonials-2-men-talking.svg";
import img3 from "../../images/testimonial-1.svg";
import img4 from "../../images/testimonial-2.svg";
import img5 from "../../images/testimonial-3.svg";

export default () => {
  return (
    <div id="acerca">
      <div className="slider-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-container">
                <img className="img-fluid" src={img2} alt="alternative" />
              </div>
            </div>
            <div className="col-lg-6">
              <h2>Testimonios</h2>

              <div className="slider-container">
                <div className="swiper-container card-slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="card">
                        <img
                          className="card-image"
                          src={img3}
                          alt="alternative"
                        />
                        <div className="card-body">
                          <p className="testimonial-text">
                            Llevaba un control en excel de los pagos, y siempre
                            tuve dificultades para dar reportes y recibos de
                            todo el dinero que administraba, con Wirhub se
                            acabaron mis problemas, ahora es muy facil entregar
                            cuenta de cada pago y gasto que se realiza.
                          </p>
                          <p className="testimonial-author">
                            Gerardo Estrada - Fraccionamiento San Francisco
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="card">
                        <img
                          className="card-image"
                          src={img4}
                          alt="alternative"
                        />
                        <div className="card-body">
                          <p className="testimonial-text">
                            Wirhub me ayudo a darle transparencia a los
                            condóminos sobre cada peso que entra y sale del
                            fraccionamiento, esto hizo que el número de casas al
                            corriente con sus cuotas de mantenimiento aumentara.
                          </p>
                          <p className="testimonial-author">
                            María López - Fraccionamiento Del pilar
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="swiper-slide">
                      <div className="card">
                        <img
                          className="card-image"
                          src={img5}
                          alt="alternative"
                        />
                        <div className="card-body">
                          <p className="testimonial-text">
                            Me pareció muy bien tener notificaciones si alguien
                            llega de visita mientas yo no estoy, y poder ver que
                            se hace con el dinero que pagamos.
                          </p>
                          <p className="testimonial-author">
                            Ricardo Ibarra - Residencial Tres Cruces
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="basic-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Caso de estudio</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="image-container">
                <div className="video-wrapper">
                  <a
                    className="popup-youtube"
                    href="https://www.youtube.com/watch?v=ahsWmQIYYpc"
                    data-effect="fadeIn"
                  >
                    <img className="img-fluid" src={img1} alt="alternative" />
                    <span className="video-play-button">
                      <span></span>
                    </span>
                  </a>
                </div>
              </div>

              <p>
                Este video te mostrará un caso de estudio del fraccionamiento
                Bosque de Sanctorum, uno de nuestros{" "}
                <strong>grandes clientes</strong> y te ayudara a entender de que
                forma <strong>Wirhub</strong> te puede ayudar.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
