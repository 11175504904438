import React, { Component, Fragment } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import InformacionAsociacion from "../fraccionamiento/InformacionAsociacion";
import AdministracionUsuarios from "../fraccionamiento/AdministracionUsuarios";
import Cuotas from "../fraccionamiento/Cuotas";
import Cuenta from "../fraccionamiento/Cuenta";
import Solicitudes from "../fraccionamiento/Solicitudes";
import Informacion from "../fraccionamiento/Informacion";
import CondominoInformacion from "../fraccionamiento/CondominoInformacion";
import CondominoCuotas from "../fraccionamiento/CondominoCuotas";
import InformacionCluster from "../fraccionamiento/InformacionCluster";
import InformacionCasa from "../fraccionamiento/InformacionCasa";
import img1 from "../../img/default-wirhub.png";
import img2 from "../../img/community.png";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import {
  getSubscriptions,
  asociarMembresia,
} from "../../actions/stripeActions";
import {
  loadCountries,
  loadStates,
  loadCities,
  loadRolAsociacion,
  loadStatus,
  loadTipoServicioCuota,
  loadFrecuencia,
  loadRolesAdmon,
} from "../../actions/catalogoActions";
import {
  loadMembresias,
  loadAsociacionesCiudad,
  emptyAsociaciones,
  addAsociacion,
  loadTipoDeAsociaciones,
  busquedaFraccionamiento,
  loadSubsAsociacion,
  agregarSolicitud,
  loadSolicitudes,
  cambiarStatusMembresia,
  agregarSubasociacion,
  eliminarSubasociacion,
  loadSolicitudesPendientes,
  loadServiciosAsociacion,
  sendInvitation,
  addServicio,
  deleteServicio,
  actualizaNombreAsociacion,
  eliminaMembresia,
  loadMembresiasInactivas,
  loadMembresiasAdministradores,
} from "../../actions/asociacionActions";

import { getCurrentPage } from "../../actions/pageActions";
import Spinner from "../common/Spinner";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";
import TextFieldGroup from "../utils/TextFielGroup";
import SelectCiudad from "../utils/SelectCiudad";
import Subscribe from "./Subscribe";
import "./estilos.css";

registerLocale("es", es);

const dateInicio = new Date();
dateInicio.setHours(0, 0, 0, 0);

class Fraccionamiento extends Component {
  constructor() {
    super();
    this.state = {
      suscripcionID: "0",
      membresiaInactivaID: "0",
      errors: {},
      cargando: false,
      botonGuardar: "Guardar",
      nombreCuota: "",
      montoCuota: "",
      tipoServicioCuota: "0",
      planSelect: "0",
      estatusCuota: "0",
      ciudadValue: "0",
      ciudadAValue: "0",
      diasSinCargo: "",
      cargoPagoEx: "",
      paisValue: "0",
      estadoValue: "0",
      tipoDeAsociacion: "0",
      inputNombreFraccionamiento: "",
      modalAbierto: 0,
      subAsociacion: "0",
      casa: "0",
      rol: "0",
      asociacionMembresia: "",
      celdaActiva: null,
      valores: {},
      filaSelecionada: null,
      usuarioSeleccionado: null,
      casaSelecionada: null,
      filasSeleccionadas: [],
      asociacionesCuotaSeleccionadas: [],
      clusterSeleccionado: "",
      identificadorCasa: "",
      identificadorCluster: "",
      nombreCasa: "",
      nombreCluster: "",
      startDate: dateInicio,
      endDate: null,
      casasAbierto: false,
      clusterCuotaSeleccinado: "",
      checkCuotaAll: false,
      sinFinCheck: false,
      frecuenciaCuota: "0",
      tipoCuotaModal: { tipo: "" },
      tipoEdicion: "Clúster",
      pagina: 1,
      activeStep: 1,
      filasSeleccionadasAdmon: [],
      paginaAdmon: 1,
      celdaActivaAdmon: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitUnirse = this.onSubmitUnirse.bind(this);
    this.onSubmitBusqueda = this.onSubmitBusqueda.bind(this);
    this.onClickUnirse = this.onClickUnirse.bind(this);
    this.onClickCerrar = this.onClickCerrar.bind(this);
    this.onClickCancelar = this.onClickCancelar.bind(this);

    this.onSubmitCrearAsociacion = this.onSubmitCrearAsociacion.bind(this);
    this.onSubmitAgregarCluster = this.onSubmitAgregarCluster.bind(this);
    this.onSubmitAgregarCasa = this.onSubmitAgregarCasa.bind(this);

    this.clicTabla = this.clicTabla.bind(this);

    this.clicTablaAdmon = this.clicTablaAdmon.bind(this);
    this.dblClicTablaAdmon = this.dblClicTablaAdmon.bind(this);
    this.onClickEliminarAdmon = this.onClickEliminarAdmon.bind(this);
    this.onClickEditarAdmon = this.onClickEditarAdmon.bind(this);
    this.handleChangeChkAdmon = this.handleChangeChkAdmon.bind(this);
    this.handleChangeChkAllAdmon = this.handleChangeChkAllAdmon.bind(this);
    this.onClickConfirmacionEliminarAdmon = this.onClickConfirmacionEliminarAdmon.bind(
      this
    );

    this.onClickEliminar = this.onClickEliminar.bind(this);
    this.onClickAddUser = this.onClickAddUser.bind(this);
    this.onClickEditar = this.onClickEditar.bind(this);
    this.onClickEditarCasa = this.onClickEditarCasa.bind(this);
    this.onClickEliminarCasa = this.onClickEliminarCasa.bind(this);
    this.handleChangeChk = this.handleChangeChk.bind(this);
    this.handleChangeChkAll = this.handleChangeChkAll.bind(this);
    this.onClickConfirmacionEliminar = this.onClickConfirmacionEliminar.bind(
      this
    );
    this.onSubmitAgregarCuota = this.onSubmitAgregarCuota.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.onClickMostrarCasas = this.onClickMostrarCasas.bind(this);
    this.handleCuotasCheck = this.handleCuotasCheck.bind(this);
    this.onSubmitEnviarInvitacion = this.onSubmitEnviarInvitacion.bind(this);
    this.onClickEliminarCuota = this.onClickEliminarCuota.bind(this);
    this.onSubmitEditarNombre = this.onSubmitEditarNombre.bind(this);

    this.handleOnClickStepper = this.handleOnClickStepper.bind(this);
    this.handleOnClickNext = this.handleOnClickNext.bind(this);
    this.handleOnClickBack = this.handleOnClickBack.bind(this);
    this.handleOnClickStart = this.handleOnClickStart.bind(this);
  }

  clicTablaAdmon = (e) => {
    this.setState({ celdaActivaAdmon: e });
  };
  dblClicTablaAdmon = () => {};
  onClickEliminarAdmon = (e) => {
    this.setState({ usuarioSeleccionado: e });
  };
  onClickEditarAdmon = () => {};
  handleChangeChkAdmon = () => {};
  handleChangeChkAllAdmon = () => {};
  onClickConfirmacionEliminarAdmon = () => {
    this.props.eliminaMembresia({
      membresiaID: this.state.usuarioSeleccionado,
      membresia: this.props.asociacion.membresiaActiva._id,
    });

    this.props.loadMembresiasAdministradores({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
    });

    this.setState({ usuarioSeleccionado: null });
  };

  handleOnClickStart = () => {
    this.props.asociarMembresia({
      suscripcionID: this.state.suscripcionID,
      membresiaInactivaID: this.state.membresiaInactivaID,
    });
  };

  handleOnClickStepper = (step) => {
    this.setState({ activeStep: step });
  };

  handleOnClickNext = () => {
    let nextStep =
      this.state.activeStep + 1 === 4 ? 3 : this.state.activeStep + 1;
    this.setState({ activeStep: nextStep });
  };

  handleOnClickBack = () => {
    let prevStep =
      this.state.activeStep - 1 === 1 ? 1 : this.state.activeStep - 1;
    this.setState({ activeStep: prevStep });
  };

  onClickMostrarCasas = (e) => {
    this.setState({ casasAbierto: !this.state["casasAbierto"] }, () => {
      this.setState({
        clusterCuotaSeleccinado: this.state["casasAbierto"] ? e : -1,
      });
    });
  };

  clicTabla = (e) => {
    this.setState({ celdaActiva: e, filaSelecionada: e });
  };

  onSubmitEnviarInvitacion = (e) => {
    e.preventDefault();
    const invitation = {
      nombreInvitacion: this.state.nombreInvitacion,
      apellidoInvitacion: this.state.apellidoInvitacion,
      correoInvitacion: this.state.correoInvitacion,
      asociacion: this.state.casaSelecionada,
    };
    this.props.sendInvitation(invitation);
  };

  onSubmitEditarNombre = (e) => {
    e.preventDefault();

    this.props.actualizaNombreAsociacion({
      asociacion:
        this.state.tipoEdicion === "Clúster"
          ? this.state.filaSelecionada
          : this.state.casaSelecionada,
      nombre: this.state.nombreAsociacionEdicion,
      membresia: this.props.asociacion.membresiaActiva._id,
    });

    window.cerrrarModal("editInstanciaModelo");
  };

  clicTablaCuota = (e) => {
    this.setState({ clusterCuotaSeleccinado: e });
  };

  onClickEliminarCuota = (e) => {
    e.preventDefault();

    this.props.deleteServicio({
      servicio: this.state.tipoCuotaModal.servicio,
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
    });
  };

  handleDateChange = (date) => {
    date.setHours(0, 0, 0, 0);
    this.setState({
      startDate: date,
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  onSubmitAgregarCuota = (e) => {
    e.preventDefault();

    const data = {
      nombre: this.state.nombreCuota,
      costo: this.state.montoCuota + "",
      tipo:
        this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ==
        "Fraccionamiento"
          ? this.state.tipoServicioCuota
          : "5e6c644d770b738a1477715f",
      estatus: this.state.estatusCuota,
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      suscriptores: this.state.asociacionesCuotaSeleccionadas,
      maxDiasCargo: this.state.diasSinCargo + "",
      cargo: this.state.cargoPagoEx + "",
      inicio: this.state.startDate,
      fin: this.state.endDate,
      frecuenciaCuota: this.state.frecuenciaCuota,
      servicio: this.state.tipoCuotaModal.servicio
        ? this.state.tipoCuotaModal.servicio
        : undefined,
    };

    this.props.addServicio(data);
  };

  dblClicTabla = (e) => {
    window.abrirModal("casasModal");
    this.setState({ clusterSeleccionado: e });
  };

  handleChangeChk = (e) => {
    if (e.target.checked) {
      let s = this.state.filasSeleccionadas;
      s.unshift(e.target.name);
      this.setState({ filasSeleccionadas: s });
    } else {
      let s = this.state.filasSeleccionadas;
      const removeIndex = s.indexOf(e.target.name);
      s.splice(removeIndex, 1);
      this.setState({ filasSeleccionadas: s });
    }
  };

  handleCuotasCheck = (e) => {
    let total = 0;

    this.props.asociacion.membresiaActiva.asociacion.departamentos.map(
      (depa) => {
        total++;
        depa.areas.map((casa) => total++);
      }
    );

    if (e.target.checked) {
      let s = this.state.asociacionesCuotaSeleccionadas;
      s.unshift(e.target.name);
      this.setState({ asociacionesCuotaSeleccionadas: s });
      this.setState({ checkCuotaAll: s.length === total ? true : false });
    } else {
      let s = this.state.asociacionesCuotaSeleccionadas;
      const removeIndex = s.indexOf(e.target.name);
      s.splice(removeIndex, 1);
      this.setState({ asociacionesCuotaSeleccionadas: s });
      this.setState({ checkCuotaAll: false });
    }
  };

  handleCuotasDepartamentosCheck = (e) => {
    let total = 0;

    this.props.asociacion.membresiaActiva.asociacion.departamentos.map(
      (depa) => {
        total++;
        depa.areas.map((casa) => total++);
      }
    );

    if (e.target.checked) {
      let s = this.state.asociacionesCuotaSeleccionadas;
      s.unshift(e.target.name);
      this.props.asociacion.membresiaActiva.asociacion.departamentos
        .filter((depa) => depa._id === e.target.name)[0]
        .areas.map((casa) => {
          s.unshift(casa._id);
        });
      this.setState({ asociacionesCuotaSeleccionadas: s });
      this.setState({ checkCuotaAll: s.length === total ? true : false });
    } else {
      let s = this.state.asociacionesCuotaSeleccionadas;
      let removeIndex = s.indexOf(e.target.name);
      s.splice(removeIndex, 1);

      this.props.asociacion.membresiaActiva.asociacion.departamentos
        .filter((depa) => depa._id === e.target.name)[0]
        .areas.map((casa) => {
          removeIndex = s.indexOf(casa._id);
          s.splice(removeIndex, 1);
        });

      this.setState({ asociacionesCuotaSeleccionadas: s });
      this.setState({ checkCuotaAll: false });
    }
  };

  handleChangeChkAll = (e, check) => {
    if (e.target.checked) {
      check.forEach((a) => {
        if (a !== null) {
          a.checked = true;
          let s = this.state.filasSeleccionadas;
          s.unshift(a.name);
          this.setState({ filasSeleccionadas: s });
        }
      });
    } else {
      check.forEach((a) => {
        if (a !== null) {
          a.checked = false;
          let s = this.state.filasSeleccionadas;
          const removeIndex = s.indexOf(a.name);
          s.splice(removeIndex, 1);
          this.setState({ filasSeleccionadas: s });
        }
      });
    }
  };

  onClickEliminarMembresia = (e) => {
    this.props.eliminaMembresia({
      membresiaID: e,
      membresia: this.props.asociacion.membresiaActiva._id,
    });
  };

  onClickEliminar = (e) => {
    this.setState({ filaSelecionada: e, tipoEdicion: "Clúster" });
  };

  onClickEditar = (e) => {
    this.setState({ filaSelecionada: e, tipoEdicion: "Clúster" });
  };

  onClickEliminarCasa = (e) => {
    this.setState({ casaSelecionada: e, tipoEdicion: "Casa" });
  };

  onClickEditarCasa = (e) => {
    this.setState({ casaSelecionada: e, tipoEdicion: "Casa" });
  };

  onClickAddUser = (e) => {
    this.setState({ casaSelecionada: e });
  };

  setTipoCuotaModal = (e) => {
    this.setState({ tipoCuotaModal: e });
  };

  onClickConfirmacionEliminar() {
    let data = {};
    data.membresia = this.props.asociacion.membresiaActiva._id;
    data.tipo = this.state.tipoEdicion;
    if (this.state.filasSeleccionadas.length === 0) {
      data.asociaciones = [
        this.state.tipoEdicion === "Clúster"
          ? this.state.filaSelecionada
          : this.state.casaSelecionada,
      ];
      this.props.eliminarSubasociacion(data);
      this.setState({
        filaSelecionada: undefined,
        filasSeleccionadas: [],
        casaSelecionada: null,
      });
    } else {
      data.asociaciones = this.state.filasSeleccionadas;
      this.props.eliminarSubasociacion(data);
      this.setState({
        filaSelecionada: undefined,
        filasSeleccionadas: [],
        casaSelecionada: null,
      });
    }
  }

  onSubmitAgregarCluster = (e) => {
    e.preventDefault();

    let data = {
      nombre: this.state["nombreCluster"],
      estatus: "5e01126c03f8a31d5755f9c8",
      tipo: "5e01053fc95ea519226474e1",
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      membresia: localStorage.getItem(this.props.auth.user.id),
    };
    this.props.agregarSubasociacion(data);

    this.setState({ nombreCluster: "" });
  };

  onSubmitAgregarCasa = (e) => {
    e.preventDefault();

    let data = {
      nombre: this.state["nombreCasa"],
      estatus: "5e01126c03f8a31d5755f9c8",
      tipo: "5e1a241b72dbf1926c6016b8",
      asociacion: this.state.clusterSeleccionado,
      membresia: localStorage.getItem(this.props.auth.user.id),
    };
    this.props.agregarSubasociacion(data);
  };

  onClickUnirse(e) {
    e.preventDefault();
    this.setState({ modalAbierto: 1 });
    this.setState({ asociacionMembresia: e.target.id });
    const data = {
      asociacion: e.target.id,
      stage: 1,
    };
    this.props.loadRolAsociacion(data);
    this.props.loadSubsAsociacion(data);

    window.abrirModal("agregarMembresia");
  }

  onClickCancelar(e) {
    e.preventDefault();

    const data = {
      id: e.target.id,
      estatus: "5e01128003f8a31d5755f9c9",
    };
    this.props.cambiarStatusMembresia(data);
  }

  onClickCerrar(e) {
    e.preventDefault();
    this.setState({ modalAbierto: 2 });
    window.cerrrarModal("agregarMembresia");
  }

  onSubmit(e) {
    e.preventDefault();
  }

  onSubmitBusqueda(e) {
    e.preventDefault();
    let data = {};
    data.nombre = this.state["inputNombreFraccionamiento"];
    data.ciudad = this.state["ciudadAValue"];
    this.props.busquedaFraccionamiento(data);
  }

  onSubmitUnirse(e) {
    e.preventDefault();

    let data = {};
    data.rol = this.state["rol"];
    data.casa = this.state["casa"];
    data.asociacion = this.state.asociacionMembresia;

    console.log(data);

    this.props.agregarSolicitud(data);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
    this.props.loadMembresiasInactivas();
    this.props.loadSolicitudes();
    this.props.loadCountries();
    this.props.loadTipoDeAsociaciones();
    this.props.getSubscriptions();
    this.props.loadRolesAdmon();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });

      if (this.props.errors["paymentMethod"]) {
        NotificationManager.error(
          this.props.errors["paymentMethod"],
          "Error en el método de pago",
          6000
        );
      }

      if (this.props.errors["subscription"]) {
        NotificationManager.error(
          this.props.errors["subscription"],
          "Error en el método de pago",
          6000
        );
      }
    }

    if (
      this.props.asociacion.membresiaActiva.asociacion &&
      prevProps.asociacion.membresiaActiva.asociacion &&
      prevProps.asociacion.membresiaActiva.asociacion.departamentos.length !==
        this.props.asociacion.membresiaActiva.asociacion.departamentos.length
    ) {
      window.cerrrarModal("agregarClusterModal");
    }

    if (
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.departamento[0]
            .asociacion.length > 0
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      if (
        this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
        "Clúster"
      ) {
        this.setState({
          clusterSeleccionado: this.props.asociacion.membresiaActiva.asociacion
            ._id,
        });
      }

      this.props.loadSolicitudesPendientes({
        asociacion: asociacion,
      });

      this.props.loadMembresiasAdministradores({
        asociacion: asociacion,
      });

      this.props.loadServiciosAsociacion({
        asociacion: asociacion,
      });
    }

    if (this.state.clusterSeleccionado !== prevState.clusterSeleccionado) {
      this.setState({ casaSelecionada: null });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.respuesta &&
      this.props.asociacion.respuesta !== prevProps.asociacion.respuesta &&
      this.props.asociacion.respuesta.success
    ) {
      window.cerrrarModal("addUser");
      NotificationManager.success(
        "",
        this.props.asociacion.respuesta.mensaje,
        6000
      );
      this.setState({
        nombreInvitacion: "",
        correoInvitacion: "",
        apellidoInvitacion: "",
      });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.membresias &&
      prevProps.asociacion.membresias !== this.props.asociacion.membresias &&
      this.props.asociacion.membresias.length > 0 &&
      this.state.nombre
    ) {
      NotificationManager.success(
        "",
        "Fraccionamiento creado correctamente",
        6000
      );
    }

    //Cuota modal

    if (this.state.tipoCuotaModal !== prevState.tipoCuotaModal) {
      if (this.state.tipoCuotaModal.tipo === "Agregar") {
        this.setState({
          nombreCuota: "",
          montoCuota: "",
          tipoServicioCuota: "0",
          estatusCuota: "0",
          asociacionesCuotaSeleccionadas: [],
          diasSinCargo: "",
          cargoPagoEx: "",
          startDate: dateInicio,
          endDate: null,
          frecuenciaCuota: "0",
        });
      } else if (this.state.tipoCuotaModal.tipo === "Editar") {
        const info = this.props.asociacion.servicios.filter(
          (servicio) => servicio._id === this.state.tipoCuotaModal.servicio
        )[0];

        let fechaSinHoras = info.suscripciones.periodicidad.inicio
          ? new Date(info.suscripciones.periodicidad.inicio)
          : new Date();
        fechaSinHoras.setHours(0, 0, 0, 0);

        this.setState({
          nombreCuota: info.nombre,
          montoCuota: info.costo,
          tipoServicioCuota: info.tipo._id,
          estatusCuota: info.estatus._id,
          asociacionesCuotaSeleccionadas:
            info.suscripciones && info.suscripciones.suscriptores
              ? info.suscripciones.suscriptores.map(
                  (suscriptor) => suscriptor._id
                )
              : [],
          diasSinCargo: info.suscripciones.maxDiasCargo,
          cargoPagoEx: info.suscripciones.cargo,
          startDate:
            info.suscripciones.periodicidad.inicio !== null
              ? fechaSinHoras
              : null,
          endDate:
            info.suscripciones.periodicidad.fin !== null
              ? new Date(info.suscripciones.periodicidad.fin)
              : null,
          frecuenciaCuota: info.suscripciones.frecuencia._id,
        });
      }
    }

    //Cerrrar modal por cuota agregada o actualizada

    if (
      this.props.asociacion &&
      this.props.asociacion.added &&
      this.props.asociacion.added !== prevProps.asociacion.added
    ) {
      window.cerrrarModal("agregarCuota");

      if (
        this.props.asociacion.servicios.length <
        prevProps.asociacion.servicios.length
      ) {
        NotificationManager.success("", "Cuota eliminada correctamente", 6000);
      } else {
        NotificationManager.success(
          "",
          this.state.tipoCuotaModal.tipo === "Agregar"
            ? "Cuota agregada correctamente"
            : "La cuota fue editada correctamente",
          6000
        );
      }
    }

    if (
      this.props.stripe &&
      this.props.stripe.subscriptions !== prevProps.stripe.subscriptions
    ) {
      if (this.props.stripe.subscriptions.data.length > 0) {
        this.setState({
          suscripcionID: this.props.stripe.subscriptions.data[0].id,
        });
      }
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.inactivas !== prevProps.asociacion.inactivas
    ) {
      if (this.props.asociacion.inactivas.length > 0) {
        this.setState({
          membresiaInactivaID: this.props.asociacion.inactivas[0]._id,
        });
      }
    }
  }

  onSubmitCrearAsociacion = (e) => {
    e.preventDefault();
    const asociacion = {};
    this.setState({ botonGuardar: "Creando asociación", cargando: true });

    asociacion.nombre = this.state["nombre"];
    asociacion.calle = this.state["calle"];
    asociacion.numero = this.state["numero"];
    asociacion.colonia = this.state["colonia"];
    asociacion.codigoPostal = this.state["codigoPostal"];
    asociacion.ciudad = this.state["ciudadValue"];
    asociacion.estado = this.state["estadoValue"];
    asociacion.pais = this.state["paisValue"];

    this.props.addAsociacion(asociacion);
  };

  onChange = (e) => {
    const toggleKey = (key) => (prevState) => {
      return { [key]: !prevState[key] };
    };

    switch (e.target.name) {
      case "checkCuotaAll":
        this.setState({ checkCuotaAll: !this.state.checkCuotaAll });

        if (e.target.checked) {
          let s = [];

          this.props.asociacion.membresiaActiva.asociacion.departamentos.map(
            (depa) => {
              s.unshift(depa._id);
              depa.areas.map((area) => s.unshift(area._id));
            }
          );
          this.setState({ asociacionesCuotaSeleccionadas: s });
        } else {
          this.setState({ asociacionesCuotaSeleccionadas: [] });
        }
        break;
      case "frecuenciaCuota":
        this.setState({ frecuenciaCuota: e.target.value });
        /* if (e.target.value !== "5e6ff169430039057ebc1eb4") {
          this.setState({ startDate: new Date() });
        } */
        break;
      case "selectedFile":
        this.setState({ selectedFile: e.target.files[0] });
        break;
      case "pais":
        this.props.loadStates(e.target.value);
        this.setState({ paisValue: e.target.value });
        break;
      case "estado":
        this.props.loadCities(e.target.value);
        this.setState({ estadoValue: e.target.value });
        break;
      case "ciudad":
        this.setState({ ciudadValue: e.target.value });
        break;
      case "paisA":
        this.props.loadStates(e.target.value);
        this.props.emptyAsociaciones();
        break;
      case "estadoA":
        this.props.loadCities(e.target.value);
        this.props.emptyAsociaciones();
        break;
      case "ciudadA":
        this.setState({ ciudadAValue: e.target.value });
        let data = {};
        data.nombre = this.state["inputNombreFraccionamiento"];
        data.ciudad = e.target.value;
        this.props.loadAsociacionesCiudad(data);
        break;
      case "subAsociacion":
        this.setState({ subAsociacion: e.target.value });

        const info = {
          asociacion: e.target.value,
          stage: 2,
        };
        this.props.loadSubsAsociacion(info);
        break;
      case "sinFinCheck":
        this.setState(toggleKey(e.target.name));
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const {
      errors,
      modalAbierto,
      filaSelecionada,
      clusterSeleccionado,
      casaSelecionada,
    } = this.state;
    const { percentage, profile } = this.props.profile;
    const {
      countries,
      states,
      cities,
      rolAsociacion,
      rolesAdmon,
    } = this.props.catalog;

    const {
      membresias,
      asociacionesCiudad,
      tipoDeAsociaciones,
      solicitudes,
      membresiaActiva,
    } = this.props.asociacion;

    let nombreCluster =
      membresiaActiva && filaSelecionada
        ? membresiaActiva.asociacion.departamentos.filter(
            (depa) => depa._id === filaSelecionada
          )[0].nombre
        : "";

    let nombreCasa =
      membresiaActiva &&
      filaSelecionada &&
      casaSelecionada &&
      membresiaActiva.asociacion.departamentos.filter(
        (depa) => depa._id === filaSelecionada
      ).length > 0 &&
      membresiaActiva.asociacion.departamentos
        .filter((depa) => depa._id === filaSelecionada)[0]
        .areas.filter((casa) => casa._id === casaSelecionada).length > 0
        ? membresiaActiva.asociacion.departamentos
            .filter((depa) => depa._id === filaSelecionada)[0]
            .areas.filter((casa) => casa._id === casaSelecionada)[0].nombre
        : "";

    let Content;
    let modalContent;

    modalContent = (
      <div>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="casasModal"
          id="casasModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {clusterSeleccionado &&
                  membresiaActiva &&
                  membresiaActiva.asociacion &&
                  membresiaActiva.asociacion.tipo.nombre === "Fraccionamiento"
                    ? membresiaActiva.asociacion.departamentos.filter(
                        (depa) => depa._id === clusterSeleccionado
                      )[0].nombre
                    : clusterSeleccionado &&
                      membresiaActiva &&
                      membresiaActiva.asociacion
                    ? membresiaActiva.asociacion.nombre
                    : ""}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                {clusterSeleccionado &&
                membresiaActiva.asociacion.tipo.nombre === "Fraccionamiento" ? (
                  <div>
                    <InformacionCluster
                      cluster={
                        membresiaActiva.asociacion.departamentos.filter(
                          (depa) => depa._id === clusterSeleccionado
                        )[0]
                      }
                      clusterSeleccionado={clusterSeleccionado}
                      filasSeleccionadas={this.state.filasSeleccionadas}
                      celdaActiva={this.state.celdaActiva}
                      onClickEliminar={this.onClickEliminarCasa}
                      onClickEditar={this.onClickEditarCasa}
                      handleChangeChk={this.handleChangeChk}
                      handleChangeChkAll={(e, check) =>
                        this.handleChangeChkAll(e, check)
                      }
                      onClickConfirmacionEliminar={
                        this.onClickConfirmacionEliminar
                      }
                      onSubmitAgregarCasa={this.onSubmitAgregarCasa}
                      onChange={this.onChange}
                      nombre={this.state.nombreCasa}
                      errors={this.state.errors}
                      onClickAddUser={this.onClickAddUser}
                      onClickEliminarMembresia={this.onClickEliminarMembresia}
                      rolesAdmon={this.rolesAdmon}
                    />
                  </div>
                ) : (
                  <Spinner />
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="editInstanciaModelo" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Editar{" "}
                  {this.state.tipoEdicion &&
                  this.state.tipoEdicion === "Clúster"
                    ? nombreCluster
                    : nombreCasa}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  id="actualizaNombre"
                  onSubmit={this.onSubmitEditarNombre}
                  noValidate
                >
                  <TextFieldGroup
                    name="nombreAsociacionEdicion"
                    error={this.props.errors["nombreAsociacionEdicion"]}
                    placeholder="Nuevo nombre"
                    value={
                      this.state["nombreAsociacionEdicion"]
                        ? this.state["nombreAsociacionEdicion"]
                        : ""
                    }
                    onChange={this.onChange}
                    label="Nombre"
                    id="nombreAsociacionEdicionID"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <input
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="Cancel"
                />

                <input
                  type="submit"
                  className="btn btn-info"
                  value="Guardar"
                  form="actualizaNombre"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="addUser" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Invitar nuevo ususario a{" "}
                  {membresiaActiva &&
                  clusterSeleccionado &&
                  membresiaActiva.asociacion.tipo.nombre === "Fraccionamiento"
                    ? this.state.casaSelecionada !== null &&
                      clusterSeleccionado &&
                      membresiaActiva.asociacion.departamentos
                        .filter((depa) => depa._id === clusterSeleccionado)[0]
                        .areas.filter(
                          (casa) => casa._id === this.state.casaSelecionada
                        ).length > 0
                      ? membresiaActiva.asociacion.departamentos
                          .filter((depa) => depa._id === clusterSeleccionado)[0]
                          .areas.filter(
                            (casa) => casa._id === this.state.casaSelecionada
                          )[0].nombre
                      : ""
                    : this.state.casaSelecionada !== null &&
                      clusterSeleccionado &&
                      membresiaActiva.asociacion.departamentos.filter(
                        (depa) => depa._id === this.state.casaSelecionada
                      ).length > 0
                    ? membresiaActiva &&
                      this.state.casaSelecionada !== null &&
                      membresiaActiva.asociacion.departamentos.filter(
                        (depa) => depa._id === this.state.casaSelecionada
                      )[0].nombre
                    : ""}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  name="invitacionForm"
                  id="invitacionForm"
                  onSubmit={this.onSubmitEnviarInvitacion}
                >
                  <TextFieldGroup
                    name="nombreInvitacion"
                    error={this.props.errors["nombreInvitacion"]}
                    placeholder="Nombre"
                    value={
                      this.state["nombreInvitacion"]
                        ? this.state["nombreInvitacion"]
                        : ""
                    }
                    onChange={this.onChange}
                    label="Nombre del invitado"
                    id="nombreInvitacionID"
                  />
                  <TextFieldGroup
                    name="apellidoInvitacion"
                    error={this.props.errors["apellidoInvitacion"]}
                    placeholder="Apellidos"
                    value={
                      this.state["apellidoInvitacion"]
                        ? this.state["apellidoInvitacion"]
                        : ""
                    }
                    onChange={this.onChange}
                    label="Apellidos"
                    id="apellidoInvitacionID"
                  />
                  <TextFieldGroup
                    name="correoInvitacion"
                    error={this.props.errors["correoInvitacion"]}
                    placeholder="Email"
                    value={
                      this.state["correoInvitacion"]
                        ? this.state["correoInvitacion"]
                        : ""
                    }
                    onChange={this.onChange}
                    label="Correo electrónico"
                    id="correoInvitacionID"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <input
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  value="Cerrar"
                />
                <input
                  type="submit"
                  className="btn btn-info"
                  value="Enviar"
                  form="invitacionForm"
                />
              </div>
            </div>
          </div>
        </div>

        <div id="deleteObjectModal" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Eliminar{" "}
                  {this.state.tipoEdicion &&
                  this.state.filasSeleccionadas.length === 0
                    ? this.state.tipoEdicion === "Clúster"
                      ? nombreCluster
                      : nombreCasa
                    : this.state.filasSeleccionadas.length === 1
                    ? "1 registro"
                    : this.state.filasSeleccionadas.length + " registros"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  {this.state.filasSeleccionadas.length === 0 ||
                  this.state.filasSeleccionadas.length === 1
                    ? "¿Está seguro que quiere eliminar el registro seleccionado?"
                    : "¿Está seguro que quiere los registros seleccionados?"}
                </p>
                <p className="text-warning">
                  <small>Ésta acción no puede ser revertida</small>
                </p>
              </div>
              <div className="modal-footer">
                <input
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="Cancel"
                />
                <input
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  value="Eliminar"
                  onClick={() => {
                    this.state.usuarioSeleccionado
                      ? this.onClickConfirmacionEliminarAdmon()
                      : this.onClickConfirmacionEliminar();
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="agregarClusterModal"
          tabIndex="0"
          role="dialog"
          aria-labelledby="agregarClusterModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="agregarClusterModal">
                  Agregar nuevo clúster{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body modal-scroll">
                <form
                  id="agregarClusterForm"
                  onSubmit={this.onSubmitAgregarCluster}
                >
                  <div className="form-group">
                    <label htmlFor="nombreCluster">Nombre</label>
                    <input
                      type="text"
                      className={classnames("form-control", {
                        "is-invalid": errors["nombre"],
                      })}
                      id="nombreCluster"
                      aria-describedby="nombreHelp"
                      placeholder="Nombre"
                      name="nombreCluster"
                      value={this.state["nombreCluster"]}
                      onChange={this.onChange}
                    />
                    <small id="nombreHelp" className="form-text text-muted">
                      Nombre del clúster o edificio
                    </small>
                    {errors["nombre"] && (
                      <div className="invalid-feedback">{errors["nombre"]}</div>
                    )}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  form="agregarClusterForm"
                  type="submit"
                  className="btn btn-primary"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="agregarCuota"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="agregarCuotaModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="agregarCuotaModal">
                  {this.state.tipoCuotaModal.tipo} cuota{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body modal-scroll">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      {this.props.catalog &&
                      (!this.props.catalog.estatusCuotas ||
                        !this.props.catalog.tipoDeServiciosCuotas) ? (
                        <Spinner></Spinner>
                      ) : (
                        <form
                          id="agregarCuotaForm"
                          onSubmit={this.onSubmitAgregarCuota}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5>
                                Detalle <hr></hr>
                              </h5>
                            </div>
                            <div className="col-12">
                              <div className="group inputPlaceholder form-group">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": errors["nombre"],
                                  })}
                                  id="nombreCuotaID"
                                  name="nombreCuota"
                                  onChange={this.onChange}
                                  value={this.state.nombreCuota}
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="nombreCuotaID">Nombre</label>
                                {errors["nombre"] && (
                                  <div className="invalid-feedback">
                                    {errors["nombre"]}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="group inputPlaceholder form-group">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": errors["costo"],
                                  })}
                                  id="montoCuotaID"
                                  name="montoCuota"
                                  onChange={this.onChange}
                                  value={this.state.montoCuota}
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="montoCuotaID">
                                  Monto en MXN
                                </label>
                                {errors["costo"] && (
                                  <div className="invalid-feedback">
                                    {errors["costo"]}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12" style={{ padding: "0px" }}>
                            {membresiaActiva.asociacion.tipo.nombre ===
                            "Fraccionamiento" ? (
                              <div className="form-group">
                                <label htmlFor="tipoServicioCuota">
                                  Aplica para
                                </label>
                                <select
                                  className={classnames("form-control", {
                                    "is-invalid": errors["tipo"],
                                  })}
                                  id="tipoServicioCuota"
                                  name="tipoServicioCuota"
                                  onChange={this.onChange}
                                  value={this.state.tipoServicioCuota}
                                >
                                  <option value="0">Elige</option>
                                  {this.props.catalog.tipoDeServiciosCuotas.map(
                                    (tipo, i) => {
                                      return (
                                        <option key={tipo._id} value={tipo._id}>
                                          {tipo.nombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors["tipo"] && (
                                  <div className="invalid-feedback">
                                    {errors["tipo"]}
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="form-group">
                              <label htmlFor="estatusCuota">Estatus</label>
                              <select
                                className={classnames("form-control", {
                                  "is-invalid": errors["estatus"],
                                })}
                                id="estatusCuota"
                                name="estatusCuota"
                                onChange={this.onChange}
                                value={this.state.estatusCuota}
                              >
                                <option value="0">Elige</option>
                                {this.props.catalog.estatusCuotas.map(
                                  (estatus, i) => {
                                    return (
                                      <option
                                        key={estatus._id}
                                        value={estatus._id}
                                      >
                                        {estatus.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              {errors["estatus"] && (
                                <div className="invalid-feedback">
                                  {errors["estatus"]}
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="col-sm-12 col-md-8">
                      {this.props.catalog && !this.props.catalog.frecuencias ? (
                        <Spinner></Spinner>
                      ) : (
                        <div className="row">
                          <div className="col-12">
                            <h5>
                              Periodicidad <hr></hr>
                            </h5>
                          </div>
                          <div className="col-4">
                            <div className="group inputDatePlaceholder form-group">
                              <DatePicker
                                selected={
                                  this.state.frecuenciaCuota ===
                                  "5e6ff169430039057ebc1eb4"
                                    ? null
                                    : this.state.startDate
                                }
                                disabled={
                                  this.state.frecuenciaCuota ===
                                  "5e6ff169430039057ebc1eb4"
                                    ? true
                                    : false
                                }
                                onChange={this.handleDateChange}
                                locale="es"
                                name="fechaInicioPeriodicidad"
                                id="inicioID"
                                dateFormat="yyyy-MM-dd"
                              />
                              <span className="highlight"></span>
                              <span className="bar"></span>
                              <label htmlFor="inicioID">Fecha de inicio</label>
                              {errors["inicio"] && (
                                <div className="invalid-feedback">
                                  {errors["inicio"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="group inputDatePlaceholder form-group">
                              <DatePicker
                                selected={
                                  this.state.sinFinCheck ||
                                  this.state.frecuenciaCuota ===
                                    "5e6ff169430039057ebc1eb4"
                                    ? null
                                    : this.state.endDate
                                }
                                onChange={this.handleEndDateChange}
                                locale="es"
                                disabled={
                                  this.state.frecuenciaCuota ===
                                  "5e6ff169430039057ebc1eb4"
                                    ? true
                                    : this.state.sinFinCheck
                                }
                                name="fechaFinPeriodicidad"
                                id="finID"
                                dateFormat="yyyy-MM-dd"
                              />
                              <span className="highlight"></span>
                              <span className="bar"></span>
                              <label htmlFor="finID">Fecha de termino</label>
                              {errors["fin"] && (
                                <div className="invalid-feedback">
                                  {errors["fin"]}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-4">
                            <div
                              className="custom-control custom-switch"
                              style={{ top: "10px" }}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="sinFinCheck"
                                checked={this.state.sinFinCheck}
                                onChange={this.onChange}
                                name="sinFinCheck"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sinFinCheck"
                              >
                                Sin termino
                              </label>
                            </div>
                          </div>

                          <div className="col-4">
                            <select
                              className={classnames("form-control", {
                                "is-invalid": errors["frecuenciaCuota"],
                              })}
                              id="frecuenciaCuota"
                              name="frecuenciaCuota"
                              onChange={this.onChange}
                              value={this.state.frecuenciaCuota}
                            >
                              <option value="0">Elige una frecuencia</option>
                              {this.props.catalog.frecuencias.map((tipo, i) => {
                                return (
                                  <option key={tipo._id} value={tipo._id}>
                                    {tipo.nombre}
                                  </option>
                                );
                              })}
                            </select>
                            {errors["frecuenciaCuota"] && (
                              <div className="invalid-feedback">
                                {errors["frecuenciaCuota"]}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <div className="group inputPlaceholder form-group">
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": errors["maxDiasCargo"],
                                })}
                                id="diasSinCargoID"
                                name="diasSinCargo"
                                onChange={this.onChange}
                                value={this.state.diasSinCargo}
                              />
                              <span className="highlight"></span>
                              <span className="bar"></span>
                              <label htmlFor="diasSinCargoID">
                                Días sin penalización
                              </label>
                              {errors["maxDiasCargo"] && (
                                <div className="invalid-feedback">
                                  {errors["maxDiasCargo"]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-4">
                            <div className="group inputPlaceholder form-group">
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": errors["cargo"],
                                })}
                                id="cargoPagoExID"
                                name="cargoPagoEx"
                                onChange={this.onChange}
                                value={this.state.cargoPagoEx}
                              />
                              <span className="highlight"></span>
                              <span className="bar"></span>
                              <label htmlFor="cargoPagoExID">
                                Penalización en MXN
                              </label>
                              {errors["cargo"] && (
                                <div className="invalid-feedback">
                                  {errors["cargo"]}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-12">
                            <h5>
                              Suscriptores <hr></hr>
                            </h5>
                          </div>

                          {this.props.asociacion &&
                          this.props.asociacion.membresiaActiva &&
                          this.props.asociacion.membresiaActiva.asociacion
                            .departamentos &&
                          this.props.asociacion.membresiaActiva.asociacion
                            .departamentos.length > 0 ? (
                            <div
                              className={classnames("col-12", {
                                disabled:
                                  this.state.frecuenciaCuota ===
                                  "5e6ff169430039057ebc1eb4",
                              })}
                            >
                              <table className="table table-striped table-hover">
                                <thead>
                                  <tr>
                                    {membresiaActiva.asociacion.tipo.nombre ===
                                    "Fraccionamiento" ? (
                                      <th></th>
                                    ) : (
                                      ""
                                    )}
                                    <th>
                                      <span className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          id="selectAll"
                                          name="checkCuotaAll"
                                          checked={this.state.checkCuotaAll}
                                          onChange={this.onChange}
                                        />
                                        <label htmlFor="selectAll" />
                                      </span>
                                    </th>

                                    <th>
                                      {membresiaActiva.asociacion.tipo
                                        .nombre === "Fraccionamiento"
                                        ? "Nombre del clúster"
                                        : "Nombre de la casa"}
                                    </th>
                                    {membresiaActiva.asociacion.tipo.nombre ===
                                    "Fraccionamiento" ? (
                                      <th>Casas</th>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.asociacion.membresiaActiva.asociacion.departamentos.map(
                                    (fila, index) => {
                                      return (
                                        <Fragment key={fila._id}>
                                          <tr
                                            className={
                                              fila._id ===
                                              this.state.clusterCuotaSeleccinado
                                                ? "table-active"
                                                : ""
                                            }
                                            onClick={() =>
                                              this.clicTablaCuota(fila._id)
                                            }
                                            onDoubleClick={() =>
                                              this.onClickMostrarCasas(fila._id)
                                            }
                                          >
                                            {membresiaActiva.asociacion.tipo
                                              .nombre === "Fraccionamiento" ? (
                                              <td>
                                                <i
                                                  className={classnames("fa", {
                                                    "fa-caret-down":
                                                      this.state.casasAbierto &&
                                                      this.state
                                                        .clusterCuotaSeleccinado ===
                                                        fila._id,
                                                    "fa-caret-right":
                                                      this.state
                                                        .clusterCuotaSeleccinado !==
                                                        fila._id ||
                                                      !this.state.casasAbierto,
                                                  })}
                                                  onClick={() =>
                                                    this.onClickMostrarCasas(
                                                      fila._id
                                                    )
                                                  }
                                                ></i>
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            <td>
                                              <span className="custom-checkbox">
                                                <input
                                                  type="checkbox"
                                                  name={fila._id}
                                                  id="clusterCheckID"
                                                  onChange={
                                                    this
                                                      .handleCuotasDepartamentosCheck
                                                  }
                                                  checked={
                                                    this.state.asociacionesCuotaSeleccionadas.includes(
                                                      fila._id
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label htmlFor="clusterCheckID" />
                                              </span>
                                            </td>
                                            <td>{fila.nombre}</td>
                                            {membresiaActiva.asociacion.tipo
                                              .nombre === "Fraccionamiento"
                                              ? fila.areas.length
                                              : ""}
                                          </tr>
                                          {this.state.casasAbierto &&
                                            this.state
                                              .clusterCuotaSeleccinado ===
                                              fila._id && (
                                              <tr className="detalleCasas">
                                                <td></td>
                                                <td></td>
                                                <td>
                                                  <table className="table tablaDetalle">
                                                    <thead>
                                                      <tr>
                                                        <th></th>
                                                        <th>
                                                          Número de la casa
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {this.props.asociacion.membresiaActiva.asociacion.departamentos
                                                        .filter(
                                                          (depa) =>
                                                            depa._id ===
                                                            this.state
                                                              .clusterCuotaSeleccinado
                                                        )[0]
                                                        .areas.map((casa) => {
                                                          return (
                                                            <tr key={casa._id}>
                                                              <td>
                                                                <span className="custom-checkbox">
                                                                  <input
                                                                    type="checkbox"
                                                                    name={
                                                                      casa._id
                                                                    }
                                                                    id="casaCheckID"
                                                                    onChange={
                                                                      this
                                                                        .handleCuotasCheck
                                                                    }
                                                                    checked={
                                                                      this.state.asociacionesCuotaSeleccionadas.includes(
                                                                        casa._id
                                                                      )
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  />
                                                                  <label htmlFor="casaCheckID" />
                                                                </span>
                                                              </td>
                                                              <td>
                                                                {casa.nombre}
                                                              </td>
                                                            </tr>
                                                          );
                                                        })}
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            )}
                                        </Fragment>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="col-12">
                              Aún no tienes casas registradas, agrega la
                              primera!
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {this.state.tipoCuotaModal.tipo === "Editar" ? (
                  <button
                    className="btn btn-warning"
                    onClick={this.onClickEliminarCuota}
                  >
                    Eliminar
                  </button>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  form="agregarCuotaForm"
                  type="submit"
                  className="btn btn-primary"
                >
                  {this.state.tipoCuotaModal.tipo}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="agregarAsociacionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="agregarAsociacionTitel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="agregarAsociacionTitel">
                  Agregar nuevo Fraccionamiento{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body modal-scroll"></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames("modal fade", {
            "": modalAbierto === 1,
            show: modalAbierto === 2,
          })}
          id="bucarAsociacionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="buscarAsociacionTitel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="buscarAsociacionTitel">
                  Unirse a un fraccionamiento{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body modal-scroll">
                <div className="row justify-content-center">
                  <div className="col-12 text-center">
                    <h3>
                      Buscar por nombre del fraccionamiento o por ubicación
                    </h3>
                    <br></br>
                    <form
                      onSubmit={this.onSubmitBusqueda}
                      className="form-inline text-center justify-content-center"
                    >
                      <div className="form-group mx-sm-3 mb-2">
                        <label
                          htmlFor="inputNombreFraccionamiento"
                          className="sr-only"
                        >
                          Nombre
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputNombreFraccionamiento"
                          name="inputNombreFraccionamiento"
                          onChange={this.onChange}
                          placeholder="Nombre"
                        />
                      </div>
                      <button type="submit" className="btn btn-light mb-2">
                        Buscar
                      </button>
                    </form>
                    <br></br>

                    <form>
                      <div className="form-row justify-content-center align-items-center">
                        <div className="col-4">
                          <label
                            className="mr-sm-2 sr-only"
                            htmlFor="paisSelect"
                          >
                            Pais
                          </label>
                          <select
                            className="custom-select mr-sm-2"
                            id="paisSelect"
                            onChange={this.onChange}
                            name="paisA"
                          >
                            <option value="0">Selecciona un país</option>
                            {countries !== undefined
                              ? countries.map((pais, i) => {
                                  return (
                                    <option
                                      value={pais._id}
                                      key={pais._id ? pais._id : i}
                                    >
                                      {pais.nombre}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        </div>

                        {states !== undefined &&
                        Object.keys(states).length > 0 ? (
                          <div className="col-4">
                            <label
                              className="mr-sm-2 sr-only"
                              htmlFor="estadoSelect"
                            >
                              Estado
                            </label>
                            <select
                              className="custom-select mr-sm-2"
                              id="estadoSelect"
                              onChange={this.onChange}
                              name="estadoA"
                            >
                              <option value="0">Selecciona un estado</option>
                              {states.map((estado, i) => {
                                return (
                                  <option
                                    value={estado._id}
                                    key={estado._id ? estado._id : i}
                                  >
                                    {estado.nombre}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          ""
                        )}

                        {cities !== undefined &&
                        Object.keys(cities).length > 0 ? (
                          <div className="col-4">
                            <label
                              className="mr-sm-2 sr-only"
                              htmlFor="ciudadSelect"
                            >
                              Ciudades
                            </label>
                            <select
                              className="custom-select mr-sm-2"
                              id="ciudadSelect"
                              onChange={this.onChange}
                              name="ciudadA"
                            >
                              <option value="0">Selecciona un ciudad</option>
                              {cities.map((ciudad, i) => {
                                return (
                                  <option
                                    value={ciudad._id}
                                    key={ciudad._id ? ciudad._id : i}
                                  >
                                    {ciudad.nombre}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </form>
                  </div>
                </div>
                <br></br>
                <div className="row">
                  {asociacionesCiudad !== undefined &&
                    asociacionesCiudad.map((asociacion, i) => {
                      return (
                        <div className="col-12" key={i}>
                          <div
                            className="card-deck"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="card">
                              <img
                                className="img-fluid"
                                src={img1}
                                style={{ height: "180px" }}
                                alt=""
                              />
                              <div className="card-body">
                                <h4 className="card-title">
                                  {asociacion.identificador} (
                                  {asociacion.tipo.nombre})
                                </h4>
                                <h5 className="card-title">
                                  {asociacion.nombre}
                                </h5>
                                <p className="card-text">
                                  <strong>Dirección</strong>
                                  <br></br>
                                  {asociacion.direccion.calle}{" "}
                                  {asociacion.direccion.numero}
                                  <br></br>
                                  {asociacion.direccion.colonia}
                                  <br></br>
                                  {asociacion.direccion.codigoPostal}
                                  <br></br>
                                  {asociacion.direccion.ciudad[0].nombre}
                                  <br></br>
                                  {
                                    asociacion.direccion.ciudad[0].estado[0]
                                      .nombre
                                  }
                                  <br></br>
                                  <strong>
                                    Número de usuarios:{" "}
                                    {asociacion.membresias &&
                                      asociacion.membresias.count}
                                  </strong>
                                  <br></br>
                                </p>
                              </div>
                              <div className="card-footer">
                                <small
                                  className="text-muted"
                                  style={{ paddingTop: "10px" }}
                                >
                                  Creada el {asociacion.created}
                                </small>
                                <div className="float-right">
                                  {asociacion.solicitud ? (
                                    <button
                                      type="submit"
                                      className="btn btn-secondary"
                                    >
                                      Solicitud pendiente
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-secondary"
                                      id={asociacion._id}
                                      onClick={this.onClickUnirse}
                                    >
                                      Unirse
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="agregarMembresia"
          tabIndex="0"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Completa la información</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.onClickCerrar}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {rolAsociacion.length === 0 ? (
                  <Spinner />
                ) : (
                  <form onSubmit={this.onSubmitUnirse} id="agregarSolicitud">
                    <div className="form-row">
                      <div className="col-12">
                        <label htmlFor="rolInput" style={{ marginTop: "10px" }}>
                          Rol
                        </label>
                        <select
                          className={classnames("custom-select", {
                            "is-invalid": errors["rol"],
                          })}
                          id="rolInput"
                          name="rol"
                          onChange={this.onChange}
                        >
                          <option value="0">Selecciona un rol</option>
                          {rolAsociacion.map((elemento, i) => {
                            return (
                              <option value={elemento._id} key={i}>
                                {elemento.title}
                              </option>
                            );
                          })}
                        </select>
                        {errors["rol"] ? (
                          <div className="invalid-feedback">
                            Por favor selecciona un rol
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {rolAsociacion.filter(
                        (rol) => rol._id === this.state["rol"]
                      ).length > 0 &&
                      rolAsociacion.filter(
                        (rol) => rol._id === this.state["rol"]
                      )[0].title === "Condómino" ? (
                        <div className="col-12">
                          {this.props.asociacion.subs && (
                            <div className="col-12">
                              <label
                                htmlFor="subAsociacion"
                                style={{ marginTop: "10px" }}
                              >
                                Cúster
                              </label>
                              <select
                                className={classnames("custom-select", {
                                  "is-invalid": errors["subAsociacion"],
                                })}
                                id="subAsociacion"
                                name="subAsociacion"
                                onChange={this.onChange}
                              >
                                <option value="0">
                                  Selecciona un Clúster...
                                </option>
                                {this.props.asociacion.subs.map(
                                  (elemento, i) => {
                                    return (
                                      <option value={elemento._id} key={i}>
                                        {elemento.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              {errors["subAsociacion"] ? (
                                <div className="invalid-feedback">
                                  Por favor selecciona un clúster
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          {this.props.asociacion.subs &&
                            (this.props.asociacion.casas.length > 0 ||
                              this.props.asociacion.subs.length > 0) && (
                              <div className="col-12">
                                <label
                                  htmlFor="casa"
                                  style={{ marginTop: "10px" }}
                                >
                                  Casa
                                </label>
                                <select
                                  className={classnames("custom-select", {
                                    "is-invalid": errors["casa"],
                                  })}
                                  id="casa"
                                  name="casa"
                                  onChange={this.onChange}
                                >
                                  <option value="0">
                                    Selecciona una casa...
                                  </option>
                                  {this.props.asociacion.casas.map(
                                    (elemento, i) => {
                                      return (
                                        <option value={elemento._id} key={i}>
                                          {elemento.nombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors["casa"] ? (
                                  <div className="invalid-feedback">
                                    Por favor selecciona una casa
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.onClickCerrar}
                >
                  Cerrrar
                </button>
                <button
                  form="agregarSolicitud"
                  type="submit"
                  className="btn btn-primary"
                >
                  Enviar solicitud
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.asociacion && this.props.asociacion.membresias) {
      Content = (
        <div className="row">
          {solicitudes && solicitudes.length > 0 && (
            <div className="col-12 mb-3">
              <h3 className="h3 mb-3 mt-0">Solicitudes pendientes </h3>
              <div className="card clearfix">
                {solicitudes.length > 0
                  ? solicitudes.map((solicitud, i) => {
                      return (
                        <div
                          className="card-body d-flex align-items-center"
                          key={i}
                        >
                          <div className="d-inline-block p-2">
                            <img
                              src={img2}
                              className="rounded-circle"
                              style={{
                                width: "100px",
                                verticalAlign: "bottom",
                              }}
                            />
                          </div>
                          <div className="d-inline-block p-2">
                            <span className="d-block">
                              {solicitud.fraccionamiento &&
                                solicitud.fraccionamiento.nombre}
                            </span>
                            <span className="d-block">
                              {solicitud.cluster && solicitud.cluster.nombre}
                            </span>
                            <span className="d-block">
                              {solicitud.asociacion.nombre}
                            </span>

                            {this.props.asociacion &&
                            !this.props.asociacion.cancelaciones.includes(
                              solicitud._id.toString()
                            ) ? (
                              <span className="d-block">
                                <span className="badge badge-warning">
                                  {solicitud.estatus.nombre}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="d-inline-block p-2"
                            style={{ marginLeft: "auto" }}
                          >
                            {this.props.asociacion &&
                            this.props.asociacion.cancelaciones.includes(
                              solicitud._id.toString()
                            ) ? (
                              <button
                                type="button"
                                className="btn btn-danger"
                                disabled
                              >
                                Cancelada
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-info"
                                id={solicitud._id}
                                onClick={this.onClickCancelar}
                              >
                                Cancelar solicitud
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          )}

          {membresias === null || Object.keys(membresias).length === 0 ? (
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <h4>Registra tu fraccionamiento, residencial o condominio</h4>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-9 col-md-12">
                        <div className="section_title text-center">
                          {profile.user && !profile.user.emailVerified ? (
                            <p className="mt-3">
                              Para utilizar Wirhub es necesario que confirmes tu
                              correo electrónico. Te hemos enviado un correo
                              para que puedas verificar tu cuenta, también
                              puedes dirigirte a la seccion de perfil dónde
                              podrás enviar el correo de confirmación
                              nuevamente.
                            </p>
                          ) : (
                            <Fragment>
                              <div id="stepper1" className="bs-stepper">
                                <div className="bs-stepper-header">
                                  <div
                                    className={classnames("step", {
                                      "active-step":
                                        this.state.activeStep === 1,
                                    })}
                                  >
                                    <button className="step-trigger">
                                      <span className="bs-stepper-circle">
                                        1
                                      </span>
                                      <span className="bs-stepper-label">
                                        Registro
                                      </span>
                                    </button>
                                  </div>
                                  <div className="line"></div>
                                  <div
                                    className={classnames("step", {
                                      "active-step":
                                        this.state.activeStep === 2,
                                    })}
                                  >
                                    <button className="step-trigger">
                                      <span className="bs-stepper-circle">
                                        2
                                      </span>
                                      <span className="bs-stepper-label">
                                        Pago
                                      </span>
                                    </button>
                                  </div>
                                  <div className="line"></div>
                                  <div
                                    className={classnames("step", {
                                      "active-step":
                                        this.state.activeStep === 3,
                                    })}
                                  >
                                    <button className="step-trigger">
                                      <span className="bs-stepper-circle">
                                        3
                                      </span>
                                      <span className="bs-stepper-label">
                                        Resumen
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="bs-stepper-content">
                                  <div
                                    id="test-l-1"
                                    className={classnames("content", {
                                      "fade active":
                                        this.state.activeStep === 1,
                                    })}
                                  >
                                    <div className="asociacion-registro">
                                      {this.props.asociacion.inactivas.length >
                                      0 ? (
                                        <Fragment>
                                          <h4
                                            style={{
                                              marginBottom: "25px",
                                              marginTop: "35px",
                                            }}
                                          >
                                            Membresia
                                          </h4>

                                          <p>
                                            Tienes registrado el siguiente
                                            fraccionamiento
                                          </p>

                                          {this.props.asociacion.inactivas.map(
                                            (inactiva, i) => {
                                              return (
                                                <div
                                                  key={i}
                                                  className="justify-content-center"
                                                >
                                                  <h4>
                                                    {inactiva.asociacion.nombre}
                                                  </h4>
                                                  <label className="listInactive">
                                                    {
                                                      inactiva.asociacion
                                                        .direccion.calle
                                                    }
                                                  </label>
                                                  <label className="listInactive">
                                                    {
                                                      inactiva.asociacion
                                                        .direccion.numero
                                                    }
                                                  </label>
                                                  <label className="listInactive">
                                                    {
                                                      inactiva.asociacion
                                                        .direccion.colonia
                                                    }
                                                  </label>
                                                  <label className="listInactive">
                                                    {
                                                      inactiva.asociacion
                                                        .direccion.ciudad.nombre
                                                    }
                                                  </label>
                                                  <label className="listInactive">
                                                    {
                                                      inactiva.asociacion
                                                        .direccion.ciudad.estado
                                                        .nombre
                                                    }
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}
                                        </Fragment>
                                      ) : (
                                        <form
                                          id="crearAsociacionForm"
                                          className="crearAsociacionForm"
                                          onSubmit={
                                            this.onSubmitCrearAsociacion
                                          }
                                        >
                                          <div className="form-group">
                                            <label htmlFor="nombreA">
                                              Nombre del fraccionamiento
                                            </label>
                                            <input
                                              type="text"
                                              className={classnames(
                                                "form-control",
                                                {
                                                  "is-invalid":
                                                    errors["nombre"],
                                                }
                                              )}
                                              id="nombreA"
                                              aria-describedby="nombreHelp"
                                              placeholder="Nombre"
                                              name="nombre"
                                              onChange={this.onChange}
                                            />
                                            {errors["nombre"] && (
                                              <div className="invalid-feedback">
                                                {errors["nombre"]}
                                              </div>
                                            )}
                                          </div>
                                          {/* 
                                      <div className="form-group">
                                        <label htmlFor="tipoSelect">
                                          Tipo de fraccionamiento
                                        </label>
                                        <select
                                          className={classnames(
                                            "form-control",
                                            {
                                              "is-invalid": errors["tipo"],
                                            }
                                          )}
                                          id="tipoSelect"
                                          name="tipoDeAsociacion"
                                          value={this.state.tipoDeAsociacion}
                                          onChange={this.onChange}
                                        >
                                          <option value="0">
                                            Selecciona un tipo
                                          </option>
                                          {tipoDeAsociaciones &&
                                            tipoDeAsociaciones.map(
                                              (tipo, i) => {
                                                return (
                                                  <option
                                                    value={tipo._id}
                                                    key={
                                                      tipo._id ? tipo._id : i
                                                    }
                                                  >
                                                    {tipo.nombre}
                                                  </option>
                                                );
                                              }
                                            )}
                                        </select>
                                        {errors["tipo"] && (
                                          <div className="invalid-feedback">
                                            {errors["tipo"]}
                                          </div>
                                        )}
                                      </div> */}

                                          <TextFieldGroup
                                            name="calle"
                                            error={this.props.errors["calle"]}
                                            placeholder="Calle"
                                            value={
                                              this.state["calle"]
                                                ? this.state["calle"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Calle"
                                            id="CalleAsociacion"
                                          />
                                          <TextFieldGroup
                                            name="numero"
                                            error={this.props.errors["numero"]}
                                            placeholder="234 A"
                                            value={
                                              this.state["numero"]
                                                ? this.state["numero"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Número"
                                            id="NumeroAsociacion"
                                          />
                                          <TextFieldGroup
                                            name="colonia"
                                            error={this.props.errors["colonia"]}
                                            placeholder="Nombre de la colonia"
                                            value={
                                              this.state["colonia"]
                                                ? this.state["colonia"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Colonia"
                                            id="ColoniaAsociacion"
                                          />
                                          <TextFieldGroup
                                            name="codigoPostal"
                                            error={
                                              this.props.errors["codigoPostal"]
                                            }
                                            placeholder="72400"
                                            value={
                                              this.state["codigoPostal"]
                                                ? this.state["codigoPostal"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Código postal"
                                            id="CodigoAsociacion"
                                          />
                                          {/* 
                                        <TextFieldGroup
                                          name="location"
                                          error={this.props.errors["location"]}
                                          placeholder="19.115401,-98.252259"
                                          value={
                                            this.state["location"]
                                              ? this.state["location"]
                                              : ""
                                          }
                                          onChange={this.onChange}
                                          label="Coordenadas"
                                          id="CoordenadasAsociacion"
                                        /> */}

                                          <SelectCiudad
                                            paises={countries}
                                            estados={states}
                                            ciudades={cities}
                                            onChange={this.onChange}
                                            paisValue={this.state.paisValue}
                                            estadoValue={this.state.estadoValue}
                                            ciudadValue={this.state.ciudadValue}
                                            errorP={errors["pais"]}
                                            errorE={errors["estado"]}
                                            errorC={errors["ciudad"]}
                                          />
                                        </form>
                                      )}
                                    </div>

                                    {this.props.asociacion.inactivas.length >
                                    0 ? (
                                      <button
                                        className="btn btn-primary mt-4"
                                        onClick={this.handleOnClickNext}
                                      >
                                        Siguiente
                                      </button>
                                    ) : (
                                      <button
                                        form="crearAsociacionForm"
                                        type="submit"
                                        className="btn btn-primary"
                                        style={{ marginTop: "40px" }}
                                        disabled={this.state.cargando}
                                      >
                                        {this.state.botonGuardar}
                                      </button>
                                    )}

                                    <hr />
                                  </div>
                                  <div
                                    id="test-l-2"
                                    className={classnames("content", {
                                      "fade active":
                                        this.state.activeStep === 2,
                                    })}
                                  >
                                    {this.props.stripe.subscriptions &&
                                    this.props.stripe.subscriptions.data &&
                                    this.props.stripe.subscriptions.data
                                      .length === 0 ? (
                                      <Subscribe />
                                    ) : (
                                      <div>
                                        <h4
                                          style={{
                                            marginBottom: "25px",
                                            marginTop: "35px",
                                          }}
                                        >
                                          Suscripciones
                                        </h4>

                                        <p>
                                          Tienes registradada la siguiente
                                          suscripción:
                                        </p>

                                        {this.props.stripe.subscriptions &&
                                          this.props.stripe.subscriptions
                                            .data &&
                                          this.props.stripe.subscriptions.data.map(
                                            (stripeSubs, i) => {
                                              return (
                                                <ul
                                                  key={i}
                                                  className="subscripcionesList"
                                                >
                                                  <li>
                                                    <strong>Estatus:</strong>{" "}
                                                    {stripeSubs.status}
                                                  </li>

                                                  <li>
                                                    <strong>
                                                      Fecha de inicio:
                                                    </strong>{" "}
                                                    {new Date(
                                                      stripeSubs.created * 1000
                                                    ).toLocaleDateString(
                                                      "es-ES",
                                                      {
                                                        weekday: "long",
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                      }
                                                    )}
                                                  </li>
                                                  <li>
                                                    <strong>
                                                      Termino del periodo:
                                                    </strong>{" "}
                                                    {new Date(
                                                      stripeSubs.current_period_end *
                                                        1000
                                                    ).toLocaleDateString(
                                                      "es-ES",
                                                      {
                                                        weekday: "long",
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                      }
                                                    )}
                                                  </li>

                                                  <li>
                                                    <strong>Subtotal:</strong> ${" "}
                                                    {stripeSubs.plan.amount /
                                                      100}{" "}
                                                    MXN + IVA
                                                  </li>
                                                </ul>
                                              );
                                            }
                                          )}

                                        <button
                                          className="btn btn-primary mt-4"
                                          onClick={this.handleOnClickNext}
                                        >
                                          Siguiente
                                        </button>
                                      </div>
                                    )}

                                    <hr />
                                  </div>
                                  <div
                                    id="test-l-3"
                                    className={classnames("content", {
                                      "fade active":
                                        this.state.activeStep === 3,
                                    })}
                                  >
                                    <h4
                                      style={{
                                        marginBottom: "25px",
                                        marginTop: "35px",
                                      }}
                                    >
                                      Resumen
                                    </h4>

                                    <p>
                                      Se asociará tu membresia del
                                      fraccionamiento
                                    </p>

                                    <select
                                      className={classnames("form-control", {
                                        "is-invalid":
                                          errors["membresiaInactivaID"],
                                      })}
                                      id="membresiaInactivaID"
                                      name="membresiaInactivaID"
                                      onChange={this.onChange}
                                      value={this.state.membresiaInactivaID}
                                    >
                                      <option value="0">Elige</option>
                                      {this.props.asociacion.inactivas.map(
                                        (tipo, i) => {
                                          return (
                                            <option
                                              key={tipo._id}
                                              value={tipo._id}
                                            >
                                              {tipo.asociacion.nombre}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>

                                    {errors["membresiaInactivaID"] ? (
                                      <div className="invalid-feedback">
                                        Por favor selecciona un clúster
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <p style={{ marginTop: "1rem" }}>
                                      a la suscripción
                                    </p>

                                    <select
                                      className={classnames("form-control", {
                                        "is-invalid": errors["suscripcionID"],
                                      })}
                                      id="suscripcionID"
                                      name="suscripcionID"
                                      onChange={this.onChange}
                                      value={this.state.suscripcionID}
                                    >
                                      <option value="0">Elige</option>
                                      {this.props.stripe.subscriptions &&
                                        this.props.stripe.subscriptions.data &&
                                        this.props.stripe.subscriptions.data.map(
                                          (stripeSubs, i) => {
                                            return (
                                              <option
                                                key={stripeSubs.id}
                                                value={stripeSubs.id}
                                              >
                                                Subtotal: ${" "}
                                                {stripeSubs.plan.amount / 100}{" "}
                                                MXN + IVA
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>

                                    {errors["suscripcionID"] ? (
                                      <div className="invalid-feedback">
                                        Por favor selecciona un clúster
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <button
                                      className="btn btn-primary mt-4"
                                      onClick={this.handleOnClickStart}
                                    >
                                      Empezar
                                    </button>

                                    {errors["asociacionMembresia"] ? (
                                      <div className="invalid-feedback">
                                        Error al crear la asociación
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <hr />
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                        <br />
                        {/* <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-info"
                              data-toggle="modal"
                              data-target="#bucarAsociacionModal"
                            >
                              Unirse a un fraccionamiento
                            </button>
                          </div> */}
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : this.props.asociacion.membresiaActiva &&
            this.props.asociacion.membresiaActiva.rol &&
            this.props.asociacion.membresiaActiva.rol[0].title ===
              "Administrador" ? (
            <Fragment>
              <div className="col-12 mb-3"></div>
              <Cuenta membresiaActiva={this.props.asociacion.membresiaActiva} />

              {this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
              "Fraccionamiento" ? (
                <div className="col-12 mb-3"></div>
              ) : (
                ""
              )}

              {this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
              "Fraccionamiento" ? (
                <Informacion />
              ) : (
                ""
              )}

              <div className="col-12 mb-3"></div>

              {this.props.asociacion.solicitudesPendientes &&
              this.props.asociacion.solicitudesPendientes.length > 0 ? (
                <Solicitudes
                  aceptarSolicitud={this.props.cambiarStatusMembresia}
                  cancelarSolicitud={this.props.cambiarStatusMembresia}
                />
              ) : (
                ""
              )}

              <div className="col-12 mb-3"></div>

              {this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
              "Fraccionamiento" ? (
                <InformacionAsociacion
                  filasSeleccionadas={this.state.filasSeleccionadas}
                  pagina={this.state.pagina}
                  celdaActiva={this.state.celdaActiva}
                  clicTabla={this.clicTabla}
                  dblClicTabla={this.dblClicTabla}
                  onClickEliminar={this.onClickEliminar}
                  onClickEditar={this.onClickEditar}
                  handleChangeChk={this.handleChangeChk}
                  handleChangeChkAll={(e, check) =>
                    this.handleChangeChkAll(e, check)
                  }
                  onClickConfirmacionEliminar={this.onClickConfirmacionEliminar}
                />
              ) : (
                <InformacionCasa
                  cluster={membresiaActiva.asociacion}
                  clusterSeleccionado={membresiaActiva.asociacion._id}
                  filasSeleccionadas={this.state.filasSeleccionadas}
                  celdaActiva={this.state.celdaActiva}
                  onClickEliminar={this.onClickEliminarCasa}
                  onClickEditar={this.onClickEditarCasa}
                  handleChangeChk={this.handleChangeChk}
                  handleChangeChkAll={(e, check) =>
                    this.handleChangeChkAll(e, check)
                  }
                  onClickConfirmacionEliminar={this.onClickConfirmacionEliminar}
                  onSubmitAgregarCasa={this.onSubmitAgregarCasa}
                  onChange={this.onChange}
                  nombre={this.state.nombreCasa}
                  errors={this.state.errors}
                  onClickAddUser={this.onClickAddUser}
                  onClickEliminarMembresia={this.onClickEliminarMembresia}
                  rolesAdmon={this.rolesAdmon}
                />
              )}

              <div className="col-12 mb-3"></div>

              <AdministracionUsuarios
                filasSeleccionadas={this.state.filasSeleccionadasAdmon}
                pagina={this.state.paginaAdmon}
                celdaActiva={this.state.celdaActivaAdmon}
                clicTabla={this.clicTablaAdmon}
                dblClicTabla={this.dblClicTablaAdmon}
                onClickEliminar={this.onClickEliminarAdmon}
                onClickEditar={this.onClickEditarAdmon}
                handleChangeChk={this.handleChangeChkAdmon}
                handleChangeChkAll={(e, check) =>
                  this.handleChangeChkAllAdmon(e, check)
                }
                onClickConfirmacionEliminar={
                  this.onClickConfirmacionEliminarAdmon
                }
              />

              <div className="col-12 mb-3"></div>
              <Cuotas
                loadStatus={this.props.loadStatus}
                loadTipoServicioCuota={this.props.loadTipoServicioCuota}
                loadFrecuencia={this.props.loadFrecuencia}
                setTipo={this.setTipoCuotaModal}
              />
            </Fragment>
          ) : this.props.asociacion.membresiaActiva &&
            this.props.asociacion.membresiaActiva.rol &&
            this.props.asociacion.membresiaActiva.rol[0].title !==
              "Administrador" ? (
            <Fragment>
              <CondominoInformacion />
              <div className="col-12 mb-3"></div>
              <CondominoCuotas />
            </Fragment>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      Content = (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Fraccionamiento</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Fraccionamiento</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {Content}
        </main>
      </div>
    );
  }
}

Fraccionamiento.propTypes = {
  auth: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadCountries: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  loadRolesAdmon: PropTypes.func.isRequired,
  asociarMembresia: PropTypes.func.isRequired,
  loadStates: PropTypes.func.isRequired,
  loadCities: PropTypes.func.isRequired,
  loadRolAsociacion: PropTypes.func.isRequired,
  loadAsociacionesCiudad: PropTypes.func.isRequired,
  busquedaFraccionamiento: PropTypes.func.isRequired,
  loadSubsAsociacion: PropTypes.func.isRequired,
  emptyAsociaciones: PropTypes.func.isRequired,
  agregarSolicitud: PropTypes.func.isRequired,
  loadSolicitudes: PropTypes.func.isRequired,
  cambiarStatusMembresia: PropTypes.func.isRequired,
  agregarSubasociacion: PropTypes.func.isRequired,
  eliminarSubasociacion: PropTypes.func.isRequired,
  loadSolicitudesPendientes: PropTypes.func.isRequired,
  loadServiciosAsociacion: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  addAsociacion: PropTypes.func.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadTipoDeAsociaciones: PropTypes.func.isRequired,
  loadTipoServicioCuota: PropTypes.func.isRequired,
  loadFrecuencia: PropTypes.func.isRequired,
  loadStatus: PropTypes.func.isRequired,
  sendInvitation: PropTypes.func.isRequired,
  addServicio: PropTypes.func.isRequired,
  deleteServicio: PropTypes.func.isRequired,
  actualizaNombreAsociacion: PropTypes.func.isRequired,
  eliminaMembresia: PropTypes.func.isRequired,
  loadMembresiasInactivas: PropTypes.func.isRequired,
  loadMembresiasAdministradores: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
  stripe: state.stripe,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  loadCountries,
  loadStates,
  loadCities,
  loadAsociacionesCiudad,
  emptyAsociaciones,
  addAsociacion,
  loadTipoDeAsociaciones,
  busquedaFraccionamiento,
  loadRolAsociacion,
  loadSubsAsociacion,
  agregarSolicitud,
  loadSolicitudes,
  cambiarStatusMembresia,
  agregarSubasociacion,
  eliminarSubasociacion,
  loadSolicitudesPendientes,
  loadServiciosAsociacion,
  loadStatus,
  loadTipoServicioCuota,
  loadFrecuencia,
  sendInvitation,
  addServicio,
  deleteServicio,
  actualizaNombreAsociacion,
  eliminaMembresia,
  loadMembresiasInactivas,
  getSubscriptions,
  asociarMembresia,
  loadRolesAdmon,
  loadMembresiasAdministradores,
})(Fraccionamiento);
