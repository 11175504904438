import React, { useState } from "react";
import classnames from "classnames";
import { loadStripe } from "@stripe/stripe-js";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { createSubscription } from "../../actions/stripeActions";

const promise = loadStripe(
  "pk_live_51Hhg48D3K9DBIKCv2i0by52yAd727ZKWVEFAjIoTgcyIDZro9VCVxjXDoGZ4q49yrBfYoFbkGjVzZuwaOq2eryde007FVU1i4Y",
  {
    locale: "es",
  }
);

const hoy = new Date();
const unMes = new Date(hoy.setMonth(hoy.getMonth() + 1));

const SubscribeForm = (props) => {
  const [planSelect, setPlanSelect] = useState("0");
  const [name, setName] = useState("");
  const [messages, _setMessages] = useState("");
  const [mensaje, _setMensaje] = useState(
    "Iniciar prueba gratuita por 30 días"
  );
  const [desactivado, setDesactivado] = useState(false);
  const [subscription, setSubscription] = useState();

  // helper for displaying status messages.
  const setMessage = (message) => {
    _setMessages(`${message}`);
  };

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  const getPlan = (param) => {
    switch (param) {
      case "price_1ITxz2D3K9DBIKCv2RCc1N9D":
        return "Inicial";
      case "price_1ITy1bD3K9DBIKCvK5slxhE3":
        return "Intermedio";
      case "price_1ITy30D3K9DBIKCvlF0CO17C":
        return "Avanzado";
      default:
        return "Selecciona una plan";
    }
  };

  const getCost = (param) => {
    switch (param) {
      case "price_1ITxz2D3K9DBIKCv2RCc1N9D":
        return 349;
      case "price_1ITy1bD3K9DBIKCvK5slxhE3":
        return 749;
      case "price_1ITy30D3K9DBIKCvlF0CO17C":
        return 1499;
      default:
        return 0;
    }
  };

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    setDesactivado(true);
    _setMensaje("Procesando...");

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use card Element to tokenize payment details
    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
      },
    });

    if (error) {
      setMessage(error.message);
      setDesactivado(false);
      _setMensaje("Iniciar prueba gratuita por 30 días");
      return "";
    }

    props.createSubscription({
      priceID: "price_1ITxz2D3K9DBIKCv2RCc1N9D",
      paymentMethodId: paymentMethod.id,
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 resumenCuenta">
          <h4 style={{ marginBottom: "25px", marginTop: "35px" }}>
            {getPlan(planSelect)}
          </h4>
          <hr></hr>

          <div className="row">
            <div className="col-6">
              <p className="pTexto">
                Plan {planSelect === "0" ? "" : getPlan(planSelect)}
              </p>
            </div>
            <div className="col-6">
              <p className="pCosto">30 días gratis</p>
              <span className="spanCosto">
                Luego, $ {getCost(planSelect)} MXN + IVA
              </span>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-6">
              <p className="pTexto">Subtotal</p>
            </div>
            <div className="col-6">
              <p className="pCosto">$ {getCost(planSelect)} MXN</p>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <p className="pTexto">IVA (16 %)</p>
            </div>
            <div className="col-6">
              <p className="pCosto">
                $ {Math.round(getCost(planSelect) * 0.16 * 100) / 100} MXN
              </p>
            </div>
          </div>

          <hr />

          <div className="row align-bottom">
            <div className="col-6">
              <p className="pTexto">Total después del período de prueba</p>
            </div>
            <div className="col-6">
              <p className="pCosto">
                ${" "}
                {Math.round(
                  (getCost(planSelect) + getCost(planSelect) * 0.16) * 100
                ) / 100}{" "}
                MXN
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <form onSubmit={handleSubmit} style={{ marginLeft: "10px" }}>
            <h4 style={{ marginBottom: "25px", marginTop: "35px" }}>
              Ingresa los datos de pago
            </h4>

            <label className="Text">
              <span>
                Selecciona el plan que más se adapte a tus necesidades
              </span>
            </label>

            <select
              className={classnames("form-control", {
                "is-invalid": props.errors["plan"],
              })}
              id="planSelect"
              name="planSelect"
              onChange={(e) => setPlanSelect(e.target.value)}
              value={planSelect}
            >
              <option value="0">Elige un plan</option>
              <option value="price_1ITxz2D3K9DBIKCv2RCc1N9D">Inicial</option>
              <option value="price_1ITy1bD3K9DBIKCvK5slxhE3">Intermedio</option>
              <option value="price_1ITy30D3K9DBIKCvlF0CO17C">Avanzado</option>
              )}
            </select>

            <label className="Text">
              <span>Información de la tarjeta</span>
            </label>

            <CardElement />

            <div className="invalid-feedback-card">{messages}</div>

            <label className="Text">
              <span>Nombre en la tarjeta</span>
            </label>

            <input
              type="text"
              className="inputStriper"
              placeholder=""
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <hr></hr>

            <button
              className="submitButton"
              type="submit"
              disabled={desactivado}
            >
              {mensaje}
            </button>

            <label className="Text">
              <span>
                Si no cancelas la prueba, se te cobrará un importe de MXN{" "}
                {Math.round(
                  (getCost(planSelect) + getCost(planSelect) * 0.16) * 100
                ) / 100}{" "}
                por mes, a partir del{" "}
                {unMes.toLocaleDateString("es-ES", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

const Subscribe = (props) => {
  return (
    <Elements stripe={promise}>
      <SubscribeForm {...props} />
    </Elements>
  );
};

Subscribe.propTypes = {
  asociacion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createSubscription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  errors: state.errors,
});

export default connect(mapStateToProps, { createSubscription })(Subscribe);
