import {
  GET_CUENTA,
  GET_PAGOS,
  GET_CARGOS_CASA,
  GET_ULTIMO_CARGO,
  GET_GASTOS,
  NOTIFICAR_PAGO,
  NOTIFICAR_GASTO,
  NOTIFICAR_EDICION,
} from "../actions/types";

const initialState = {
  cuenta: {},
  pagos: [],
  cargosCasa: [],
  updated: false,
  ultimoCargo: {},
  gastos: [],
  notificarPago: false,
  notificarGasto: false,
  notificarEdicion: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUENTA:
      return {
        ...state,
        cuenta: action.payload,
      };
    case GET_PAGOS:
      return {
        ...state,
        pagos: action.payload,
      };
    case GET_ULTIMO_CARGO:
      return {
        ...state,
        ultimoCargo: action.payload,
      };
    case GET_GASTOS:
      return {
        ...state,
        gastos: action.payload,
      };
    case GET_CARGOS_CASA:
      return {
        ...state,
        cargosCasa: action.payload,
      };
    case NOTIFICAR_GASTO:
      return {
        ...state,
        notificarGasto: action.payload,
      };
    case NOTIFICAR_EDICION:
      return {
        ...state,
        notificarEdicion: action.payload,
      };

    case NOTIFICAR_PAGO:
      return {
        ...state,
        notificarPago: action.payload,
      };

    default:
      return state;
  }
}
