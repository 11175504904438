import React from "react";
import img1 from "../../images/services-icon-1.svg";
import img2 from "../../images/services-icon-2.svg";
import img3 from "../../images/services-icon-3.svg";
import img4 from "../../images/online-payment.png";
import img5 from "../../images/details-2-office-team-work.svg";

export default () => {
  return (
    <div>
      <div id="services" className="cards-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>Nuestros servicios</h2>
              <p className="p-heading p-large">
                Te ayudamos con la administración de tu fraccionamiento,
                facilitándote herramientas que te brindaran total transparencia
                a ti y a tus usuarios sobre cada uno de los pagos y gastos
                realizados, visitas registradas, votaciones sobre temas de
                interés y otros grandiosos servicios.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <img className="card-image" src={img1} alt="alternative" />
                <div className="card-body">
                  <h4 className="card-title">Administración</h4>
                  <p>
                    Llevar los registros de todos los pagos de las cuotas de tu
                    fraccionamiento será muy sencillo con wirhub, así como
                    transparentar con todos los condóminos la información de
                    cada pago realizado.
                  </p>
                </div>
              </div>

              <div className="card">
                <img className="card-image" src={img2} alt="alternative" />
                <div className="card-body">
                  <h4 className="card-title">Control de accesos</h4>
                  <p>
                    Podrás administrar los accesos a cada una de las casas,
                    autorizando y registrando a cada una de las visitas.
                  </p>
                </div>
              </div>

              <div className="card">
                <img className="card-image" src={img3} alt="alternative" />
                <div className="card-body">
                  <h4 className="card-title">Notificaciones</h4>
                  <p>
                    Podrás notificar a todos los usuarios sobre pagos pendientes
                    y realizados, también podrás compartir información relevante
                    sobre tu fraccionamiento.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="basic-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-container">
                <img className="img-fluid" src={img5} alt="alternative" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-container">
                <h2>Notificaciones de todos los movimientos</h2>
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Recordatorios de las cuotas por vencer
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Pagos y gastos realizados</div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Visitas recibidas</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Estados de cuenta</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Información relevante</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="basic-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h2>Acepta pagos en línea</h2>
                <p>
                  No te quedes sin recibir los pagos de tus condóminos, con
                  Wirhub puedes recibir pago en línea sin necesidad que tengas
                  una oficina abierta y sin importar la hora.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img className="img-fluid" src={img4} alt="alternative" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
