import { UPDATE_TTRANSPORTES, LOAD_TABLE } from "../actions/types";

const initialState = {
  tipoDeTransportes: null,
  tabla: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TTRANSPORTES:
      return {
        ...state,
        tipoDeTransportes: action.payload
      };
    case LOAD_TABLE:
      return {
        ...state,
        tabla: action.payload
      };
    default:
      return state;
  }
}
