export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const UPDATED_USER = "UPDATED_USER";
export const UPLOADING_FILE = "UPLOADING_FILE";
export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const SEND_EMAIL = "SEND_EMAIL";

export const SIDEBAR = "SIDEBAR";
export const UPDATE_TTRANSPORTES = "UPDATE_TTRANSPORTES";
export const ADD_TABLE = "ADD_TABLE";
export const LOAD_TABLE = "LOAD_TABLE";
export const ADD_PROFILE = "ADD_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const LOADING = "LOADING";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";

export const ADD_COUNTRIES = "ADD_COUNTRIES";
export const ADD_STATES = "ADD_STATES";
export const ADD_CITIES = "ADD_CITIES";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const LOAD_EXPERIENCE = "LOAD_EXPERIENCE";
export const LOAD_EDUCATION = "LOAD_EDUCATION";
export const LOAD_VERIFICATION = "LOAD_VERIFICATION";
export const CELLPHONE_VALIDATED = "CELLPHONE_VALIDATED";

export const LOAD_MEMBRESIAS = "LOAD_MEMBRESIAS";
export const LOAD_ASOCIACIONES = "LOAD_ASOCIACIONES";
export const EMTPTY_ASOCIACIONES = "EMTPTY_ASOCIACIONES";
export const ADD_ASOCIACION = "ADD_ASOCIACION";
export const LOAD_TIPOASOCIACIONES = "LOAD_TIPOASOCIACIONES";
export const LOAD_ROLASOCIACION = "LOAD_ROLASOCIACION";
export const LOAD_SUBASOCIACIONES = "LOAD_SUBASOCIACIONES";
export const LOAD_SUBASOCIACIONES2STAGE = "LOAD_SUBASOCIACIONES2STAGE";
export const ADD_SOLICITUD = "ADD_SOLICITUD";
export const LOAD_SOLICITUDESP = "LOAD_SOLICITUDESP";
export const CANCELAR_SOLICITUD = "CANCELAR_SOLICITUD";
export const LOAD_MEMBRESIAACTIVA = "LOAD_MEMBRESIAACTIVA";
export const LOAD_SERVICIOS = "LOAD_SERVICIOS";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const LOAD_ESTATUSCUOTAS = "LOAD_ESTATUSCUOTAS";
export const LOAD_TIPODESERVICIOSCUOTAS = "LOAD_TIPODESERVICIOSCUOTAS";
export const LOAD_FRECUENCIA = "LOAD_FRECUENCIA";

export const CONFIRM_EMAIL = "CONFIRM_EMAIL";
export const ISNOT_UPLOADING = "ISNOT_UPLOADING";
export const SENT_EMAIL = "SENT_EMAIL";
export const SENT_INVITATION = "SENT_INVITATION";

export const UPDATE_USERSPREFERENCE = "UPDATE_USERSPREFERENCE";
export const GET_USERSPREFERENCE = "GET_USERSPREFERENCE";
export const GET_TIPODEASOCIACION = "GET_TIPODEASOCIACION";

export const UPDATED_PROFILE = "UPDATED_PROFILE";
export const UPDATED_PASSWORD = "UPDATED_PASSWORD";
export const UPDATED_INFO = "UPDATED_INFO";
export const GET_INVITATIONINFO = "GET_INVITATIONINFO";
export const GET_CUENTA = "GET_CUENTA";
export const ADD_SERVICIO = "ADD_SERVICIO";
export const RESET_ADDED = "RESET_ADDED";
export const GET_PAGOS = "GET_PAGOS";
export const GET_CARGOS_CASA = "GET_CARGOS_CASA";
export const GET_TIPO_PAGOS = "GET_TIPO_PAGOS";
export const GET_FORMA_PAGOS = "GET_FORMA_PAGOS";
export const GET_ULTIMO_CARGO = "GET_ULTIMO_CARGO";
export const LOAD_NOTIFICACIONES = "LOAD_NOTIFICACIONES";
export const GET_GASTOS = "GET_GASTOS";
export const NOTIFICAR_PAGO = "NOTIFICAR_PAGO";
export const NOTIFICAR_GASTO = "NOTIFICAR_GASTO";
export const SENT_RECOVERYEMAIL = "SENT_RECOVERYEMAIL";
export const CONFIRM_TOKEN = "CONFIRM_TOKEN";
export const PUBLICAR_COMUNICADO = "PUBLICAR_COMUNICADO";
export const GET_COMUNICADOS = "GET_COMUNICADOS";
export const GET_ACCESOS = "GET_ACCESOS";
export const GET_TIPO_ACCESOS = "GET_TIPO_ACCESOS";
export const GET_TIPO_IDENTIFICACION = "GET_TIPO_IDENTIFICACION";
export const GET_EMPRESAS = "GET_EMPRESAS";
export const GET_ULTIMOS_ACCESOS_DESTINO = "GET_ULTIMOS_ACCESOS_DESTINO";
export const LOAD_ESTATUS_ACCESOS = "LOAD_ESTATUS_ACCESOS";
export const GET_SAS = "GET_SAS";
export const GET_DATA_FILES = "GET_DATA_FILES";
export const NOTIFICAR_EDICION = "NOTIFICAR_EDICION";
export const GET_ESTADISTICA_CASAS = "GET_ESTADISTICA_CASAS";
export const GET_ESTADISTICA_USUARIOS = "GET_ESTADISTICA_USUARIOS";
export const GET_ESTADISTICA_CLUSTERS = "GET_ESTADISTICA_CLUSTERS";
export const GET_ESTADISTICA_CUOTAS = "GET_ESTADISTICA_CUOTAS";
export const GET_ESTADISTICA_CUENTA = "GET_ESTADISTICA_CUENTA";
export const GET_AREAS_COMUNES = "GET_AREAS_COMUNES";
export const GET_EVENTOS_AREA = "GET_EVENTOS_AREA";
export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const GET_CHECKOUT_SESSION = "GET_CHECKOUT_SESSION";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
export const LOAD_MEMBRESIAS_INACTIVAS = "LOAD_MEMBRESIAS_INACTIVAS";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const GET_PAYMENTMETHOD = "GET_PAYMENTMETHOD";
export const GET_URL_PORTAL_CUSTOMER = "GET_URL_PORTAL_CUSTOMER";
export const LOAD_ROLES_ADMON = "LOAD_ROLES_ADMON";
export const GET_ADMINISTRADORES = "GET_ADMINISTRADORES";
export const GET_TAREAS = "GET_TAREAS";
export const GET_ESTADO_CUENTA = "GET_ESTADO_CUENTA";
