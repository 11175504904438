import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import { validateToken, updatePassword } from "../../actions/authActions";
import { loadMembresias } from "../../actions/asociacionActions";
import { loadNotificaciones } from "../../actions/notificacionActions";
import { withRouter } from "react-router";
import classnames from "classnames";

class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };

    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmitChangePassword = (e) => {
    e.preventDefault();

    this.props.updatePassword({
      user: this.props.match.params.token,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    this.props.validateToken(this.props.match.params.token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated) {
      this.props.loadMembresias();
      this.props.loadNotificaciones();
      this.props.history.push("/dashboard");
    }
  }

  render() {
    const { errors } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <main role="main" className="col-12">
            <div className="row wrapper border-bottom white-bg page-heading justify-content-center">
              <div className="col-6">
                <h2 style={{ textAlign: "center" }}>Recupera tu contraseña</h2>
              </div>
            </div>
            <div className="row justify-content-center mb-5">
              <div className="col-4" style={{ minHeight: "200px" }}>
                <h5 style={{ textAlign: "center", paddingTop: "50px" }}>
                  {errors.usuarioNotFound ? errors.usuarioNotFound : ""}
                  {errors.error ? errors.error : ""}
                </h5>

                <h5 style={{ textAlign: "center" }}>
                  {this.props.auth.confirmationToken ? (
                    <div className="card">
                      <div className="card-body">
                        <form onSubmit={this.onSubmitChangePassword}>
                          <div className="form-group">
                            <label htmlFor="passwordID">Nueva contraseña</label>
                            <input
                              type="password"
                              className={classnames("form-control", {
                                "is-invalid": errors.password,
                              })}
                              id="passwordID"
                              name="password"
                              onChange={this.onChange}
                              value={this.state.password}
                              autoComplete="off"
                            />
                            {errors["password"] && (
                              <div className="invalid-feedback">
                                {errors["password"]}
                              </div>
                            )}
                          </div>

                          <div className="form-group">
                            <label htmlFor="confirmPasswordID">
                              Confirmar nueva contraseña
                            </label>
                            <input
                              type="password"
                              className={classnames("form-control", {
                                "is-invalid": errors.confirmPassword,
                              })}
                              id="confirmPasswordID"
                              name="confirmPassword"
                              onChange={this.onChange}
                              value={this.state.confirmPassword}
                              autoComplete="off"
                            />
                            {errors["confirmPassword"] && (
                              <div className="invalid-feedback">
                                {errors["confirmPassword"]}
                              </div>
                            )}
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Cambiar contraseña
                          </button>
                        </form>
                        <hr></hr>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </h5>

                {this.props.auth.isUploading ? <Spinner /> : ""}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

Recovery.propTypes = {
  auth: PropTypes.object.isRequired,
  validateToken: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadNotificaciones: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, {
    validateToken,
    updatePassword,
    loadMembresias,
    loadNotificaciones,
  })(Recovery)
);
