/**
 * We do this to get a copy of moment w/timezones without polluting the global scope
 */
require("moment/locale/es.js");
let moment = require("moment");
// moment-timezone makes changes to existing moment object, returning adjusted
moment = require("moment-timezone/builds/moment-timezone-with-data-2012-2022");

export const currentTimezone = moment.tz.guess();
/**
 * This will create a 'moment' object that *is* moment.tz(), and automatically use the
 * 'timezone' used when you called 'getMoment()'
 */
export const getMoment = (timezone = currentTimezone) => {
  const m = (...args) => moment.tz(...args, timezone);
  m.localeData = moment.localeData;

  return m;
};

/**
 * 'datetime' is a JS Date object
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const convertDateTimeToDate = (datetime, tzMoment) => {
  return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
};

/**
 * 'hour' is an integer from 0 - 23 specifying the hour to set on the Date
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getTimeAsDate = (hour, tzMoment) => {
  const m = tzMoment("1970-01-01");
  return new Date(m.hour(hour).minute(0).format());
};

/*
 * 'now' is your 'getNow' method
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};
