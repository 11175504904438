import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import { loadMembresias } from "../../actions/asociacionActions";
import { getCurrentPage } from "../../actions/pageActions";
import { loadNotificaciones } from "../../actions/notificacionActions";
import Spinner from "../common/Spinner";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class Notificaciones extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;
    const { notificaciones } = this.props.notificaciones;
    const { membresiaActiva } = this.props.asociacion;

    let content;
    let modalContent;

    modalContent = <div>Algo</div>;

    if (Object.keys(membresiaActiva).length > 0) {
      content = (
        <div className="row">
          <div className="col-12">
            {notificaciones &&
            notificaciones[0] &&
            notificaciones[0].lista &&
            notificaciones[0].lista.length > 0
              ? notificaciones[0].lista.map((notificacion) => {
                  return (
                    <div className="notificacionElement" key={notificacion._id}>
                      <div className="_4l_v">
                        <span>
                          <span className="tituloNotificacion">
                            {notificacion.titulo}
                          </span>
                          <span className="contenidoNotificacion">
                            {notificacion.texto}
                          </span>
                        </span>
                        <div className="_33f clearfix" direction="left">
                          <div className="_ohe lfloat"></div>
                          <div className="">
                            <div className="_42ef _8u">
                              <span className="_6t8b">
                                <span className="timestampContent">
                                  {timeAgo.format(
                                    new Date(notificacion.created)
                                  )}
                                </span>

                                <span></span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      );
    } else {
      content = <Spinner />;
    }

    return (
      <div className="row justify-content-md-center">
        {modalContent}
        <main role="main" className="col-10 mt-2">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Tus notificaciones</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Notificaciones</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Notificaciones.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadNotificaciones: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
  notificaciones: state.notificaciones,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  loadNotificaciones,
})(Notificaciones);
