import React, { Component } from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { sendInvitationAdmon } from "../../actions/asociacionActions";

import "../../css/tabla.css";

class AdministracionUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
      agregarAdministrador: false,
      rolesAdmon: "0",
      nombreAdministrador: "",
      apellidoAdministrador: "",
      correoAdministrador: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
    this.onClickAgregarAdministrador = this.onClickAgregarAdministrador.bind(
      this
    );
    this.onSubmitAgregarAdministrador = this.onSubmitAgregarAdministrador.bind(
      this
    );
  }

  conClickAgregarCluster(e) {
    e.preventDefault();
    /* window.abrirModal("agregarClusterModal"); */
  }

  onClickAgregarAdministrador = (e) => {
    this.setState({
      agregarAdministrador: !this.state["agregarAdministrador"],
    });
  };

  onSubmitAgregarAdministrador = (e) => {
    e.preventDefault();

    const invitation = {
      nombreAdministrador: this.state.nombreAdministrador,
      apellidoAdministrador: this.state.apellidoAdministrador,
      correoAdministrador: this.state.correoAdministrador,
      asociacion:
        this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
        "Clúster"
          ? this.props.asociacion.membresiaActiva.asociacion._id
          : this.props.clusterSeleccionado
          ? this.props.clusterSeleccionado
          : "",
      rolesAdmon: this.state.rolesAdmon,
    };
    this.props.sendInvitationAdmon(invitation);
  };

  adelantarPagina = (e) => {
    if (
      Math.ceil(
        this.props.asociacion.membresiaActiva.administradores.length / 5
      ) > this.state.pagina
    ) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina + 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ pagina: parseInt(e.currentTarget.dataset.id, 10) });
  };

  atrasarPagina = (e) => {
    if (this.state.pagina !== 1) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina - 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { membresiaActiva, administradores } = this.props.asociacion;
    const { filasSeleccionadas, celdaActiva, errors, catalog } = this.props;
    const { pagina, busqueda, agregarAdministrador } = this.state;

    if (!membresiaActiva) {
      return <div>Loading...</div>;
    }

    return (
      <div className="col-12">
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand">Administración de usuarios</a>
          <div className="form-inline">
            <button
              className="btn btn-success btn-tabla"
              onClick={this.onClickAgregarAdministrador}
            >
              <i className="material-icons">&#xE147;</i> <span>Agregar</span>
            </button>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
              name="busqueda"
              onChange={this.onChange}
            />
          </div>
        </nav>
        <div className="card">
          <div className="card-body">
            <div className="container list-asociaciones">
              <div className="row justify-content-md-center">
                <div
                  className={classnames("col-4 mb-3", {
                    ocultar: !agregarAdministrador,
                  })}
                >
                  <form
                    id="agregarAdministradorForm"
                    onSubmit={this.onSubmitAgregarAdministrador}
                  >
                    <div className="form-group">
                      <label htmlFor="correoAdministrador">
                        Correo del administrador
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["correoAdministrador"],
                        })}
                        id="correoAdministrador"
                        placeholder="Correo electrónico"
                        name="correoAdministrador"
                        value={this.state.correoAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["correoAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["correoAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="nombreAdministrador">Nombre</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["nombreAdministrador"],
                        })}
                        id="nombreAdministrador"
                        placeholder="Nombre"
                        name="nombreAdministrador"
                        value={this.state.nombreAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["nombreAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["nombreAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="apellidoAdministrador">Apellido</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["apellidoAdministrador"],
                        })}
                        id="apellidoAdministrador"
                        placeholder="Apellido"
                        name="apellidoAdministrador"
                        value={this.state.apellidoAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["apellidoAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["apellidoAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="rolesAdmon">Rol</label>
                      <select
                        className={classnames("form-control", {
                          "is-invalid": errors["rol"],
                        })}
                        id="rolesAdmon"
                        name="rolesAdmon"
                        onChange={this.onChange}
                        value={this.state.rolesAdmon}
                      >
                        <option value="0">Elige un rol</option>
                        {this.props.catalog.rolesAdmon &&
                          this.props.catalog.rolesAdmon.map((tipo, i) => {
                            return (
                              <option key={tipo._id} value={tipo._id}>
                                {tipo.title}
                              </option>
                            );
                          })}
                      </select>
                      {errors["rol"] && (
                        <div className="invalid-feedback">{errors["rol"]}</div>
                      )}
                    </div>

                    <input
                      type="submit"
                      className="btn btn-info btn-block mt-4"
                      value="Agregar"
                    />
                  </form>
                </div>

                {membresiaActiva.asociacion &&
                membresiaActiva.asociacion.departamentos.length > 0 ? (
                  <div className="col-12">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Apellidos</th>
                          <th>Correo</th>
                          <th>Rol</th>
                          <th>Último login</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {administradores
                          .filter((administrador) =>
                            busqueda
                              ? administrador.user.email
                                  .toUpperCase()
                                  .includes(busqueda.toUpperCase())
                              : true
                          )
                          .map((fila, index) => {
                            if (
                              index + 1 <= pagina * 5 &&
                              index + 1 > pagina * 5 - 5
                            )
                              return (
                                <tr
                                  key={fila._id}
                                  className={
                                    fila._id === celdaActiva
                                      ? "table-active pointerFila"
                                      : "pointerFila"
                                  }
                                  onClick={() => this.props.clicTabla(fila._id)}
                                  onDoubleClick={() =>
                                    this.props.dblClicTabla(fila._id)
                                  }
                                >
                                  <td>{fila.user.name}</td>
                                  <td>{fila.user.lastName}</td>
                                  <td>{fila.user.email}</td>
                                  <td>{fila.rol.title}</td>
                                  <td>{fila.user.lastLogin}</td>
                                  <td>
                                    {/* <a
                                      href="#editInstanciaModelo"
                                      className="edit"
                                      data-toggle="modal"
                                      onClick={() => {
                                        this.props.onClickEditar(fila._id);
                                      }}
                                    >
                                      <i
                                        className="material-icons"
                                        data-toggle="tooltip"
                                        title="Editar"
                                      >
                                        &#xE254;
                                      </i>
                                    </a> */}
                                    <a
                                      href="#deleteObjectModal"
                                      className="delete"
                                      data-toggle="modal"
                                      onClick={() => {
                                        this.props.onClickEliminar(fila._id);
                                      }}
                                    >
                                      <i
                                        className="material-icons"
                                        data-toggle="tooltip"
                                        title="Eliminar"
                                      >
                                        &#xE872;
                                      </i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            else return "";
                          })}
                      </tbody>
                    </table>

                    <div className="clearfix">
                      <div className="hint-text">
                        Mostrando{" "}
                        <b>
                          {Math.ceil(
                            administradores.filter((administrador) =>
                              busqueda
                                ? administrador.user.email
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length / 5
                          ) === pagina
                            ? administradores.filter((administrador) =>
                                busqueda
                                  ? administrador.user.email
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length %
                                5 ===
                              0
                              ? 5
                              : administradores.filter((administrador) =>
                                  busqueda
                                    ? administrador.user.email
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length % 5
                            : administradores.filter((administrador) =>
                                busqueda
                                  ? administrador.user.email
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length === 0
                            ? "0"
                            : "5"}
                        </b>{" "}
                        de{" "}
                        <b>
                          {
                            administradores.filter((administrador) =>
                              busqueda
                                ? administrador.user.email
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length
                          }
                        </b>{" "}
                        registros
                      </div>
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className={
                              pagina === 1 ? "page-link disabled" : "page-link"
                            }
                            onClick={this.atrasarPagina}
                          >
                            Anterior
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              administradores.filter((administrador) =>
                                busqueda
                                  ? administrador.user.email
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length / 5
                            ),
                          },
                          (item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  pagina === i + 1
                                    ? "page-item active"
                                    : "page-item"
                                }
                              >
                                <button
                                  className="page-link"
                                  onClick={this.actualizaPagina}
                                  data-id={i + 1}
                                >
                                  {i + 1}
                                </button>
                              </li>
                            );
                          }
                        )}

                        <li className="page-item">
                          <button
                            onClick={this.adelantarPagina}
                            className={
                              Math.ceil(
                                administradores.filter((administrador) =>
                                  busqueda
                                    ? administrador.user.email
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length / 5
                              ) <= pagina
                                ? "page-link disabled"
                                : "page-link"
                            }
                          >
                            Siguiente
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    Aún no tienes administradores, registrados,{" "}
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={this.conClickAgregarCluster}
                    >
                      agregar el primero!
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdministracionUsuarios.propTypes = {
  asociacion: PropTypes.object.isRequired,
  sendInvitationAdmon: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,

  catalog: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  catalog: state.catalog,
  errors: state.errors,
});

export default connect(mapStateToProps, { sendInvitationAdmon })(
  AdministracionUsuarios
);
