import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, sendRecoveryEmail } from "../../actions/authActions";
import { loadMembresias } from "../../actions/asociacionActions";
import classnames from "classnames";
import { loadNotificaciones } from "../../actions/notificacionActions";
import { withRouter } from "react-router";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      sentEmailRecoveryBtn: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth.isAuthenticated) {
      this.props.loadMembresias();
      this.props.loadNotificaciones();
      this.props.history.push("/dashboard");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  sendRecoveryEmail() {
    this.props.sendRecoveryEmail({ email: this.state.email });
    this.setState({ sentEmailRecoveryBtn: true });
  }

  render() {
    const { errors, sentEmailRecoveryBtn } = this.state;
    return (
      <Fragment>
        <div
          className="modal fade"
          id="restablecerPassword"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="restablecerPasswordLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="restablecerPasswordLabel">
                  Restablecer contraseña
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p className="p-large mt-3" style={{ textAlign: "center" }}>
                  {errors.usuarioNoExiste
                    ? errors.usuarioNoExiste
                    : `Te enviaremos un correo a la dirección 
                  ${this.state.email} con las instrucciones para
                  que puedas restablecer tu contraseña`}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className={classnames("btn btn-primary", {
                    disabled: sentEmailRecoveryBtn,
                  })}
                  onClick={() =>
                    sentEmailRecoveryBtn ? {} : this.sendRecoveryEmail()
                  }
                >
                  {sentEmailRecoveryBtn ? "Correo enviado" : "Enviar correo"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <form
          onSubmit={this.onSubmit}
          className={classnames("justify-content-center", {
            "form-inline": !errors.emailLogin && !errors.passwordLogin,
          })}
          noValidate
        >
          <div className="form-group inputPlaceholder">
            <input
              type="email"
              className={classnames("form-control-input mr-2", {
                "is-invalid": errors.emailLogin,
                "mb-2": !errors.emailLogin || !errors.passwordLogin,
              })}
              placeholder=""
              name="email"
              id="email"
              value={this.state.email}
              onChange={this.onChange}
              required
            />
            <label className="label-control" htmlFor="email">
              Correo
            </label>
            {errors.emailLogin && (
              <div className="error">{errors.emailLogin}</div>
            )}
          </div>
          <div className="form-group inputPlaceholder">
            <input
              type="password"
              className={classnames("form-control-input mr-2", {
                "is-invalid": errors.passwordLogin,
                "mb-2": !errors.emailLogin || !errors.passwordLogin,
              })}
              placeholder=""
              name="password"
              value={this.state.password}
              onChange={this.onChange}
              required
            />
            <label className="label-control" htmlFor="email">
              Contraseña
            </label>
            {errors.passwordLogin && (
              <div className="error">{errors.passwordLogin}</div>
            )}
          </div>

          <button
            type="button"
            className={classnames("btn btn-warning mb-2 mr-2", {
              "d-none": errors.passwordLogin !== "Contraseña incorrecta",
            })}
            data-toggle="modal"
            data-target="#restablecerPassword"
          >
            ¿Olvidaste la contraseña?
          </button>
          <button type="submit" className="btn btn-info mb-2">
            Entrar
          </button>
        </form>
      </Fragment>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  sendRecoveryEmail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadNotificaciones: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    loadMembresias,
    loadNotificaciones,
    sendRecoveryEmail,
  })(Login)
);
