import React, { Component } from "react";
import TextFieldGroup from "../utils/TextFielGroup";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import {
  loadMembresias,
  getTareas,
  agregaActualizaTarea,
  eliminaTarea,
} from "../../actions/asociacionActions";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";
TimeAgo.addLocale(es);

const timeAgo = new TimeAgo("es-MX");
class Kanban extends Component {
  constructor() {
    super();
    this.state = {
      asociacionMembresia: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.departamento[0]
            .asociacion.length > 0
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getTareas({
        asociacion: asociacion,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.departamento[0]
            .asociacion.length > 0
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getTareas({
        asociacion: asociacion,
      });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  onSubmitTareas = (e) => {
    e.preventDefault();
    this.props.agregaActualizaTarea({
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
      estatus: this.state.estatus,
      id: this.state.tareaSeleccionada,
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
    });
    window.cerrrarModal("modalTareas");
  };

  onAgregarTarea = () => {
    window.abrirModal("modalTareas");
    this.setState({
      nombre: "",
      descripcion: "",
      estatus: "TODO",
      tareaSeleccionada: "",
    });
  };

  onEliminarTarea = (e) => {
    e.preventDefault();
    this.props.eliminaTarea({
      id: this.state.tareaSeleccionada,
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
    });
    window.cerrrarModal("modalTareas");
  };

  render() {
    const { percentage } = this.props.profile;
    const { tareas } = this.props.asociacion;
    const { errors } = this.props;

    const {
      modalAbierto,
      tareaSeleccionada,
      nombre,
      descripcion,
      estatus,
    } = this.state;

    let content;
    let modalContent;

    modalContent = (
      <div id="modalTareas" className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {tareaSeleccionada ? "Crear tarea" : "Editar tarea"}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form
                id="agregaActualizaTarea"
                onSubmit={this.onSubmitTareas}
                noValidate
              >
                <TextFieldGroup
                  name="nombre"
                  error={this.props.errors["nombre"]}
                  placeholder="Nombre"
                  value={this.state["nombre"] ? this.state["nombre"] : ""}
                  onChange={this.onChange}
                  label="Nombre"
                  id="nombreID"
                />

                <TextFieldGroup
                  name="descripcion"
                  error={this.props.errors["descripcion"]}
                  placeholder="Descripción"
                  value={
                    this.state["descripcion"] ? this.state["descripcion"] : ""
                  }
                  onChange={this.onChange}
                  label="Descripción"
                  id="descripcionID"
                />

                <div className="form-group">
                  <label htmlFor="estatus">Estatus</label>
                  <select
                    className={classnames("form-control", {
                      "is-invalid": errors["estatus"],
                    })}
                    id="estatus"
                    name="estatus"
                    onChange={this.onChange}
                    value={this.state.estatus}
                  >
                    <option value="TODO">Pendiente</option>
                    <option value="IN_PROGRESS">En progreso</option>
                    <option value="DONE">Realizada</option>
                  </select>
                  {errors["estatus"] && (
                    <div className="invalid-feedback">{errors["estatus"]}</div>
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {tareaSeleccionada ? (
                <input
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  value="Eliminar"
                  onClick={this.onEliminarTarea}
                />
              ) : (
                ""
              )}

              <input
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                value="Cancel"
              />

              <input
                type="submit"
                className="btn btn-info"
                value="Guardar"
                form="agregaActualizaTarea"
              />
            </div>
          </div>
        </div>
      </div>
    );

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <h5 class="card-header">Pendientes</h5>
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div class="list-group w-100">
                          {tareas &&
                            tareas
                              .filter((tarea) => tarea.estatus === "TODO")
                              .map((tarea, i) => (
                                <a
                                  href="#"
                                  class="list-group-item list-group-item-action"
                                  aria-current="true"
                                  key={tarea._id}
                                  onClick={() => {
                                    window.abrirModal("modalTareas");

                                    this.setState({
                                      nombre: tarea.nombre,
                                      descripcion: tarea.descripcion,
                                      estatus: tarea.estatus,
                                      tareaSeleccionada: tarea._id,
                                    });
                                  }}
                                >
                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{tarea.nombre}</h5>
                                    <small>
                                      Creada{" "}
                                      {timeAgo.format(new Date(tarea.created))}
                                    </small>
                                  </div>
                                  <p class="mb-1">{tarea.descripcion}</p>
                                </a>
                              ))}
                          {tareas &&
                          tareas.filter((tarea) => tarea.estatus === "TODO")
                            .length === 0 ? (
                            <p class="mb-1">No hay ninguna tarea registrada</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <h5 class="card-header">En proceso</h5>
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div class="list-group w-100">
                          {tareas &&
                            tareas
                              .filter(
                                (tarea) => tarea.estatus === "IN_PROGRESS"
                              )
                              .map((tarea, i) => (
                                <a
                                  href="#"
                                  class="list-group-item list-group-item-action"
                                  aria-current="true"
                                  key={tarea._id}
                                  onClick={() => {
                                    window.abrirModal("modalTareas");

                                    this.setState({
                                      nombre: tarea.nombre,
                                      descripcion: tarea.descripcion,
                                      estatus: tarea.estatus,
                                      tareaSeleccionada: tarea._id,
                                    });
                                  }}
                                >
                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{tarea.nombre}</h5>
                                    <small>
                                      Creada{" "}
                                      {timeAgo.format(new Date(tarea.created))}
                                    </small>
                                  </div>
                                  <p class="mb-1">{tarea.descripcion}</p>
                                </a>
                              ))}
                          {tareas &&
                          tareas.filter(
                            (tarea) => tarea.estatus === "IN_PROGRESS"
                          ).length === 0 ? (
                            <p class="mb-1">No hay ninguna tarea registrada</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <h5 class="card-header">Finalizadas</h5>
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div class="list-group w-100">
                          {tareas &&
                            tareas
                              .filter((tarea) => tarea.estatus === "DONE")
                              .map((tarea, i) => (
                                <a
                                  href="#"
                                  class="list-group-item list-group-item-action"
                                  aria-current="true"
                                  key={tarea._id}
                                  onClick={() => {
                                    window.abrirModal("modalTareas");

                                    this.setState({
                                      nombre: tarea.nombre,
                                      descripcion: tarea.descripcion,
                                      estatus: tarea.estatus,
                                      tareaSeleccionada: tarea._id,
                                    });
                                  }}
                                >
                                  <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{tarea.nombre}</h5>
                                    <small>
                                      Creada{" "}
                                      {timeAgo.format(new Date(tarea.created))}
                                    </small>
                                  </div>
                                  <p class="mb-1">{tarea.descripcion}</p>
                                </a>
                              ))}
                          {tareas &&
                          tareas.filter((tarea) => tarea.estatus === "DONE")
                            .length === 0 ? (
                            <p class="mb-1">No hay ninguna tarea registrada</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Kanban</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Kanban</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <div></div>
              <button
                type="button"
                class="btn btn-secondary p-2"
                style={{
                  marginBottom: "20px",
                }}
                onClick={this.onAgregarTarea}
              >
                Agregar tarea
              </button>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Kanban.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  getTareas: PropTypes.func.isRequired,
  agregaActualizaTarea: PropTypes.func.isRequired,
  eliminaTarea: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  getTareas,
  agregaActualizaTarea,
  eliminaTarea,
})(Kanban);
