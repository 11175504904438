import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendEmail } from "../../actions/landingActions";
import { NotificationManager } from "react-notifications";

class Contacto extends Component {
  constructor() {
    super();
    this.state = {
      paqueteF: "0",
      nameF: "",
      mailF: "",
      cellphoneF: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newEmail = {
      nombre: this.state.nameF,
      correo: this.state.mailF,
      celular: this.state.cellphoneF,
      paquete: this.state.paqueteF,
    };

    this.props.sendEmail(newEmail);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.landing &&
      this.props.landing !== prevProps.landing &&
      this.props.landing.sentEmail
    ) {
      this.setState({
        nameF: "",
        mailF: "",
        cellphoneF: "",
        paqueteF: "0",
      });

      NotificationManager.success("", "Correo enviado satifactoriamente", 6000);
    }
  }

  render() {
    return (
      <div id="contacto" className="form-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-2" style={{ textAlign: "center" }}>
              <h2>Información de contacto</h2>
              <ul className="list-unstyled li-space-lg">
                <li className="address">
                  No dudes en llamarnos o enviarnos un mensaje
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i>Puebla, México
                  <i className="fas fa-phone ml-2"></i>
                  <a className="turquoise" href="tel:00526645832420">
                    +52 664 583 2420
                  </a>
                  <i className="fab fa-whatsapp ml-2"></i>
                  <a
                    href="https://wa.me/526645832420"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Whatsapp
                  </a>
                  <i className="fas fa-envelope  ml-2"></i>
                  <a className="turquoise" href="mailto:info@wirhub.com">
                    info@wirhub.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h2>Te contactaremos</h2>
                <p>
                  Wirhub es una herramienta que te facilitará la administración
                  de tu fraccionamiento y la visualización de tus
                  responsabilidades como propietario dentro un inmueble en el
                  régimen de propiedad en condominio. Descubre todo lo que
                  Wirhub puede hacer por ti.
                </p>
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      <strong className="blue">Administra fácilmente</strong>{" "}
                      pagos, gastos, accesos y muchas cosas más
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      <strong className="blue">
                        Incrementa el número de usuarios al corriente
                      </strong>{" "}
                      en sus responsabilidades.
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      <strong className="blue">
                        Encuentra todo en un solo lugar
                      </strong>
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      <strong className="blue">Notificaciones</strong> cuando
                      los plazos de tus responsabilidades estén por cumplirse
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-container">
                <form
                  id="requestForm"
                  data-toggle="validator"
                  data-focus="false"
                  onSubmit={this.onSubmit}
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-input"
                      id="nameF"
                      name="nameF"
                      onChange={this.onChange}
                      value={this.state.nameF}
                      required
                    />
                    <label className="label-control" htmlFor="nameF">
                      Nombre completo
                    </label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control-input"
                      id="mailF"
                      name="mailF"
                      onChange={this.onChange}
                      value={this.state.mailF}
                      required
                    />
                    <label className="label-control" htmlFor="mailF">
                      Email
                    </label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control-input"
                      id="cellphoneF"
                      name="cellphoneF"
                      onChange={this.onChange}
                      value={this.state.cellphoneF}
                      required
                    />
                    <label className="label-control" htmlFor="cellphoneF">
                      Teléfono
                    </label>
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control-select"
                      id="paqueteF"
                      name="paqueteF"
                      onChange={this.onChange}
                      value={this.state.paqueteF}
                      required
                    >
                      <option className="select-option" value="0">
                        Interesado en...
                      </option>
                      <option className="select-option" value="1">
                        Inicial
                      </option>
                      <option className="select-option" value="2">
                        Intermedio
                      </option>
                      <option className="select-option" value="3">
                        Avanzado
                      </option>
                      <option className="select-option" value="4">
                        Personalizado
                      </option>
                    </select>
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="form-control-submit-button"
                    >
                      Enviar
                    </button>
                  </div>
                  <div className="form-message">
                    <div
                      id="rmsgSubmit"
                      className="h3 text-center hidden"
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contacto.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  landing: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  landing: state.landing,
});

export default connect(mapStateToProps, { sendEmail })(Contacto);
