import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../layout/Header";
import Services from "../layout/Services";
import Clientes from "../layout/Clientes";
import Acerca from "../layout/Acerca";
import Contacto from "../layout/Contacto";
import Register from "../layout/Register";
import { withRouter } from "react-router";

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    const script = document.createElement("script");
    script.src =
      "https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.compatibility.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="landing">
        <Header />
        <Services />
        <Clientes />
        <Acerca />
        <Contacto />
        <Register token={this.props.match.params.token} />
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(Landing));
