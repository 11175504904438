import React from "react";

export default () => {
  return (
    <div id="precios" className="cards-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Elige el plan que más se ajuste a tus necesidades</h2>
            <p className="p-heading p-large">
              Todos nuestros planes incluyen 30 días de prueba
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="card-title">Inicial</div>
                <div className="card-subtitle">Usuarios ilimitados</div>
                <hr className="cell-divide-hr" />
                <div className="price">
                  <span className="currency">$</span>
                  <span className="value">449</span>
                  <div className="frequency">mensual</div>
                </div>
                <hr className="cell-divide-hr" />
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Control de cuotas, pagos y gastos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Notificaciones vía Email</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Noticias y avisos</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Administración de áreas de uso común
                    </div>
                  </li>

                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">
                      Control de acceso de visitas
                    </div>
                  </li>

                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">Encuestas y votaciones</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">
                      Administración de tramites internos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">
                      Notificaciones vía SMS
                      <p className="letras">*Sin mensajes de texto</p>
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">Pagos en línea</div>
                  </li>
                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">Avisos de ocasión</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">App Android/IOS</div>
                  </li>
                </ul>

                <div className="button-wrapper">
                  <a className="btn-solid-reg page-scroll" href="#register">
                    Contratar
                  </a>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="label">
                <p className="best-value">Más contratado</p>
              </div>
              <div className="card-body">
                <div className="card-title">Intermedio</div>
                <div className="card-subtitle">Usuarios ilimitados</div>
                <hr className="cell-divide-hr" />
                <div className="price">
                  <span className="currency">$</span>
                  <span className="value">999</span>
                  <div className="frequency">mensual</div>
                </div>
                <hr className="cell-divide-hr" />
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Control de cuotas, pagos y gastos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Control de acceso de visitas
                    </div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Notificaciones vía Email</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Noticias y avisos</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Administración de áreas de uso común
                    </div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Encuestas y votaciones</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Administración de tramites internos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Notificaciones vía SMS{" "}
                      <p className="letras">*limitado a 100 mensajes</p>
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">Pagos en línea</div>
                  </li>
                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">Avisos de ocasión</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-times"></i>
                    <div className="media-body">App Android/IOS</div>
                  </li>
                </ul>
                <div className="button-wrapper">
                  <a className="btn-solid-reg page-scroll" href="#register">
                    Contratar
                  </a>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="card-title">Avanzado</div>
                <div className="card-subtitle">Usuarios ilimitados</div>
                <hr className="cell-divide-hr" />
                <div className="price">
                  <span className="currency">$</span>
                  <span className="value">1999</span>
                  <div className="frequency">mensual</div>
                </div>
                <hr className="cell-divide-hr" />
                <ul className="list-unstyled li-space-lg">
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Control de cuotas, pagos y gastos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Control de acceso de visitas
                    </div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Notificaciones vía Email</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Noticias y avisos</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Administración de áreas de uso común
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Encuestas y votaciones</div>
                  </li>

                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Administración de tramites internos
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">
                      Notificaciones vía SMS{" "}
                      <p className="letras">*limitado a 200 mensajes</p>
                    </div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Pagos en línea</div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">Avisos de ocasión</div>
                  </li>
                  <li className="media">
                    <i className="fas fa-check"></i>
                    <div className="media-body">App Android/IOS</div>
                  </li>
                </ul>
                <div className="button-wrapper">
                  <a className="btn-solid-reg page-scroll" href="#register">
                    Contratar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
