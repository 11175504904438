import React, { Component, Fragment } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import classnames from "classnames";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import {
  loadMembresias,
  loadAccesos,
  getUtimosAccesosDestino,
  registrarVisita,
  resetUtimosAccesosDestino,
  finalizarVisita,
} from "../../actions/asociacionActions";
import {
  loadTipoDeAccesos,
  loadTipoDeIdentificacion,
  loadEmpresas,
  loadStatus,
} from "../../actions/catalogoActions";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import Camara from "./Camara";
import "../../css/profile.css";
import Clusters from "../pagos/Clusters";
import { NotificationManager } from "react-notifications";

import { BlobServiceClient } from "@azure/storage-blob";
import {
  uploadPicture,
  getSASUrl,
  getFilesData,
} from "../../actions/azureActions";

import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
const uuidv4 = require("uuid/v4");
TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

const startDate = new Date();
const endDate = new Date();
startDate.setDate(startDate.getDate() - 15);
endDate.setDate(endDate.getDate() + 1);

class Accesos extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
      tituloComunicado: "",
      edicion: false,
      comunicadoActivo: "",
      filasSeleccionadas: [],
      pagina: 1,
      celdaActiva: null,
      agregar: false,
      casaSelect: "0",
      tipoVisitaSelect: "0",
      tipoIdentificacionSelect: "0",
      numeroIdentificacion: "",
      nombreVisita: "",
      placasVisita: "",
      colorSelect: "0",
      tipoTransporte: "0",
      referencia: "",
      empresaSelect: "0",
      visitaSeleccionada: "",
      menuAbierto: "",
      paginaAccesos: 1,
      nombreFiltro: false,
      placasFiltro: false,
      identificacionFiltro: false,
      marbeteFiltro: true,
      busqueda: "",
      rangoStart: startDate,
      rangoEnd: endDate,
      rangoActivo: false,
      estatusAcceso: "",
      idAcceso: "",
      camara: false,
      imageSrc: "",
      contenedor: "",
      archivos: {},
    };
    this.onChange = this.onChange.bind(this);
    this.clicTabla = this.clicTabla.bind(this);
    this.dblClicTabla = this.dblClicTabla.bind(this);
    this.toggleAgregar = this.toggleAgregar.bind(this);
    this.onClickVisita = this.onClickVisita.bind(this);
    this.abrirMenu = this.abrirMenu.bind(this);

    this.setImageSrc = this.setImageSrc.bind(this);

    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
    this.toggleFiltro = this.toggleFiltro.bind(this);
    this.buscarAccesos = this.buscarAccesos.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editarAcceso = this.editarAcceso.bind(this);
    this.finalizarAcceso = this.finalizarAcceso.bind(this);

    this.tomarFoto = this.tomarFoto.bind(this);
  }

  handleChange = (a, b) => {
    this.setState({
      rangoStart: b.startDate.zone("-06:00").format(),
      rangoEnd: b.endDate.zone("-06:00").format(),
    });

    if (a.type === "apply") {
      this.props.loadAccesos({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: 1,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: b.startDate.zone("-06:00").format(),
        rangoEnd: b.endDate.zone("-06:00").format(),
      });

      this.setState({ paginaAccesos: 1, rangoActivo: true });
    }
  };

  editarAcceso = (e) => {
    window.abrirModal("registrarAcceso");
    this.setState({ camara: false });
    let info = this.props.asociacion.accesos[0].registros.filter(
      (a) => a._id === e
    )[0];
    this.setState({
      idAcceso: e,
      edicion: true,
      clusterSeleccionado:
        this.props.asociacion.membresiaActiva.asociacion.tipo ===
        "Fraccionamiento"
          ? info.cluster._id
          : info.destino._id,
      casaSelect: info.destino._id,
      tipoVisitaSelect: info.tipo._id,
      tipoIdentificacionSelect: info.visitante.tipoDeIdentificacion._id,
      numeroIdentificacion: info.visitante.numeroDeIdentificacion,
      nombreVisita: info.visitante.nombre,
      placasVisita: info.visitante.placas,
      colorSelect: info.visitante.color,
      tipoTransporte: info.visitante.tipoTransporte,
      referencia: info.visitante.referencia,
      empresaSelect: info.visitante.empresa ? info.visitante.empresa : "0",
      estatusAcceso: info.estatus._id,
      archivos: info.referencias,
    });

    var map = info.referencias;

    if (Object.keys(map).length > 0) {
      this.props.getFilesData({ contenedor: map.imagen });
    }

    if (info.visitante.empresa) this.props.loadEmpresas(info.tipo._id);
    this.props.loadTipoDeAccesos();
    this.props.loadTipoDeIdentificacion();
    this.props.loadStatus({ tipo: "Accesos" });
  };

  setImageSrc = (e) => {
    this.setState({ imageSrc: e });
  };

  adelantarPagina = (e) => {
    if (
      Math.ceil(this.props.asociacion.accesos[0]._id.total.count / 10) >
      this.state.paginaAccesos
    ) {
      this.setState(
        (prevState, e) => ({
          paginaAccesos: prevState.paginaAccesos + 1,
        }),
        () => {
          this.props.loadAccesos({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            page: this.state.paginaAccesos,
            nombreFiltro: this.state.nombreFiltro,
            placasFiltro: this.state.placasFiltro,
            identificacionFiltro: this.state.identificacionFiltro,
            marbeteFiltro: this.state.marbeteFiltro,
            busqueda: this.state.busqueda,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
          });
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ paginaAccesos: e });
    this.props.loadAccesos({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      page: e,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
    });
  };

  buscarAccesos = (e) => {
    this.setState({ paginaAccesos: 1 });
    this.props.loadAccesos({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      page: 1,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
    });
  };

  toggleFiltro = (e, campo) => {
    this.setState({
      [campo]: e.target.checked,
    });
  };

  atrasarPagina = (e) => {
    if (this.state.paginaAccesos !== 1) {
      this.setState(
        (prevState, e) => ({
          paginaAccesos: prevState.paginaAccesos - 1,
        }),
        () => {
          this.props.loadAccesos({
            asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
            page: this.state.paginaAccesos,
            nombreFiltro: this.state.nombreFiltro,
            placasFiltro: this.state.placasFiltro,
            identificacionFiltro: this.state.identificacionFiltro,
            marbeteFiltro: this.state.marbeteFiltro,
            busqueda: this.state.busqueda,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
          });
        }
      );
    }
  };

  clicTabla = (e) => {
    this.setState({ celdaActiva: e });
  };

  tomarFoto = (e) => {
    this.setState((prevState) => ({
      camara: !prevState.camara,
    }));

    this.setState({ imageSrc: "" });
  };

  abrirMenu = (e) => {
    this.setState((prevState) => ({
      menuAbierto: prevState.menuAbierto === e ? "" : e,
    }));
  };
  onClickVisita = (e) => {
    this.setState({
      visitaSeleccionada: e,
      tipoIdentificacionSelect: this.props.asociacion.ultimosAccesosDestino[e]
        .identificacionID,
      numeroIdentificacion: this.props.asociacion.ultimosAccesosDestino[e]
        .numeroDeIdentificacion,
      nombreVisita: this.props.asociacion.ultimosAccesosDestino[e].nombre,
      placasVisita: this.props.asociacion.ultimosAccesosDestino[e].placas,
      colorSelect: this.props.asociacion.ultimosAccesosDestino[e].color,
      tipoTransporte: this.props.asociacion.ultimosAccesosDestino[e]
        .tipoTransporte,
    });
  };

  dblClicTabla = (e) => {
    const contenedor = "visita-" + uuidv4();
    this.props.getSASUrl({ archivo: "visitas", contenedor: contenedor });
    window.abrirModal("registrarAcceso");
    this.setState({
      camara: false,
      edicion: false,
      clusterSeleccionado: e,
      casaSelect:
        this.props.asociacion.membresiaActiva.asociacion.tipo.nombre ===
        "Fraccionamiento"
          ? "0"
          : e,
      tipoVisitaSelect: "0",
      tipoIdentificacionSelect: "0",
      numeroIdentificacion: "",
      nombreVisita: "",
      placasVisita: "",
      colorSelect: "0",
      tipoTransporte: "0",
      referencia: "",
      empresaSelect: "0",
      visitaSeleccionada: "",
      contenedor: contenedor,
      imageSrc: "",
    });

    this.props.resetUtimosAccesosDestino();
    this.props.loadTipoDeAccesos();
    this.props.loadTipoDeIdentificacion();
  };

  toggleAgregar = (e) => {
    this.setState({ camara: false });
    this.setState((prevState) => ({
      agregar: !prevState.agregar,
    }));
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  registrarAcceso = () => {
    if (this.props.azure.sasURL && this.state.imageSrc) {
      const blobServiceClient = new BlobServiceClient(this.props.azure.sasURL);
      const containerName = ""; // Carpeta, en blanco para tomar la que se asigna en backend
      const containerClient = blobServiceClient.getContainerClient(
        containerName
      );

      this.props.uploadPicture(this.state.imageSrc, containerClient);
    }

    this.props.registrarVisita({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      tipoDeIdentificacion: this.state.tipoIdentificacionSelect,
      numeroDeIdentificacion: this.state.numeroIdentificacion,
      nombre: this.state.nombreVisita,
      placas: this.state.placasVisita,
      color: this.state.colorSelect,
      tipoTransporte: this.state.tipoTransporte,
      referencia: this.state.referencia,
      empresa: this.state.empresaSelect,
      destino: this.state.casaSelect,
      tipo: this.state.tipoVisitaSelect,
      edicion: this.state.edicion,
      estatus: this.state.estatusAcceso,
      idAcceso: this.state.idAcceso,
      page: this.state.paginaAccesos,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
      contenedor: this.state.contenedor,
    });

    this.setState({ busqueda: "", rangoActivo: false });
  };

  finalizarAcceso = (e) => {
    this.props.finalizarVisita({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      idAcceso: e,
      nombreFiltro: this.state.nombreFiltro,
      placasFiltro: this.state.placasFiltro,
      identificacionFiltro: this.state.identificacionFiltro,
      marbeteFiltro: this.state.marbeteFiltro,
      busqueda: this.state.busqueda,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
    });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
    if (
      this.props.asociacion.accesos.length === 0 &&
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadAccesos({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: this.state.paginaAccesos,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    /* if (
      this.props.asociacion.updated &&
      prevProps.asociacion.accesos !== this.props.asociacion.accesos
    ) {
      window.cerrrarModal("registrarAcceso");
      NotificationManager.success("", "Comunicado guardado exitosamente", 6000);
    } */

    if (
      this.props.asociacion.accesos.length === 0 &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadAccesos({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: this.state.paginaAccesos,
        nombreFiltro: this.state.nombreFiltro,
        placasFiltro: this.state.placasFiltro,
        identificacionFiltro: this.state.identificacionFiltro,
        marbeteFiltro: this.state.marbeteFiltro,
        busqueda: this.state.busqueda,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }

    if (
      this.state.casaSelect !== "0" &&
      this.props.asociacion.accesos !== prevProps.asociacion.accesos
    ) {
      window.cerrrarModal("registrarAcceso");

      NotificationManager.success("", "Acceso guardado exitosamente", 6000);
      this.setState({
        casaSelect: "0",
        camara: false,
        contenedor: "",
        imageSrc: "",
      });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      case "tipoVisitaSelect":
        this.setState({ [e.target.name]: e.target.value });
        if (
          this.props.catalog.tipoDeAcceso.filter(
            (a) => a._id === e.target.value
          ).length > 0 &&
          this.props.catalog.tipoDeAcceso.filter(
            (a) => a._id === e.target.value
          )[0].empresa
        )
          this.props.loadEmpresas(e.target.value);
        if (this.state.casaSelect !== "0")
          this.props.getUtimosAccesosDestino({
            destino: this.state.casaSelect,
            tipoDeAcceso: e.target.value,
          });

        break;
      case "casaSelect":
        this.setState({ [e.target.name]: e.target.value });
        if (this.state.tipoVisitaSelect !== "0")
          this.props.getUtimosAccesosDestino({
            destino: e.target.value,
            tipoDeAcceso: this.state.tipoVisitaSelect,
          });
        break;

      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;
    const { paginaAccesos, edicion, clusterSeleccionado, errors } = this.state;
    const {
      membresiaActiva,
      ultimosAccesosDestino,
      accesos,
    } = this.props.asociacion;
    const {
      tipoDeAcceso,
      tipoDeIdentificacion,
      empresas,
      estatusAccesos,
    } = this.props.catalog;

    let content;
    let modalContent;

    modalContent = (
      <div>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="registrarAcceso"
          id="registrarAcceso"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {edicion ? "Editar " : "Registrar "} acceso
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                <div className="row">
                  <div className="col-12">
                    <select
                      className={classnames("custom-select", {
                        "is-invalid": errors["casa"],
                      })}
                      id="casaSelect"
                      name="casaSelect"
                      onChange={this.onChange}
                      value={this.state.casaSelect}
                    >
                      {membresiaActiva &&
                      membresiaActiva.asociacion &&
                      membresiaActiva.asociacion.tipo.nombre ===
                        "Fraccionamiento" ? (
                        <option value="0">Selecciona una casa</option>
                      ) : (
                        ""
                      )}
                      {membresiaActiva &&
                      membresiaActiva.asociacion &&
                      clusterSeleccionado &&
                      membresiaActiva.asociacion.tipo.nombre ===
                        "Fraccionamiento" ? (
                        membresiaActiva.asociacion.departamentos
                          .filter((depa) => depa._id === clusterSeleccionado)[0]
                          .areas.map((casa) => {
                            return (
                              <option key={casa._id} value={casa._id}>
                                {casa.nombre}
                              </option>
                            );
                          })
                      ) : clusterSeleccionado ? (
                        <option
                          value={
                            membresiaActiva.asociacion.departamentos.filter(
                              (depa) => depa._id === clusterSeleccionado
                            )[0]._id
                          }
                        >
                          {
                            membresiaActiva.asociacion.departamentos.filter(
                              (depa) => depa._id === clusterSeleccionado
                            )[0].nombre
                          }
                        </option>
                      ) : (
                        ""
                      )}
                    </select>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <select
                      className={classnames("custom-select", {
                        "is-invalid": errors["tipoVisita"],
                      })}
                      id="tipoVisitaSelect"
                      name="tipoVisitaSelect"
                      onChange={this.onChange}
                      value={this.state.tipoVisitaSelect}
                    >
                      <option value="0">Selecciona el tipo de acceso</option>

                      {tipoDeAcceso &&
                        tipoDeAcceso.map((tipo) => {
                          return (
                            <option key={tipo._id} value={tipo._id}>
                              {tipo.nombre}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                {this.state.tipoVisitaSelect !== "0" &&
                tipoDeAcceso.filter(
                  (a) => a._id === this.state.tipoVisitaSelect
                ).length > 0 &&
                tipoDeAcceso.filter(
                  (a) => a._id === this.state.tipoVisitaSelect
                )[0].empresa ? (
                  <div className="row mt-4">
                    <div className="col-12">
                      <select
                        className={classnames("custom-select", {
                          "is-invalid": errors["empresa"],
                        })}
                        id="empresaSelect"
                        name="empresaSelect"
                        onChange={this.onChange}
                        value={this.state.empresaSelect}
                      >
                        <option value="0">
                          Selecciona la empresa del visitante
                        </option>

                        {empresas &&
                          empresas.map((empresa) => {
                            return (
                              <option key={empresa._id} value={empresa._id}>
                                {empresa.nombre}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className="row mt-4"
                  style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                  <div className="col-6 visitanteDiv">
                    <h5>Visitante</h5>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ marginBottom: "1.25rem" }}
                      >
                        <select
                          className={classnames("custom-select", {
                            "is-invalid": errors["tipoIdentificacionSelect"],
                          })}
                          id="tipoIdentificacionSelect"
                          name="tipoIdentificacionSelect"
                          onChange={this.onChange}
                          value={this.state.tipoIdentificacionSelect}
                        >
                          <option value="0">
                            Selecciona el tipo de identificación
                          </option>

                          {tipoDeIdentificacion &&
                            tipoDeIdentificacion.map((tipo) => {
                              return (
                                <option key={tipo._id} value={tipo._id}>
                                  {tipo.nombre}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-12">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": errors["numeroDeIdentificacion"],
                            })}
                            id="numeroIdentificacion"
                            name="numeroIdentificacion"
                            onChange={this.onChange}
                            value={this.state.numeroIdentificacion}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="numeroIdentificacion">
                            Número de identificación
                          </label>
                          {errors["numeroDeIdentificacion"] && (
                            <div className="invalid-feedback">
                              {errors["numeroDeIdentificacion"]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": errors["nombre"],
                            })}
                            id="nombreVisita"
                            name="nombreVisita"
                            onChange={this.onChange}
                            value={this.state.nombreVisita}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="nombreVisita">Nombre completo</label>
                          {errors["nombre"] && (
                            <div className="invalid-feedback">
                              {errors["nombre"]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": errors["placas"],
                            })}
                            id="placasVisita"
                            name="placasVisita"
                            onChange={this.onChange}
                            value={this.state.placasVisita}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="placasVisita">Placas del auto</label>
                          {errors["placas"] && (
                            <div className="invalid-feedback">
                              {errors["placas"]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-12"
                        style={{ marginBottom: "1.25rem" }}
                      >
                        <select
                          className={classnames("custom-select", {
                            "is-invalid": errors["color"],
                          })}
                          id="colorSelect"
                          name="colorSelect"
                          onChange={this.onChange}
                          value={this.state.colorSelect}
                        >
                          <option value="0">
                            Selecciona el color del vehículo
                          </option>
                          <option value="Blanco">Blanco</option>
                          <option value="Negro">Negro</option>
                          <option value="Azul">Azul</option>
                          <option value="Verde">Verde</option>
                          <option value="Rojo">Rojo</option>
                          <option value="Amarillo">Amarillo</option>
                          <option value="Gris">Gris</option>
                          <option value="Plata">Plata</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>

                      <div
                        className="col-12"
                        style={{ marginBottom: "1.25rem" }}
                      >
                        <select
                          className={classnames("custom-select", {
                            "is-invalid": errors["tipoTransporte"],
                          })}
                          id="tipoTransporte"
                          name="tipoTransporte"
                          onChange={this.onChange}
                          value={this.state.tipoTransporte}
                        >
                          <option value="0">
                            Selecciona el tipo de vehículo
                          </option>

                          <option value="Auto">Auto</option>
                          <option value="Motocicleta">Motocicleta</option>
                          <option value="Pickup">Pickup</option>
                          <option value="Camioneta">Camioneta</option>
                          <option value="Van">Van</option>
                          <option value="Camión">Camión</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 ultimasVisitasDiv">
                    <div className="row">
                      <h5 style={{ paddingLeft: "15px" }}>
                        Marbete, colgante o tarjetón
                      </h5>
                      <div className="col-12">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": errors["referencia"],
                            })}
                            id="referencia"
                            name="referencia"
                            onChange={this.onChange}
                            value={this.state.referencia}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="referencia">
                            Número de identificación
                          </label>
                          {errors["referencia"] && (
                            <div className="invalid-feedback">
                              {errors["referencia"]}
                            </div>
                          )}
                        </div>
                      </div>

                      {edicion && (
                        <div
                          className="col-12"
                          style={{ marginBottom: "1.25rem" }}
                        >
                          <select
                            className={classnames("custom-select", {
                              "is-invalid": errors["estatusAcceso"],
                            })}
                            id="estatusAcceso"
                            name="estatusAcceso"
                            onChange={this.onChange}
                            value={this.state.estatusAcceso}
                          >
                            {estatusAccesos &&
                              estatusAccesos.map((estatus) => {
                                return (
                                  <option key={estatus._id} value={estatus._id}>
                                    Estatus: {estatus.nombre}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      )}
                    </div>
                    {!edicion && (
                      <Fragment>
                        <h5>Últimas visitas</h5>
                        <div className="row">
                          <div className="col-12">
                            {ultimosAccesosDestino && (
                              <div className="list-group">
                                {ultimosAccesosDestino.map((acceso, i) => {
                                  return (
                                    <button
                                      key={i}
                                      type="button"
                                      onClick={() => this.onClickVisita(i)}
                                      className={classnames(
                                        "list-group-item list-group-item-action",
                                        {
                                          active:
                                            this.state.visitaSeleccionada === i,
                                        }
                                      )}
                                    >
                                      <strong>{acceso.placas}</strong> |{" "}
                                      {acceso.color} |{" "}
                                      <strong>{acceso.tipoTransporte}</strong> |{" "}
                                      {acceso.identificacion} |{" "}
                                      {acceso.numeroDeIdentificacion} |{" "}
                                      <strong>{acceso.nombre}</strong>
                                    </button>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>

                  {this.state.camara && (
                    <Camara
                      setImageSrc={this.setImageSrc}
                      imageSrc={this.state.imageSrc}
                    />
                  )}

                  {edicion &&
                    this.props.azure &&
                    this.props.azure.files.length > 0 && (
                      <div className="col-12">
                        <img
                          src={`https://wirhub.blob.core.windows.net/${this.state.archivos.imagen}/${this.props.azure.files[0].name}`}
                          alt="Captura"
                        />
                      </div>
                    )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>

                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => this.tomarFoto()}
                >
                  Registrar foto
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.registrarAcceso()}
                >
                  {edicion ? "Guardar" : "Registrar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (
      !this.props.asociacion ||
      !this.props.asociacion.membresias ||
      !this.props.asociacion.membresiaActiva ||
      !this.props.asociacion.membresiaActiva.asociacion
    ) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <Clusters
              filasSeleccionadas={this.state.filasSeleccionadas}
              pagina={this.state.pagina}
              celdaActiva={this.state.celdaActiva}
              clicTabla={this.clicTabla}
              dblClicTabla={this.dblClicTabla}
              agregar={this.state.agregar}
            />
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="hint-text d-sm-none d-md-block ">
                          <h5>Últimos accesos</h5>
                        </div>
                        <div className="barraBusqueda">
                          <h5 className="display-on-sm padding-on-sm">
                            Últimos accesos
                          </h5>

                          <input
                            className="form-control padding-on-sm"
                            type="text"
                            style={{ minWidth: "100px", marginRight: "15px" }}
                            placeholder="Buscar"
                            name="busqueda"
                            onChange={this.onChange}
                            onKeyUp={() => {
                              this.buscarAccesos();
                            }}
                          />

                          <div className="btn-group padding-on-sm width-sm">
                            <button
                              type="button"
                              className="btn btn-secondary dropdown-toggle btn-sm"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                            <div className="dropdown-menu menu-sm">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="dropdownCheck2"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "nombreFiltro")
                                  }
                                  checked={this.state.nombreFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="dropdownCheck2"
                                >
                                  Nombre
                                </label>
                              </div>
                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="placasFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "placasFiltro")
                                  }
                                  checked={this.state.placasFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="placasFiltroID"
                                >
                                  Placas
                                </label>
                              </div>
                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="identificacionFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "identificacionFiltro")
                                  }
                                  checked={this.state.identificacionFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="identificacionFiltroID"
                                >
                                  Identificación
                                </label>
                              </div>

                              <div className="dropdown-divider"></div>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="marbeteFiltroID"
                                  onChange={(e) =>
                                    this.toggleFiltro(e, "marbeteFiltro")
                                  }
                                  checked={this.state.marbeteFiltro}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="marbeteFiltroID"
                                >
                                  Marbete/Tarjetón
                                </label>
                              </div>
                            </div>
                          </div>

                          <DateRangePicker
                            onEvent={this.handleChange}
                            initialSettings={{
                              endDate: endDate,
                              locale: {
                                format: "YYYY/MM/DD hh:mm A",
                                cancelLabel: "Cancelar",
                                applyLabel: "Buscar",
                                fromLabel: "de",
                                toLabel: "a",
                                daysOfWeek: [
                                  "Dom",
                                  "Lun",
                                  "Mar",
                                  "Mié",
                                  "Jue",
                                  "Vie",
                                  "Sáb",
                                ],
                                monthNames: [
                                  "Enero",
                                  "Febrero",
                                  "Marzo",
                                  "Abril",
                                  "Mayo",
                                  "Junio",
                                  "Julio",
                                  "Agosto",
                                  "Septiembre",
                                  "Octubre",
                                  "Noviembre",
                                  "Diciembre",
                                ],
                              },
                              startDate: startDate,
                              timePicker: true,
                            }}
                          >
                            <input
                              className="form-control search-date padding-on-sm"
                              type="text"
                              name="rangoDeFechas"
                              onChange={this.onChange}
                            />
                          </DateRangePicker>
                          <button
                            type="button"
                            className="btn btn-secondary padding-on-sm width-sm"
                            style={{ minWidth: "150px" }}
                            onClick={() => {
                              this.toggleAgregar();
                              this.setState({
                                edicion: false,
                              });
                            }}
                          >
                            {this.state.agregar
                              ? "Cancelar"
                              : "Registrar acceso"}
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <hr></hr>
                      </div>
                    </div>

                    {!accesos ||
                    accesos.length === 0 ||
                    accesos[0].registros.length === 0 ? (
                      <div className="row">
                        <div className="col-12">
                          Aún no hay ningún acceso registrado
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {accesos[0].registros.map((acceso) => {
                          return (
                            <div key={acceso._id} className="col-12 list-div">
                              <div className="dropdown">
                                <span
                                  className="menu-ellipsis"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  &#8942;
                                </span>
                                <div className="dropdown-menu dropdown-acceso">
                                  <button
                                    onClick={() => {
                                      this.finalizarAcceso(acceso._id);
                                    }}
                                  >
                                    Finalizar visita
                                  </button>
                                  <div className="dropdown-divider"></div>
                                  <button
                                    onClick={() => {
                                      this.editarAcceso(acceso._id);
                                    }}
                                  >
                                    Editar
                                  </button>
                                </div>
                              </div>

                              <div className="titulo">
                                {acceso.visitante.nombre} &nbsp; &nbsp;
                                {acceso.visitante.placas} &nbsp; &nbsp;
                                {acceso.visitante.tipoTransporte} &nbsp; &nbsp;
                                {acceso.visitante.color} &nbsp; &nbsp;
                                {
                                  acceso.visitante.tipoDeIdentificacion.nombre
                                }{" "}
                                &nbsp; &nbsp;
                                {acceso.visitante.numeroDeIdentificacion} &nbsp;
                                &nbsp; Marbete/Tarjetón:{" "}
                                {acceso.visitante.referencia} &nbsp; &nbsp;
                              </div>
                              <div className="contenido">
                                <span
                                  className={classnames("", {
                                    "dot-warning":
                                      acceso.estatus.nombre === "Activo",
                                    "dot-ok":
                                      acceso.estatus.nombre === "Finalizado",
                                  })}
                                ></span>
                                Entró {timeAgo.format(new Date(acceso.created))}{" "}
                                &nbsp; &nbsp;
                                {acceso.tipo.nombre} &nbsp; &nbsp;
                                {acceso.destino.nombre} &nbsp; &nbsp;
                                {acceso.cluster.nombre} &nbsp; &nbsp;
                              </div>
                            </div>
                          );
                        })}
                        <div className="col-12">
                          {accesos &&
                            accesos.length > 0 &&
                            accesos[0]._id &&
                            accesos[0]._id.total.count > 0 && (
                              <ul className="pagination">
                                <li className="page-item">
                                  <button
                                    type="button"
                                    className="page-link"
                                    disabled={
                                      paginaAccesos === 1 ? true : false
                                    }
                                    onClick={() => {
                                      this.atrasarPagina();
                                    }}
                                  >
                                    Anterior
                                  </button>
                                </li>
                                {Array.from(
                                  {
                                    length: Math.min(
                                      10,
                                      Math.ceil(accesos[0]._id.total.count / 10)
                                    ),
                                  },
                                  (item, i) => {
                                    return (
                                      <li
                                        key={i}
                                        className={
                                          paginaAccesos === i + 1
                                            ? "page-item active"
                                            : "page-item"
                                        }
                                      >
                                        <button
                                          className="page-link"
                                          onClick={() =>
                                            this.actualizaPagina(i + 1)
                                          }
                                        >
                                          {i + 1}
                                        </button>
                                      </li>
                                    );
                                  }
                                )}

                                <li className="page-item">
                                  <button
                                    onClick={() => {
                                      this.adelantarPagina();
                                    }}
                                    className={
                                      Math.ceil(
                                        accesos[0]._id.total.count / 10
                                      ) <= paginaAccesos
                                        ? "page-link disabled"
                                        : "page-link"
                                    }
                                  >
                                    Siguiente
                                  </button>
                                </li>
                              </ul>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    if (!membresiaActiva || !membresiaActiva.asociacion) {
      return <div>Loading...</div>;
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Accesos</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Accesos</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Accesos.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  azure: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  loadTipoDeAccesos: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadAccesos: PropTypes.func.isRequired,
  getUtimosAccesosDestino: PropTypes.func.isRequired,
  registrarVisita: PropTypes.func.isRequired,
  resetUtimosAccesosDestino: PropTypes.func.isRequired,
  finalizarVisita: PropTypes.func.isRequired,
  loadTipoDeIdentificacion: PropTypes.func.isRequired,
  loadEmpresas: PropTypes.func.isRequired,
  loadStatus: PropTypes.func.isRequired,
  getSASUrl: PropTypes.func.isRequired,
  getFilesData: PropTypes.func.isRequired,
  uploadPicture: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
  azure: state.azure,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  loadAccesos,
  loadTipoDeAccesos,
  loadTipoDeIdentificacion,
  loadEmpresas,
  getUtimosAccesosDestino,
  registrarVisita,
  resetUtimosAccesosDestino,
  loadStatus,
  finalizarVisita,
  getSASUrl,
  uploadPicture,
  getFilesData,
})(Accesos);
