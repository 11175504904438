import {
  ADD_COUNTRIES,
  ADD_CITIES,
  ADD_STATES,
  LOAD_ROLASOCIACION,
  LOAD_ESTATUSCUOTAS,
  LOAD_ESTATUS_ACCESOS,
  LOAD_TIPODESERVICIOSCUOTAS,
  LOAD_FRECUENCIA,
  GET_TIPODEASOCIACION,
  GET_TIPO_PAGOS,
  GET_FORMA_PAGOS,
  GET_TIPO_ACCESOS,
  GET_TIPO_IDENTIFICACION,
  GET_EMPRESAS,
  LOAD_ROLES_ADMON,
} from "../actions/types";

const initialState = {
  countries: [],
  states: [],
  cities: [],
  rolAsociacion: [],
  estatusCuotas: null,
  estatusAccesos: null,
  tipoDeServiciosCuotas: null,
  frecuencias: null,
  tipoDeAsociacion: [],
  tipoDePago: [],
  formaDePago: [],
  tipoDeAcceso: [],
  tipoDeIdentificacion: [],
  empresas: [],
  rolesAdmon: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case ADD_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case ADD_CITIES:
      return {
        ...state,
        cities: action.payload,
      };
    case LOAD_ROLASOCIACION:
      return {
        ...state,
        rolAsociacion: action.payload,
      };
    case LOAD_ESTATUSCUOTAS:
      return {
        ...state,
        estatusCuotas: action.payload,
      };

    case LOAD_ESTATUS_ACCESOS:
      return {
        ...state,
        estatusAccesos: action.payload,
      };
    case LOAD_ROLES_ADMON:
      return {
        ...state,
        rolesAdmon: action.payload,
      };
    case GET_EMPRESAS:
      return {
        ...state,
        empresas: action.payload,
      };
    case GET_TIPO_ACCESOS:
      return {
        ...state,
        tipoDeAcceso: action.payload,
      };
    case LOAD_TIPODESERVICIOSCUOTAS:
      return {
        ...state,
        tipoDeServiciosCuotas: action.payload,
      };
    case LOAD_FRECUENCIA:
      return {
        ...state,
        frecuencias: action.payload,
      };
    case GET_TIPODEASOCIACION:
      return {
        ...state,
        tipoDeAsociacion: action.payload,
      };
    case GET_TIPO_PAGOS:
      return {
        ...state,
        tipoDePago: action.payload,
      };
    case GET_TIPO_IDENTIFICACION:
      return {
        ...state,
        tipoDeIdentificacion: action.payload,
      };
    case GET_FORMA_PAGOS:
      return {
        ...state,
        formaDePago: action.payload,
      };
    default:
      return state;
  }
}
