import React, { Component, Fragment } from "react";
import Login from "../auth/Login";
import { withRouter } from "react-router";
import img1 from "../../images/group.png";
import CookieBanner from "react-cookie-banner";

const message =
  "Utilizamos cookies y tecnologías similares para habilitar los servicios y la funcionalidad de nuestro sitio y para comprender tu interacción con nuestro servicio. Al utilizar nuestro sitio, aceptas que utilicemos dichas tecnologías para marketing y estadísticas.";
const styles = {
  banner: {
    height: "auto",
    background: "rgba(52, 64, 81, 0.88)",
    backgroundSize: "30px 30px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600,
    position: "fixed",
    bottom: "0px",
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: "auto",
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 25px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 244,
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
};

class Header extends Component {
  render() {
    return (
      <Fragment>
        <header id="header" className="header">
          <div className="header-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-container">
                    <h1>
                      <span>Plataforma de administración de condominios</span>
                    </h1>
                    <p className="p-large">
                      Si administras un fraccionamiento o condominio o si bien
                      vives en uno, Wirhub es tu solución para la digitalización
                      de toda la administración, brindándote herramientas para
                      el registro de ingresos, egresos, estados de cuenta,
                      comunicados, visitas y muchos beneficios más.
                    </p>
                    <Login />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="image-container">
                    <img
                      className="img-fluid"
                      src={img1}
                      alt="App de administración de fraccionamientos y condominios"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <CookieBanner
          styles={styles}
          message={message}
          link={
            <a
              href="https://wirhub.com/privacidad"
              target="_blank"
              rel="noopener noreferrer"
            >
              Más información sobre el uso de cookies
            </a>
          }
          buttonMessage="Entendido"
          dismissOnScroll={false}
          dismissOnClick={true}
          onAccept={() => {}}
        />
      </Fragment>
    );
  }
}

export default withRouter(Header);
