import axios from "axios";

import {
  ADD_COUNTRIES,
  GET_ERRORS,
  ADD_STATES,
  ADD_CITIES,
  LOAD_ROLASOCIACION,
  UPDATE_PROGRESS,
  LOAD_ESTATUSCUOTAS,
  LOAD_TIPODESERVICIOSCUOTAS,
  LOAD_FRECUENCIA,
  GET_TIPODEASOCIACION,
  GET_TIPO_PAGOS,
  GET_FORMA_PAGOS,
  GET_TIPO_ACCESOS,
  GET_TIPO_IDENTIFICACION,
  GET_EMPRESAS,
  LOAD_ESTATUS_ACCESOS,
  LOAD_ROLES_ADMON,
} from "./types";

// Load countries
export const loadCountries = () => (dispatch) => {
  axios
    .get("/api/catalogos/pais")
    .then((res) => {
      dispatch({
        type: ADD_COUNTRIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load tipo de asociacion
export const loadTipoDeAsociacion = () => (dispatch) => {
  axios
    .get("/api/catalogos/tipoDeAsociacion")
    .then((res) => {
      dispatch({
        type: GET_TIPODEASOCIACION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load states
export const loadStates = (pais, reset) => (dispatch) => {
  axios
    .get("/api/catalogos/estado/" + pais)
    .then((res) => {
      dispatch({
        type: ADD_STATES,
        payload: res.data,
      });
      if (reset)
        dispatch({
          type: ADD_CITIES,
          payload: [],
        });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load cities
export const loadCities = (ciudad) => (dispatch) => {
  axios
    .get("/api/catalogos/ciudad/" + ciudad)
    .then((res) => {
      dispatch({
        type: ADD_CITIES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load roll
export const loadRolAsociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  axios
    .post("/api/rol/rolAsociacion", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_ROLASOCIACION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadStatus = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  axios
    .post("/api/catalogos/estatusTipo", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      switch (data.tipo) {
        case "Servicios":
          dispatch({
            type: LOAD_ESTATUSCUOTAS,
            payload: res.data,
          });
          break;
        case "Accesos":
          dispatch({
            type: LOAD_ESTATUS_ACCESOS,
            payload: res.data,
          });
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadTipoServicioCuota = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  axios
    .get("/api/catalogos/tipoDeServicio", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_TIPODESERVICIOSCUOTAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadFrecuencia = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  axios
    .get("/api/catalogos/frecuencia", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_FRECUENCIA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load tipo de pagos
export const loadTipoDePago = () => (dispatch) => {
  axios
    .get("/api/catalogos/tipoDePago")
    .then((res) => {
      dispatch({
        type: GET_TIPO_PAGOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load forma de pago
export const loadFormaDePago = () => (dispatch) => {
  axios
    .get("/api/catalogos/formaDePago")
    .then((res) => {
      dispatch({
        type: GET_FORMA_PAGOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load tipo de accesos
export const loadTipoDeAccesos = () => (dispatch) => {
  axios
    .get("/api/catalogos/tipoDeAcceso")
    .then((res) => {
      dispatch({
        type: GET_TIPO_ACCESOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load tipo de identificaciones
export const loadTipoDeIdentificacion = () => (dispatch) => {
  axios
    .get("/api/catalogos/tipoDeIdentificacion")
    .then((res) => {
      dispatch({
        type: GET_TIPO_IDENTIFICACION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadEmpresas = (tipoDeAcceso) => (dispatch) => {
  axios
    .get("/api/catalogos/empresaDeServicios/" + tipoDeAcceso)
    .then((res) => {
      dispatch({
        type: GET_EMPRESAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load tipo de accesos
export const loadRolesAdmon = () => (dispatch) => {
  axios
    .get("/api/rol/administradores")
    .then((res) => {
      dispatch({
        type: LOAD_ROLES_ADMON,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
