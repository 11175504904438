import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import {
  loadMembresias,
  getAreasComunes,
  registrarAreaComun,
  registrarEvento,
  getEventosArea,
  eliminarEvento,
} from "../../actions/asociacionActions";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";
import "../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css";
import TextFieldGroup from "../utils/TextFielGroup";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
require("moment/locale/es.js");

moment.locale("es");
const localizer = momentLocalizer(moment);

class Areas extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
      edicion: false,
      nombreAreaComun: "",
      areaComunSelect: "",
      events: [],
      edicionEvento: false,
      title: "",
      start: new Date(),
      end: new Date(),
      allDay: true,
      clusterSelect: "0",
      casaSelect: "0",
      eventoID: "",
    };
    this.onChange = this.onChange.bind(this);
    this.agregarAreaComun = this.agregarAreaComun.bind(this);
    this.registrarAreaComun = this.registrarAreaComun.bind(this);
    this.registrarEvento = this.registrarEvento.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.eliminarEvento = this.eliminarEvento.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.areas &&
      this.props.asociacion.areas.length > 0 &&
      prevProps.asociacion.areas !== this.props.asociacion.areas
    ) {
      window.cerrrarModal("registrarAreaComun");
      this.setState({ areaComunSelect: this.props.asociacion.areas[0]._id });
      this.props.getEventosArea({
        areaComun: this.props.asociacion.areas[0]._id,
      });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.eventosArea &&
      prevProps.asociacion.eventosArea !== this.props.asociacion.eventosArea
    ) {
      if (this.props.asociacion.eventosArea.length > 0) {
        let eventosAux = this.props.asociacion.eventosArea[0].eventos;

        eventosAux.map((ev) => {
          ev.start = new Date(ev.start);
          ev.end = new Date(ev.end);
        });

        this.setState({ events: eventosAux });
        window.cerrrarModal("registrarEvento");
      }
    }

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      let asociacion = this.props.asociacion.membresiaActiva.asociacion._id;
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        asociacion =
          this.props.asociacion.membresiaActiva.asociacion.tipo ===
          "Fraccionamiento"
            ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                .asociacion[0]._id
            : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                ._id;
      }

      this.props.getAreasComunes({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      });
    }
  }

  onChange = (e) => {
    const toggleKey = (key) => (prevState) => {
      return { [key]: !prevState[key] };
    };

    switch (e.target.name) {
      case "areaComunSelect":
        this.setState({ [e.target.name]: e.target.value });
        this.props.getEventosArea({
          areaComun: e.target.value,
        });
        break;
      case "allDay":
        this.setState(toggleKey(e.target.name));
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  agregarAreaComun = (e) => {
    window.abrirModal("registrarAreaComun");
  };

  registrarAreaComun = (e) => {
    e.preventDefault();

    this.props.registrarAreaComun({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      nombreAreaComun: this.state.nombreAreaComun,
    });
  };

  registrarEvento = (e) => {
    e.preventDefault();

    this.props.registrarEvento({
      title: this.state.title,
      start: this.state.start,
      end: this.state.end,
      allDay: this.state.allDay,
      asociacion: this.state.casaSelect,
      areaComun: this.state.areaComunSelect,
      edicion: this.state.edicionEvento,
      eventoID: this.state.eventoID,
    });
  };

  handleSelect = ({ start, end }) => {
    window.abrirModal("registrarEvento");

    this.myRef.current.setStartDate(start);
    this.myRef.current.setEndDate(end);

    this.setState({
      start: start,
      end: end,
      edicionEvento: false,
      eventoID: "",
    });
  };

  handleEdit = (event) => {
    window.abrirModal("registrarEvento");

    this.myRef.current.setStartDate(event.start);
    this.myRef.current.setEndDate(event.end);

    let a = this.props.asociacion.membresiaActiva.asociacion.departamentos.filter(
      (depa) => depa.subs.filter((id) => id === event.asociacion).length > 0
    )[0];

    this.setState({
      edicionEvento: true,
      title: event.title,
      start: event.start,
      end: event.end,
      allDay: event.allDay,
      clusterSelect: a._id,
      casaSelect: event.asociacion,
      eventoID: event._id,
    });
  };

  handleChange = (a, b) => {
    this.setState({
      start: b.startDate.zone("-06:00").format(),
      end: b.endDate.zone("-06:00").format(),
    });
  };

  eliminarEvento = () => {
    this.props.eliminarEvento({
      areaComun: this.state.areaComunSelect,
      edicion: this.state.edicionEvento,
      eventoID: this.state.eventoID,
    });
  };

  render() {
    const { percentage } = this.props.profile;
    const { areas, membresiaActiva } = this.props.asociacion;
    const { edicion, errors, edicionEvento, clusterSelect } = this.state;

    let content;
    let modalContent;

    const selectionRange = {
      startDate: this.state.start,
      endDate: this.state.end,
      key: "selection",
    };

    modalContent = (
      <div>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="registrarAreaComun"
          id="registrarAreaComun"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {edicion ? "Editar " : "Agregar "} área común
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                <form
                  id="guardaAreaComun"
                  onSubmit={this.registrarAreaComun}
                  noValidate
                >
                  <TextFieldGroup
                    name="nombreAreaComun"
                    error={this.props.errors["nombreAreaComun"]}
                    placeholder="Nombre del área común"
                    value={
                      this.state["nombreAreaComun"]
                        ? this.state["nombreAreaComun"]
                        : ""
                    }
                    onChange={this.onChange}
                    label="Nombre"
                    id="nombreAreaComunID"
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <input
                  type="submit"
                  className="btn btn-info"
                  value="Guardar"
                  form="guardaAreaComun"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="registrarEvento"
          id="registrarEvento"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {edicionEvento ? "Editar " : "Agregar "} evento
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                <form
                  id="guardarEvento"
                  onSubmit={this.registrarEvento}
                  noValidate
                >
                  <TextFieldGroup
                    name="title"
                    error={this.props.errors["title"]}
                    placeholder="Título del evento"
                    value={this.state["title"] ? this.state["title"] : ""}
                    onChange={this.onChange}
                    label="Nombre"
                    id="titleID"
                  />
                  <label htmlFor="rangoDeFechasID">Fecha</label>
                  <DateRangePicker
                    onEvent={this.handleChange}
                    ref={this.myRef}
                    initialSettings={{
                      endDate: this.state.end,
                      locale: {
                        format: "YYYY/MM/DD hh:mm A",
                        cancelLabel: "Cancelar",
                        applyLabel: "Aceptar",
                        fromLabel: "de",
                        toLabel: "a",
                        daysOfWeek: [
                          "Dom",
                          "Lun",
                          "Mar",
                          "Mié",
                          "Jue",
                          "Vie",
                          "Sáb",
                        ],
                        monthNames: [
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octubre",
                          "Noviembre",
                          "Diciembre",
                        ],
                      },
                      startDate: this.state.start,
                      timePicker: true,
                    }}
                  >
                    <input
                      className="form-control search-date"
                      type="text"
                      name="rangoDeFechas"
                      id="rangoDeFechasID"
                    />
                  </DateRangePicker>

                  <label htmlFor="clusterSelect" style={{ marginTop: "20px" }}>
                    Clúster
                  </label>
                  <select
                    className={classnames("custom-select", {
                      "is-invalid": errors["asociacion"],
                    })}
                    id="clusterSelect"
                    name="clusterSelect"
                    onChange={this.onChange}
                    value={this.state.clusterSelect}
                  >
                    <option value="0">Selecciona un cluster</option>

                    {membresiaActiva &&
                      membresiaActiva.asociacion &&
                      membresiaActiva.asociacion.departamentos.map(
                        (cluster) => {
                          return (
                            <option key={cluster._id} value={cluster._id}>
                              {cluster.nombre}
                            </option>
                          );
                        }
                      )}
                  </select>

                  <label htmlFor="casaSelect" style={{ marginTop: "20px" }}>
                    Casa
                  </label>

                  <select
                    className={classnames("custom-select", {
                      "is-invalid": errors["asociacion"],
                    })}
                    id="casaSelect"
                    name="casaSelect"
                    onChange={this.onChange}
                    value={this.state.casaSelect}
                  >
                    <option value="0">Selecciona una casa</option>

                    {clusterSelect !== "0" &&
                      membresiaActiva.asociacion.departamentos
                        .filter((depa) => depa._id === clusterSelect)[0]
                        .areas.map((casa) => {
                          return (
                            <option key={casa._id} value={casa._id}>
                              {casa.nombre}
                            </option>
                          );
                        })}
                  </select>

                  <div
                    className="custom-control custom-switch"
                    style={{ marginTop: "20px" }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="allDay"
                      checked={this.state.allDay}
                      onChange={this.onChange}
                      name="allDay"
                    />
                    <label className="custom-control-label" htmlFor="allDay">
                      Todo el día
                    </label>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {edicionEvento ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.eliminarEvento();
                    }}
                  >
                    Eliminar
                  </button>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <input
                  type="submit"
                  className="btn btn-info"
                  value="Guardar"
                  form="guardarEvento"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 d-flex justify-content-between">
                        <div className="hint-text">
                          <h5>Todas tus áreas registradas</h5>
                        </div>
                        <div style={{ display: "inline-flex" }}>
                          {areas && areas.length > 0 ? (
                            <select
                              className={classnames(
                                "custom-select select-area-comun",
                                {
                                  "is-invalid": errors["casa"],
                                }
                              )}
                              id="areaComunSelect"
                              name="areaComunSelect"
                              onChange={this.onChange}
                              value={this.state.areaComunSelect}
                            >
                              {areas &&
                                areas.map((casa) => {
                                  return (
                                    <option key={casa._id} value={casa._id}>
                                      {casa.nombre}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            ""
                          )}

                          {this.props.asociacion.membresiaActiva.rol[0]
                            .title === "Condómino" ? (
                            ""
                          ) : (
                            <button
                              type="button"
                              className="btn btn-secondary"
                              style={{ minWidth: "150px" }}
                              onClick={() => {
                                this.agregarAreaComun();
                              }}
                            >
                              Agregar área
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <hr></hr>
                      </div>
                    </div>

                    {!areas || areas.length === 0 ? (
                      <div className="row">
                        <div className="col-12">
                          Aún no hay ninguna área común registrada
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12">
                          <Calendar
                            selectable
                            localizer={localizer}
                            events={this.state.events}
                            style={{ height: 500 }}
                            startAccessor="start"
                            endAccessor="end"
                            timeslots={5}
                            step={30}
                            culture="es"
                            messages={{
                              next: "Siguiente",
                              previous: "Anterior",
                              today: "Hoy",
                              month: "Mes",
                              week: "Semana",
                              day: "Día",
                            }}
                            onSelectEvent={(event) => this.handleEdit(event)}
                            onSelectSlot={this.handleSelect}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Agenda de áreas comunes</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Áreas comunes</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Areas.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  getAreasComunes: PropTypes.func.isRequired,
  registrarAreaComun: PropTypes.func.isRequired,
  registrarEvento: PropTypes.func.isRequired,
  getEventosArea: PropTypes.func.isRequired,
  eliminarEvento: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  getAreasComunes,
  registrarAreaComun,
  registrarEvento,
  getEventosArea,
  eliminarEvento,
})(Areas);
