import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import { confirmEmail } from "../../actions/authActions";
import { withRouter } from "react-router";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.props.confirmEmail(this.props.match.params.token);
  }

  render() {
    const { errors } = this.props;
    return (
      <div className="container-fluid">
        <div className="row">
          <main role="main" className="col-12">
            <div className="row wrapper border-bottom white-bg page-heading justify-content-center">
              <div className="col-6">
                <h2 style={{ textAlign: "center" }}>
                  Confirmación de correo electrónico
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ minHeight: "200px" }}>
                <h5 style={{ textAlign: "center", paddingTop: "50px" }}>
                  {errors.usuarioNotFound ? errors.usuarioNotFound : ""}
                  {errors.error ? errors.error : ""}
                </h5>

                <h5 style={{ textAlign: "center" }}>
                  {this.props.auth.isConfirmed
                    ? "El correo se valido correctamente"
                    : ""}

                  {this.props.auth.isConfirmed
                    ? setTimeout(this.props.history.push("/"), 8000)
                    : ""}
                </h5>

                {this.props.auth.isUploading ? <Spinner /> : ""}
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

Confirmation.propTypes = {
  auth: PropTypes.object.isRequired,
  confirmEmail: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(
  connect(mapStateToProps, { confirmEmail })(Confirmation)
);
