import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import { loadFormaDePago, loadTipoDePago } from "../../actions/catalogoActions";
import TextFieldGroup from "../utils/TextFielGroup";
import { BlobServiceClient } from "@azure/storage-blob";

import {
  loadMembresias,
  loadServiciosAsociacion,
} from "../../actions/asociacionActions";

import {
  getFilesData,
  deleteFile,
  uploadFiles,
  getSASUrl,
} from "../../actions/azureActions";

import { getCurrentPage } from "../../actions/pageActions";
import {
  loadCuenta,
  loadPagos,
  loadCargosPendientes,
  registrarPago,
  resetCargosCasa,
  getUltimoCargo,
  resetErrors,
  registrarGasto,
  loadGastos,
  modificarGasto,
} from "../../actions/cuentaActions";
import Spinner from "../common/Spinner";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";
import { PermissibleRender } from "@brainhubeu/react-permissible";

import Registro from "../pagos/Registro";
import Gastos from "../pagos/Gastos";
import Clusters from "../pagos/Clusters";
import RegistroGasto from "../pagos/RegistroGasto";
import CondominoCagosPendientes from "./CondominoCagosPendientes";
import CondominoPagosRealizados from "./CondominoPagosRealizados";
import { NotificationManager } from "react-notifications";
const uuidv4 = require("uuid/v4");
var dateFormat = require("dateformat");

const startDate = new Date();
startDate.setDate(startDate.getDate() - 100);

class Pagos extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
      filasSeleccionadas: [],
      pagina: 1,
      celdaActiva: null,
      agregar: false,
      agregarGasto: false,
      tipoDeCargo: "1",
      cargosSeleccionados: [],
      monto: 0,
      casaSelect: "0",
      formaDePago: "0",
      tipo: "0",
      cuota: "0",
      cargosFuturos: [],
      cargosFuturosSeleccionados: [],
      loaded: false,
      edicionGasto: false,
      gastoActivo: "",
      concepto: "",
      identificadorGasto: "",
      conceptoGasto: "",
      montoGasto: "",
      tipoGasto: "",
      estatusGasto: "",
      referenciasGasto: {},
      updatedGasto: "",
      createdGasto: "",
      archivoEliminar: { nombre: "" },
      archivos: [],
      archivosCompleto: [],
      contenedor: "",
      rangoStart: startDate,
      rangoEnd: new Date(),
    };
    this.onChange = this.onChange.bind(this);
    this.clicTabla = this.clicTabla.bind(this);
    this.dblClicTabla = this.dblClicTabla.bind(this);
    this.toggleAgregar = this.toggleAgregar.bind(this);
    this.toggleAgregarGasto = this.toggleAgregarGasto.bind(this);
    this.toggleCargos = this.toggleCargos.bind(this);
    this.onSubmitAgregarPago = this.onSubmitAgregarPago.bind(this);
    this.onClickGasto = this.onClickGasto.bind(this);
    this.onClickEliminar = this.onClickEliminar.bind(this);
    this.onClickConfirmacionEliminar = this.onClickConfirmacionEliminar.bind(
      this
    );
    this.editarGasto = this.editarGasto.bind(this);
  }

  async monstrarArchivos(files) {
    const gasto = this.props.cuentas.gastos[0].registros.filter(
      (a) => a._id === this.state.gastoActivo
    )[0];

    const contenedor =
      gasto.referencias && gasto.referencias.adjuntos
        ? gasto.referencias.adjuntos
        : "gasto-" + uuidv4();
    this.props.getSASUrl({
      archivo: "gastos",
      contenedor: contenedor,
      edicion: gasto.referencias && gasto.referencias.adjuntos ? true : false,
    });

    let nombres = [];
    for (const file of files) {
      await nombres.push(file.name);
    }

    await this.setState({
      archivos: nombres,
      archivosCompleto: files,
      contenedor: contenedor,
      referenciasGasto: { adjuntos: contenedor },
    });
  }

  clicTabla = (e) => {
    this.setState({ celdaActiva: e });
  };

  editarGasto = (e) => {
    const data = {
      concepto: this.state.conceptoGasto,
      monto: this.state.montoGasto,
      cuenta: this.props.cuentas.cuenta._id,
      gastoID: this.state.gastoActivo,
    };

    if (this.state.contenedor) {
      data.referencia = this.state.contenedor;
      data.archivos = this.state.archivos;
    }

    if (this.props.azure.sasURL && this.state.archivos.length > 0) {
      const blobServiceClient = new BlobServiceClient(this.props.azure.sasURL);

      const containerName = ""; // Carpeta, en blanco para tomar la que se asigna en backend
      const containerClient = blobServiceClient.getContainerClient(
        containerName
      );

      this.props.uploadFiles(this.state.archivosCompleto, containerClient);
    }

    data.page = 1;
    data.rangoStart = this.state.rangoStart;
    data.rangoEnd = this.state.rangoEnd;

    this.props.modificarGasto(data);
  };

  onClickConfirmacionEliminar = () => {
    this.props.deleteFile({
      archivo: this.state.archivoEliminar.nombre,
      contenedor: this.state.archivoEliminar.contenedor,
    });
  };

  onClickEliminar = (container, nombre) => {
    this.setState({
      archivoEliminar: { contenedor: container, nombre: nombre },
    });
  };

  onClickGasto = (edicion, gastoActivo) => {
    const gasto = this.props.cuentas.gastos[0].registros.filter(
      (a) => a._id === gastoActivo
    )[0];

    if (gasto.referencias && gasto.referencias.adjuntos)
      this.props.getFilesData({ contenedor: gasto.referencias.adjuntos });

    this.setState({
      edicionGasto: edicion,
      gastoActivo: gastoActivo,
      identificadorGasto: gasto.identificador,
      conceptoGasto: gasto.concepto,
      montoGasto: gasto.monto,
      tipoGasto: gasto.tipo.nombre,
      estatusGasto: gasto.estatus.nombre,
      referenciasGasto: gasto.referencias,
      updatedGasto: gasto.updated,
      createdGasto: gasto.created,
      archivos: [],
      archivosCompleto: [],
      contenedor: "",
    });
  };

  onSubmitAgregarPago = (e) => {
    e.preventDefault();
    let nuevoPago = {};
    switch (this.state.tipoDeCargo) {
      case "1":
        nuevoPago = {
          concepto: this.state.concepto,
          cargos: this.state.cargosSeleccionados,
          tipo: this.state.tipo,
          formaDePago: this.state.formaDePago,
          monto: this.state.monto + "",
          casa: this.state.casaSelect,
          tipoDeCargo: this.state.tipoDeCargo,
          referencia: this.state.referencia,
          asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        };

        break;

      case "2":
        nuevoPago = {
          concepto: this.state.concepto,
          cargos: this.state.cargosFuturos.filter((a, i) =>
            this.state.cargosFuturosSeleccionados.includes(i)
          ),
          tipo: "5ebc068be6e7d173c390467b",
          formaDePago: this.state.formaDePago,
          monto: this.state.monto + "",
          casa: this.state.casaSelect,
          tipoDeCargo: this.state.tipoDeCargo,
          referencia: this.state.referencia,
          asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        };

        break;

      case "3":
        nuevoPago = {
          concepto: this.state.concepto,
          cargos: this.state.cuota,
          tipo: this.state.tipo,
          formaDePago: this.state.formaDePago,
          monto: this.state.monto + "",
          casa: this.state.casaSelect,
          tipoDeCargo: this.state.tipoDeCargo,
          referencia: this.state.referencia,
          asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
          cuenta: this.props.cuentas.cuenta._id,
        };

        break;

      default:
        break;
    }
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 10);
    endDate.setDate(endDate.getDate() + 1);

    nuevoPago.page = 1;
    nuevoPago.rangoStart = startDate;
    nuevoPago.rangoEnd = endDate;

    this.props.registrarPago(nuevoPago);
  };

  dblClicTabla = (e) => {
    window.abrirModal("agregarPago");
    this.setState({ clusterSeleccionado: e, casaSelect: e });

    this.props.loadServiciosAsociacion({
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      casa: e,
    });

    this.props.loadTipoDePago();
    this.props.loadFormaDePago();
    this.props.resetCargosCasa();

    if (
      this.props.asociacion.membresiaActiva.asociacion.tipo.nombre === "Clúster"
    ) {
      this.setState({
        cargosFuturosSeleccionados: [],
        cargosSeleccionados: [],
        monto: 0,
      });

      this.props.loadCargosPendientes({
        destino: this.props.asociacion.membresiaActiva.asociacion._id,
        page: "0",
        asociacion: e,
      });

      this.setState({ cargosSeleccionados: [] });
    }
  };

  toggleAgregar = (e) => {
    this.setState((prevState) => ({
      agregar: !prevState.agregar,
    }));
  };

  toggleAgregarGasto = (e) => {
    if (!this.state.agregarGasto) {
      window.scrollTo(0, this.adjuntosRef.offsetTop - 350);
    }

    this.setState((prevState) => ({
      agregarGasto: !prevState.agregarGasto,
    }));
  };

  toggleCargos = (e) => {
    if (this.state.tipo && this.state.tipo === "5ebc0692e6e7d173c390467c") {
      this.setState({
        cargosSeleccionados: [e],
        monto: this.props.cuentas.cargosCasa.filter(
          (cargo) => cargo._id === e
        )[0].monto,
      });
    } else {
      if (this.state.cargosSeleccionados.indexOf(e) > -1) {
        this.setState((prevState) => ({
          cargosSeleccionados: prevState.cargosSeleccionados.filter(
            (item) => e !== item
          ),
          monto:
            prevState.monto -
            this.props.cuentas.cargosCasa.filter((cargo) => cargo._id === e)[0]
              .monto,
        }));
      } else {
        this.setState((prevState) => ({
          cargosSeleccionados: [...prevState.cargosSeleccionados, e],
          monto:
            prevState.monto +
            this.props.cuentas.cargosCasa.filter((cargo) => cargo._id === e)[0]
              .monto,
        }));
      }
    }
  };

  toggleCargosFuturos = (e) => {
    if (this.state.tipo && this.state.tipo === "5ebc0692e6e7d173c390467c") {
      this.setState({
        cargosFuturosSeleccionados: [e],
        monto: this.state.cargosFuturos[e].monto,
      });
    } else {
      if (this.state.cargosFuturosSeleccionados.indexOf(e) > -1) {
        this.setState((prevState) => ({
          cargosFuturosSeleccionados: prevState.cargosFuturosSeleccionados.filter(
            (item) => e !== item
          ),
          monto: prevState.monto - this.state.cargosFuturos[e].monto,
        }));
      } else {
        this.setState((prevState) => ({
          cargosFuturosSeleccionados: [
            ...prevState.cargosFuturosSeleccionados,
            e,
          ],
          monto: prevState.monto + this.state.cargosFuturos[e].monto,
        }));
      }
    }
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();

    if (
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadCuenta({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      });
      this.props.loadPagos({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: 1,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (
      this.props.cuentas &&
      this.props.cuentas.cuenta._id !== prevProps.cuentas.cuenta._id
    ) {
      this.props.loadGastos({
        cuenta: this.props.cuentas.cuenta._id,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.asociacion !== prevProps.asociacion &&
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadCuenta({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      });
      this.props.loadPagos({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        page: 1,
        rangoStart: this.state.rangoStart,
        rangoEnd: this.state.rangoEnd,
      });
    }

    if (
      this.props.asociacion.membresiaActiva.asociacion &&
      this.props.asociacion.membresiaActiva.asociacion !==
        prevProps.asociacion.membresiaActiva.asociacion
    ) {
      this.props.loadServiciosAsociacion({
        asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        casa: this.state.casaSelect,
      });
    }

    if (
      this.props.cuentas &&
      this.props.cuentas.notificarPago &&
      prevProps.cuentas.notificarPago !== this.props.cuentas.notificarPago
    ) {
      this.setState({
        tipoDeCargo: "1",
        casaSelect: "0",
        cargosFuturosSeleccionados: [],
        cargosSeleccionados: [],
        cargosFuturos: [],
        monto: 0,
      });

      this.props.resetErrors();

      window.cerrrarModal("agregarPago");
      NotificationManager.success("", "Pago registrado exitosamente", 6000);

      this.toggleAgregar();
    }

    if (
      this.props.cuentas &&
      this.props.cuentas.notificarGasto &&
      this.props.cuentas.notificarGasto !== prevProps.cuentas.notificarGasto
    ) {
      this.props.resetErrors();
      NotificationManager.success("", "Cargo registrado exitosamente", 6000);
      this.toggleAgregarGasto();
    }

    if (
      this.props.cuentas &&
      this.props.cuentas.notificarEdicion &&
      this.props.cuentas.notificarEdicion !== prevProps.cuentas.notificarEdicion
    ) {
      this.props.resetErrors();
      NotificationManager.success("", "Gasto guardado exitosamente", 6000);
      if (this.state.contenedor !== "")
        this.props.getFilesData({ contenedor: this.state.contenedor });
      this.setState({ contenedor: "", archivos: [], archivosCompleto: [] });
    }

    if (
      this.props.cuentas.ultimoCargo &&
      this.props.cuentas.ultimoCargo !== prevProps.cuentas.ultimoCargo &&
      this.state.tipoDeCargo === "2"
    ) {
      let fin = new Date();
      let i = 0;
      let inicio,
        prueba,
        concepto,
        newCargo,
        vencimiento,
        final = [];

      fin = new Date(this.props.cuentas.ultimoCargo.ultimoCargo.fin);
      fin.setHours(fin.getHours() + 6);

      inicio = new Date(this.props.cuentas.ultimoCargo.ultimoCargo.inicio);
      inicio.setHours(inicio.getHours() + 6);

      prueba = new Date(this.props.cuentas.ultimoCargo.ultimoCargo.inicio);
      prueba.setHours(prueba.getHours() + 6);

      do {
        inicio = new Date(
          inicio.setMonth(
            inicio.getMonth() +
              this.props.cuentas.ultimoCargo.frecuencia.cantidad
          )
        );

        vencimiento = inicio;

        fin = new Date(
          prueba.setMonth(
            inicio.getMonth() +
              this.props.cuentas.ultimoCargo.frecuencia.cantidad
          )
        );

        fin = new Date(fin.setTime(fin.getTime() - 1 * 86400000));

        /* fin.setTime(fin.getTime() - 3 * 86400000); */

        vencimiento.setDate(
          inicio.getDate() + this.props.cuentas.ultimoCargo.maxDiasCargo
        );

        concepto =
          this.props.cuentas.ultimoCargo.servicio.nombre +
          " periodo del " +
          dateFormat(inicio, "isoDate") +
          " al " +
          dateFormat(fin, "isoDate");

        newCargo = {
          concepto: concepto,
          servicio: this.props.cuentas.ultimoCargo.servicio._id,
          cuentaOrigen: this.props.cuentas.ultimoCargo.cuenta._id,
          cuentaDestino: this.props.cuentas.ultimoCargo.cuentaFraccionamiento
            ._id,
          monto: this.props.cuentas.ultimoCargo.servicio.costo,
          inicio: inicio,
          fin: fin,
          vencimiento: vencimiento,
          tipo: "Anticipado",
        };

        final.push(newCargo);

        i++;
      } while (i <= 12);

      this.setState({
        cargosFuturos: final,
      });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      case "tipo":
        if (e.target.value === "5ebc0692e6e7d173c390467c") {
          this.setState({
            cargosSeleccionados: [],
            monto: 0,
            cargosFuturosSeleccionados: [],
          });
        }

        this.setState({ [e.target.name]: e.target.value });
        break;
      case "casaSelect":
        this.setState({
          [e.target.name]: e.target.value,
          cargosFuturosSeleccionados: [],
          cargosSeleccionados: [],
          monto: 0,
        });

        if (this.state.tipoDeCargo === "1") {
          this.props.loadCargosPendientes({
            destino: this.props.asociacion.membresiaActiva.asociacion._id,
            page: "0",
            asociacion: e.target.value,
          });
          this.setState({ cargosSeleccionados: [] });
        } else if (
          this.state.cuota &&
          this.state.tipoDeCargo === "2" &&
          this.state.cuota !== "0"
        ) {
          this.props.getUltimoCargo({
            fraccionamiento: this.props.asociacion.membresiaActiva.asociacion
              ._id,
            asociacion: e.target.value,
            servicio: this.state.cuota,
          });
        } else if (this.state.tipoDeCargo === "3") {
        }
        break;
      case "tipoDeCargoRadio":
        this.setState({
          tipoDeCargo: e.currentTarget.value,
          cargosFuturosSeleccionados: [],
          cargosSeleccionados: [],
          cargosFuturos: [],
          tipo: "5ebc068be6e7d173c390467b",
          monto: 0,
          cuota: "0",
        });
        if (this.props.asociacion.membresiaActiva.tipo === "Fraccionamiento") {
          this.setState({ casaSelect: "0" });
        }

        if (
          this.props.asociacion.membresiaActiva.tipo === "Clúster" &&
          e.currentTarget.value === "2" &&
          this.state.cuota
        ) {
          this.props.getUltimoCargo({
            fraccionamiento: this.props.asociacion.membresiaActiva.asociacion
              ._id,
            asociacion: this.state.clusterSeleccionado,
            servicio: this.state.cuota,
          });
        }

        this.props.resetCargosCasa();

        break;
      case "cuota":
        this.setState({ [e.target.name]: e.target.value });

        if (this.state.tipoDeCargo === "2") {
          if (this.state.casaSelect !== "0" && e.target.value != "0") {
            this.props.getUltimoCargo({
              fraccionamiento: this.props.asociacion.membresiaActiva.asociacion
                ._id,
              asociacion: this.state.casaSelect,
              servicio: e.target.value,
            });
          }
        } else if (this.state.tipoDeCargo === "3") {
          this.setState({
            monto: this.props.asociacion.servicios.filter(
              (cuota) => cuota._id === e.target.value
            )[0].costo,
          });
        }

        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;
    const { errors, clusterSeleccionado, edicionGasto } = this.state;

    const { membresiaActiva, servicios } = this.props.asociacion;
    const { cargosCasa } = this.props.cuentas;
    const { tipoDePago, formaDePago } = this.props.catalog;

    let content;
    let modalContent;

    modalContent = (
      <div>
        <div id="deleteObjectModal" className="modal fade">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  Eliminar {this.state.archivoEliminar.nombre}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>¿Está seguro que quiere eliminar el archivo seleccionado?</p>
                <p className="text-danger">
                  <small>Ésta acción no puede ser revertida</small>
                </p>
              </div>
              <div className="modal-footer">
                <input
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  value="Cancel"
                />
                <input
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  value="Eliminar"
                  onClick={() => {
                    this.onClickConfirmacionEliminar();
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="detalleGasto"
          id="detalleGasto"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {edicionGasto ? "Editar " : "Detalle "} gasto
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body modal-scroll">
                <div className="row">
                  <div className="col-12">
                    <TextFieldGroup
                      name="identificadorGasto"
                      error={this.props.errors["identificadorGasto"]}
                      placeholder=""
                      value={
                        this.state["identificadorGasto"]
                          ? this.state["identificadorGasto"]
                          : ""
                      }
                      onChange={this.onChange}
                      label="Identificador del gasto"
                      id="identificadorGasto"
                      disabled={true}
                    />

                    <TextFieldGroup
                      name="conceptoGasto"
                      error={this.props.errors["conceptoGasto"]}
                      placeholder=""
                      value={
                        this.state["conceptoGasto"]
                          ? this.state["conceptoGasto"]
                          : ""
                      }
                      onChange={this.onChange}
                      label="Concepto"
                      id="conceptoGasto"
                      disabled={edicionGasto ? false : true}
                    />

                    <TextFieldGroup
                      name="montoGasto"
                      error={this.props.errors["montoGasto"]}
                      placeholder=""
                      value={
                        this.state["montoGasto"]
                          ? this.state["montoGasto"] + ""
                          : ""
                      }
                      onChange={this.onChange}
                      label="Monto en MXN"
                      id="montoGasto"
                      disabled={edicionGasto ? false : true}
                    />

                    <TextFieldGroup
                      name="tipoGasto"
                      error={this.props.errors["tipoGasto"]}
                      placeholder=""
                      value={
                        this.state["tipoGasto"] ? this.state["tipoGasto"] : ""
                      }
                      onChange={this.onChange}
                      label="Tipo"
                      id="tipoGasto"
                      disabled={true}
                    />

                    <TextFieldGroup
                      name="estatusGasto"
                      error={this.props.errors["estatusGasto"]}
                      placeholder=""
                      value={
                        this.state["estatusGasto"]
                          ? this.state["estatusGasto"]
                          : ""
                      }
                      onChange={this.onChange}
                      label="Estatus"
                      id="estatusGasto"
                      disabled={true}
                    />

                    <h5>Adjuntos</h5>

                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Tamaño</th>
                          <th scope="col">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.azure &&
                          this.state.referenciasGasto &&
                          this.state.referenciasGasto.adjuntos &&
                          this.props.azure.files.length > 0 &&
                          this.props.azure.files.map((archivo, i) => {
                            return (
                              <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `https://wirhub.blob.core.windows.net/${this.state.referenciasGasto.adjuntos}/${archivo.name}`,
                                    }}
                                    target="_blank"
                                  >
                                    {archivo.name}
                                  </Link>
                                </td>
                                <td>
                                  {archivo.size / 1000 > 1000
                                    ? Math.round(archivo.size / 1000 / 1000)
                                    : Math.round(archivo.size / 1000)}{" "}
                                  {archivo.size / 1000 > 1000 ? "MB" : "KB"}
                                </td>
                                <td>
                                  <a
                                    href="#deleteObjectModal"
                                    className="delete"
                                    data-toggle="modal"
                                    onClick={() => {
                                      this.onClickEliminar(
                                        this.state.referenciasGasto.adjuntos,
                                        archivo.name
                                      );
                                    }}
                                  >
                                    <i
                                      className="material-icons"
                                      data-toggle="tooltip"
                                      title="Eliminar"
                                    >
                                      &#xE872;
                                    </i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>

                  {edicionGasto && (
                    <div className="col-12 mt-2">
                      <div className="custom-file">
                        <input
                          style={{ display: "none" }}
                          className="custom-file-input"
                          id="archivoGasto"
                          type="file"
                          multiple={true}
                          onChange={(e) =>
                            this.monstrarArchivos(e.target.files)
                          }
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="archivoGasto"
                        >
                          Seleccionar Archivo
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-12 mt-2">
                    {this.state.archivos &&
                      edicionGasto &&
                      this.state.archivos.map((file, i) => {
                        return (
                          <div className="row" key={i}>
                            <div className="col-12">{file}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                {edicionGasto && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.editarGasto()}
                  >
                    Guardar
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="agregarPago"
          name="agregarPago"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="agregarCuotaModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="agregarCuotaModal">
                  Registrar pago |{" "}
                  {clusterSeleccionado &&
                    membresiaActiva.asociacion.departamentos.filter(
                      (depa) => depa._id === clusterSeleccionado
                    )[0].nombre}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body modal-scroll">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 form-inline">
                      <label className="my-1 mr-2" htmlFor="casaSelect">
                        Casa:
                      </label>
                      <select
                        className={classnames(
                          "custom-select my-1 mr-sm-2 mr-3",
                          {
                            "is-invalid": errors["casa"],
                          }
                        )}
                        id="casaSelect"
                        name="casaSelect"
                        onChange={this.onChange}
                        value={this.state.casaSelect}
                      >
                        {clusterSeleccionado &&
                        this.props.asociacion.membresiaActiva.asociacion.tipo
                          .nombre === "Clúster" ? (
                          <option
                            value={
                              membresiaActiva.asociacion.departamentos.filter(
                                (depa) => depa._id === clusterSeleccionado
                              )[0]._id
                            }
                          >
                            {
                              membresiaActiva.asociacion.departamentos.filter(
                                (depa) => depa._id === clusterSeleccionado
                              )[0].nombre
                            }
                          </option>
                        ) : (
                          clusterSeleccionado &&
                          membresiaActiva.asociacion.departamentos
                            .filter(
                              (depa) => depa._id === clusterSeleccionado
                            )[0]
                            .areas.map((casa) => {
                              return (
                                <option key={casa._id} value={casa._id}>
                                  {casa.nombre}
                                </option>
                              );
                            })
                        )}
                      </select>

                      <div
                        className="custom-control custom-radio custom-control-inline"
                        style={{ marginLeft: "30px" }}
                      >
                        <input
                          type="radio"
                          id="tipoDeCargoRadio"
                          name="tipoDeCargoRadio"
                          className="custom-control-input"
                          value="1"
                          checked={this.state.tipoDeCargo === "1"}
                          onChange={this.onChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="tipoDeCargoRadio"
                        >
                          Cargos pendientes
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline ml-2">
                        <input
                          type="radio"
                          id="customRadioInline2"
                          name="tipoDeCargoRadio"
                          className="custom-control-input"
                          value="2"
                          checked={this.state.tipoDeCargo === "2"}
                          onChange={this.onChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioInline2"
                        >
                          Pago anticipado
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          id="customRadioInline3"
                          name="tipoDeCargoRadio"
                          className="custom-control-input"
                          value="3"
                          checked={this.state.tipoDeCargo === "3"}
                          onChange={this.onChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadioInline3"
                        >
                          Nuevo cargo
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4 mt-md-3">
                      {this.props.cuentas &&
                      (!this.props.cuentas.cargosCasa ||
                        !tipoDePago ||
                        !formaDePago) ? (
                        <Spinner></Spinner>
                      ) : (
                        <form
                          id="agregarPagoForm"
                          name="agregarPagoForm"
                          onSubmit={this.onSubmitAgregarPago}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5>
                                Detalle <hr></hr>
                              </h5>
                            </div>
                            <div className="col-12">
                              <div className="group inputPlaceholder form-group">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": errors["concepto"],
                                  })}
                                  id="concepto"
                                  name="concepto"
                                  onChange={this.onChange}
                                  value={this.state.concepto}
                                  isrequired="true"
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="concepto">Concepto</label>
                                {errors["concepto"] && (
                                  <div className="invalid-feedback">
                                    {errors["concepto"]}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="group inputPlaceholder form-group">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": errors["referencia"],
                                  })}
                                  id="referencia"
                                  name="referencia"
                                  onChange={this.onChange}
                                  value={this.state.referencia}
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="referencia">Referencia</label>
                                {errors["referencia"] && (
                                  <div className="invalid-feedback">
                                    {errors["referencia"]}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="group inputPlaceholder form-group">
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": errors["monto"],
                                  })}
                                  id="monto"
                                  name="monto"
                                  onChange={this.onChange}
                                  value={this.state.monto}
                                  disabled={
                                    this.state.tipoDeCargo === "2"
                                      ? true
                                      : false
                                  }
                                />
                                <span className="highlight"></span>
                                <span className="bar"></span>
                                <label htmlFor="monto">Monto en MXN</label>
                                {errors["monto"] && (
                                  <div className="invalid-feedback">
                                    {errors["monto"]}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12" style={{ padding: "0px" }}>
                            <div className="form-group">
                              <label htmlFor="tipo">Tipo de pago</label>
                              <select
                                className={classnames("form-control", {
                                  "is-invalid": errors["tipo"],
                                })}
                                id="tipo"
                                name="tipo"
                                onChange={this.onChange}
                                value={this.state.tipo}
                                disabled={
                                  this.state.tipoDeCargo === "2" ? true : false
                                }
                              >
                                <option value="0">Elige</option>
                                {this.props.catalog.tipoDePago.map(
                                  (tipo, i) => {
                                    return (
                                      <option key={tipo._id} value={tipo._id}>
                                        {tipo.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              {errors["tipo"] && (
                                <div className="invalid-feedback">
                                  {errors["tipo"]}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="formaDePago">Forma de pago</label>
                              <select
                                className={classnames("form-control", {
                                  "is-invalid": errors["formaDePago"],
                                })}
                                id="formaDePago"
                                name="formaDePago"
                                onChange={this.onChange}
                                value={this.state.formaDePago}
                              >
                                <option value="0">Elige</option>
                                {this.props.catalog.formaDePago.map(
                                  (formaDePago, i) => {
                                    return (
                                      <option
                                        key={formaDePago._id}
                                        value={formaDePago._id}
                                      >
                                        {formaDePago.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              {errors["formaDePago"] && (
                                <div className="invalid-feedback">
                                  {errors["formaDePago"]}
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    <div
                      className={classnames("col-sm-12 col-md-8 mt-md-3", {
                        ocultar: this.state.tipoDeCargo !== "1",
                      })}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h5>
                            Cargos pendientes de pago <hr></hr>
                          </h5>
                        </div>
                        <div className="col-12">
                          <div className="list-group">
                            {cargosCasa.map((cargo) => {
                              return (
                                <button
                                  type="button"
                                  className={classnames(
                                    "list-group-item list-group-item-action",
                                    {
                                      active:
                                        this.state.cargosSeleccionados.indexOf(
                                          cargo._id
                                        ) > -1,
                                    }
                                  )}
                                  key={cargo._id}
                                  onClick={() => this.toggleCargos(cargo._id)}
                                >
                                  {cargo.concepto}
                                  {"  "} Tipo: {cargo.tipo} $ {cargo.monto} MXN
                                </button>
                              );
                            })}
                            {cargosCasa.length === 0 ? (
                              <span>No hay ningún cargo pendiente</span>
                            ) : (
                              ""
                            )}
                          </div>

                          {errors["cargos"] && (
                            <div className="invalid-cargo">
                              {errors["cargos"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className={classnames("col-sm-12 col-md-8 mt-md-3", {
                        ocultar: this.state.tipoDeCargo !== "2",
                      })}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h5>
                            Pago anticipado <hr></hr>
                          </h5>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="cuota">Cuota o servicio</label>
                            <select
                              className={classnames("form-control", {
                                "is-invalid": errors["cuota"],
                              })}
                              id="cuota"
                              name="cuota"
                              onChange={this.onChange}
                              value={this.state.cuota}
                            >
                              <option value="0">Elige</option>
                              {servicios &&
                                this.state.casaSelect &&
                                this.state.casaSelect !== "0" &&
                                servicios
                                  .filter(
                                    (cuota) =>
                                      cuota.suscripciones.frecuencia.nombre !==
                                      "Evento único"
                                  )
                                  .filter(
                                    (cuota) =>
                                      cuota.suscripciones.suscriptores.filter(
                                        (suscriptor) =>
                                          suscriptor._id ===
                                          this.state.casaSelect
                                      ).length > 0
                                  )
                                  .map((cuota, i) => {
                                    return (
                                      <option key={cuota._id} value={cuota._id}>
                                        {cuota.nombre}, Costo: $ {cuota.costo}{" "}
                                        MXN, frecuencia:{" "}
                                        {cuota.suscripciones.frecuencia.nombre}
                                      </option>
                                    );
                                  })}
                            </select>
                            {errors["cuota"] && (
                              <div className="invalid-feedback">
                                {errors["cuota"]}
                              </div>
                            )}
                          </div>

                          <label htmlFor="lista">Cargos futuros</label>

                          <div className="list-group" id="lista">
                            {this.state.cargosFuturos.map((cargo, i) => {
                              return (
                                <button
                                  type="button"
                                  className={classnames(
                                    "list-group-item list-group-item-action",
                                    {
                                      active:
                                        this.state.cargosFuturosSeleccionados.indexOf(
                                          i
                                        ) > -1,
                                    }
                                  )}
                                  key={i}
                                  onClick={() => this.toggleCargosFuturos(i)}
                                >
                                  {cargo.concepto}
                                  {"  "} Tipo: {cargo.tipo} $ {cargo.monto} MXN
                                </button>
                              );
                            })}
                            {this.state.cargosFuturos.length === 0 ? (
                              <span>
                                Selecciona una casa y el servicio deseado para
                                calcular los próximos cargos
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          {errors["cargosFuturos"] && (
                            <div className="invalid-cargo">
                              {errors["cargosFuturos"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className={classnames("col-sm-12 col-md-8 mt-md-3", {
                        ocultar: this.state.tipoDeCargo !== "3",
                      })}
                    >
                      <div className="row">
                        <div className="col-12">
                          <h5>
                            Nuevo cargo <hr></hr>
                          </h5>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="cuota">Cuota o servicio</label>
                            <select
                              className={classnames("form-control", {
                                "is-invalid": errors["cuota"],
                              })}
                              id="cuota"
                              name="cuota"
                              onChange={this.onChange}
                              value={this.state.cuota}
                            >
                              <option value="0">Elige</option>
                              {servicios &&
                                servicios
                                  .filter(
                                    (cuota) =>
                                      cuota.suscripciones.frecuencia.nombre ===
                                      "Evento único"
                                  )
                                  .map((cuota, i) => {
                                    return (
                                      <option key={cuota._id} value={cuota._id}>
                                        {cuota.nombre}, Costo: $ {cuota.costo}{" "}
                                        MXN, frecuencia:{" "}
                                        {cuota.suscripciones.frecuencia.nombre}
                                      </option>
                                    );
                                  })}
                            </select>
                            {errors["cuota"] && (
                              <div className="invalid-feedback">
                                {errors["cuota"]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button
                  form="agregarPagoForm"
                  type="submit"
                  className="btn btn-primary"
                >
                  Registrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <PermissibleRender
              userPermissions={
                Object.keys(this.props.asociacion.membresiaActiva).length > 0 &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "INGRESOS"]}
              oneperm
            >
              <Clusters
                filasSeleccionadas={this.state.filasSeleccionadas}
                pagina={this.state.pagina}
                celdaActiva={this.state.celdaActiva}
                clicTabla={this.clicTabla}
                dblClicTabla={this.dblClicTabla}
                agregar={this.state.agregar}
              />

              <Registro
                toggleAgregar={this.toggleAgregar}
                agregar={this.state.agregar}
              />
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                Object.keys(this.props.asociacion.membresiaActiva).length > 0 &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "EGRESOS"]}
              oneperm
            >
              <div
                ref={(ref) => (this.adjuntosRef = ref)}
                style={{ width: "100%" }}
              >
                <RegistroGasto
                  registrarGasto={this.props.registrarGasto}
                  agregar={this.state.agregarGasto}
                  rangoStart={this.state.rangoStart}
                  rangoEnd={this.state.rangoEnd}
                />
              </div>

              <Gastos
                toggleAgregar={this.toggleAgregarGasto}
                agregar={this.state.agregarGasto}
                onClickGasto={this.onClickGasto}
                gastoActivo={this.state.gastoActivo}
              />
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                Object.keys(this.props.asociacion.membresiaActiva).length > 0 &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["ESTADO_CUENTA_CONDOMINO"]}
              oneperm
            >
              <CondominoCagosPendientes />
              <CondominoPagosRealizados />
            </PermissibleRender>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Contabilidad</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Ingresos y egresos</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Pagos.propTypes = {
  auth: PropTypes.object.isRequired,
  azure: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
  loadServiciosAsociacion: PropTypes.func.isRequired,
  getFilesData: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  loadCuenta: PropTypes.func.isRequired,
  loadPagos: PropTypes.func.isRequired,
  loadCargosPendientes: PropTypes.func.isRequired,
  registrarPago: PropTypes.func.isRequired,
  resetCargosCasa: PropTypes.func.isRequired,
  getUltimoCargo: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  loadFormaDePago: PropTypes.func.isRequired,
  loadTipoDePago: PropTypes.func.isRequired,
  registrarGasto: PropTypes.func.isRequired,
  loadGastos: PropTypes.func.isRequired,
  modificarGasto: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  getSASUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  azure: state.azure,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
  cuentas: state.cuentas,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
  loadCuenta,
  loadPagos,
  loadCargosPendientes,
  loadFormaDePago,
  loadTipoDePago,
  loadServiciosAsociacion,
  registrarPago,
  resetCargosCasa,
  getUltimoCargo,
  resetErrors,
  registrarGasto,
  loadGastos,
  getFilesData,
  deleteFile,
  modificarGasto,
  uploadFiles,
  getSASUrl,
})(Pagos);
