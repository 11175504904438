import { LOCATION_CHANGE } from "react-router-redux";
import { CHANGE_PAGE } from "../actions/types";

const initialState = {
  currentPage: "/"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.page
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        currentPage: action.payload.pathname
      };
    default: {
      return {
        ...state
      };
    }
  }
}
