import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { registerUser, getInvitationInfo } from "../../actions/authActions";
import { withRouter } from "react-router-dom";
import Aviso from "../layout/Aviso";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      lastName: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      aviso: true,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (this.props.match.params.token) {
      this.props.getInvitationInfo({ token: this.props.match.params.token });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (this.props.auth.invitation !== prevProps.auth.invitation) {
      this.setState({
        email: this.props.auth.invitation.email,
        name: this.props.auth.invitation.name,
        lastName: this.props.auth.invitation.lastName,
      });
    }
  }

  onChange(e) {
    if (e.target.name === "avisoPrivacidadCheck")
      this.setState({ aviso: !this.state.aviso });
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      asociacion:
        this.props.match.params.token && this.props.auth.invitation.asociacion
          ? this.props.auth.invitation.asociacion
          : "",
      user:
        this.props.match.params.token && this.props.auth.invitation.user
          ? this.props.auth.invitation.user
          : "",
      rol:
        this.props.match.params.token && this.props.auth.invitation.rol
          ? this.props.auth.invitation.rol
          : "",
    };

    this.props.registerUser(newUser, this.props.history);
  }

  render() {
    const { errors } = this.state;

    return (
      <div id="register" className="form-2">
        <div
          className="modal fade"
          id="politicaDePrivacidadModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="politicaDePrivacidadTitel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="politicaDePrivacidadTitel">
                  Aviso de privacidad
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Aviso />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-3">
              <h2>Registro</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="register">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-md-8 col-lg-6 m-auto">
                            <form noValidate onSubmit={this.onSubmit}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={classnames(
                                    "form-control form-control-lg",
                                    {
                                      "is-invalid": errors.name,
                                    }
                                  )}
                                  placeholder="Nombre"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.onChange}
                                />
                                {errors.name && (
                                  <div className="invalid-feedback">
                                    {errors.name}
                                  </div>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={classnames(
                                    "form-control form-control-lg",
                                    {
                                      "is-invalid": errors.lastName,
                                    }
                                  )}
                                  placeholder="Apellidos"
                                  name="lastName"
                                  value={this.state.lastName}
                                  onChange={this.onChange}
                                />
                                {errors.lastName && (
                                  <div className="invalid-feedback">
                                    {errors.lastName}
                                  </div>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="email"
                                  className={classnames(
                                    "form-control form-control-lg",
                                    {
                                      "is-invalid": errors.email,
                                    }
                                  )}
                                  placeholder="Correo electrónico"
                                  name="email"
                                  value={this.state.email}
                                  onChange={this.onChange}
                                />
                                {errors.email && (
                                  <div className="invalid-feedback">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className={classnames(
                                    "form-control form-control-lg",
                                    {
                                      "is-invalid": errors.password,
                                    }
                                  )}
                                  placeholder="Contraseña"
                                  name="password"
                                  value={this.state.password}
                                  onChange={this.onChange}
                                />
                                {errors.password && (
                                  <div className="invalid-feedback">
                                    {errors.password}
                                  </div>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className={classnames(
                                    "form-control form-control-lg",
                                    {
                                      "is-invalid": errors.password2,
                                    }
                                  )}
                                  placeholder="Confirma tu contraseña"
                                  name="password2"
                                  value={this.state.password2}
                                  onChange={this.onChange}
                                />
                                {errors.password2 && (
                                  <div className="invalid-feedback">
                                    {errors.password2}
                                  </div>
                                )}
                              </div>

                              <div className="form-group form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="avisoPrivacidadCheck"
                                  name="avisoPrivacidadCheck"
                                  onChange={this.onChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="avisoPrivacidadCheck"
                                >
                                  Aceptar aviso de privacidad
                                </label>
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  data-toggle="modal"
                                  data-target="#politicaDePrivacidadModal"
                                >
                                  Ver aviso
                                </button>
                              </div>

                              <input
                                type="submit"
                                className="btn btn-info btn-block mt-4"
                                value="Registrarse"
                                disabled={this.state.aviso}
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getInvitationInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser, getInvitationInfo })(
  withRouter(Register)
);
