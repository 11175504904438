import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import {
  getCustomer,
  getSubscriptions,
  getProducts,
  getPaymentMethod,
  createCustomerPortalSession,
} from "../../actions/stripeActions";

class Suscripciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    this.props.getCustomer();
    this.props.getSubscriptions();
    this.props.getProducts();
    this.props.getPaymentMethod();
    this.props.createCustomerPortalSession();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { user } = this.props.auth;
    const { profile } = this.props.profile;
    const {
      subscriptions,
      customer,
      products,
      paymentMethod,
    } = this.props.stripe;

    if (!user || !profile) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title">Suscripción</h5>
            </div>

            {subscriptions.data &&
              subscriptions.data.map((subscription, i) => {
                return (
                  <div className="suscripcion" key={i}>
                    <h6>
                      {products.data &&
                        products.data.filter(
                          (a) => a.id === subscription.plan.product
                        ).length > 0 &&
                        products.data.filter(
                          (a) => a.id === subscription.plan.product
                        )[0].name}
                    </h6>
                    <h6>
                      ${" "}
                      {Math.round(
                        (subscription.plan.amount / 100 +
                          (subscription.plan.amount / 100) * 0.16) *
                          100
                      ) / 100}{" "}
                      MXN
                    </h6>

                    <h6>
                      Estatus:{" "}
                      {subscription.status === "trialing"
                        ? "Periodo de prueba"
                        : "Activo"}
                    </h6>

                    <h6>
                      Próximo cobro{" "}
                      {new Date(
                        subscription.current_period_end * 1000
                      ).toLocaleDateString("es-ES", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </h6>

                    <h6>
                      Miembro desde{" "}
                      {new Date(subscription.created * 1000).toLocaleDateString(
                        "es-ES",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                    </h6>
                    <hr />

                    <h5>Método de pago</h5>

                    {paymentMethod &&
                      paymentMethod.data &&
                      paymentMethod.data.filter(
                        (a) => a.id === subscription.default_payment_method
                      ).length > 0 && (
                        <div>
                          <h6>
                            Tipo de tarjeta{" "}
                            {
                              paymentMethod.data.filter(
                                (a) =>
                                  a.id === subscription.default_payment_method
                              )[0].card.brand
                            }
                          </h6>

                          <h6>
                            Nombre en la tarjeta{" "}
                            {
                              paymentMethod.data.filter(
                                (a) =>
                                  a.id === subscription.default_payment_method
                              )[0].billing_details.name
                            }
                          </h6>

                          <h6>
                            **** **** ****{" "}
                            {
                              paymentMethod.data.filter(
                                (a) =>
                                  a.id === subscription.default_payment_method
                              )[0].card.last4
                            }
                          </h6>

                          <h6>
                            Fecha de vencimiento{" "}
                            {
                              paymentMethod.data.filter(
                                (a) =>
                                  a.id === subscription.default_payment_method
                              )[0].card.exp_month
                            }{" "}
                            {
                              paymentMethod.data.filter(
                                (a) =>
                                  a.id === subscription.default_payment_method
                              )[0].card.exp_year
                            }
                          </h6>
                        </div>
                      )}
                  </div>
                );
              })}

            <a
              href={this.props.stripe.portalCustomer.urlSession}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-success m-3"
            >
              Portal de facturación
            </a>

            <div className="card-footer text-muted"></div>
          </div>
        </div>
      </div>
    );
  }
}

Suscripciones.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getSubscriptions: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getPaymentMethod: PropTypes.func.isRequired,
  createCustomerPortalSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  asociacion: state.asociacion,
  stripe: state.stripe,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getCustomer,
  getSubscriptions,
  getProducts,
  getPaymentMethod,
  createCustomerPortalSession,
})(Suscripciones);
