import React, { Component, useRef } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import TextFieldGroup from "../utils/TextFielGroup";
import { BlobServiceClient } from "@azure/storage-blob";
import { uploadFiles, getSASUrl } from "../../actions/azureActions";
const uuidv4 = require("uuid/v4");
const inputFileRef = useRef < HTMLInputElement > null;

class RegistroGasto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      conceptoGasto: "",
      montoGasto: "",
      archivos: [],
      archivosCompleto: [],
      contenedor: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.onSubmitRegistrarGasto = this.onSubmitRegistrarGasto.bind(this);
  }

  async monstrarArchivos(files) {
    const contenedor = "gasto-" + uuidv4();
    this.props.getSASUrl({ archivo: "gastos", contenedor: contenedor });

    let nombres = [];
    for (const file of files) {
      await nombres.push(file.name);
    }

    await this.setState({
      archivos: nombres,
      archivosCompleto: files,
      contenedor: contenedor,
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      this.props.cuentas &&
      this.props.cuentas.notificarGasto &&
      this.props.cuentas.notificarGasto !== prevProps.cuentas.notificarGasto
    ) {
      this.setState({
        conceptoGasto: "",
        montoGasto: "",
        archivos: [],
        archivosCompleto: [],
        contenedor: "",
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmitRegistrarGasto = (e) => {
    e.preventDefault();

    const data = {
      concepto: this.state.conceptoGasto,
      monto: this.state.montoGasto,
      cuenta: this.props.cuentas.cuenta._id,
    };

    if (this.state.contenedor) {
      data.referencia = this.state.contenedor;
      data.archivos = this.state.archivos;
    }

    if (this.props.azure.sasURL && this.state.archivos.length > 0) {
      const blobServiceClient = new BlobServiceClient(this.props.azure.sasURL);

      const containerName = ""; // Carpeta, en blanco para tomar la que se asigna en backend
      const containerClient = blobServiceClient.getContainerClient(
        containerName
      );

      this.props.uploadFiles(this.state.archivosCompleto, containerClient);
    }

    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 10);
    endDate.setDate(endDate.getDate() + 1);

    data.page = 1;
    data.rangoStart = startDate;
    data.rangoEnd = endDate;

    this.props.registrarGasto(data);
  };

  render() {
    const { membresiaActiva } = this.props.asociacion;

    if (!membresiaActiva) {
      return <div>Loading...</div>;
    }

    return (
      <div
        className={classnames("col-12 mt-3", {
          ocultarDiv: !this.props.agregar,
          mostrarDiv: this.props.agregar,
        })}
      >
        <nav className="navbar">
          <a className="navbar-brand">Agregar gasto</a>
        </nav>
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.onSubmitRegistrarGasto}>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <TextFieldGroup
                      name="conceptoGasto"
                      error={this.state.errors["conceptoGasto"]}
                      placeholder="Concepto"
                      label="Concepto del gasto"
                      value={
                        this.state.conceptoGasto
                          ? this.state["conceptoGasto"]
                          : ""
                      }
                      onChange={this.onChange}
                      id="conceptoGasto"
                    />
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <TextFieldGroup
                      name="montoGasto"
                      error={this.state.errors["montoGasto"]}
                      placeholder="Monto"
                      label="Monto del gasto"
                      value={
                        this.state.montoGasto ? this.state["montoGasto"] : ""
                      }
                      onChange={this.onChange}
                      id="montoGasto"
                    />
                  </div>

                  <div className="col-12 mt-2">
                    <div className="custom-file">
                      <input
                        style={{ display: "none" }}
                        className="custom-file-input"
                        id="archivoGasto"
                        ref={inputFileRef}
                        type="file"
                        multiple={true}
                        onChange={(e) => this.monstrarArchivos(e.target.files)}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="archivoGasto"
                      >
                        Seleccionar Archivo
                      </label>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    {this.state.archivos &&
                      this.state.archivos.map((file, i) => {
                        return (
                          <div className="row" key={i}>
                            <div className="col-12">{file}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <button
                      className="btn btn-info"
                      style={{ float: "right" }}
                      type="submit"
                    >
                      Registar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

RegistroGasto.propTypes = {
  asociacion: PropTypes.object.isRequired,
  azure: PropTypes.object.isRequired,
  cuentas: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  getSASUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  cuentas: state.cuentas,
  azure: state.azure,
  errors: state.errors,
});

export default connect(mapStateToProps, { uploadFiles, getSASUrl })(
  RegistroGasto
);
