import {
  CREATE_CHECKOUT_SESSION,
  GET_CHECKOUT_SESSION,
  CREATE_CUSTOMER,
  GET_SUBSCRIPTIONS,
  CREATE_SUBSCRIPTION,
  LOAD_PRODUCTS,
  GET_PAYMENTMETHOD,
  GET_URL_PORTAL_CUSTOMER,
} from "../actions/types";

const initialState = {
  checkoutSession: {},
  session: {},
  customer: {},
  subscriptions: {},
  subscription: {},
  products: {},
  paymentMethod: {},
  portalCustomer: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_CHECKOUT_SESSION:
      return {
        ...state,
        checkoutSession: action.payload,
      };
    case GET_CHECKOUT_SESSION:
      return {
        ...state,
        session: action.payload,
      };
    case CREATE_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case LOAD_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_PAYMENTMETHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case GET_URL_PORTAL_CUSTOMER:
      return {
        ...state,
        portalCustomer: action.payload,
      };
    default:
      return state;
  }
}
