import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentPage } from "../../actions/pageActions";
import { getProfilePicture } from "../../actions/authActions";
import { Link } from "react-router-dom";
import { PermissibleRender } from "@brainhubeu/react-permissible";

class Sidebar extends Component {
  componentDidMount() {
    this.props.getProfilePicture();
  }
  render() {
    const { user } = this.props.auth;
    const { currentPage } = this.props.page;
    return (
      <nav
        className="col-md-3 col-lg-2 d-lg-block bg-light sidebar collapse navbar-collapse"
        id="sidebarMenu"
      >
        <div className="sidebar-sticky">
          <ul className="nav flex-column">
            <li>{}</li>
            <PermissibleRender
              userPermissions={
                Object.keys(this.props.asociacion.membresiaActiva).length > 0 &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "USUARIO_NUEVO", "PROFILE"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/profile" ? "active" : ""
                  }`}
                  to="/profile"
                >
                  <img
                    className="rounded-circle"
                    src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/img/${this.props.auth.profilePicture}`}
                    alt={user.name}
                    style={{ width: "25px", marginRight: "5px" }}
                    title="Foto de perfil"
                  />
                  {user.name}
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "USUARIO_NUEVO", "DASHBOARD"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/dashboard" ? "active" : ""
                  }`}
                  to="/dashboard"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-bar-chart-2"
                  >
                    <line x1="18" y1="20" x2="18" y2="10"></line>
                    <line x1="12" y1="20" x2="12" y2="4"></line>
                    <line x1="6" y1="20" x2="6" y2="14"></line>
                  </svg>
                  Dashboard <span className="sr-only">(current)</span>
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "USUARIO_NUEVO", "FRACCIONAMIENTO"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/fraccionamiento" ? "active" : ""
                  }`}
                  to="/fraccionamiento"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                  Fracc.
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ""
              }
              requiredPermissions={["TODO", "CONTABILIDAD"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/contabilidad" ? "active" : ""
                  }`}
                  to="/contabilidad"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                  Contabilidad
                </Link>
              </li>
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ""
              }
              requiredPermissions={["TODO", "COMUNICADOS"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/comunicados" ? "active" : ""
                  }`}
                  to="/comunicados"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mail"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                    <polyline points="22,6 12,13 2,6" />
                  </svg>
                  Comunicados
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "ACCESOS"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/accesos" ? "active" : ""
                  }`}
                  to="/accesos"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-users"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                  Accesos
                </Link>
              </li>
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "KANBAN"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/kanban" ? "active" : ""
                  }`}
                  to="/kanban"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check"
                  >
                    <polyline points="20 6 9 17 4 12"></polyline>
                  </svg>
                  Kanban
                </Link>
              </li>
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["ACCESOS_CONDOMINO"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/ingresos" ? "active" : ""
                  }`}
                  to="/ingresos"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-users"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                  Accesos
                </Link>
              </li>
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["TODO", "AREAS"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/areas" ? "active" : ""
                  }`}
                  to="/areas"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-calendar"
                  >
                    <rect
                      x="3"
                      y="4"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                  Agenda
                </Link>
              </li>
            </PermissibleRender>

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["NADIE"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/servicios" ? "active" : ""
                  }`}
                  to="/servicios"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-grid"
                  >
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect>
                  </svg>
                  Servicios
                </Link>
              </li>
            </PermissibleRender>
            {/*             <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPage === "/productos" ? "active" : ""
                }`}
                to="/productos"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-shopping-cart"
                >
                  <circle cx="9" cy="21" r="1"></circle>
                  <circle cx="20" cy="21" r="1"></circle>
                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                </svg>
                Productos
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  currentPage === "/actividades" ? "active" : ""
                }`}
                to="/actividades"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check-square"
                >
                  <polyline points="9 11 12 14 22 4"></polyline>
                  <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                </svg>
                Actividades
              </Link>
            </li> */}

            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["NADIE"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/encuestas" ? "active" : ""
                  }`}
                  to="/encuestas"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-text"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                  </svg>
                  Encuestas
                </Link>
              </li>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol
                  ? this.props.asociacion.membresiaActiva.rol[0].permissions
                  : ["USUARIO_NUEVO"]
              }
              requiredPermissions={["NADIE"]}
              oneperm
            >
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    currentPage === "/aclaraciones" ? "active" : ""
                  }`}
                  to="/aclaraciones"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-repeat"
                  >
                    <polyline points="17 1 21 5 17 9"></polyline>
                    <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                    <polyline points="7 23 3 19 7 15"></polyline>
                    <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                  </svg>
                  Aclaraciones
                </Link>
              </li>
            </PermissibleRender>
          </ul>

          <PermissibleRender
            userPermissions={
              this.props.asociacion.membresiaActiva &&
              this.props.asociacion.membresiaActiva.rol
                ? this.props.asociacion.membresiaActiva.rol[0].permissions
                : ["USUARIO_NUEVO"]
            }
            requiredPermissions={["TODO", "REPORTES"]}
            oneperm
          >
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
              <span>Reportes</span>
              <a className="d-flex align-items-center text-muted" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-plus-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <line x1="12" y1="8" x2="12" y2="16" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                </svg>
              </a>
            </h6>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-file-text"
                  >
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1="16" y1="13" x2="8" y2="13" />
                    <line x1="16" y1="17" x2="8" y2="17" />
                    <polyline points="10 9 9 9 8 9" />
                  </svg>
                  Ingresos del mes
                </a>
              </li>
            </ul>
          </PermissibleRender>
        </div>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  getCurrentPage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  getProfilePicture: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  page: state.page,
  asociacion: state.asociacion,
});

export default connect(mapStateToProps, { getCurrentPage, getProfilePicture })(
  Sidebar
);
