import React, { Component } from "react";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

import { updateInfoAsociacion } from "../../actions/asociacionActions";
import {
  loadCountries,
  loadStates,
  loadCities,
  loadTipoDeAsociacion,
} from "../../actions/catalogoActions";

import { NotificationManager } from "react-notifications";

import classnames from "classnames";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../css/tabla.css";

// Arreglar los niveles de información de cluster

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class Informacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
      tipoDeAsociacion: "0",
      countryDireccion: "0",
      stateDireccion: "0",
      cityDireccion: "0",
      identificadorDireccion: "",
      nombreAsociacionEdit: "",
      calleDireccionName: "",
      numeroDireccionName: "",
      coloniaDireccion: "",
      codigoPostalDireccion: "",
      timeAgo: "hace 1 año",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.onSubmitActualizarInfo = this.onSubmitActualizarInfo.bind(this);
  }

  onChange = (e) => {
    switch (e.target.name) {
      case "countryDireccion":
        this.props.loadStates(e.target.value, true);
        this.setState({ countryDireccion: e.target.value });
        this.setState({ stateDireccion: "0" });
        this.setState({ cityDireccion: "0" });
        break;
      case "stateDireccion":
        this.props.loadCities(e.target.value);
        this.setState({ stateDireccion: e.target.value });
        this.setState({ cityDireccion: "0" });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  onSubmitActualizarInfo = (e) => {
    e.preventDefault();

    const informacionGeneral = {
      asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
      nombre: this.state.nombreAsociacionEdit,
      tipo: this.state.tipoDeAsociacion,

      identificador: this.state.identificadorDireccion,
      calle: this.state.calleDireccionName,
      numero: this.state.numeroDireccionName,
      colonia: this.state.coloniaDireccion,
      codigoPostal: this.state.codigoPostalDireccion,
      ciudad: this.state.cityDireccion,
    };

    this.props.updateInfoAsociacion(informacionGeneral);
  };

  componentDidMount() {
    if (this.props.asociacion && this.props.asociacion.membresiaActiva) {
      this.setState({
        timeAgo: timeAgo.format(
          new Date(this.props.asociacion.membresiaActiva.asociacion.updated)
        ),
      });
      /*       setInterval(() => {
        this.setState({
          timeAgo: timeAgo.format(
            new Date(this.props.asociacion.membresiaActiva.asociacion.updated)
          ),
        });
      }, 60000); */
    }
    this.props.loadTipoDeAsociacion();

    this.props.loadCountries();

    this.props.loadStates(
      this.props.asociacion.membresiaActiva.asociacion.direccion.ciudad.estado
        .pais._id
    );
    this.props.loadCities(
      this.props.asociacion.membresiaActiva.asociacion.direccion.ciudad.estado
        ._id
    );

    this.setState({
      countryDireccion: this.props.asociacion.membresiaActiva.asociacion
        .direccion.ciudad.estado.pais._id,
    });
    this.setState({
      stateDireccion: this.props.asociacion.membresiaActiva.asociacion.direccion
        .ciudad.estado._id,
    });
    this.setState({
      cityDireccion: this.props.asociacion.membresiaActiva.asociacion.direccion
        .ciudad._id,
    });
    this.setState({
      nombreAsociacionEdit: this.props.asociacion.membresiaActiva.asociacion
        .nombre,
      identificadorDireccion: this.props.asociacion.membresiaActiva.asociacion
        .direccion.identificador,
      calleDireccionName: this.props.asociacion.membresiaActiva.asociacion
        .direccion.calle,
      numeroDireccionName: this.props.asociacion.membresiaActiva.asociacion
        .direccion.numero,
      coloniaDireccion: this.props.asociacion.membresiaActiva.asociacion
        .direccion.colonia,
      codigoPostalDireccion: this.props.asociacion.membresiaActiva.asociacion
        .direccion.codigoPostal,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.asociacion &&
      this.props.asociacion.membresiaActiva &&
      this.props.catalog !== prevProps.catalog
    ) {
      this.setState({
        tipoDeAsociacion: this.props.asociacion.membresiaActiva.asociacion.tipo
          ._id,
      });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.updated !== prevProps.asociacion.updated &&
      this.props.asociacion.updated === true
    ) {
      NotificationManager.success(
        "",
        "Información actualizada correctamente",
        6000
      );
    }
  }

  render() {
    const { membresiaActiva } = this.props.asociacion;
    const { countries, states, cities, tipoDeAsociacion } = this.props.catalog;

    if (!membresiaActiva || !cities) {
      return <div>Loading...</div>;
    }

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Información general</h5>
                  <hr></hr>
                </div>
                <form
                  name="actualizacionForm"
                  id="actualizacionForm"
                  onSubmit={this.onSubmitActualizarInfo}
                  style={{ width: "100%" }}
                >
                  <div className="col-12">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["nombre"],
                            })}
                            maxLength="30"
                            size="30"
                            id="nombreAsociacionID"
                            name="nombreAsociacionEdit"
                            value={this.state.nombreAsociacionEdit}
                            onChange={this.onChange}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="nombreAsociacionID">Nombre</label>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <select
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["tipo"],
                            })}
                            id="tipoDeAsociacion"
                            onChange={this.onChange}
                            name="tipoDeAsociacion"
                            value={this.state.tipoDeAsociacion}
                            style={{ height: "45px" }}
                          >
                            <option value="0" key="999">
                              Seleccione un tipo de asociacion
                            </option>

                            {tipoDeAsociacion &&
                              tipoDeAsociacion.map((tipo, index) => {
                                return (
                                  <option value={tipo._id} key={index}>
                                    {tipo.nombre}
                                  </option>
                                );
                              })}
                          </select>
                          {this.props.errors["tipo"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["tipo"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <h5>Dirección</h5>
                    <hr></hr>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["identificador"],
                            })}
                            maxLength="30"
                            size="30"
                            id="identificadorDireccionID"
                            name="identificadorDireccion"
                            value={this.state.identificadorDireccion}
                            onChange={this.onChange}
                            disabled
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="identificadorDireccionID">
                            Identificador
                          </label>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["calle"],
                            })}
                            maxLength="30"
                            size="30"
                            id="calleDireccionID"
                            name="calleDireccionName"
                            value={this.state.calleDireccionName}
                            onChange={this.onChange}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="calleDireccionID">Calle</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["numero"],
                            })}
                            maxLength="30"
                            size="30"
                            id="numeroDireccionID"
                            name="numeroDireccionName"
                            value={this.state.numeroDireccionName}
                            onChange={this.onChange}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="numeroDireccionID">Número</label>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["colonia"],
                            })}
                            maxLength="30"
                            size="30"
                            id="coloniaDireccionID"
                            name="coloniaDireccion"
                            value={this.state.coloniaDireccion}
                            onChange={this.onChange}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="coloniaDireccionID">Colonia</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="group inputPlaceholder form-group">
                          <input
                            type="text"
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["codigoPostal"],
                            })}
                            maxLength="30"
                            size="30"
                            id="codigoPostalID"
                            name="codigoPostalDireccion"
                            value={this.state.codigoPostalDireccion}
                            onChange={this.onChange}
                            required
                          />
                          <span className="highlight"></span>
                          <span className="bar"></span>
                          <label htmlFor="codigoPostalID">Código postal</label>
                        </div>
                      </div>

                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <select
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["pais"],
                            })}
                            id="countryDireccion"
                            onChange={this.onChange}
                            name="countryDireccion"
                            value={this.state.countryDireccion}
                            style={{ height: "45px" }}
                          >
                            <option value="0" key="999">
                              Seleccione un país
                            </option>

                            {countries &&
                              countries.map((pais, index) => {
                                return (
                                  <option value={pais._id} key={index}>
                                    {pais.nombre}
                                  </option>
                                );
                              })}
                          </select>
                          {this.props.errors["pais"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["pais"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <select
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["estado"],
                            })}
                            id="stateDireccion"
                            onChange={this.onChange}
                            name="stateDireccion"
                            value={this.state.stateDireccion}
                            style={{ height: "45px" }}
                          >
                            <option value="0" key="999">
                              Seleccione un estado
                            </option>

                            {states &&
                              states.map((estado, index) => {
                                return (
                                  <option value={estado._id} key={index}>
                                    {estado.nombre}
                                  </option>
                                );
                              })}
                          </select>
                          {this.props.errors["estado"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["estado"]}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                          <select
                            className={classnames("form-control", {
                              "is-invalid": this.props.errors["ciudad"],
                            })}
                            id="cityDireccion"
                            onChange={this.onChange}
                            name="cityDireccion"
                            value={this.state.cityDireccion}
                            style={{ height: "45px" }}
                          >
                            <option value="0" key="999">
                              Seleccione una ciudad
                            </option>

                            {cities &&
                              cities.map((ciudad, index) => {
                                return (
                                  <option value={ciudad._id} key={index}>
                                    {ciudad.nombre}
                                  </option>
                                );
                              })}
                          </select>
                          {this.props.errors["ciudad"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["ciudad"]}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr></hr>
                  <div className="col-12 d-flex justify-content-between">
                    <div className="hint-text">
                      Acutalizado {this.state.timeAgo}
                    </div>

                    <div>
                      <button
                        type="submit"
                        form="actualizacionForm"
                        className="btn btn-info mt-2"
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Informacion.propTypes = {
  asociacion: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loadTipoDeAsociacion: PropTypes.func.isRequired,
  updateInfoAsociacion: PropTypes.func.isRequired,
  loadCountries: PropTypes.func.isRequired,
  loadStates: PropTypes.func.isRequired,
  loadCities: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  errors: state.errors,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  loadTipoDeAsociacion,
  loadCountries,
  loadStates,
  loadCities,
  updateInfoAsociacion,
})(Informacion);
