import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class CondominoCuotas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  render() {
    const { membresiaActiva, servicios } = this.props.asociacion;

    if (!membresiaActiva) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Cuotas del fraccionamiento</h5>
                  <hr></hr>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {servicios && servicios.length === 0 ? (
                    <div className="row">
                      <div className="col-12">
                        No hay ninguna cuota registrada
                      </div>
                    </div>
                  ) : (
                    servicios.map((servicio) => {
                      return (
                        <div className="row" key={servicio._id}>
                          <div className="col-sm-6">
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                              Nombre
                            </label>
                            <h6 className="tx-15 mg-b-10">{servicio.nombre}</h6>
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                              Monto
                            </label>
                            <h6 className="tx-15 mg-b-10">
                              $ {servicio.costo} MXN
                            </h6>
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                              Aplica a
                            </label>
                            <h6 className="tx-15 mg-b-10">
                              {servicio.tipo.nombre}
                            </h6>
                            <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                              Estatus
                            </label>
                            <h6 className="tx-15 mg-b-10">
                              {servicio.estatus.nombre}
                            </h6>
                          </div>
                          {servicio.suscripciones &&
                          servicio.suscripciones.suscriptores ? (
                            <div className="col-sm-6 tx-right d-none d-md-block">
                              <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                                Número de suscriptores
                              </label>
                              <h6 className="tx-15 mg-b-10">
                                {
                                  servicio.suscripciones.suscriptores.filter(
                                    (miembro) =>
                                      miembro.tipo.nombre ===
                                      servicio.tipo.nombre
                                  ).length
                                }{" "}
                                {servicio.tipo.nombre}s{" "}
                              </h6>
                              <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                                Penalización por pago tadío
                              </label>
                              <h6 className="tx-15 mg-b-10">
                                {servicio.suscripciones.cargo} MXN
                              </h6>
                              <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                                Días libres de penalización
                              </label>
                              <h6 className="tx-15 mg-b-10">
                                {servicio.suscripciones.maxDiasCargo}
                              </h6>
                              <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                                Periodicidad
                              </label>
                              <h6 className="tx-15 mg-b-10">
                                {servicio.suscripciones.frecuencia.nombre}
                              </h6>
                              <label className="tx-sans tx-uppercase tx-10 tx-medium tx-spacing-1 tx-color-03">
                                Fecha de inicio
                              </label>
                              <h6 className="tx-15 mg-b-10">
                                {servicio.suscripciones.periodicidad.inicio &&
                                servicio.suscripciones.periodicidad.inicio !==
                                  null
                                  ? servicio.suscripciones.periodicidad.inicio
                                  : "Evento único"}{" "}
                              </h6>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="col-12 d-flex justify-content-between">
                            <div>
                              <h6 className="tx-15 mg-b-10">
                                Creada{" "}
                                {timeAgo.format(new Date(servicio.created))}
                              </h6>
                              <h6 className="tx-15 mg-b-10">
                                Actualizada{" "}
                                {timeAgo.format(new Date(servicio.updated))}
                              </h6>
                            </div>
                          </div>
                          {servicios.length > 1 ? (
                            <div className="col-12">
                              <hr></hr>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CondominoCuotas.propTypes = {
  asociacion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(CondominoCuotas);
