import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import PrivateRoute from "./components/common/PrivateRoute";

import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";

import Register from "./components/auth/Register";
import Login from "./components/auth/Login";

import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import Confirmation from "./components/profile/Confirmation";
import Recovery from "./components/auth/Recovery";
import Fraccionamiento from "./components/fraccionamiento/Fraccionamiento";
import Pagos from "./components/pagos/Pagos";
import Accesos from "./components/accesos/Accesos";
import Areas from "./components/areas/Areas";
import Ingresos from "./components/accesos/Ingresos";
import Comunicados from "./components/comunicados/Comunicados";
import Aclaraciones from "./components/aclaraciones/Aclaraciones";
import Actividades from "./components/actividades/Actividades";
import Encuestas from "./components/encuestas/Encuestas";
import Productos from "./components/productos/Productos";
import Servicios from "./components/servicios/Servicios";
import Notificaciones from "./components/notificaciones/notificaciones";
import Aviso from "./components/layout/Aviso";
import Kanban from "./components/kanban/Kanban";

import "./App.css";
import "react-notifications/lib/notifications.css";

import { NotificationContainer } from "react-notifications";

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <NotificationContainer />
            <Navbar />
            <Route exact path="/" component={Landing} />

            <Route exact path="/invitation/:token" component={Landing} />
            <Route exact path="/privacidad" component={Aviso} />
            <div className="container-fluid">
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/confirmation/:token"
                component={Confirmation}
              />
              <Route exact path="/recovery/:token" component={Recovery} />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/profile" component={Profile} />

                <PrivateRoute
                  exact
                  path="/fraccionamiento"
                  component={Fraccionamiento}
                />
                <PrivateRoute exact path="/contabilidad" component={Pagos} />
                <PrivateRoute exact path="/kanban" component={Kanban} />
                <PrivateRoute exact path="/accesos" component={Accesos} />
                <PrivateRoute exact path="/ingresos" component={Ingresos} />

                <PrivateRoute
                  exact
                  path="/comunicados"
                  component={Comunicados}
                />
                <PrivateRoute exact path="/areas" component={Areas} />
                <PrivateRoute exact path="/servicios" component={Servicios} />
                <PrivateRoute exact path="/productos" component={Productos} />
                <PrivateRoute
                  exact
                  path="/notificaciones"
                  component={Notificaciones}
                />
                <PrivateRoute
                  exact
                  path="/actividades"
                  component={Actividades}
                />
                <PrivateRoute exact path="/encuestas" component={Encuestas} />
                <PrivateRoute
                  exact
                  path="/aclaraciones"
                  component={Aclaraciones}
                />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
