import { GET_SAS, GET_DATA_FILES } from "../actions/types";

const initialState = {
  files: [],
  sasURL: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SAS:
      return {
        ...state,
        sasURL: action.payload,
      };
    case GET_DATA_FILES:
      return { ...state, files: action.payload };

    default:
      return state;
  }
}
