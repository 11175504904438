import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadProfile } from "../../actions/profileActions";
import { loadMembresias } from "../../actions/asociacionActions";
import { getCurrentPage } from "../../actions/pageActions";
import Sidebar from "../layout/Sidebar";
import "../../css/profile.css";

class Aclaraciones extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      asociacionMembresia: "",
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.getCurrentPage({ page: window.location.pathname });
    this.props.loadProfile();
    this.props.loadMembresias();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const { percentage } = this.props.profile;

    let content;
    let modalContent;

    modalContent = <div>Algo</div>;

    if (!this.props.asociacion || !this.props.asociacion.membresias) {
      content = (
        <div>
          <div className="timeline-item">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
          <div className="timeline-item mt-3">
            <div className="animated-background">
              <div className="background-masker header-top"></div>
              <div className="background-masker header-left"></div>
              <div className="background-masker header-right"></div>
              <div className="background-masker header-bottom"></div>
              <div className="background-masker subheader-left"></div>
              <div className="background-masker subheader-right"></div>
              <div className="background-masker subheader-bottom"></div>
              <div className="background-masker content-top"></div>
              <div className="background-masker content-first-end"></div>
              <div className="background-masker content-second-line"></div>
              <div className="background-masker content-second-end"></div>
              <div className="background-masker content-third-line"></div>
              <div className="background-masker content-third-end"></div>
            </div>
          </div>
        </div>
      );
    } else {
      if (
        !this.props.asociacion.membresias ||
        this.props.asociacion.membresias.length !== 0
      ) {
        content = (
          <div className="row">
            <div className="col-12">Contenido</div>
          </div>
        );
      } else {
        content = (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-xl-9 col-md-9">
                          <div className="section_title text-center">
                            <h3>
                              Aún no eres miembro de ninguna asociación o
                              fraccionamiento
                            </h3>
                            <p>
                              Con Wirhub puedes administrar tu fraccionamiento o
                              formar parte de él para conocer las útimas
                              noticias, estados de cuenta y realizar pagos.
                            </p>

                            <br />

                            <div className="text-center">
                              <Link
                                className="btn btn-info"
                                to="/fraccionamiento"
                              >
                                Empezar
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Aclaraciones</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Aclaraciones</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {content}
        </main>
      </div>
    );
  }
}

Aclaraciones.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  loadProfile: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  loadMembresias: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  errors: state.errors,
  asociacion: state.asociacion,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  loadProfile,
  loadMembresias,
})(Aclaraciones);
