import axios from "axios";

import {
  GET_CUENTA,
  GET_ERRORS,
  UPDATE_PROGRESS,
  GET_PAGOS,
  GET_CARGOS_CASA,
  GET_ULTIMO_CARGO,
  GET_GASTOS,
  NOTIFICAR_PAGO,
  NOTIFICAR_GASTO,
  NOTIFICAR_EDICION,
} from "./types";

// Load cuenta
export const loadCuenta = (data) => (dispatch) => {
  axios
    .post("/api/cuentas/getCuenta", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_CUENTA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load pagos
export const loadPagos = (data) => (dispatch) => {
  axios
    .post("/api/cuentas/getPagos", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_PAGOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load cargos pendientes
export const loadCargosPendientes = (data) => (dispatch) => {
  axios
    .post("/api/cuentas/getCargosCasa", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_CARGOS_CASA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Registra pago
export const registrarPago = (data) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  dispatch({
    type: NOTIFICAR_PAGO,
    payload: false,
  });

  axios
    .post("/api/cuentas/registrarPago", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_PAGOS,
        payload: res.data,
      });
      dispatch({
        type: NOTIFICAR_PAGO,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Registra pago
export const resetCargosCasa = () => (dispatch) => {
  dispatch({
    type: GET_CARGOS_CASA,
    payload: [],
  });
};

// Registra pago
export const resetErrors = () => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};

// Load cuenta
export const getUltimoCargo = (data) => (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/cuentas/getCargosFuturos", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.length > 0)
        dispatch({
          type: GET_ULTIMO_CARGO,
          payload: res.data[0],
        });
      else
        dispatch({
          type: GET_ULTIMO_CARGO,
          payload: {},
        });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load pagos
export const loadGastos = (data) => (dispatch) => {
  axios
    .post("/api/cuentas/getGastos", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_GASTOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load pagos
export const registrarGasto = (data) => (dispatch) => {
  dispatch({
    type: NOTIFICAR_GASTO,
    payload: false,
  });

  axios
    .post("/api/cuentas/registrarGasto", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_GASTOS,
        payload: res.data,
      });
      dispatch({
        type: NOTIFICAR_GASTO,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Modificar un gasto
export const modificarGasto = (data) => (dispatch) => {
  dispatch({
    type: NOTIFICAR_EDICION,
    payload: false,
  });

  axios
    .post("/api/cuentas/modificarGasto", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_GASTOS,
        payload: res.data,
      });
      dispatch({
        type: NOTIFICAR_EDICION,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load pagos
export const loadPagosRealizados = (data) => (dispatch) => {
  axios
    .post("/api/cuentas/getPagosRealizados", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_PAGOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
