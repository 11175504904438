import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { loadGastos } from "../../actions/cuentaActions";
import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
import "../../css/tabla.css";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

const startDate = new Date();
startDate.setDate(startDate.getDate() - 15);

class Gastos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
      rangoStart: startDate,
      rangoEnd: new Date(),
      rangoActivo: false,
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.onClickGasto = this.onClickGasto.bind(this);
    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (a, b) => {
    this.setState({
      rangoStart: b.startDate.zone("-06:00").format(),
      rangoEnd: b.endDate.zone("-06:00").format(),
    });

    if (a.type === "apply") {
      this.props.loadGastos({
        cuenta: this.props.cuentas.cuenta._id,
        page: 1,
        rangoStart: b.startDate.zone("-06:00").format(),
        rangoEnd: b.endDate.zone("-06:00").format(),
      });

      this.setState({ pagina: 1 });
    }
  };

  adelantarPagina = (e) => {
    if (
      Math.ceil(this.props.cuentas.gastos[0]._id.total.count / 10) >
      this.state.pagina
    ) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina + 1,
        }),
        () => {
          this.props.loadGastos({
            cuenta: this.props.cuentas.cuenta._id,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
            page: this.state.pagina,
          });
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ pagina: e });
    this.props.loadGastos({
      cuenta: this.props.cuentas.cuenta._id,
      rangoStart: this.state.rangoStart,
      rangoEnd: this.state.rangoEnd,
      page: e,
    });
  };

  atrasarPagina = (e) => {
    if (this.state.pagina !== 1) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina - 1,
        }),
        () => {
          this.props.loadGastos({
            cuenta: this.props.cuentas.cuenta._id,
            rangoStart: this.state.rangoStart,
            rangoEnd: this.state.rangoEnd,
            page: this.state.pagina,
          });
        }
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickGasto = (edicion, gasto) => {
    window.abrirModal("detalleGasto");
    this.props.onClickGasto(edicion, gasto);
  };

  render() {
    const { gastos } = this.props.cuentas;
    const { pagina } = this.state;

    return (
      <div className="col-12 mt-3">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between">
                  <div className="hint-text">
                    <h5>Últimos gastos</h5>
                  </div>

                  <div style={{ display: "inline-flex" }}>
                    <DateRangePicker
                      onEvent={this.handleChange}
                      initialSettings={{
                        endDate: new Date(),
                        locale: {
                          format: "YYYY/MM/DD hh:mm A",
                          cancelLabel: "Cancelar",
                          applyLabel: "Buscar",
                          fromLabel: "de",
                          toLabel: "a",
                          daysOfWeek: [
                            "Dom",
                            "Lun",
                            "Mar",
                            "Mié",
                            "Jue",
                            "Vie",
                            "Sáb",
                          ],
                          monthNames: [
                            "Enero",
                            "Febrero",
                            "Marzo",
                            "Abril",
                            "Mayo",
                            "Junio",
                            "Julio",
                            "Agosto",
                            "Septiembre",
                            "Octubre",
                            "Noviembre",
                            "Diciembre",
                          ],
                        },
                        startDate: startDate,
                        timePicker: true,
                      }}
                    >
                      <input
                        className="form-control search-date"
                        type="text"
                        name="rangoDeFechas"
                      />
                    </DateRangePicker>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ minWidth: "150px" }}
                      onClick={() => {
                        this.props.toggleAgregar();
                      }}
                    >
                      {this.props.agregar ? "Cancelar" : "Registrar gasto"}
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <hr></hr>
                </div>
                {!gastos && (
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                )}
              </div>

              {!gastos ||
              gastos.length === 0 ||
              gastos[0].registros.length === 0 ? (
                <div className="row">
                  <div className="col-12">
                    Aún no hay ningún gasto registrado
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="list-group">
                      {gastos[0].registros.map((gasto) => {
                        return (
                          <div key={gasto._id} className="col-12 list-div">
                            <div className="dropdown">
                              <span
                                className="menu-ellipsis"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                &#8942;
                              </span>
                              <div className="dropdown-menu dropdown-acceso">
                                <button
                                  onClick={() => {
                                    this.onClickGasto(false, gasto._id);
                                  }}
                                >
                                  Detalle
                                </button>
                                <div className="dropdown-divider"></div>
                                <button
                                  onClick={() => {
                                    this.onClickGasto(true, gasto._id);
                                  }}
                                >
                                  Editar
                                </button>
                              </div>
                            </div>

                            <div className="titulo">
                              {gasto.concepto} &nbsp; &nbsp;
                            </div>
                            <div className="contenido">
                              <span
                                className={classnames("", {
                                  "dot-warning":
                                    gasto.estatus.nombre === "Pendiente",
                                  "dot-ok": gasto.estatus.nombre === "Aplicado",
                                })}
                              ></span>
                              Identificador: {gasto.identificador} &nbsp; &nbsp;
                              Creado {timeAgo.format(new Date(gasto.created))}{" "}
                              &nbsp; &nbsp; Monto: $ {"  "}
                              {gasto.monto}
                              {"  "} MXN
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-12">
                    {gastos &&
                      gastos.length > 0 &&
                      gastos[0]._id &&
                      gastos[0]._id.total.count > 0 && (
                        <ul className="pagination">
                          <li className="page-item">
                            <button
                              type="button"
                              className="page-link"
                              disabled={pagina === 1 ? true : false}
                              onClick={() => {
                                this.atrasarPagina();
                              }}
                            >
                              Anterior
                            </button>
                          </li>
                          {Array.from(
                            {
                              length: Math.min(
                                10,
                                Math.ceil(gastos[0]._id.total.count / 10)
                              ),
                            },
                            (item, i) => {
                              return (
                                <li
                                  key={i}
                                  className={
                                    pagina === i + 1
                                      ? "page-item active"
                                      : "page-item"
                                  }
                                >
                                  <button
                                    className="page-link"
                                    onClick={() => this.actualizaPagina(i + 1)}
                                  >
                                    {i + 1}
                                  </button>
                                </li>
                              );
                            }
                          )}

                          <li className="page-item">
                            <button
                              onClick={() => {
                                this.adelantarPagina();
                              }}
                              className={
                                Math.ceil(gastos[0]._id.total.count / 10) <=
                                pagina
                                  ? "page-link disabled"
                                  : "page-link"
                              }
                            >
                              Siguiente
                            </button>
                          </li>
                        </ul>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Gastos.propTypes = {
  asociacion: PropTypes.object.isRequired,
  cuentas: PropTypes.object.isRequired,
  loadGastos: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  cuentas: state.cuentas,
});

export default connect(mapStateToProps, { loadGastos })(Gastos);
