import axios from "axios";
import store from "../store";

import {
  ADD_PROFILE,
  GET_ERRORS,
  ADD_EDUCATION,
  ADD_EXPERIENCE,
  UPDATE_PROGRESS,
  LOAD_EXPERIENCE,
  LOAD_EDUCATION,
  LOAD_VERIFICATION,
  CELLPHONE_VALIDATED,
  GET_USERSPREFERENCE,
  UPDATED_PROFILE,
} from "./types";

// clear current profile
export const clearCurrentProfile = () => (dispatch) => {
  dispatch({
    type: ADD_PROFILE,
    payload: {},
  });
};
// Register User
export const addProfile = (userData, verificar) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({ type: UPDATED_PROFILE, payload: false });

  axios
    .post("/api/profile", userData, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_PROFILE,
        payload: res.data,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });

      dispatch({ type: UPDATED_PROFILE, payload: true });

      if (verificar && res.data.cellPhone && res.data.countryCode) {
        axios
          .post("/api/profile/validate/cellPhone", {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              if (totalLength !== null) {
                dispatch({
                  type: UPDATE_PROGRESS,
                  payload: Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  ),
                });
              }
            },
          })
          .then((res) => {
            dispatch({
              type: LOAD_VERIFICATION,
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch({
              type: GET_ERRORS,
              payload:
                err.response && err.response.data ? err.response.data : err,
            });
          });

        window.abrirVerificarModal();
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Load profile User
export const loadProfile = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .get("/api/profile", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.experience)
        dispatch({
          type: LOAD_EXPERIENCE,
          payload: res.data.experience,
        });

      if (res.data.education)
        dispatch({
          type: LOAD_EDUCATION,
          payload: res.data.education,
        });

      dispatch({
        type: ADD_PROFILE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Validate experience
export const validateExperience = (userData) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/profile/validate/experience/", userData, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_EXPERIENCE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Validate education
export const validateEducation = (userData) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/profile/validate/education/", userData, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_EDUCATION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

// Cellphone verification
export const cellphoneVerification = (codigo) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  if (codigo === store.getState().profile.verification.codigo) {
    axios
      .post("/api/profile/verification/cellphone", {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                "x-decompressed-content-length"
              );

          if (totalLength !== null) {
            dispatch({
              type: UPDATE_PROGRESS,
              payload: Math.round((progressEvent.loaded * 100) / totalLength),
            });
          }
        },
      })
      .then((res) => {
        dispatch({
          type: ADD_PROFILE,
          payload: res.data,
        });

        dispatch({
          type: GET_ERRORS,
          payload: {},
        });

        dispatch({
          type: CELLPHONE_VALIDATED,
          payload: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response && err.response.data ? err.response.data : err,
        });
      });
  } else {
    dispatch({
      type: GET_ERRORS,
      payload: { codigo: "Código incorrecto" },
    });
  }
};

export const updateUsersPreference = (data) => (dispatch) => {
  axios
    .post("/api/users/updateUserPreference", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({ type: GET_USERSPREFERENCE, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getUsersPreference = (data) => (dispatch) => {
  axios
    .post("/api/users/getUsersPreference", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({ type: GET_USERSPREFERENCE, payload: res.data });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
