import React, { useState } from "react";
import Webcam from "react-webcam";

const Camara = ({ setImageSrc, imageSrc }) => {
  const webcamRef = React.useRef(null);
  const [width, setWidth] = useState(0);
  const [deviceId, setDeviceId] = React.useState("0");
  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
      if (
        mediaDevices.filter(({ kind }) => kind === "videoinput").lenght === 1
      ) {
        setDeviceId(
          mediaDevices.filter(({ kind }) => kind === "videoinput")[0].deviceId
        );
      }
    },
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const capture = React.useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const div = React.useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const handleInputChange = (event) => {
    setDeviceId(event.target.value);
  };

  return (
    <div className="col-12 text-center" ref={div}>
      {imageSrc ? (
        <img
          src={imageSrc}
          alt="Captura"
          style={{
            width: `${width - 24}px`,
            height: `${width * 0.55}px`,
          }}
        />
      ) : (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={width - 24}
            videoConstraints={{ deviceId: deviceId, width: 1280, height: 720 }}
            height={480}
          />

          <select
            className="custom-select my-1 mr-sm-2 mr-3"
            id="deviceId"
            name="deviceId"
            onChange={handleInputChange}
            value={deviceId}
          >
            <option value="0">Selecciona una camara</option>

            {devices &&
              devices.map((device, key) => {
                return (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label}
                  </option>
                );
              })}
          </select>

          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={capture}
          >
            Capturar
          </button>
        </>
      )}
    </div>
  );
};

export default Camara;
