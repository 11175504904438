import axios from "axios";

import {
  LOAD_MEMBRESIAS,
  UPDATE_PROGRESS,
  GET_ERRORS,
  LOAD_ASOCIACIONES,
  EMTPTY_ASOCIACIONES,
  LOAD_TIPOASOCIACIONES,
  LOAD_SUBASOCIACIONES,
  LOAD_SUBASOCIACIONES2STAGE,
  ADD_SOLICITUD,
  CANCELAR_SOLICITUD,
  LOAD_MEMBRESIAACTIVA,
  LOAD_SOLICITUDESP,
  LOAD_SERVICIOS,
  UPDATED_INFO,
  SENT_INVITATION,
  ADD_SERVICIO,
  RESET_ADDED,
  PUBLICAR_COMUNICADO,
  GET_COMUNICADOS,
  GET_ACCESOS,
  GET_ULTIMOS_ACCESOS_DESTINO,
  GET_AREAS_COMUNES,
  GET_EVENTOS_AREA,
  LOAD_MEMBRESIAS_INACTIVAS,
  GET_ADMINISTRADORES,
  GET_TAREAS,
  GET_ESTADO_CUENTA,
} from "./types";

export const loadMembresias = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .get("/api/asociaciones/membresias/usuario", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_MEMBRESIAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadMembresiasAdministradores = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/administradores", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ADMINISTRADORES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadMembresiasInactivas = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .get("/api/asociaciones/membresias/inactivas", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_MEMBRESIAS_INACTIVAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadAsociacionesCiudad = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/busquedaCiudad/", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_ASOCIACIONES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const emptyAsociaciones = () => (dispatch) => {
  dispatch({
    type: EMTPTY_ASOCIACIONES,
  });
};

// Register User
export const addAsociacion = (userData) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones", userData, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_MEMBRESIAS_INACTIVAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadTipoDeAsociaciones = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .get("/api/catalogos/tipoDeAsociacion", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_TIPOASOCIACIONES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const busquedaFraccionamiento = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/busqueda", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_ASOCIACIONES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const agregarMembresia = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/membresias", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_MEMBRESIAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadSubsAsociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/subAsociaciones", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (data.stage === 1) {
        dispatch({
          type: LOAD_SUBASOCIACIONES,
          payload: res.data,
        });
      } else {
        dispatch({
          type: LOAD_SUBASOCIACIONES2STAGE,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const agregarSolicitud = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/agregarSolicitud", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_SOLICITUD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadSolicitudes = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/loadSolicitudes", {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_SOLICITUD,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response && err.response.data ? err.response.data : err,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: { error: "loadSolicitudes" },
        });
      }
    });
};

export const cambiarStatusMembresia = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/actualizaEstatusMembresia", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: CANCELAR_SOLICITUD,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadMembresia = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/membresias/membresia", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_MEMBRESIAACTIVA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const agregarSubasociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/sub", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      axios
        .post("/api/asociaciones/membresias/membresia", data, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );

            if (totalLength !== null) {
              dispatch({
                type: UPDATE_PROGRESS,
                payload: Math.round((progressEvent.loaded * 100) / totalLength),
              });
            }
          },
        })
        .then((res) => {
          dispatch({
            type: LOAD_MEMBRESIAACTIVA,
            payload: res.data,
          });
          dispatch({
            type: GET_ERRORS,
            payload: {},
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload:
              err.response && err.response.data ? err.response.data : err,
          });
        });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const eliminarSubasociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/deleteSubs", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.success) {
        axios
          .post("/api/asociaciones/membresias/membresia", data, {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              if (totalLength !== null) {
                dispatch({
                  type: UPDATE_PROGRESS,
                  payload: Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  ),
                });
              }
            },
          })
          .then((res) => {
            dispatch({
              type: LOAD_MEMBRESIAACTIVA,
              payload: res.data,
            });
            dispatch({
              type: GET_ERRORS,
              payload: {},
            });
          })
          .catch((err) => {
            dispatch({
              type: GET_ERRORS,
              payload: {
                eliminarSubasociacion: "No se pudo eliminar la asociacion",
              },
            });
          });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {
            eliminarSubasociacion: "No se pudo eliminar la asociacion",
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          eliminarSubasociacion: "No se pudo eliminar la asociacion",
        },
      });
    });
};

export const loadSolicitudesPendientes = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/solicitudes", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_SOLICITUDESP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadServiciosAsociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/serviciosAsociacion", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: LOAD_SERVICIOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const updateInfoAsociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: UPDATED_INFO,
    payload: false,
  });

  axios
    .post("/api/asociaciones/updateInfo", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.success === true)
        dispatch({
          type: UPDATED_INFO,
          payload: true,
        });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const sendInvitation = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({ type: SENT_INVITATION, payload: { success: false } });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/sendInvitation", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: SENT_INVITATION, payload: res.data });
      }
    })
    .catch((err) => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response && err.response.data ? err.response.data : err,
        });
      else console.log(err);
    });
};

export const sendInvitationAdmon = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({ type: SENT_INVITATION, payload: { success: false } });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/sendInvitationAdmon", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: SENT_INVITATION, payload: res.data });
      }
    })
    .catch((err) => {
      if (err.response)
        dispatch({
          type: GET_ERRORS,
          payload: err.response && err.response.data ? err.response.data : err,
        });
      else console.log(err);
    });
};

export const addServicio = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: RESET_ADDED,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/addServicio", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_SERVICIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const deleteServicio = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: RESET_ADDED,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/deleteServicio", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_SERVICIO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const actualizaNombreAsociacion = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/updateName", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.success) {
        axios
          .post("/api/asociaciones/membresias/membresia", data, {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              if (totalLength !== null) {
                dispatch({
                  type: UPDATE_PROGRESS,
                  payload: Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  ),
                });
              }
            },
          })
          .then((res) => {
            dispatch({
              type: LOAD_MEMBRESIAACTIVA,
              payload: res.data,
            });
            dispatch({
              type: GET_ERRORS,
              payload: {},
            });
          })
          .catch((err) => {
            dispatch({
              type: GET_ERRORS,
              payload: {
                eliminarSubasociacion: "No se pudo eliminar la asociacion",
              },
            });
          });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {
            eliminarSubasociacion: "No se pudo eliminar la asociacion",
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          eliminarSubasociacion: "No se pudo eliminar la asociacion",
        },
      });
    });
};

export const eliminaMembresia = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/eliminaMembresia", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      if (res.data.success) {
        axios
          .post("/api/asociaciones/membresias/membresia", data, {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              if (totalLength !== null) {
                dispatch({
                  type: UPDATE_PROGRESS,
                  payload: Math.round(
                    (progressEvent.loaded * 100) / totalLength
                  ),
                });
              }
            },
          })
          .then((res) => {
            dispatch({
              type: LOAD_MEMBRESIAACTIVA,
              payload: res.data,
            });
            dispatch({
              type: GET_ERRORS,
              payload: {},
            });
          })
          .catch((err) => {
            dispatch({
              type: GET_ERRORS,
              payload: {
                eliminarSubasociacion: "No se pudo eliminar la asociacion",
              },
            });
          });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {
            eliminarSubasociacion: "No se pudo eliminar la asociacion",
          },
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          eliminarSubasociacion: "No se pudo eliminar la asociacion",
        },
      });
    });
};

export const publicarComunicado = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/publicarComunicado", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: PUBLICAR_COMUNICADO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getComunicados = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: UPDATED_INFO,
    payload: false,
  });

  axios
    .post("/api/asociaciones/getComunicados", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_COMUNICADOS,
        payload: res.data,
      });

      dispatch({
        type: UPDATED_INFO,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const eliminaComunicado = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: UPDATED_INFO,
    payload: false,
  });

  axios
    .post("/api/asociaciones/eliminaComunicado", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_COMUNICADOS,
        payload: res.data,
      });

      dispatch({
        type: UPDATED_INFO,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadAccesos = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });
  dispatch({
    type: UPDATED_INFO,
    payload: false,
  });

  axios
    .post("/api/asociaciones/getAccesos", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ACCESOS,
        payload: res.data,
      });
      dispatch({
        type: UPDATED_INFO,
        payload: true,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getUtimosAccesosDestino = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/ultimosAccesos", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ULTIMOS_ACCESOS_DESTINO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const registrarVisita = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/acceso", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ACCESOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const resetUtimosAccesosDestino = () => (dispatch) => {
  dispatch({
    type: GET_ULTIMOS_ACCESOS_DESTINO,
    payload: [],
  });
};

export const finalizarVisita = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/finalizaVisita", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ACCESOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const loadAccesosDestino = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/getAccesosDestino", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ACCESOS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getAreasComunes = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/getAreasComunes", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_AREAS_COMUNES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const registrarAreaComun = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/registrarAreaComun", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_AREAS_COMUNES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const registrarEvento = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/registrarEvento", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_EVENTOS_AREA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getEventosArea = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/getEventosArea", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_EVENTOS_AREA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const eliminarEvento = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: GET_ERRORS,
    payload: {},
  });

  axios
    .post("/api/asociaciones/eliminarEvento", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_EVENTOS_AREA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getTareas = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/getTareas", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_TAREAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const agregaActualizaTarea = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/tarea", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_TAREAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const eliminaTarea = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/eliminarTarea", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_TAREAS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};

export const getEstadoDeCuenta = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/asociaciones/getEstadoDeCuenta", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ESTADO_CUENTA,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response && err.response.data ? err.response.data : err,
      });
    });
};
