import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addProfile,
  loadProfile,
  validateExperience,
  cellphoneVerification,
} from "../../actions/profileActions";
import {
  loadCountries,
  loadStates,
  loadCities,
} from "../../actions/catalogoActions";
import { getCurrentPage } from "../../actions/pageActions";
import { uploadFile, sendConfirmationEmail } from "../../actions/authActions";
import { loadMembresias } from "../../actions/asociacionActions";
import Spinner from "../common/Spinner";
import Sidebar from "../layout/Sidebar";

import "../../css/profile.css";
import Cropper from "react-cropper";
import TextFieldGroup from "../utils/TextFielGroup";
import SelectCiudad from "../utils/SelectCiudad";
import Ajustes from "../profile/Ajustes";
import Suscripciones from "../profile/Suscripciones";
import { NotificationManager } from "react-notifications";

/*
TODO
Agregar perfil al registro
Agregar spinner al cargar
Crear actualizaciòn generalizada
Agregar verificaciòn de correo
Agregar verificacion de celular
*/

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      errors: {},
      selectedFile: "",
      src: "",
      cropImg: null,
      filename: null,
      tabActual: 1,
      paisValue: "0",
      estadoValue: "0",
      ciudadValue: "0",
      countryCode: "52",
      codigo: "0",
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmitVerificar = this.onSubmitVerificar.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.onChangeCrop = this.onChangeCrop.bind(this);
    this.useDefaultImage = this.useDefaultImage.bind(this);

    this.clicTabla = this.clicTabla.bind(this);
    this.clickTab = this.clickTab.bind(this);
    this.onSubmitCrear = this.onSubmitCrear.bind(this);
    this.onClickVerificar = this.onClickVerificar.bind(this);
    this.onClickVerificarEmail = this.onClickVerificarEmail.bind(this);
  }

  onChange = (e) => {
    switch (e.target.name) {
      case "selectedFile":
        this.setState({ selectedFile: e.target.files[0] });
        break;
      case "pais":
        this.setState({ paisValue: e.target.value });
        this.props.loadStates(e.target.value);
        break;
      case "estado":
        this.setState({ estadoValue: e.target.value });
        this.props.loadCities(e.target.value);
        break;
      case "ciudad":
        this.setState({ ciudadValue: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  onClickVerificar(e) {
    const perfil = {};
    const direccion = {};

    perfil.handle = this.state["handle"];
    perfil.website = this.state["website"];
    perfil.cellPhone = this.state["cellPhone"];
    perfil.countryCode = this.state["countryCode"];
    perfil.bio = this.state["bio"];

    direccion.identificador = this.state["identificador"];
    direccion.calle = this.state["calle"];
    direccion.numero = this.state["numero"];
    direccion.colonia = this.state["colonia"];
    direccion.codigoPostal = this.state["codigoPostal"];
    direccion.ciudad = this.state["ciudadValue"];

    if (this.state["location"]) {
      const location = {};
      location.type = "Point";
      location.coordinates = this.state["location"].split(",");

      direccion.location = location;
    }

    perfil.direccion = direccion;

    perfil.youtube = this.state["youtube"];
    perfil.twitter = this.state["twitter"];
    perfil.facebook = this.state["facebook"];
    perfil.linkedin = this.state["linkedin"];
    perfil.instagram = this.state["instagram"];

    this.props.addProfile(perfil, true);
  }

  onClickVerificarEmail(e) {
    e.preventDefault();
    this.props.sendConfirmationEmail();
  }

  onSubmitVerificar(e) {
    e.preventDefault();

    this.props.cellphoneVerification(this.state["codigo"]);
  }

  clicTabla = (e) => {
    this.setState({ celdaActiva: e });
  };

  onSubmitCrear = (e) => {
    e.preventDefault();
    const perfil = {};
    const direccion = {};

    perfil.handle = this.state["handle"];
    perfil.website = this.state["website"];
    perfil.cellPhone = this.state["cellPhone"];
    perfil.countryCode = this.state["countryCode"];
    perfil.bio = this.state["bio"];

    direccion.identificador = this.state["identificador"];
    direccion.calle = this.state["calle"];
    direccion.numero = this.state["numero"];
    direccion.colonia = this.state["colonia"];
    direccion.codigoPostal = this.state["codigoPostal"];
    direccion.ciudad = this.state["ciudadValue"];

    if (this.state["location"]) {
      const location = {};
      location.type = "Point";
      location.coordinates = this.state["location"].split(",");

      direccion.location = location;
    }

    perfil.direccion = direccion;

    perfil.youtube = this.state["youtube"];
    perfil.twitter = this.state["twitter"];
    perfil.facebook = this.state["facebook"];
    perfil.linkedin = this.state["linkedin"];
    perfil.instagram = this.state["instagram"];

    this.props.addProfile(perfil, false);
  };

  clickTab = (e) => {
    this.setState({ tabActual: e });
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    this.props.loadMembresias();

    this.props.getCurrentPage({ page: window.location.pathname });
    this.useDefaultImage();
    this.props.loadProfile();
    this.props.loadCountries();

    this.setState({ email: this.props.auth.user.email });
    this.setState({
      handle:
        this.props.profile.profile.handle === undefined
          ? ""
          : String(this.props.profile.profile.handle),
    });
    this.setState({
      website:
        this.props.profile.profile.website === undefined
          ? ""
          : String(this.props.profile.profile.website),
    });
    this.setState({
      bio:
        this.props.profile.profile.bio === undefined
          ? ""
          : String(this.props.profile.profile.bio),
    });
    this.setState({
      countryCode:
        this.props.profile.profile.countryCode === undefined
          ? "52"
          : String(this.props.profile.profile.countryCode),
    });
    this.setState({
      cellPhone:
        this.props.profile.profile.cellPhone === undefined
          ? ""
          : String(this.props.profile.profile.cellPhone),
    });

    this.setState({
      cellVerified:
        this.props.profile.profile.cellVerified === undefined
          ? false
          : this.props.profile.profile.cellVerified,
    });

    if (this.props.profile.profile.social === undefined) {
      this.setState({
        youtube: "",
        twitter: "",
        facebook: "",
        linkedin: "",
        instagram: "",
      });
    } else {
      this.setState({
        youtube: this.props.profile.profile.social.youtube
          ? String(this.props.profile.profile.social.youtube)
          : "",
      });
      this.setState({
        twitter: this.props.profile.profile.social.twitter
          ? String(this.props.profile.profile.social.twitter)
          : "",
      });
      this.setState({
        facebook: this.props.profile.profile.social.facebook
          ? String(this.props.profile.profile.social.facebook)
          : "",
      });
      this.setState({
        linkedin: this.props.profile.profile.social.linkedin
          ? String(this.props.profile.profile.social.linkedin)
          : "",
      });
      this.setState({
        instagram: this.props.profile.profile.social.instagram
          ? String(this.props.profile.profile.social.instagram)
          : "",
      });
    }
  }

  onChangeCrop(e) {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);

    this.setState({ filename: files[0].name });
  }

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    let formData = new FormData();

    formData.append(
      "selectedFile",
      dataURLtoFile(
        this.cropper.getCroppedCanvas().toDataURL(),
        this.state.filename
      )
    );

    this.props.uploadFile(formData);
  }

  useDefaultImage() {
    this.setState({
      src: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/img/${this.props.auth.profilePicture}`,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }

    if (this.props.catalog.states !== prevProps.catalog.states) {
      this.props.loadCities(
        Object.keys(this.props.catalog.states).length > 0
          ? this.props.catalog.states[0]._id
          : "5cb77ec0087cc7113501accd"
      );
    }

    if (
      this.props.catalog.countries !== prevProps.catalog.countries &&
      Object.keys(this.props.catalog.countries).length > 0
    ) {
      this.props.loadStates(this.props.catalog.countries[0]._id);
    }

    if (
      this.props.profile.profile.cellPhone !==
      prevProps.profile.profile.cellPhone
    ) {
      this.setState({
        countryCode:
          this.props.profile.profile.countryCode === undefined
            ? "52"
            : String(this.props.profile.profile.countryCode),
      });
      this.setState({
        cellPhone:
          this.props.profile.profile.cellPhone === undefined
            ? ""
            : String(this.props.profile.profile.cellPhone),
      });
    }

    if (
      this.props.profile.profile.handle !== prevProps.profile.profile.handle
    ) {
      this.setState({
        handle:
          this.props.profile.profile.handle === undefined
            ? ""
            : String(this.props.profile.profile.handle),
      });
      this.setState({
        website:
          this.props.profile.profile.website === undefined
            ? ""
            : String(this.props.profile.profile.website),
      });
      this.setState({
        bio:
          this.props.profile.profile.bio === undefined
            ? ""
            : String(this.props.profile.profile.bio),
      });

      if (this.props.profile.profile.social === undefined) {
        this.setState({
          youtube: "",
          twitter: "",
          facebook: "",
          linkedin: "",
          instagram: "",
        });
      } else {
        this.setState({
          youtube: this.props.profile.profile.social.youtube
            ? String(this.props.profile.profile.social.youtube)
            : "",
        });
        this.setState({
          twitter: this.props.profile.profile.social.twitter
            ? String(this.props.profile.profile.social.twitter)
            : "",
        });
        this.setState({
          facebook: this.props.profile.profile.social.facebook
            ? String(this.props.profile.profile.social.facebook)
            : "",
        });
        this.setState({
          linkedin: this.props.profile.profile.social.linkedin
            ? String(this.props.profile.profile.social.linkedin)
            : "",
        });
        this.setState({
          instagram: this.props.profile.profile.social.instagram
            ? String(this.props.profile.profile.social.instagram)
            : "",
        });
      }
    }

    if (
      this.props.profile.profile.direccion !==
      prevProps.profile.profile.direccion
    ) {
      this.setState({
        identificador: String(
          this.props.profile.profile.direccion.identificador
        ),
      });
      this.setState({
        calle: String(this.props.profile.profile.direccion.calle),
      });
      this.setState({
        numero: String(this.props.profile.profile.direccion.numero),
      });
      this.setState({
        colonia: String(this.props.profile.profile.direccion.colonia),
      });
      this.setState({
        codigoPostal: String(this.props.profile.profile.direccion.codigoPostal),
      });
      this.setState({
        location: this.props.profile.profile.direccion.location.coordinates.toString(),
      });

      this.setState({
        paisValue: this.props.profile.profile.direccion.ciudad.estado.pais._id,
      });
      this.setState({
        estadoValue: this.props.profile.profile.direccion.ciudad.estado._id,
      });
      this.setState({
        ciudadValue: this.props.profile.profile.direccion.ciudad._id,
      });
    }

    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      prevProps.profile.updated !== this.props.profile.updated &&
      this.props.profile.updated === true
    ) {
      NotificationManager.success(
        "Perfil actualizado correctamente",
        "",
        10000
      );
    }

    if (
      prevProps.auth.isEmailSent !== this.props.auth.isEmailSent &&
      this.props.auth.isEmailSent === true
    ) {
      NotificationManager.success(
        "Correo de verificación enviado correctamente",
        "",
        10000
      );
    }
  }

  render() {
    const { user, isUploading } = this.props.auth;
    const { profile, percentage } = this.props.profile;
    const { countries, states, cities } = this.props.catalog;

    let profileContent;
    let modalContent;

    modalContent = (
      <div>
        <div
          className="modal fade"
          id="verifyCellphone"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="verifyCellphoneLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="verifyCellphoneLabel">
                  Verificar número celular
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Hemos enviado un mensaje con tu código de verificación al número{" "}
                {this.state.countryCode} {this.state.cellPhone}
                <br />
                <br />
                <div className="container">
                  <div className="row justify-content-md-center">
                    <div className="col-md-8">
                      <form onSubmit={this.onSubmitVerificar}>
                        <div className="form-group">
                          <label htmlFor="codigo">Código de verificación</label>
                          <input
                            type="text"
                            className={classnames(
                              "form-control form-control-line",
                              {
                                "is-invalid": this.props.errors["codigo"],
                              },
                              {
                                "is-valid": this.props.profile.validation,
                              }
                            )}
                            id="codigo"
                            placeholder="Código de 4 dígitos"
                            name="codigo"
                            onChange={this.onChange}
                          />

                          {this.props.errors["codigo"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["codigo"]}
                            </div>
                          )}

                          {this.props.profile.validation && (
                            <div className="valid-feedback">
                              Teléfono verificado correctamente
                            </div>
                          )}
                        </div>

                        <input
                          type="submit"
                          className="btn btn-success"
                          value="Verificar"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="pictureModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="pictureModaTitle"
          aria-hidden="false"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="pictureModalTitle">
                  Cambiar foto de perfil
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div style={{ width: "100%" }}>
                    <Cropper
                      style={{ height: 400, width: "100%" }}
                      aspectRatio={1 / 1}
                      guides={false}
                      src={this.state.src}
                      ref={(cropper) => {
                        this.cropper = cropper;
                      }}
                      minContainerWidth={400}
                      minContainerHeight={400}
                    />
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="es"
                      onChange={this.onChangeCrop}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileLang"
                    >
                      Seleccionar Archivo
                    </label>
                  </div>
                </div>
                {isUploading && (
                  <div className="col-12">Subiendo archivo...</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.cropImage}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (Object.keys(profile).length > 0) {
      profileContent = (
        <div className="row">
          <div className="col-lg-4 col-xlg-3 col-md-5">
            <div className="card">
              <div className="card-body">
                <center className="m-t-30">
                  {" "}
                  <img
                    src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/img/${this.props.auth.profilePicture}`}
                    className="img-circle"
                    style={{ width: "150px" }}
                    data-toggle="modal"
                    data-target="#pictureModal"
                    alt="Imagen de perfil"
                  />
                  <h4 className="card-title m-t-10">{user.name}</h4>
                  <h5 className="card-title m-t-10">
                    {profile.handle === undefined ? "" : profile.handle}
                  </h5>
                  <h6 className="card-subtitle">{user.email}</h6>
                  {/* <div className="row text-center justify-content-md-center">
                    <div className="col-4">
                      <a href="javascript:void(0)" className="link">
                        <i className="fas fa-user-friends"></i>{" "}
                        <font className="font-medium">254</font>
                      </a>
                    </div>
                    <div className="col-4">
                      <a href="javascript:void(0)" className="link">
                        <i className="fas fa-user-friends"></i>{" "}
                        <font className="font-medium">54</font>
                      </a>
                    </div>
                  </div> */}
                </center>
              </div>
              <div>
                <hr />{" "}
              </div>
              <div className="card-body">
                {" "}
                <small className="text-muted">Correo electrónico </small>
                <h6>
                  {user.email}{" "}
                  {profile.user.emailVerified ? (
                    <i className="far fa-check-circle"></i>
                  ) : (
                    ""
                  )}
                </h6>{" "}
                {profile.cellPhone && (
                  <div>
                    <small className="text-muted p-t-30 db">Celular</small>
                    <h6>
                      +{profile.countryCode} {profile.cellPhone}{" "}
                      {profile.cellVerified ? (
                        <i className="far fa-check-circle"></i>
                      ) : (
                        ""
                      )}
                    </h6>
                  </div>
                )}
                {profile.direccion && (
                  <div>
                    <small className="text-muted p-t-30 db">Dirección</small>
                    <h6>
                      {profile.direccion.calle} {profile.direccion.numero}{" "}
                    </h6>
                    <h6>
                      {profile.direccion.colonia}{" "}
                      {profile.direccion.codigoPostal}{" "}
                    </h6>
                    <h6>
                      {profile.direccion.ciudad.estado.nombre},{" "}
                      {profile.direccion.ciudad.estado.pais.nombre}{" "}
                    </h6>
                  </div>
                )}
                {profile.social && (
                  <div>
                    <small className="text-muted p-t-30 db">
                      Social Profile
                    </small>
                    <br />
                    {profile.social.facebook ? (
                      <button className="btn btn-circle btn-secondary espacio">
                        <i className="fab fa-facebook-f" />
                      </button>
                    ) : (
                      ""
                    )}
                    {profile.social.twitter ? (
                      <button className="btn btn-circle btn-secondary espacio">
                        <i className="fab fa-twitter" />
                      </button>
                    ) : (
                      ""
                    )}
                    {profile.social.youtube ? (
                      <button className="btn btn-circle btn-secondary espacio">
                        <i className="fab fa-youtube" />
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <br />
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xlg-9 col-md-7">
            <div className="card">
              <ul className="nav nav-tabs profile-tab" role="tablist">
                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link active show"
                    data-toggle="tab"
                    href="#perfil"
                    role="tab"
                    aria-selected="true"
                  >
                    Perfil
                  </a>{" "}
                </li>

                <li className="nav-item">
                  {" "}
                  <a
                    className="nav-link show"
                    data-toggle="tab"
                    href="#documents"
                    role="tab"
                    aria-selected="false"
                  >
                    Ajustes
                  </a>{" "}
                </li>

                {this.props.asociacion &&
                this.props.asociacion.membresiaActiva &&
                this.props.asociacion.membresiaActiva.rol &&
                this.props.asociacion.membresiaActiva.rol.length > 0 &&
                this.props.asociacion.membresiaActiva.rol[0].title ===
                  "Condómino" ? (
                  ""
                ) : (
                  <li className="nav-item">
                    {" "}
                    <a
                      className="nav-link show"
                      data-toggle="tab"
                      href="#suscriptions"
                      role="tab"
                      aria-selected="false"
                    >
                      Suscripciones
                    </a>{" "}
                  </li>
                )}
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane active show"
                  id="perfil"
                  role="tabpanel"
                >
                  <div className="card-body">
                    <form
                      className="form-horizontal form-material"
                      onSubmit={this.onSubmitCrear}
                    >
                      <TextFieldGroup
                        name="handle"
                        error={this.props.errors["handle"]}
                        placeholder="Nombre de usuario"
                        label="Nombre de usuario"
                        value={this.state["handle"] ? this.state["handle"] : ""}
                        onChange={this.onChange}
                        id="handle"
                      />

                      <div className="form-group">
                        <label
                          className="col-12"
                          style={{ paddingLeft: "0px" }}
                          htmlFor="email"
                        >
                          Correo electrónico
                        </label>
                        <div className="row">
                          <div className="col-12">
                            <input
                              id="email"
                              type="emil"
                              placeholder="usuario@website.com"
                              className={classnames(
                                "form-control form-control-line",
                                {
                                  "is-invalid": this.props.errors["email"],
                                }
                              )}
                              onChange={this.onChange}
                              name="email"
                              value={
                                this.state["email"] ? this.state["email"] : ""
                              }
                              style={{
                                width: profile.user.emailVerified
                                  ? "100%"
                                  : "80%",
                                display: "inline-block",
                              }}
                            />

                            {profile.user.emailVerified !== undefined &&
                            !profile.user.emailVerified ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.onClickVerificarEmail}
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                                disabled={
                                  this.state["email"] &&
                                  this.state["email"].length > 5
                                    ? false
                                    : true
                                }
                              >
                                Verificar
                              </button>
                            ) : (
                              ""
                            )}

                            {this.props.errors["email"] && (
                              <div className="invalid-feedback">
                                {this.props.errors["email"]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="bioTextArea">Bio</label>
                          <textarea
                            name="bio"
                            className="form-control"
                            id="bioTextArea"
                            placeholder="Escribe algo sobre ti"
                            value={this.state["bio"] ? this.state["bio"] : ""}
                            onChange={this.onChange}
                          />
                          {this.props.errors["bio"] && (
                            <div className="invalid-feedback">
                              {this.props.errors["bio"]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <label
                          className="col-12"
                          style={{ paddingLeft: "0px" }}
                        >
                          Celular
                        </label>
                        <div className="row telefono">
                          <div
                            className="col-sm-12 col-md-4"
                            style={{ paddingLeft: "0px" }}
                          >
                            <select
                              className="form-control form-control-line"
                              onChange={this.onChange}
                              name="countryCode"
                            >
                              <option value="52">+52 México</option>
                              <option value="1">+1 USA</option>
                            </select>
                          </div>
                          <div
                            className="col-sm-12 col-md-8"
                            style={{ paddingRight: "0px" }}
                          >
                            <input
                              type="text"
                              placeholder="10 dígitos"
                              className={classnames(
                                "form-control form-control-line",
                                {
                                  "is-invalid": this.props.errors["cellPhone"],
                                }
                              )}
                              onChange={this.onChange}
                              name="cellPhone"
                              value={
                                this.state["cellPhone"]
                                  ? this.state["cellPhone"]
                                  : ""
                              }
                              style={{
                                width: "70%",
                                display: "inline-block",
                              }}
                            />

                            {this.props.profile.profile.cellVerified !==
                              undefined &&
                            !this.props.profile.profile.cellVerified ? (
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.onClickVerificar}
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                                disabled={
                                  this.state["cellPhone"] &&
                                  this.state["cellPhone"].length === 10
                                    ? false
                                    : true
                                }
                              >
                                Verificar
                              </button>
                            ) : (
                              ""
                            )}

                            {this.props.errors["cellPhone"] && (
                              <div className="invalid-feedback">
                                {this.props.errors["cellPhone"]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-12">
                          <div className="mb-3">
                            <div className="accordion" id="accordionD">
                              <div
                                className="card"
                                style={{
                                  borderBottom: "1px solid rgba(0,0,0,.125)",
                                }}
                              >
                                <div className="card-header" id="headingD">
                                  <h2 className="mb-0">
                                    <button
                                      className="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#ColapseD"
                                      aria-expanded="true"
                                      aria-controls="ColapseD"
                                    >
                                      Dirección
                                    </button>
                                  </h2>
                                </div>

                                <div
                                  id="ColapseD"
                                  className="collapse show"
                                  aria-labelledby="headingD"
                                  data-parent="#accordionD"
                                >
                                  <div className="card-body">
                                    <div
                                      style={{
                                        marginTop: "-30px",
                                        paddingTop: "30px",
                                      }}
                                    >
                                      <div className="form-row">
                                        <div className="col-12">
                                          <TextFieldGroup
                                            name="identificador"
                                            error={
                                              this.props.errors["identificador"]
                                            }
                                            placeholder="Casa"
                                            value={
                                              this.state["identificador"]
                                                ? this.state["identificador"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Identificador"
                                            id="identificadorID"
                                          />
                                          <TextFieldGroup
                                            name="calle"
                                            error={this.props.errors["calle"]}
                                            placeholder="Nombre de la calle"
                                            value={
                                              this.state["calle"]
                                                ? this.state["calle"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Calle"
                                            id="calleID"
                                          />
                                          <TextFieldGroup
                                            name="numero"
                                            error={this.props.errors["numero"]}
                                            placeholder="Número de la casa"
                                            value={
                                              this.state["numero"]
                                                ? this.state["numero"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Número"
                                            id="numeroID"
                                          />
                                          <TextFieldGroup
                                            name="colonia"
                                            error={this.props.errors["colonia"]}
                                            placeholder="Nombre de la colonia"
                                            value={
                                              this.state["colonia"]
                                                ? this.state["colonia"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Colonia"
                                            id="coloniaID"
                                          />
                                          <TextFieldGroup
                                            name="codigoPostal"
                                            error={
                                              this.props.errors["codigoPostal"]
                                            }
                                            placeholder="Código postal de la casa"
                                            value={
                                              this.state["codigoPostal"]
                                                ? this.state["codigoPostal"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Código postal"
                                            id="cpID"
                                          />

                                          <TextFieldGroup
                                            name="location"
                                            error={
                                              this.props.errors["location"]
                                            }
                                            placeholder="19.115401,-98.252259"
                                            value={
                                              this.state["location"]
                                                ? this.state["location"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                            label="Coordenadas"
                                            id="coordenadasID"
                                          />

                                          <SelectCiudad
                                            paises={countries}
                                            estados={states}
                                            ciudades={cities}
                                            onChange={this.onChange}
                                            paisValue={this.state.paisValue}
                                            estadoValue={this.state.estadoValue}
                                            ciudadValue={this.state.ciudadValue}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-12">
                          <div className="mb-3">
                            <div className="accordion" id="accordionS">
                              <div
                                className="card"
                                style={{
                                  borderBottom: "1px solid rgba(0,0,0,.125)",
                                }}
                              >
                                <div className="card-header" id="headingS">
                                  <h2 className="mb-0">
                                    <button
                                      className="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#ColapseS"
                                      aria-expanded="true"
                                      aria-controls="ColapseS"
                                    >
                                      Redes sociales
                                    </button>
                                  </h2>
                                </div>

                                <div
                                  id="ColapseS"
                                  className="collapse show"
                                  aria-labelledby="headingS"
                                  data-parent="#accordionS"
                                >
                                  <div className="card-body">
                                    <div
                                      style={{
                                        marginTop: "-30px",
                                        paddingTop: "30px",
                                      }}
                                    >
                                      <div className="form-row">
                                        <div className="col-12">
                                          <TextFieldGroup
                                            name="facebook"
                                            error={
                                              this.props.errors["facebook"]
                                            }
                                            label="Facebook"
                                            id="facebookID"
                                            placeholder="www.facebook.com/userID"
                                            value={
                                              this.state["facebook"]
                                                ? this.state["facebook"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                          />
                                          <TextFieldGroup
                                            name="twitter"
                                            error={this.props.errors["twitter"]}
                                            placeholder="www.twitter.com/userID"
                                            label="Twitter"
                                            id="twitterID"
                                            value={
                                              this.state["twitter"]
                                                ? this.state["twitter"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                          />

                                          <TextFieldGroup
                                            name="instagram"
                                            error={
                                              this.props.errors["instagram"]
                                            }
                                            label="Instagram"
                                            id="instagramID"
                                            placeholder="www.instagram.com/userID"
                                            value={
                                              this.state["instagram"]
                                                ? this.state["instagram"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                          />

                                          <TextFieldGroup
                                            name="linkedin"
                                            error={
                                              this.props.errors["linkedin"]
                                            }
                                            label="Linkedin"
                                            id="linkedinID"
                                            placeholder="www.linkedin.com/userID"
                                            value={
                                              this.state["linkedin"]
                                                ? this.state["linkedin"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                          />

                                          <TextFieldGroup
                                            name="youtube"
                                            error={this.props.errors["youtube"]}
                                            label="Youtube"
                                            id="youtubeID"
                                            placeholder="www.youtube.com/UserID"
                                            value={
                                              this.state["youtube"]
                                                ? this.state["youtube"]
                                                : ""
                                            }
                                            onChange={this.onChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-sm-12">
                          <input
                            type="submit"
                            className="btn btn-success"
                            value="Actualizar información"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane show" id="documents" role="tabpanel">
                  <Ajustes />
                </div>

                <div
                  className="tab-pane show"
                  id="suscriptions"
                  role="tabpanel"
                >
                  {this.props.asociacion &&
                  this.props.asociacion.membresiaActiva &&
                  this.props.asociacion.membresiaActiva.rol &&
                  this.props.asociacion.membresiaActiva.rol.length > 0 &&
                  this.props.asociacion.membresiaActiva.rol[0].title ===
                    "Condómino" ? (
                    ""
                  ) : (
                    <Suscripciones />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      profileContent = <Spinner />;
    }

    return (
      <div className="row">
        <Sidebar />
        {modalContent}
        <main role="main" className="col-12 col-lg-10 ml-sm-auto px-4-5">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <h2>Perfil</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <strong>Perfil</strong>
                </li>
              </ol>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="progress"
                style={{
                  height: "1px",
                  opacity: percentage === 100 ? 0 : 1,
                }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
          </div>

          {profileContent}
        </main>
      </div>
    );
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  sendConfirmationEmail: PropTypes.func.isRequired,
  loadProfile: PropTypes.func.isRequired,
  loadMembresias: PropTypes.func.isRequired,

  addProfile: PropTypes.func.isRequired,
  validateExperience: PropTypes.func.isRequired,
  cellphoneVerification: PropTypes.func.isRequired,
  loadCountries: PropTypes.func.isRequired,
  loadStates: PropTypes.func.isRequired,
  loadCities: PropTypes.func.isRequired,
  catalog: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  asociacion: state.asociacion,
  auth: state.auth,
  errors: state.errors,
  education: state.education,
  experiences: state.experience,
  catalog: state.catalog,
});

export default connect(mapStateToProps, {
  getCurrentPage,
  uploadFile,
  sendConfirmationEmail,
  loadProfile,
  addProfile,
  loadCountries,
  loadStates,
  loadCities,
  validateExperience,
  cellphoneVerification,
  loadMembresias,
})(Profile);
