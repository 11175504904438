import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { loadCargosPendientes } from "../../actions/cuentaActions";

class CondominoCagosPendientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.asociacion &&
      this.props.asociacion !== prevProps.asociacion &&
      this.props.asociacion.membresiaActiva
    ) {
      if (
        this.props.asociacion.membresiaActiva.rol.length > 0 &&
        this.props.asociacion.membresiaActiva.rol[0].title === "Condómino"
      ) {
        this.props.loadCargosPendientes({
          destino:
            this.props.asociacion.membresiaActiva.asociacion.tipo ===
            "Fraccionamiento"
              ? this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                  .asociacion[0]._id
              : this.props.asociacion.membresiaActiva.asociacion.departamento[0]
                  ._id,
          page: "0",
          asociacion: this.props.asociacion.membresiaActiva.asociacion._id,
        });
      }
    }
  }

  render() {
    const { membresiaActiva } = this.props.asociacion;
    const { cargosCasa } = this.props.cuentas;

    if (!membresiaActiva && !cargosCasa) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12 mb-3">
        <div className="card">
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5>Cargos pendientes de pago</h5>
                  <hr></hr>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-borderless mb-0">
                      <thead className="border-bottom">
                        <tr className="small text-uppercase text-muted">
                          <th scope="col" style={{ width: "75%" }}>
                            Concepto
                          </th>
                          <th className="text-right" scope="col">
                            Estatus
                          </th>
                          <th className="text-right" scope="col">
                            Tipo
                          </th>
                          <th className="text-right" scope="col">
                            Monto
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cargosCasa.length > 0 &&
                          cargosCasa.map((cargo) => {
                            return (
                              <tr className="border-bottom" key={cargo._id}>
                                <td>
                                  <div className="font-weight-bold">
                                    {cargo.concepto}
                                  </div>
                                  <div className="small text-muted d-none d-md-block">
                                    {cargo.identificador} Cuota:{" "}
                                    {cargo.servicio.nombre} Monto:{" "}
                                    {cargo.servicio.costo}{" "}
                                  </div>
                                </td>
                                <td className="text-right">
                                  {cargo.estatus.nombre}
                                </td>
                                <td className="text-right">{cargo.tipo}</td>
                                <td className="text-right font-weight-bold">
                                  $ {cargo.monto}
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td className="text-right pb-0" colSpan="3">
                            <div className="text-uppercase small font-weight-700 text-muted">
                              Subtotal:
                            </div>
                          </td>
                          <td className="text-right pb-0">
                            <div className="h5 mb-0 font-weight-700">
                              $
                              {cargosCasa.length > 0
                                ? cargosCasa.reduce(
                                    (sum, { monto }) => sum + monto,
                                    0
                                  )
                                : "0"}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td className="text-right pb-0" colSpan="3">
                            <div className="text-uppercase small font-weight-700 text-muted">
                              Total:
                            </div>
                          </td>
                          <td className="text-right pb-0">
                            <div className="h5 mb-0 font-weight-700 text-green">
                              $
                              {cargosCasa.length > 0
                                ? cargosCasa.reduce(
                                    (sum, { monto }) => sum + monto,
                                    0
                                  )
                                : "0"}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CondominoCagosPendientes.propTypes = {
  asociacion: PropTypes.object.isRequired,
  cuentas: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  loadCargosPendientes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  cuentas: state.cuentas,
  errors: state.errors,
});

export default connect(mapStateToProps, { loadCargosPendientes })(
  CondominoCagosPendientes
);
