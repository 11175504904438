import React, { Component } from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { changePassword } from "../../actions/authActions";
import {
  getUsersPreference,
  updateUsersPreference,
} from "../../actions/profileActions";
import { NotificationManager } from "react-notifications";

class Ajustes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      password: "",
      oldPassword: "",
      confirmPassword: "",
      correosFeedback: false,
      correosNotificaciones: false,
      corresRecordatorios: false,
      correosProductos: false,
      correosNoticias: false,
      sms: false,
    };
    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeSubscriptions = this.onChangeSubscriptions.bind(this);
  }

  onChangeSubscriptions = (e) => {
    const toggleKey = (key) => (prevState) => {
      return { [key]: !prevState[key] };
    };

    const preserveKey = (key) => (prevState) => {
      return { [key]: prevState[key] };
    };

    if (
      !this.props.asociacion.membresiaActiva ||
      !this.props.asociacion.membresiaActiva.asociacion
    ) {
      NotificationManager.warning(
        "Debes de pertenecer a un fraccionamiento para cambiar tus suscripciones",
        "",
        10000
      );
    } else
      switch (e.target.name) {
        case "sms":
          if (!this.props.profile.profile.cellVerified) {
            NotificationManager.warning(
              "Para poder recibir notificaciones",
              "Debes de verificar tu celular",
              10000
            );
            this.setState(preserveKey(e.target.name));
          } else {
            this.props.updateUsersPreference({
              asociacionActiva: this.props.asociacion.membresiaActiva.asociacion
                ._id,
              tipoDeNotificacion: e.target.value,
              autorizacion: !this.state[e.target.name],
            });

            this.setState(toggleKey(e.target.name));
          }
          break;
        default:
          if (!this.props.profile.profile.user.emailVerified) {
            NotificationManager.warning(
              "Para poder recibir notificaciones",
              "Debes de verificar tu email",
              10000
            );
            this.setState(preserveKey(e.target.name));
          } else {
            this.props.updateUsersPreference({
              asociacionActiva: this.props.asociacion.membresiaActiva.asociacion
                ._id,
              tipoDeNotificacion: e.target.value,
              autorizacion: !this.state[e.target.name],
            });

            this.setState(toggleKey(e.target.name));
          }
      }
  };

  onSubmitChangePassword = (e) => {
    e.preventDefault();
    const data = {};

    data.oldPassword = this.state.oldPassword;
    data.password = this.state.password;
    data.confirmPassword = this.state.confirmPassword;

    this.props.changePassword(data);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.auth &&
      prevProps.auth &&
      this.props.auth.updated !== prevProps.auth.updated &&
      this.props.auth.updated === true
    ) {
      this.setState({ password: "", oldPassword: "", confirmPassword: "" });
      NotificationManager.success(
        "",
        "Contraseña actualizada correctamente",
        6000
      );
    }

    if (
      this.props.profile &&
      this.props.profile.usersPreference !== prevProps.profile.usersPreference
    ) {
      this.props.profile.usersPreference.notificacion.map((obj) => {
        return this.setState({
          [obj.tipoDeNotificacion.nombre]: obj.autorizacion,
        });
      });
    }

    if (
      this.props.asociacion &&
      this.props.asociacion.membresiaActiva &&
      this.props.asociacion.membresiaActiva !==
        prevProps.asociacion.membresiaActiva
    ) {
      this.props.getUsersPreference({
        asociacionActiva: this.props.asociacion.membresiaActiva.asociacion._id,
      });
    }
  }

  onChange = (e) => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  componentDidMount() {
    this.setState({
      sms: this.props.profile.profile.cellVerified ? true : false,
    });

    if (this.props.profile.profile.user.emailVerified) {
      this.setState({ correosFeedback: true });
      this.setState({ correosNotificaciones: true });
      this.setState({ corresRecordatorios: true });
      this.setState({ correosProductos: true });
      this.setState({ correosNoticias: true });
    }
  }

  render() {
    const { user } = this.props.auth;
    const { profile } = this.props.profile;
    const { errors } = this.props;

    if (!user || !profile) {
      return (
        <div className="timeline-item">
          <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              <h5 className="card-title">Cambiar contraseña</h5>
              <form onSubmit={this.onSubmitChangePassword}>
                <div className="form-group">
                  <label htmlFor="oldPasswordID">Contraseña anterior</label>
                  <input
                    type="password"
                    className={classnames("form-control", {
                      "is-invalid": errors.oldPassword,
                    })}
                    name="oldPassword"
                    id="oldPasswordID"
                    onChange={this.onChange}
                    value={this.state.oldPassword}
                    autoComplete="off"
                  />
                  {errors.oldPassword && (
                    <div className="invalid-feedback">{errors.oldPassword}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="passwordID">Nueva contraseña</label>
                  <input
                    type="password"
                    className={classnames("form-control", {
                      "is-invalid": errors.password,
                    })}
                    id="passwordID"
                    name="password"
                    onChange={this.onChange}
                    value={this.state.password}
                    autoComplete="off"
                  />
                  {errors["password"] && (
                    <div className="invalid-feedback">{errors["password"]}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPasswordID">
                    Confirmar nueva contraseña
                  </label>
                  <input
                    type="password"
                    className={classnames("form-control", {
                      "is-invalid": errors.confirmPassword,
                    })}
                    id="confirmPasswordID"
                    name="confirmPassword"
                    onChange={this.onChange}
                    value={this.state.confirmPassword}
                    autoComplete="off"
                  />
                  {errors["confirmPassword"] && (
                    <div className="invalid-feedback">
                      {errors["confirmPassword"]}
                    </div>
                  )}
                </div>
                <button type="submit" className="btn btn-primary">
                  Cambiar contraseña
                </button>
              </form>
              <hr></hr>
              <h5 className="card-title">Correo electrónico y SMS</h5>
              <h5
                className="card-subtitle"
                style={{ fontSize: "13px", marginBottom: "1rem" }}
              >
                Suscrito a:
              </h5>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  name="correosFeedback"
                  checked={this.state.correosFeedback}
                  value="5e9e833b11df090ed79e3545"
                  onChange={this.onChangeSubscriptions}
                />
                <label>Correos de feedback</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Ayúdanos a mejorar mediante tu retroalimentación en el servicio
              </small>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  name="correosNotificaciones"
                  checked={this.state.correosNotificaciones}
                  value="5e9e832a11df090ed79e3543"
                  onChange={this.onChangeSubscriptions}
                />
                <label>Correos de notificaciones</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Te notificaremos sobre la actividad relacionada a tu
                fraccionamiento
              </small>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  name="corresRecordatorios"
                  checked={this.state.corresRecordatorios}
                  value="5e9e82f511df090ed79e3541"
                  onChange={this.onChangeSubscriptions}
                />
                <label>Correos de recordatorios</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Te recordaremos de tareas pendientes
              </small>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.correosProductos}
                  value="5e9e82a54dd16108c404b427"
                  onChange={this.onChangeSubscriptions}
                  name="correosProductos"
                />
                <label>Correos de productos o servicios</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Correos de servicios o productos que puedes adquierir
              </small>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.correosNoticias}
                  value="5e9e5bec109097d05884cbc8"
                  onChange={this.onChangeSubscriptions}
                  name="correosNoticias"
                />
                <label>Correos de noticias</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Conoce de nuestras nuevas características
              </small>

              <div className="custom-checkbox preference-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.sms}
                  value="5e9e59e0b0cb7fc5f1032c11"
                  onChange={this.onChangeSubscriptions}
                  name="sms"
                />
                <label>Mensajes de texto</label>
              </div>
              <small className="form-text text-muted smallPreference">
                Notificaciones vía SMS
              </small>
            </div>
            <div className="card-footer text-muted"></div>
          </div>
        </div>
      </div>
    );
  }
}

Ajustes.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  asociacion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  getUsersPreference: PropTypes.func.isRequired,
  updateUsersPreference: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  asociacion: state.asociacion,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  changePassword,
  getUsersPreference,
  updateUsersPreference,
})(Ajustes);
