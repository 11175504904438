import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import currentPageReducer from "./currentPageReducer";

import transportesReducer from "./transportesReducer";
import catalogoReducer from "./catalogoReducer";
import asociacionReducer from "./asociacionReducer";
import cuentaReducer from "./cuentaReducer";
import landingReducer from "./landingReducer";
import notificacionReducer from "./notificacionReducer";
import azureReducer from "./azureReducer";
import estadisticaReducer from "./estadisticaReducer";
import stripeReducer from "./stripeReducer";

const appReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  page: currentPageReducer,
  transportes: transportesReducer,
  catalog: catalogoReducer,
  asociacion: asociacionReducer,
  cuentas: cuentaReducer,
  landing: landingReducer,
  notificaciones: notificacionReducer,
  azure: azureReducer,
  estadistica: estadisticaReducer,
  stripe: stripeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
