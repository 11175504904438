import React, { Component, Fragment } from "react";

import classnames from "classnames";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import "../../css/tabla.css";
import {
  sendInvitationAdmon,
  getEstadoDeCuenta,
} from "../../actions/asociacionActions";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es";

TimeAgo.addLocale(es);
const timeAgo = new TimeAgo("es-MX");

class InformacionCluster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      pagina: 1,
      valores: {},
      busqueda: "",
      usuariosAbierto: false,
      indexSeleccionado: -1,
      agregarCasa: false,
      agregarAdministrador: false,
      rolesAdmon: "0",
      nombreAdministrador: "",
      apellidoAdministrador: "",
      correoAdministrador: "",
    };
    this.check = [];
    this.onChange = this.onChange.bind(this);
    this.adelantarPagina = this.adelantarPagina.bind(this);
    this.atrasarPagina = this.atrasarPagina.bind(this);
    this.clicTabla = this.clicTabla.bind(this);
    this.actualizaPagina = this.actualizaPagina.bind(this);
    this.onClickAgregarCasa = this.onClickAgregarCasa.bind(this);
    this.onClickAgregarAdministrador = this.onClickAgregarAdministrador.bind(
      this
    );
    this.onSubmitAgregarAdministrador = this.onSubmitAgregarAdministrador.bind(
      this
    );
  }

  clicTabla = (e) => {
    this.setState({ celdaActiva: e });
  };

  onClickMostrarUsuarios = (e) => {
    this.setState({ usuariosAbierto: !this.state["usuariosAbierto"] }, () => {
      this.setState({
        indexSeleccionado: this.state["usuariosAbierto"] ? e : -1,
      });
    });
  };

  onClickAgregarCasa = (e) => {
    this.setState({ agregarCasa: !this.state["agregarCasa"] });
  };

  onClickAgregarAdministrador = (e) => {
    this.setState({
      agregarAdministrador: !this.state["agregarAdministrador"],
    });
  };

  conClickAgregarCluster(e) {
    e.preventDefault();
    window.abrirModal("agregarClusterModal");
  }

  componentDidMount() {}

  onSubmitAgregarAdministrador = (e) => {
    e.preventDefault();

    const invitation = {
      nombreAdministrador: this.state.nombreAdministrador,
      apellidoAdministrador: this.state.apellidoAdministrador,
      correoAdministrador: this.state.correoAdministrador,
      asociacion: this.props.clusterSeleccionado
        ? this.props.clusterSeleccionado
        : "",
      rolesAdmon: this.state.rolesAdmon,
    };
    this.props.sendInvitationAdmon(invitation);
  };

  adelantarPagina = (e) => {
    if (Math.ceil(this.props.cluster.areas.length / 5) > this.state.pagina) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina + 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  actualizaPagina = (e) => {
    this.setState({ pagina: parseInt(e.currentTarget.dataset.id, 10) });
  };

  atrasarPagina = (e) => {
    if (this.state.pagina !== 1) {
      this.setState(
        (prevState, e) => ({
          pagina: prevState.pagina - 1,
        }),
        () => {
          console.log("handleCommentSubmit AFTER", this.state.pagina);
        }
      );
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.cluster.areas.length !== this.props.cluster.areas.length &&
      this.props.cluster._id === prevProps.cluster._id
    ) {
      this.setState({ agregarCasa: !this.state["agregarCasa"] });
    }

    if (this.props.cluster !== prevProps.cluster) {
      this.setState({ pagina: 1 });
    }
  }

  render() {
    const { filasSeleccionadas, cluster, errors, catalog } = this.props;
    const {
      pagina,
      busqueda,
      celdaActiva,
      agregarCasa,
      agregarAdministrador,
    } = this.state;

    if (!cluster) {
      return <div>Loading...</div>;
    }

    return (
      <div className="col-12">
        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand">Casas</a>
          <div className="form-inline">
            <button
              className="btn btn-success btn-tabla"
              onClick={this.onClickAgregarCasa}
            >
              <i className="material-icons">&#xE147;</i> <span>Agregar</span>
            </button>
            <a
              href="#deleteObjectModal"
              className={
                filasSeleccionadas.length === 0
                  ? "btn btn-danger btn-tabla disabledBtn"
                  : "btn btn-danger btn-tabla"
              }
              data-toggle="modal"
            >
              <i className="material-icons">&#xE15C;</i> <span>Eliminar</span>
            </a>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
              name="busqueda"
              onChange={this.onChange}
            />
          </div>
        </nav>
        <div className="card">
          <div className="card-body">
            <div className="container list-asociaciones">
              <div className="row justify-content-md-center">
                <div
                  className={classnames("col-4 mb-3", {
                    ocultar: !agregarCasa,
                  })}
                >
                  <form
                    id="agregarCasaForm"
                    onSubmit={this.props.onSubmitAgregarCasa}
                  >
                    <div className="form-group">
                      <label htmlFor="nombreA">Nombre</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["nombre"],
                        })}
                        id="nombreA"
                        placeholder="Nombre"
                        name="nombreCasa"
                        value={this.props.nombreCasa}
                        onChange={this.props.onChange}
                      />

                      {errors["nombre"] && (
                        <div className="invalid-feedback">
                          {errors["nombre"]}
                        </div>
                      )}
                    </div>

                    <input
                      type="submit"
                      className="btn btn-info btn-block mt-4"
                      value="Agregar"
                    />
                  </form>
                </div>
                {cluster && cluster.areas.length > 0 ? (
                  <div className="col-12">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <span className="custom-checkbox">
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={(e) =>
                                  this.props.handleChangeChkAll(e, this.check)
                                }
                              />
                              <label htmlFor="selectAll" />
                            </span>
                          </th>

                          <th>Nombre</th>
                          <th>Estatus</th>
                          <th>Usuarios</th>
                          <th>Último pago</th>
                          <th style={{ minWidth: "150px" }}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cluster.areas
                          .filter((casa) =>
                            busqueda
                              ? casa.nombre
                                  .toUpperCase()
                                  .includes(busqueda.toUpperCase())
                              : true
                          )
                          .map((fila, index) => {
                            if (
                              index + 1 <= pagina * 5 &&
                              index + 1 > pagina * 5 - 5
                            )
                              return (
                                <Fragment key={fila._id}>
                                  <tr
                                    className={
                                      fila._id === celdaActiva
                                        ? "table-active"
                                        : ""
                                    }
                                    onDoubleClick={() =>
                                      this.onClickMostrarUsuarios(index)
                                    }
                                  >
                                    <td>
                                      <i
                                        className={classnames("fa", {
                                          "fa-caret-down":
                                            this.state.usuariosAbierto &&
                                            this.state.indexSeleccionado ===
                                              index,
                                          "fa-caret-right":
                                            this.state.indexSeleccionado !==
                                            index,
                                        })}
                                        onClick={() => {
                                          this.onClickMostrarUsuarios(index);
                                          this.props.getEstadoDeCuenta({
                                            asociacion: fila._id,
                                          });
                                        }}
                                      ></i>
                                    </td>
                                    <td>
                                      <span className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          name={fila._id}
                                          ref={(ref) =>
                                            (this.check[index] = ref)
                                          }
                                          value="1"
                                          onChange={this.props.handleChangeChk}
                                        />
                                        <label htmlFor="checkbox1" />
                                      </span>
                                    </td>
                                    <td>{fila.nombre}</td>
                                    <td>{fila.estatus.nombre}</td>
                                    <td>{fila.membresias.length}</td>
                                    <td>Sin pagos</td>
                                    <td>
                                      <a
                                        href="#addUser"
                                        className="add"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickAddUser(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Agregar"
                                        >
                                          add
                                        </i>
                                      </a>
                                      <a
                                        href="#editInstanciaModelo"
                                        className="edit"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickEditar(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Editar"
                                        >
                                          &#xE254;
                                        </i>
                                      </a>
                                      <a
                                        href="#deleteObjectModal"
                                        className="delete"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickEliminar(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Eliminar"
                                        >
                                          &#xE872;
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                  {this.state.usuariosAbierto &&
                                    this.state.indexSeleccionado === index && (
                                      <tr className="detalleCasas">
                                        <td colSpan="7">
                                          {fila.membresias.length === 0 ? (
                                            <div>
                                              <h5
                                                style={{ textAlign: "center" }}
                                              >
                                                No hay ningun usuario registrado
                                                en esta casa
                                              </h5>
                                            </div>
                                          ) : (
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  <th></th>
                                                  <th>Nombre</th>
                                                  <th>Correo</th>
                                                  <th>Usuario desde</th>
                                                  <th>Último login</th>
                                                  <th>Acciones</th>
                                                </tr>
                                              </thead>
                                              <tbody></tbody>
                                              {fila.membresias.map(
                                                (memb, fila) => {
                                                  return (
                                                    <tr key={memb._id}>
                                                      <td></td>
                                                      <td></td>
                                                      <td>
                                                        {memb.usuario.name}{" "}
                                                        {memb.usuario.lastName}
                                                      </td>
                                                      <td>
                                                        {memb.usuario.email}
                                                      </td>

                                                      <td>
                                                        {timeAgo.format(
                                                          new Date(memb.created)
                                                        )}
                                                      </td>
                                                      <td>
                                                        {timeAgo.format(
                                                          new Date(
                                                            memb.usuario.lastLogin
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                        <a
                                                          className="delete"
                                                          onClick={() => {
                                                            this.props.onClickEliminarMembresia(
                                                              memb._id
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="material-icons"
                                                            data-toggle="tooltip"
                                                            title="Eliminar"
                                                          >
                                                            &#xE872;
                                                          </i>
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </table>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                </Fragment>
                              );
                            else return "";
                          })}
                      </tbody>
                    </table>

                    <div className="clearfix">
                      <div className="hint-text">
                        Mostrando{" "}
                        <b>
                          {Math.ceil(
                            cluster.areas.filter((depa) =>
                              busqueda
                                ? depa.nombre
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length / 5
                          ) === pagina
                            ? cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length %
                                5 ===
                              0
                              ? 5
                              : cluster.areas.filter((depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length % 5
                            : cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length === 0
                            ? "0"
                            : "5"}
                        </b>{" "}
                        de{" "}
                        <b>
                          {
                            cluster.areas.filter((depa) =>
                              busqueda
                                ? depa.nombre
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length
                          }
                        </b>{" "}
                        registros
                      </div>
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className={
                              pagina === 1 ? "page-link disabled" : "page-link"
                            }
                            onClick={this.atrasarPagina}
                          >
                            Anterior
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length / 5
                            ),
                          },
                          (item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  pagina === i + 1
                                    ? "page-item active"
                                    : "page-item"
                                }
                              >
                                <button
                                  className="page-link"
                                  onClick={this.actualizaPagina}
                                  data-id={i + 1}
                                >
                                  {i + 1}
                                </button>
                              </li>
                            );
                          }
                        )}

                        <li className="page-item">
                          <button
                            onClick={this.adelantarPagina}
                            className={
                              Math.ceil(
                                cluster.areas.filter((depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length / 5
                              ) <= pagina
                                ? "page-link disabled"
                                : "page-link"
                            }
                          >
                            Siguiente
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    Aún no tienes casas registradas, agrega la primera!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <br />

        <nav className="navbar navbar-light bg-light">
          <a className="navbar-brand">Administradores</a>
          <div className="form-inline">
            <button
              className="btn btn-success btn-tabla"
              onClick={this.onClickAgregarAdministrador}
            >
              <i className="material-icons">&#xE147;</i> <span>Agregar</span>
            </button>
            <a
              href="#deleteObjectModal"
              className={
                filasSeleccionadas.length === 0
                  ? "btn btn-danger btn-tabla disabledBtn"
                  : "btn btn-danger btn-tabla"
              }
              data-toggle="modal"
            >
              <i className="material-icons">&#xE15C;</i> <span>Eliminar</span>
            </a>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Buscar"
              aria-label="Search"
              name="busqueda"
              /* onChange={this.onChange} */
            />
          </div>
        </nav>
        <div className="card">
          <div className="card-body">
            <div className="container list-asociaciones">
              <div className="row justify-content-md-center">
                <div
                  className={classnames("col-4 mb-3", {
                    ocultar: !agregarAdministrador,
                  })}
                >
                  <form
                    id="agregarAdministradorForm"
                    onSubmit={this.onSubmitAgregarAdministrador}
                  >
                    <div className="form-group">
                      <label htmlFor="correoAdministrador">
                        Correo del administrador
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["correoAdministrador"],
                        })}
                        id="correoAdministrador"
                        placeholder="Correo electrónico"
                        name="correoAdministrador"
                        value={this.state.correoAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["correoAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["correoAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="nombreAdministrador">Nombre</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["nombreAdministrador"],
                        })}
                        id="nombreAdministrador"
                        placeholder="Nombre"
                        name="nombreAdministrador"
                        value={this.state.nombreAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["nombreAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["nombreAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="apellidoAdministrador">Apellido</label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": errors["apellidoAdministrador"],
                        })}
                        id="apellidoAdministrador"
                        placeholder="Apellido"
                        name="apellidoAdministrador"
                        value={this.state.apellidoAdministrador}
                        onChange={this.onChange}
                      />

                      {errors["apellidoAdministrador"] && (
                        <div className="invalid-feedback">
                          {errors["apellidoAdministrador"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="rolesAdmon">Rol</label>
                      <select
                        className={classnames("form-control", {
                          "is-invalid": errors["rol"],
                        })}
                        id="rolesAdmon"
                        name="rolesAdmon"
                        onChange={this.onChange}
                        value={this.state.rolesAdmon}
                      >
                        <option value="0">Elige un rol</option>
                        {this.props.catalog.rolesAdmon &&
                          this.props.catalog.rolesAdmon.map((tipo, i) => {
                            return (
                              <option key={tipo._id} value={tipo._id}>
                                {tipo.title}
                              </option>
                            );
                          })}
                      </select>
                      {errors["rol"] && (
                        <div className="invalid-feedback">{errors["rol"]}</div>
                      )}
                    </div>

                    <input
                      type="submit"
                      className="btn btn-info btn-block mt-4"
                      value="Agregar"
                    />
                  </form>
                </div>
                {cluster && cluster.areas.length > 0 ? (
                  <div className="col-12">
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            <span className="custom-checkbox">
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={(e) =>
                                  this.props.handleChangeChkAll(e, this.check)
                                }
                              />
                              <label htmlFor="selectAll" />
                            </span>
                          </th>

                          <th>Nombre</th>
                          <th>Estatus</th>
                          <th>Usuarios</th>
                          <th>Último pago</th>
                          <th style={{ minWidth: "150px" }}>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cluster.areas
                          .filter((casa) =>
                            busqueda
                              ? casa.nombre
                                  .toUpperCase()
                                  .includes(busqueda.toUpperCase())
                              : true
                          )
                          .map((fila, index) => {
                            if (
                              index + 1 <= pagina * 5 &&
                              index + 1 > pagina * 5 - 5
                            )
                              return (
                                <Fragment key={fila._id}>
                                  <tr
                                    className={
                                      fila._id === celdaActiva
                                        ? "table-active"
                                        : ""
                                    }
                                    onDoubleClick={() =>
                                      this.onClickMostrarUsuarios(index)
                                    }
                                  >
                                    <td>
                                      <i
                                        className={classnames("fa", {
                                          "fa-caret-down":
                                            this.state.usuariosAbierto &&
                                            this.state.indexSeleccionado ===
                                              index,
                                          "fa-caret-right":
                                            this.state.indexSeleccionado !==
                                            index,
                                        })}
                                        onClick={() =>
                                          this.onClickMostrarUsuarios(index)
                                        }
                                      ></i>
                                    </td>
                                    <td>
                                      <span className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          name={fila._id}
                                          ref={(ref) =>
                                            (this.check[index] = ref)
                                          }
                                          value="1"
                                          onChange={this.props.handleChangeChk}
                                        />
                                        <label htmlFor="checkbox1" />
                                      </span>
                                    </td>
                                    <td>{fila.nombre}</td>
                                    <td>{fila.estatus.nombre}</td>
                                    <td>{fila.membresias.length}</td>
                                    <td>Sin pagos</td>
                                    <td>
                                      <a
                                        href="#addUser"
                                        className="add"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickAddUser(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Agregar"
                                        >
                                          add
                                        </i>
                                      </a>
                                      <a
                                        href="#editInstanciaModelo"
                                        className="edit"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickEditar(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Editar"
                                        >
                                          &#xE254;
                                        </i>
                                      </a>
                                      <a
                                        href="#deleteObjectModal"
                                        className="delete"
                                        data-toggle="modal"
                                        onClick={() => {
                                          this.props.onClickEliminar(fila._id);
                                        }}
                                      >
                                        <i
                                          className="material-icons"
                                          data-toggle="tooltip"
                                          title="Eliminar"
                                        >
                                          &#xE872;
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                  {this.state.usuariosAbierto &&
                                    this.state.indexSeleccionado === index && (
                                      <tr className="detalleCasas">
                                        <td colSpan="7">
                                          {fila.membresias.length === 0 ? (
                                            <div>
                                              <h5
                                                style={{ textAlign: "center" }}
                                              >
                                                No hay ningun usuario registrado
                                                en esta casa
                                              </h5>
                                            </div>
                                          ) : (
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  <th></th>
                                                  <th>Nombre</th>
                                                  <th>Correo</th>
                                                  <th>Usuario desde</th>
                                                  <th>Último login</th>
                                                  <th>Acciones</th>
                                                </tr>
                                              </thead>
                                              <tbody></tbody>
                                              {fila.membresias.map(
                                                (memb, fila) => {
                                                  return (
                                                    <tr key={memb._id}>
                                                      <td></td>
                                                      <td></td>
                                                      <td>
                                                        {memb.usuario.name}{" "}
                                                        {memb.usuario.lastName}
                                                      </td>
                                                      <td>
                                                        {memb.usuario.email}
                                                      </td>

                                                      <td>
                                                        {timeAgo.format(
                                                          new Date(memb.created)
                                                        )}
                                                      </td>
                                                      <td>
                                                        {timeAgo.format(
                                                          new Date(
                                                            memb.usuario.lastLogin
                                                          )
                                                        )}
                                                      </td>
                                                      <td>
                                                        <a
                                                          className="delete"
                                                          onClick={() => {
                                                            this.props.onClickEliminarMembresia(
                                                              memb._id
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            className="material-icons"
                                                            data-toggle="tooltip"
                                                            title="Eliminar"
                                                          >
                                                            &#xE872;
                                                          </i>
                                                        </a>
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </table>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                </Fragment>
                              );
                            else return "";
                          })}
                      </tbody>
                    </table>

                    <div className="clearfix">
                      <div className="hint-text">
                        Mostrando{" "}
                        <b>
                          {Math.ceil(
                            cluster.areas.filter((depa) =>
                              busqueda
                                ? depa.nombre
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length / 5
                          ) === pagina
                            ? cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length %
                                5 ===
                              0
                              ? 5
                              : cluster.areas.filter((depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length % 5
                            : cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length === 0
                            ? "0"
                            : "5"}
                        </b>{" "}
                        de{" "}
                        <b>
                          {
                            cluster.areas.filter((depa) =>
                              busqueda
                                ? depa.nombre
                                    .toUpperCase()
                                    .includes(busqueda.toUpperCase())
                                : true
                            ).length
                          }
                        </b>{" "}
                        registros
                      </div>
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className={
                              pagina === 1 ? "page-link disabled" : "page-link"
                            }
                            onClick={this.atrasarPagina}
                          >
                            Anterior
                          </button>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(
                              cluster.areas.filter((depa) =>
                                busqueda
                                  ? depa.nombre
                                      .toUpperCase()
                                      .includes(busqueda.toUpperCase())
                                  : true
                              ).length / 5
                            ),
                          },
                          (item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  pagina === i + 1
                                    ? "page-item active"
                                    : "page-item"
                                }
                              >
                                <button
                                  className="page-link"
                                  onClick={this.actualizaPagina}
                                  data-id={i + 1}
                                >
                                  {i + 1}
                                </button>
                              </li>
                            );
                          }
                        )}

                        <li className="page-item">
                          <button
                            onClick={this.adelantarPagina}
                            className={
                              Math.ceil(
                                cluster.areas.filter((depa) =>
                                  busqueda
                                    ? depa.nombre
                                        .toUpperCase()
                                        .includes(busqueda.toUpperCase())
                                    : true
                                ).length / 5
                              ) <= pagina
                                ? "page-link disabled"
                                : "page-link"
                            }
                          >
                            Siguiente
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="col-12">
                    Aún no tienes casas registradas, agrega la primera!
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InformacionCluster.propTypes = {
  asociacion: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  sendInvitationAdmon: PropTypes.func.isRequired,
  getEstadoDeCuenta: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  asociacion: state.asociacion,
  catalog: state.catalog,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  sendInvitationAdmon,
  getEstadoDeCuenta,
})(InformacionCluster);
