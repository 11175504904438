import { LOAD_NOTIFICACIONES } from "../actions/types";

const initialState = {
  notificaciones: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_NOTIFICACIONES:
      return {
        ...state,
        notificaciones: action.payload,
      };
    default:
      return state;
  }
}
