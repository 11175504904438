import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { clearCurrentProfile } from "../../actions/profileActions";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    const { isAuthenticated } = this.props.auth;
    const { currentPage } = this.props.page;

    return (
      <footer>
        <div
          className={`${
            isAuthenticated &&
            currentPage !== "/confirmation" &&
            currentPage !== "/notificaciones"
              ? "container-fluid"
              : "container"
          }`}
        >
          <div
            className={`${
              isAuthenticated &&
              currentPage !== "/confirmation" &&
              currentPage !== "/notificaciones"
                ? "row justify-content-end"
                : "row"
            }`}
          >
            <div
              className={`${
                isAuthenticated &&
                currentPage !== "/confirmation" &&
                currentPage !== "/notificaciones"
                  ? "col-12 col-md-10"
                  : "col-12"
              }`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <span className="copyright">
                      Copyright &copy; Wirhub {new Date().getFullYear()}{" "}
                    </span>
                  </div>
                  <div className="col-md-4">
                    <ul className="list-inline social-buttons">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://twitter.com/WirhubOficial"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/wirhub"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/wirhubmx/"
                        >
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="list-inline quicklinks">
                      <li className="list-inline-item">
                        <Link to="/privacidad">Política de privacidad</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  page: state.page,
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentProfile,
})(Footer);
